export function File(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="45"
      height="44"
      viewBox="0 0 45 44"
      fill="none"
      {...props}
    >
      <path
        d="M6.39768 14.3954C5.14747 14.3954 4.13281 13.3807 4.13281 12.1305C4.13281 8.38293 7.18283 5.33594 10.9274 5.33594H26.0265C27.2767 5.33594 28.2914 6.3506 28.2914 7.6008C28.2914 8.85101 27.2767 9.86567 26.0265 9.86567H10.9274C9.68022 9.86567 8.66254 10.8803 8.66254 12.1305C8.66254 13.3807 7.64788 14.3954 6.39768 14.3954Z"
        fill="currentColor"
      />
      <path
        d="M35.086 14.3955H10.9274C9.68022 14.3955 8.66254 13.3808 8.66254 12.1306C8.66254 10.8804 7.64788 9.86572 6.39768 9.86572C5.14747 9.86572 4.13281 10.8804 4.13281 12.1306V30.2495C4.13281 34.8306 7.85625 38.554 12.4373 38.554H35.086C38.0001 38.554 40.3706 36.1834 40.3706 33.2693V19.6801C40.3706 16.766 38.0001 14.3955 35.086 14.3955ZM28.2914 29.4945C26.6244 29.4945 25.2715 28.1417 25.2715 26.4747C25.2715 24.8078 26.6244 23.4549 28.2914 23.4549C29.9583 23.4549 31.3112 24.8078 31.3112 26.4747C31.3112 28.1417 29.9583 29.4945 28.2914 29.4945Z"
        fill="currentColor"
      />
    </svg>
  );
}
