export function Circles(props) {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="Frame">
        <g id="Group">
          <path
            id="Vector"
            d="M13.5554 19.1731C15.0895 19.1731 16.3331 17.9295 16.3331 16.3954C16.3331 14.8612 15.0895 13.6176 13.5554 13.6176C12.0212 13.6176 10.7776 14.8612 10.7776 16.3954C10.7776 17.9295 12.0212 19.1731 13.5554 19.1731Z"
            fill="currentColor"
          />
          <path
            id="Vector_2"
            d="M14.1109 11.9509C16.7189 11.9509 18.8331 9.8367 18.8331 7.22869C18.8331 4.62068 16.7189 2.50647 14.1109 2.50647C11.5029 2.50647 9.38867 4.62068 9.38867 7.22869C9.38867 9.8367 11.5029 11.9509 14.1109 11.9509Z"
            fill="currentColor"
          />
          <path
            id="Vector_3"
            d="M5.49984 16.3954C7.34079 16.3954 8.83317 14.903 8.83317 13.062C8.83317 11.2211 7.34079 9.7287 5.49984 9.7287C3.65889 9.7287 2.1665 11.2211 2.1665 13.062C2.1665 14.903 3.65889 16.3954 5.49984 16.3954Z"
            fill="currentColor"
          />
        </g>
      </g>
    </svg>
  );
}
