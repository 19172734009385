import { IconSettings } from '../../settings/icons';

export function Schema() {
  return {
    label: 'Hero Carousel',
    key: 'hero-carousel',
    fields: [
      {
        label: 'Icon',
        name: 'icon',
        component: 'select',
        defaultValue: 'shop',
        options: [
          {
            label: 'None',
            value: '',
          },
          {
            label: 'Shop',
            value: 'shop',
          },
          {
            label: 'Storefront',
            value: 'storefront',
          },
        ],
      },
      {
        label: 'Heading',
        name: 'heading',
        component: 'text',
        defaultValue: 'Turn more ad clicks into customers.',
      },
      {
        label: 'Description',
        name: 'description',
        component: 'textarea',
        defaultValue:
          'Meet the page builder with super powers that gives you full control over your funnel.',
      },
      {
        label: 'Buttons',
        name: 'buttons',
        component: 'group-list',
        fields: [
          {
            label: 'Link',
            name: 'link',
            component: 'link',
          },
          IconSettings,
          {
            label: 'Icon Position',
            name: 'iconPosition',
            component: 'select',
            defaultValue: 'left',
            options: [
              { label: 'Left', value: 'left' },
              { label: 'Right', value: 'right' },
            ],
          },
        ],
        maxItems: 2,
        itemProps: {
          label: '{{item.link.text}}',
        },
        defaultItem: {
          link: {
            text: 'Button',
            url: '/',
          },
          iconPosition: 'left',
        },
      },
      {
        label: 'Screens',
        name: 'screens',
        component: 'group-list',
        itemProps: {
          label: '{{item.image.filename}}',
        },
        fields: [
          {
            label: 'Image',
            name: 'image',
            component: 'image',
          },
          {
            label: 'Heading',
            name: 'heading',
            component: 'text',
          },
        ],
      },
    ],
  };
}
