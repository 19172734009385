import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode, Scrollbar } from 'swiper';
import { Schema } from './FeatureCarousel.schema';

import { Link } from '../../snippets/Link';
import { Section } from '../../snippets';

export function FeatureCarousel({ cms }) {
  const { heading, slides } = cms;
  return (
    <Section cms={cms}>
      <div className="bg-white py-16 md:py-20">
        {heading && (
          <h2 className="text-center text-black selection:bg-black selection:text-brand">
            {heading}
          </h2>
        )}
        <div className="overflow-hidden px-4 md:px-16">
          <Swiper
            scrollbar={{ hide: false }}
            slidesPerView="auto"
            spaceBetween={12}
            freeMode
            modules={[FreeMode, Scrollbar]}
            className="w-full !overflow-visible !pt-14 !pb-10"
            breakpoints={{
              768: {
                spaceBetween: 24,
              },
            }}
          >
            {slides?.length > 0 &&
              slides.map((slide, index) => {
                return (
                  <SwiperSlide
                    key={`featureCarouselSlide-${index}`}
                    className={`${
                      slide.type === 'single'
                        ? '!h-[275px] !w-[300px] md:!h-[330px] md:!w-[360px]'
                        : '!h-[275px] !w-[564px] md:!h-[330px] md:!w-[675px]'
                    } overflow-hidden rounded-2xl text-black md:rounded-[20px]`}
                    style={{
                      background:
                        'linear-gradient(0deg, rgba(217, 245, 238, 0.5), rgba(217, 245, 238, 0.5)), radial-gradient(147.62% 180.53% at 49.96% -42.8%, rgba(255, 255, 255, 0) 37.41%, rgba(16, 168, 117, 0.25) 100%), linear-gradient(0deg, rgba(255, 255, 255, 0.25), rgba(255, 255, 255, 0.25))',
                    }}
                  >
                    <Link
                      className={`${
                        slide.type === 'double-half'
                          ? 'grid-cols-2'
                          : 'grid-cols-1'
                      } grid h-full`}
                      href={slide.link?.url}
                      newTab={slide.link?.newTab}
                    >
                      <div className="flex flex-col">
                        <div className="flex items-center justify-between px-6 pt-5 pb-5 md:px-[30px] md:pt-[26px]">
                          {slide.link.text && (
                            <p className="h4 text-[18px] md:text-[22px]">
                              {slide.link.text}
                            </p>
                          )}
                          <img
                            src="svgs/circle-arrow-right.svg"
                            alt="Arrow pointing right"
                            className="h-5 w-5 opacity-30 md:h-6 md:w-6"
                          />
                        </div>
                        <div
                          className={`${
                            slide.imagePosition === 'center'
                              ? 'px-6 md:px-[30px]'
                              : ''
                          } flex h-full w-full items-center justify-center overflow-hidden`}
                        >
                          {slide.image && (
                            <img
                              src={slide.image.src}
                              alt={slide.imageAlt}
                              className={`${
                                slide.imagePosition === 'fill'
                                  ? 'h-full w-full object-cover object-top'
                                  : 'max-w-full'
                              }`}
                            />
                          )}
                        </div>
                      </div>
                      {slide.type === 'double-half' && slide.halfImage && (
                        <div className="flex h-full w-full">
                          <img
                            src={slide.halfImage.src}
                            alt={slide.halfImageAlt}
                            className="object-cover"
                          />
                        </div>
                      )}
                    </Link>
                  </SwiperSlide>
                );
              })}
          </Swiper>
        </div>
      </div>
    </Section>
  );
}

FeatureCarousel.displayName = 'FeatureCarousel';
FeatureCarousel.Schema = Schema;
