import { useArticle } from '@backpackjs/storefront';
import { useState } from 'react';
import { Schema } from './ArticleHeaderImage.schema';
import { Icons } from '../../snippets/Icons';

import { Section } from '../../snippets';

export function ArticleHeaderImage({ cms }) {
  const article = useArticle();
  const [copied, setCopied] = useState(false);

  if (!article) return null;

  const date = new Date(article.publishedAt || article.createdAt);

  function copyLink() {
    navigator.clipboard.writeText(window.location.href).then(() => {
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 3000);
    });
  }

  return (
    <Section cms={cms}>
      <div className="flex flex-col gap-8">
        {article.seo.image ? (
          <div className="overflow-hidden rounded-2xl">
            <img src={article.seo.image} alt={article.title} />
          </div>
        ) : null}
        {cms.showArticleDetails ? (
          <div className="flex w-full gap-16">
            <div className="flex flex-col gap-3">
              <p className="text-sm font-semibold text-brand">Written by</p>
              <p className="text-lg font-medium">{article.author}</p>
            </div>
            <div className="flex flex-col gap-3">
              <p className="text-sm font-semibold text-brand">Published on</p>
              <p className="text-lg font-medium">
                {date.toLocaleDateString('en-US', {
                  day: 'numeric',
                })}{' '}
                {date.toLocaleDateString('en-US', { month: 'short' })}{' '}
                {date.toLocaleDateString('en-US', { year: 'numeric' })}
              </p>
            </div>
            <div className="relative ml-auto">
              <button
                type="button"
                className="btn-primary text-white"
                onClick={copyLink}
              >
                {copied ? (
                  <>
                    <Icons icon="checkmark" />
                    Copied!
                  </>
                ) : (
                  <>
                    <Icons icon="copy" />
                    Copy Link
                  </>
                )}
              </button>
            </div>
          </div>
        ) : null}
      </div>
    </Section>
  );
}

ArticleHeaderImage.displayName = 'ArticleHeaderImage';
ArticleHeaderImage.Schema = Schema;
