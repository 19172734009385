import { useMemo } from 'react';
import { useRouter } from '@backpackjs/storefront';
import { useMediaPredicate } from 'react-media-hook';

import { useGlobalContext } from '../../contexts/globalContext';

import { FeaturedTestimonial } from './FeaturedTestimonial';
import { TestimonialMasonryModal } from './TestimonialMasonryModal';

import { Schema } from './TestimonialMasonry.schema';

import { Section } from '../../snippets';

function splitIntoArrays(items, numArrays) {
  if (numArrays <= 0) {
    throw new Error('Number of arrays should be greater than zero');
  }

  // Initialize the array of arrays
  const arrays = Array.from({ length: numArrays }, () => []);

  // Distribute items into arrays
  for (let i = 0; i < items.length; i++) {
    const index = i % numArrays;
    const item = { ...items[i], index: i };
    arrays[index].push(item);
  }

  return arrays;
}

export function TestimonialMasonry({ cms }) {
  const {
    actions: { openModal },
  } = useGlobalContext();
  const router = useRouter();
  const { heading, testimonials } = cms;

  const isDesktop = useMediaPredicate('(min-width: 984px)');

  const arrayOfGrids = useMemo(() => {
    return splitIntoArrays(testimonials, isDesktop ? 3 : 2);
  }, [testimonials, isDesktop]);

  return (
    <Section cms={cms}>
      <div className="mx-auto max-w-7xl py-16 px-4">
        {/* Heading */}
        {heading && (
          <h2 className="h2 mx-auto my-16 max-w-3xl px-4 text-center max-md:text-[44px] md:px-0">
            {heading}
          </h2>
        )}

        <div className="mx-auto grid gap-6 md:grid-cols-2 lg:grid-cols-3">
          {arrayOfGrids?.map((grid, gridIndex) => {
            return (
              <ul className="flex flex-col gap-6" key={gridIndex}>
                {grid?.map((item, columnIndex) => {
                  const {
                    featuredTestimonial,
                    quote,
                    profile,
                    name,
                    title,
                    date,
                  } = {
                    ...item,
                  };

                  return (
                    <li key={columnIndex}>
                      <div
                        className={`rounded-[20px] border border-grey-900 bg-[#060606] p-6 ${
                          featuredTestimonial?.enable
                            ? 'testimonial-black-green-white-gradient'
                            : ''
                        }`}
                        role="button"
                        aria-hidden="true"
                        onClick={() => {
                          if (
                            featuredTestimonial?.enable &&
                            featuredTestimonial?.link?.url
                          ) {
                            router.push(featuredTestimonial.link.url);
                            return;
                          }
                          openModal(
                            <TestimonialMasonryModal
                              item={item}
                              testimonials={testimonials}
                            />,
                            {
                              className: '!max-w-[850px]',
                            }
                          );
                        }}
                      >
                        {featuredTestimonial?.enable ? (
                          <FeaturedTestimonial
                            featuredTestimonial={featuredTestimonial}
                            quote={quote}
                          />
                        ) : (
                          <>
                            <blockquote className="mb-4">
                              <p className="text-base font-light leading-[1.6] text-[#ffffffbf]">
                                {quote}
                              </p>
                            </blockquote>

                            <div className="flex gap-[10px] md:items-end">
                              {profile?.src && (
                                <div className="flex h-12 w-12 overflow-hidden rounded-full">
                                  <img
                                    className="object-cover"
                                    src={profile.src}
                                    alt={name}
                                  />
                                </div>
                              )}

                              <div className="flex min-w-fit flex-col">
                                {name && (
                                  <div className="text-[15px] leading-6">
                                    {name}
                                  </div>
                                )}

                                <div className="flex items-center">
                                  {title && (
                                    <p className="text-[13px] leading-6 text-[#ffffffa6]">
                                      {title}
                                    </p>
                                  )}

                                  {date && (
                                    <>
                                      <span className="mx-2 text-base font-normal leading-6 text-[#ffffffa6]">
                                        •
                                      </span>
                                      <p className="text-[13px] leading-6 text-[#ffffffa6]">
                                        {date}
                                      </p>
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </li>
                  );
                })}
              </ul>
            );
          })}
        </div>
      </div>
    </Section>
  );
}

TestimonialMasonry.displayName = 'TestimonialMasonry';
TestimonialMasonry.Schema = Schema;
