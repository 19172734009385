import { Schema } from './ArticleFooterActions.schema';
import { Icons } from '../../snippets/Icons';

import { Link } from '../../snippets/Link';
import { Section } from '../../snippets';

export function ArticleFooterActions({ cms }) {
  const { heading, description, buttons } = cms;
  return (
    <Section cms={cms}>
      <div className="py-12 md:py-24">
        <div className="mx-auto max-w-7xl px-4 md:px-8">
          <div className="black-to-green-gradient flex flex-col items-center gap-8 rounded-[20px] p-8 md:p-16">
            <div className="flex flex-col gap-3 text-white">
              {heading ? (
                <h2 className="text-center text-4xl">{heading}</h2>
              ) : null}
              {description ? (
                <p className="text-center text-xl">{description}</p>
              ) : null}
            </div>
            {buttons.length > 0 && (
              <div className="flex flex-col-reverse gap-3 md:flex-row md:justify-center">
                {buttons.map((button, index) => {
                  return (
                    <Link
                      className={button.type}
                      key={`articleFooterButton-${index}`}
                      href={button.link?.url}
                      newTab={button.link?.newTab}
                    >
                      {button.icon && <Icons icon={button.icon} />}
                      {button.link.text}
                    </Link>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      </div>
    </Section>
  );
}

ArticleFooterActions.displayName = 'ArticleFooterActions';
ArticleFooterActions.Schema = Schema;
