export function LinkedTriangle(props) {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.9998 1.12988C9.24248 1.12988 6.99981 3.37255 6.99981 6.12988C6.99981 7.58588 7.63848 8.95788 8.71314 9.89788L4.85314 16.5832C4.04114 16.6939 3.40381 17.3632 3.40381 18.2059C3.40381 19.1259 4.15048 19.8725 5.07048 19.8725C5.99048 19.8725 6.73714 19.1259 6.73714 18.2059C6.73714 17.9739 6.68914 17.7525 6.60248 17.5525L10.8971 10.1139C11.0305 9.88322 11.0665 9.60855 10.9958 9.35122C10.9251 9.09388 10.7558 8.87522 10.5238 8.74322C9.58248 8.21122 8.99848 7.20988 8.99848 6.13122C8.99848 4.47655 10.3438 3.13122 11.9985 3.13122C13.6531 3.13122 14.9985 4.47655 14.9985 6.13122C14.9985 6.20988 14.9958 6.28855 14.9891 6.36588C14.9438 6.91655 15.3545 7.39788 15.9051 7.44322C16.4545 7.48588 16.9385 7.07922 16.9825 6.52722C16.9931 6.39655 16.9985 6.26455 16.9985 6.13122C16.9985 3.37388 14.7558 1.13122 11.9985 1.13122L11.9998 1.12988Z"
        fill="currentColor"
      />
      <path
        d="M20.4413 17.3365C19.9813 16.5391 18.9613 16.2658 18.1639 16.7258C17.9639 16.8418 17.7973 16.9925 17.6666 17.1671H9.07326C8.80659 17.1671 8.55059 17.2738 8.36259 17.4631C8.17459 17.6525 8.07059 17.9085 8.07326 18.1765C8.08259 19.2578 7.50792 20.2631 6.57326 20.8031C5.14259 21.6298 3.30259 21.1378 2.47459 19.7058C1.64792 18.2725 2.14126 16.4338 3.57326 15.6071C3.64392 15.5671 3.71326 15.5298 3.78526 15.4965C4.28392 15.2578 4.49326 14.6605 4.25459 14.1631C4.01592 13.6645 3.41859 13.4551 2.92126 13.6938C2.80526 13.7498 2.68926 13.8098 2.57459 13.8765C0.185257 15.2525 -0.636076 18.3165 0.74259 20.7045C1.66792 22.3071 3.35192 23.2031 5.08126 23.2031C5.92926 23.2031 6.78792 22.9871 7.57326 22.5338C8.83459 21.8058 9.70259 20.5671 9.97992 19.1671H17.6999C18.2026 19.8178 19.1013 20.0351 19.8319 19.6125C20.6293 19.1525 20.9026 18.1325 20.4426 17.3351L20.4413 17.3365Z"
        fill="currentColor"
      />
      <path
        d="M23.8239 16.8726C23.4786 15.5819 22.6506 14.5046 21.4933 13.8366C20.2319 13.1086 18.7239 12.9752 17.3733 13.4366L13.5319 6.78325C13.6173 6.58191 13.6666 6.36191 13.6666 6.12858C13.6666 5.20858 12.9199 4.46191 11.9999 4.46191C11.0799 4.46191 10.3333 5.20858 10.3333 6.12858C10.3333 6.97258 10.9693 7.64058 11.7826 7.75125L16.0946 15.2206C16.2279 15.4512 16.4479 15.6192 16.7053 15.6872C16.9639 15.7552 17.2373 15.7166 17.4666 15.5819C18.3973 15.0312 19.5573 15.0259 20.4933 15.5672C21.1879 15.9686 21.6839 16.6152 21.8906 17.3886C22.0986 18.1632 21.9919 18.9712 21.5906 19.6659C20.7639 21.0992 18.9239 21.5899 17.4946 20.7659C17.4253 20.7259 17.3573 20.6832 17.2933 20.6379C16.8386 20.3246 16.2146 20.4392 15.9026 20.8939C15.5906 21.3486 15.7039 21.9712 16.1586 22.2846C16.2653 22.3579 16.3773 22.4272 16.4933 22.4952C17.2786 22.9486 18.1359 23.1632 18.9839 23.1632C20.7133 23.1632 22.3986 22.2659 23.3226 20.6646C23.9906 19.5072 24.1679 18.1606 23.8226 16.8699L23.8239 16.8726Z"
        fill="currentColor"
      />
    </svg>
  );
}
