export function Components(props) {
  return (
    <svg
      width="32"
      height="33"
      viewBox="0 0 32 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="Frame">
        <g id="Group">
          <path
            id="Vector"
            d="M8.55699 24.8397H6.66721C3.9721 24.8397 1.77832 22.6477 1.77832 19.9508V13.7286C1.77832 11.0317 3.9721 8.83972 6.66721 8.83972H8.55876V11.5064H6.66721C5.44232 11.5064 4.44499 12.5037 4.44499 13.7286V19.9508C4.44499 21.1757 5.44232 22.1731 6.66721 22.1731H8.55699V24.8397Z"
            fill="currentColor"
          />
          <path
            id="Vector_2"
            d="M14.7804 27.5064H12C9.30486 27.5064 7.11108 25.3144 7.11108 22.6175V11.062C7.11108 8.3651 9.30486 6.1731 12 6.1731H14.7804V8.83976H12C10.7751 8.83976 9.77775 9.8371 9.77775 11.062V22.6175C9.77775 23.8424 10.7751 24.8398 12 24.8398H14.7804V27.5064Z"
            fill="currentColor"
          />
          <path
            id="Vector_3"
            d="M24.4449 3.50647H18.2226C15.5226 3.50647 13.3337 5.6953 13.3337 8.39536V25.2842C13.3337 27.9843 15.5226 30.1731 18.2226 30.1731H24.4449C27.1449 30.1731 29.3337 27.9843 29.3337 25.2842V8.39536C29.3337 5.6953 27.1449 3.50647 24.4449 3.50647Z"
            fill="currentColor"
          />
        </g>
      </g>
    </svg>
  );
}
