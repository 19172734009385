export function ArrowRight({ className, ...props }) {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`${
        className || ''
      } relative transition-transform group-hover:translate-x-1`}
      {...props}
    >
      <path
        d="M4.16663 10.2509H15.8333M15.8333 10.2509L9.99996 4.4176M15.8333 10.2509L9.99996 16.0843"
        stroke="currentColor"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
