import { useRef } from 'react';
import { useMediaPredicate } from 'react-media-hook';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import { Icons } from '../../snippets/Icons';
import { Link } from '../../snippets/Link';

import { useVideoHero } from './useVideoHero';

import { Schema } from './VideoHero.schema';

import { Section } from '../../snippets';

export function VideoHero({ cms }) {
  const { buttons, heading, subheading, video } = cms;
  const {
    chapters,
    url: videoUrl,
    posterImage,
    title: videoTitle,
  } = { ...video };

  const videoRef = useRef();
  const isDesktop = useMediaPredicate('(min-width: 768px)');

  const {
    handleVideoJump,
    setRemovePosterOvelay,
    removePosterOverlay,
    showTimeline,
    setShowTimeline,
    transformedChapters,
    videoCurrentTime,
    videoProgressPercentage,
  } = useVideoHero(videoRef, chapters);

  return (
    <Section cms={cms}>
      <div className="northern-lights-gradient space-y-7 pt-6 pb-20 md:pt-20">
        {/* Heading */}
        <h1 className="h1 mx-auto max-w-3xl px-4 text-center selection:bg-brand max-md:text-[44px] md:px-0">
          {heading}
        </h1>

        {/* Subheading */}
        <p className="body-1 md:sub-heading mx-auto max-w-3xl px-4 text-center leading-[140%] text-white-600 selection:bg-brand selection:text-white md:px-0">
          {subheading}
        </p>

        <div className="flex justify-center px-4 md:order-2 md:pt-[52px]">
          <div
            className={`video-parent-container relative flex max-w-7xl items-center before:absolute before:h-full before:w-[20px] before:bg-black before:transition-[right] before:duration-300 before:content-[''] ${
              showTimeline ? 'before:right-[220px]' : 'before:right-[36px]'
            }`}
          >
            <div
              className="relative flex flex-1 transition-[padding] duration-300"
              style={{
                /* eslint-disable-next-line no-nested-ternary */
                paddingRight: isDesktop
                  ? showTimeline
                    ? '220px'
                    : '36px'
                  : '0',
              }}
            >
              <div className="relative overflow-hidden rounded shadow-sm md:rounded-[15px]">
                {videoUrl && (
                  /* eslint-disable jsx-a11y/media-has-caption */
                  <video
                    className="you-cursor w-full"
                    controls
                    playsInline
                    ref={videoRef}
                  >
                    <source src={videoUrl} type="video/mp4" />
                  </video>
                )}
                {posterImage?.src && !removePosterOverlay && (
                  <div
                    role="button"
                    aria-hidden="true"
                    className="you-cursor absolute inset-0 z-10"
                    onClick={() => {
                      setRemovePosterOvelay(true);
                      videoRef?.current?.play();
                    }}
                  >
                    <div className="flex h-full w-full items-center justify-center">
                      <Icons
                        icon="playButton"
                        className=" absolute z-10 text-brand"
                      />
                      <img alt="posterImage" src={posterImage?.src} />
                    </div>
                  </div>
                )}
              </div>
            </div>

            {/* Video Timeline */}
            {transformedChapters?.length > 0 && (
              <div
                className="absolute top-0 bottom-0 right-0 hidden h-full overflow-hidden rounded-r-[12px] bg-black p-[14px] transition-[width] duration-300 md:block"
                style={{
                  width: showTimeline ? '220px' : '36px',
                }}
              >
                {/* header */}
                <div className="mb-4 flex justify-between">
                  <div className="flex items-center">
                    {showTimeline && (
                      <>
                        <h4 className="text-xs font-medium text-brand">
                          {videoTitle}
                        </h4>

                        <div className="ml-2 flex items-center">
                          <span className="w-[30px] text-2xs font-normal text-[#747474]">
                            {videoCurrentTime}
                          </span>
                          <span className="h-5 w-5">
                            <CircularProgressbar
                              value={videoProgressPercentage}
                              strokeWidth={10}
                              styles={{
                                path: {
                                  stroke: `rgba(0, 190, 143)`,
                                },
                                trail: {
                                  // Trail color
                                  stroke: '#161719',
                                },
                              }}
                            />
                          </span>
                        </div>
                      </>
                    )}
                  </div>

                  <button
                    className={`relative z-[2] transition-transform duration-300 ${
                      showTimeline ? '' : 'right-1.5 rotate-180'
                    }`}
                    onClick={() => setShowTimeline(!showTimeline)}
                    type="button"
                  >
                    <Icons icon="arrowLeftButton" className="h-5 w-5" />
                  </button>
                </div>

                {showTimeline && (
                  <>
                    <ul className="scrollbar-hide h-full overflow-y-scroll pb-[50px]">
                      {transformedChapters?.map((chapter, chapterIndex) => {
                        const { title, timestamp, isActive, subchapters } = {
                          ...chapter,
                        };

                        return (
                          <li
                            className={chapterIndex === 0 ? 'mb-3' : ''}
                            key={chapterIndex}
                          >
                            <button
                              className={` flex w-full justify-between ${
                                isActive ? 'text-brand' : ''
                              }`}
                              onClick={() => handleVideoJump(timestamp)}
                              type="button"
                            >
                              <h5 className="flex-1 overflow-hidden text-ellipsis whitespace-nowrap text-left text-2xs font-medium">
                                {title}
                              </h5>
                              <h5 className="w-20px w-20px pl-3 text-2xs font-medium">
                                {timestamp}
                              </h5>
                            </button>

                            {subchapters?.length > 0 && (
                              <ul className="my-1.5 flex flex-col border-l border-[#3D3D3D]">
                                {subchapters?.map(
                                  (subchapter, subchapterIndex) => {
                                    const {
                                      title: subchapterTitle,
                                      timestamp: subchapterTimestamp,
                                      isActive: subchapterIsActive,
                                    } = {
                                      ...subchapter,
                                    };
                                    const isLastChapter =
                                      chapters.length - 1 === chapterIndex &&
                                      subchapters.length - 1 ===
                                        subchapterIndex;
                                    return (
                                      <li
                                        className={`video-chapter-mark py-1 pl-4 ${
                                          isLastChapter
                                            ? 'last-video-chapter-mark'
                                            : ''
                                        }`}
                                        key={subchapterIndex}
                                      >
                                        <button
                                          className={`flex w-full justify-between ${
                                            subchapterIsActive
                                              ? 'text-white'
                                              : 'text-[#5C5C5C]'
                                          }`}
                                          onClick={() =>
                                            handleVideoJump(subchapterTimestamp)
                                          }
                                          type="button"
                                        >
                                          <h5 className="flex-1 overflow-hidden text-ellipsis whitespace-nowrap text-left text-2xs font-medium">
                                            {subchapterTitle}
                                          </h5>
                                          <h5 className="w-20px w-20px pl-3 text-2xs font-medium">
                                            {subchapterTimestamp}
                                          </h5>
                                        </button>
                                      </li>
                                    );
                                  }
                                )}
                              </ul>
                            )}
                          </li>
                        );
                      })}
                    </ul>

                    <div className="w-100 video-chapter-slider-fade absolute bottom-0 left-0 right-0 h-[34px]" />
                  </>
                )}
              </div>
            )}
          </div>
        </div>

        {buttons?.length > 0 && (
          <div className="mb-9 flex flex-col-reverse gap-3 px-4 md:order-1 md:mt-0 md:mb-[60px] md:flex-row md:justify-center md:px-0">
            {buttons.map((button, index) => {
              return (
                <Link
                  className={`${button.type} group`}
                  key={`featureTimelineButton-${index}`}
                  href={button?.link?.url}
                  newTab={button?.link?.newTab}
                >
                  {button.icon && button.iconPosition !== 'right' && (
                    <Icons icon={button.icon} />
                  )}
                  {button.link.text}
                  {button.icon && button.iconPosition === 'right' && (
                    <Icons icon={button.icon} />
                  )}
                </Link>
              );
            })}
          </div>
        )}
      </div>
    </Section>
  );
}

VideoHero.displayName = 'VideoHero';
VideoHero.Schema = Schema;
