export function AlignLeft(props) {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.5 4.25C4.08579 4.25 3.75 4.58579 3.75 5C3.75 5.41421 4.08579 5.75 4.5 5.75L20.5 5.75C20.9142 5.75 21.25 5.41421 21.25 5C21.25 4.58579 20.9142 4.25 20.5 4.25L4.5 4.25ZM3.75 12C3.75 11.5858 4.08579 11.25 4.5 11.25L14.5 11.25C14.9142 11.25 15.25 11.5858 15.25 12C15.25 12.4142 14.9142 12.75 14.5 12.75L4.5 12.75C4.08579 12.75 3.75 12.4142 3.75 12ZM3.75 19C3.75 18.5858 4.08579 18.25 4.5 18.25L20.5 18.25C20.9142 18.25 21.25 18.5858 21.25 19C21.25 19.4142 20.9142 19.75 20.5 19.75L4.5 19.75C4.08579 19.75 3.75 19.4142 3.75 19Z"
        fill="currentColor"
      />
    </svg>
  );
}
