export function Devices(props) {
  return (
    <svg
      width="32"
      height="33"
      viewBox="0 0 32 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="Frame">
        <g id="Group">
          <path
            id="Vector"
            d="M26.2218 13.2842H21.7773C19.5682 13.2842 17.7773 15.075 17.7773 17.2842V27.062C17.7773 29.2711 19.5682 31.062 21.7773 31.062H26.2218C28.4309 31.062 30.2218 29.2711 30.2218 27.062V17.2842C30.2218 15.075 28.4309 13.2842 26.2218 13.2842Z"
            fill="currentColor"
          />
          <path
            id="Vector_2"
            d="M14.2218 22.6175H8.22179C7.11957 22.6175 6.22179 21.7197 6.22179 20.6175V9.28415C6.22179 8.05926 7.21912 7.06193 8.44401 7.06193H23.5551C24.78 7.06193 25.7773 8.05926 25.7773 9.28415V9.7286C25.7773 10.4646 26.3747 11.0619 27.1107 11.0619C27.8467 11.0619 28.444 10.4646 28.444 9.7286V9.28415C28.444 6.58726 26.252 4.39526 23.5551 4.39526H8.44401C5.74712 4.39526 3.55512 6.58726 3.55512 9.28415V20.6175C3.55512 21.3357 3.73112 22.0095 4.02268 22.6175H3.11068C2.37468 22.6175 1.77734 23.2148 1.77734 23.9508C1.77734 24.6868 2.37468 25.2842 3.11068 25.2842H14.2218C14.9578 25.2842 15.5551 24.6868 15.5551 23.9508C15.5551 23.2148 14.9578 22.6175 14.2218 22.6175Z"
            fill="currentColor"
          />
        </g>
      </g>
    </svg>
  );
}
