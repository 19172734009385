export function Schema() {
  return {
    label: 'Logo Marquee',
    key: 'logo-marquee',
    fields: [
      {
        label: 'Heading',
        name: 'heading',
        component: 'text',
        defaultValue: 'Trusted by the most innovative brands and agencies',
      },
      {
        label: 'Background Color',
        name: 'bgColor',
        component: 'color',
      },
      {
        label: 'Logo Color',
        name: 'logoColor',
        component: 'select',
        options: [
          {
            label: 'Black',
            value: 'invert-0',
          },
          {
            label: 'Gray',
            value: 'invert-[50%]',
          },
          {
            label: 'White',
            value: 'invert',
          },
        ],
        defaultValue: 'invert-0',
      },
      {
        label: 'Partners',
        name: 'partners',
        component: 'group-list',
        itemProps: {
          label: '{{item.name}}',
        },
        fields: [
          {
            label: 'Name',
            name: 'name',
            component: 'text',
          },
          {
            label: 'Image',
            name: 'image',
            component: 'image',
          },
        ],
      },
    ],
  };
}
