export function Schema({ blog }) {
  if (!blog) return null;
  return {
    label: 'Featured Article',
    key: 'featured-article',
    fields: [
      {
        label: 'Article Handle',
        name: 'handle',
        component: 'text',
        description: 'To use most recent article, leave this field blank',
      },
    ],
  };
}
