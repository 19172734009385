import { usePage } from '@backpackjs/storefront';

import { Schema } from './SimplePageHeader.schema';

import { Section } from '../../snippets';

export function SimplePageHeader({ cms }) {
  const page = usePage();
  const date = new Date(page?.updatedAt);

  // Get the day, month, and year
  const day = date.getDate();
  const month = date.toLocaleString('default', { month: 'long' });
  const year = date.getFullYear();

  // Combine them into the "Day Month Year" format
  const formattedDate = `${day} ${month} ${year}`;

  return (
    <Section cms={cms}>
      <div className="container mb-8 border-b border-b-white-600 py-8 md:mb-16 md:py-24">
        <div className="max-w-3xl">
          <p className="body-2-em mb-3 font-semibold text-brand">
            Current as of {formattedDate}
          </p>
          <h1 className="h4 md:h1 mb-8">{cms?.pageHeader || page?.title}</h1>
          <p className="body-1 md:sub-heading text-white-600">
            {cms?.pageDescription || page?.description}
          </p>
        </div>
      </div>
    </Section>
  );
}

SimplePageHeader.displayName = 'MainHero';
SimplePageHeader.Schema = Schema;
