export function Schema() {
  return {
    label: 'Testimonial Carousel',
    key: 'testimonial-carousel',
    fields: [
      {
        label: 'Slides',
        name: 'slides',
        component: 'group-list',
        fields: [
          {
            label: 'Logo',
            name: 'logo',
            component: 'image',
          },
          {
            label: 'Logo Alt',
            name: 'logoAlt',
            component: 'text',
          },
          {
            label: 'Quote',
            name: 'quote',
            component: 'textarea',
          },
          {
            label: 'Link',
            name: 'link',
            component: 'link',
            defaultValue: {
              text: 'View Case Study',
              url: '/',
            },
          },
          {
            label: 'Profile Image',
            name: 'profile',
            component: 'image',
          },
          {
            label: 'Name',
            name: 'name',
            component: 'text',
          },
          {
            label: 'Title',
            name: 'title',
            component: 'text',
          },
          {
            label: 'Image',
            name: 'image',
            component: 'image',
          },
          {
            label: 'Image Alt',
            name: 'imageAlt',
            component: 'text',
          },
        ],
        itemProps: {
          label: '{{item.logoAlt}}',
        },
        defaultItem: {
          link: {
            text: 'View Case Study',
            url: '/',
          },
        },
      },
    ],
  };
}
