export function Schema() {
  return {
    label: 'Compare Plans',
    key: 'compare-plans',
    fields: [
      {
        label: 'Heading',
        name: 'heading',
        component: 'text',
        defaultValue: 'Compare plans',
      },
      {
        label: 'Subheading',
        name: 'subheading',
        component: 'textarea',
        defaultValue:
          'Start with a proof of concept, scale to millions of shoppers',
      },
      {
        label: 'Plans',
        name: 'plans',
        component: 'group',
        fields: [
          {
            label: 'Headers',
            name: 'headers',
            component: 'group-list',
            itemProps: {
              label: '{{item.heading}}',
            },
            fields: [
              {
                label: 'Heading',
                name: 'heading',
                component: 'text',
                defaultValue: 'Pro',
              },
              {
                label: 'Description',
                name: 'description',
                component: 'text',
                defaultValue: 'Starting at',
              },
              {
                label: 'Price',
                name: 'price',
                component: 'text',
                defaultValue: '$475',
              },
              {
                label: 'Unit',
                name: 'unit',
                component: 'text',
                description: 'Optional',
                defaultValue: 'month',
              },
              {
                label: 'Button',
                name: 'button',
                component: 'group',
                fields: [
                  {
                    label: 'Type',
                    name: 'type',
                    component: 'select',
                    options: [
                      { label: 'Primary', value: 'btn-primary' },
                      { label: 'Outline', value: 'btn-outline' },
                    ],
                  },
                  {
                    label: 'Link',
                    name: 'link',
                    component: 'link',
                  },
                ],
              },
            ],
            defaultValue: [
              {
                heading: '',
                description: '',
                price: 'Create and manage content',
                unit: '',
              },
              {
                heading: 'Free',
                description: '',
                price: '$0',
                unit: 'month',
                button: {
                  type: '',
                  link: {
                    url: '/',
                    text: 'Get Started',
                  },
                },
              },
              {
                heading: 'Pro',
                description: 'Starting at',
                price: '$475',
                unit: 'month',
                button: {
                  type: '',
                  link: {
                    url: '/',
                    text: 'Get Started',
                  },
                },
              },
              {
                heading: 'Scale',
                description: '',
                price: 'Custom',
                unit: '',
                button: {
                  type: '',
                  link: {
                    url: '/',
                    text: 'Get Started',
                  },
                },
              },
            ],
          },
          {
            label: 'Rows',
            name: 'rows',
            component: 'group-list',
            itemProps: {
              label: '{{item.primaryKey.text}}',
            },
            fields: [
              {
                label: 'Type',
                name: 'type',
                component: 'select',
                options: [
                  { label: 'Subheader', value: 'subheader' },
                  { label: 'Item', value: 'item' },
                ],
                defaultValue: 'item',
              },
              {
                label: 'Primary Key (First Column)',
                name: 'primaryKey',
                component: 'group',
                fields: [
                  {
                    label: 'Text',
                    name: 'text',
                    component: 'text',
                    defaultValue: 'Visual headless CMS',
                  },
                  {
                    label: 'Is Beta?',
                    name: 'isBeta',
                    component: 'toggle',
                    defaultValue: false,
                  },
                  {
                    label: 'Is Coming Soon?',
                    name: 'isComingSoon',
                    component: 'toggle',
                    description: 'Overrides Beta Tag',
                    defaultValue: false,
                  },
                  {
                    label: 'Description',
                    name: 'description',
                    description: 'Appears when hovering over info button',
                    component: 'text',
                  },
                ],
              },
              {
                label: 'Values',
                name: 'values',
                component: 'group-list',
                itemProps: {
                  label: '{{item.enabled}}',
                },
                fields: [
                  {
                    label: 'Feature Enabled',
                    name: 'enabled',
                    component: 'toggle',
                    toggleLabels: {
                      true: 'Enabled',
                      false: 'Disabled',
                    },
                    defaultValue: true,
                  },
                  {
                    label: 'Custom Text',
                    name: 'customText',
                    description: 'Overrides enable or disable icon above',
                    component: 'text',
                  },
                ],
              },
            ],
            defaultValue: [
              {
                type: 'item',
                primaryKey: {
                  text: 'Visual headless CMS',
                },
                values: [
                  {
                    enabled: true,
                  },
                  {
                    enabled: true,
                  },
                  {
                    enabled: true,
                  },
                ],
              },
              {
                type: 'item',
                primaryKey: {
                  text: 'Visual editor / page builder',
                },
                values: [
                  {
                    enabled: true,
                  },
                  {
                    enabled: true,
                  },
                  {
                    enabled: true,
                  },
                ],
              },
              {
                type: 'item',
                primaryKey: {
                  text: 'Real-time editing and previews',
                },
                values: [
                  {
                    enabled: true,
                  },
                  {
                    enabled: true,
                  },
                  {
                    enabled: true,
                  },
                ],
              },
              {
                type: 'item',
                primaryKey: {
                  text: 'Reusable content blocks',
                },
                values: [
                  {
                    enabled: true,
                  },
                  {
                    enabled: true,
                  },
                  {
                    enabled: true,
                  },
                ],
              },
              {
                type: 'item',
                primaryKey: {
                  text: 'Content block libray',
                },
                values: [
                  {
                    enabled: true,
                  },
                  {
                    enabled: true,
                  },
                  {
                    enabled: true,
                  },
                ],
              },
              {
                type: 'item',
                primaryKey: {
                  text: 'Bulk content management',
                },
                values: [
                  {
                    enabled: true,
                  },
                  {
                    enabled: true,
                  },
                  {
                    enabled: true,
                  },
                ],
              },
              {
                type: 'item',
                primaryKey: {
                  text: 'Content scheduling',
                },
                values: [
                  {
                    enabled: false,
                  },
                  {
                    enabled: true,
                  },
                  {
                    enabled: true,
                  },
                ],
              },
              {
                type: 'item',
                primaryKey: {
                  text: 'Content environments',
                },
                values: [
                  {
                    customText: '1',
                  },
                  {
                    customText: '2',
                  },
                  {
                    customText: 'Custom',
                  },
                ],
              },
              {
                type: 'item',
                primaryKey: {
                  text: 'Revision hitory',
                },
                values: [
                  {
                    customText: '1 day',
                  },
                  {
                    customText: '3 days',
                  },
                  {
                    customText: 'Custom',
                  },
                ],
              },
              {
                type: 'item',
                primaryKey: {
                  text: 'AI Content generation',
                  isBeta: true,
                },
                values: [
                  {
                    enabled: false,
                  },
                  {
                    enabled: false,
                  },
                  {
                    enabled: true,
                  },
                ],
              },
              {
                type: 'item',
                primaryKey: {
                  text: 'A/B testing',
                  isBeta: true,
                },
                values: [
                  {
                    enabled: false,
                  },
                  {
                    enabled: false,
                  },
                  {
                    enabled: true,
                  },
                ],
              },
              {
                type: 'subheader',
                primaryKey: {
                  text: 'Only pay for what you use',
                },
              },
              {
                type: 'item',
                primaryKey: {
                  text: 'Page views',
                },
                values: [
                  {
                    customText: 'Up to 10k',
                  },
                  {
                    customText: 'Up to 250k',
                  },
                  {
                    customText: 'Custom',
                  },
                ],
              },
              {
                type: 'item',
                primaryKey: {
                  text: 'Synced products from Shopify',
                },
                values: [
                  {
                    customText: '<10k',
                  },
                  {
                    customText: 'Unlimited',
                  },
                  {
                    customText: 'Unlimited',
                  },
                ],
              },
              {
                type: 'item',
                primaryKey: {
                  text: 'Content entries',
                },
                values: [
                  {
                    customText: '100',
                  },
                  {
                    customText: '>1000',
                  },
                  {
                    customText: '10,000+',
                  },
                ],
              },
              {
                type: 'item',
                primaryKey: {
                  text: 'User seats',
                },
                values: [
                  {
                    customText: 'Unlimited',
                  },
                  {
                    customText: 'Unlimited',
                  },
                  {
                    customText: 'Unlimited',
                  },
                ],
              },
              {
                type: 'item',
                primaryKey: {
                  text: 'Storefronts',
                },
                values: [
                  {
                    customText: '1',
                  },
                  {
                    customText: '1',
                  },
                  {
                    customText: 'Custom',
                  },
                ],
              },
              {
                type: 'subheader',
                primaryKey: {
                  text: 'Connect to Shopify & 3rd party apps',
                },
              },
              {
                type: 'item',
                primaryKey: {
                  text: 'Product catalog sync / CDN connector',
                },
                values: [
                  {
                    enabled: true,
                  },
                  {
                    enabled: true,
                  },
                  {
                    enabled: true,
                  },
                ],
              },
              {
                type: 'item',
                primaryKey: {
                  text: 'Hydrogen integration',
                },
                values: [
                  {
                    enabled: true,
                  },
                  {
                    enabled: true,
                  },
                  {
                    enabled: true,
                  },
                ],
              },
              {
                type: 'item',
                primaryKey: {
                  text: 'Metaobjects integration',
                },
                values: [
                  {
                    enabled: true,
                  },
                  {
                    enabled: true,
                  },
                  {
                    enabled: true,
                  },
                ],
              },
              {
                type: 'item',
                primaryKey: {
                  text: 'Support for Remix and Oxygen',
                },
                values: [
                  {
                    enabled: true,
                  },
                  {
                    enabled: true,
                  },
                  {
                    enabled: true,
                  },
                ],
              },
              {
                type: 'item',
                primaryKey: {
                  text: '3rd party integration library',
                },
                values: [
                  {
                    enabled: true,
                  },
                  {
                    enabled: true,
                  },
                  {
                    enabled: true,
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  };
}
