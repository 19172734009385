export function Schema({ article }) {
  if (!article) return null;

  return {
    label: 'Article Header Heading',
    key: 'article-header-heading',
    fields: [
      {
        label: 'Author Profiles',
        name: 'authors',
        component: 'group-list',
        fields: [
          {
            label: 'Name',
            name: 'name',
            component: 'text',
            description: 'Must match author names used for articles',
          },
          {
            label: 'Title',
            name: 'title',
            component: 'text',
          },
          {
            label: 'Profile Picture',
            name: 'image',
            component: 'image',
          },
        ],
        itemProps: {
          label: '{{item.name}}',
        },
      },
    ],
  };
}
