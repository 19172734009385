export function GlobeSearch(props) {
  return (
    <svg
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g opacity="0.65">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24.5877 39.5348C23.7431 39.6582 22.8791 39.7223 22.0001 39.7223C12.2124 39.7223 4.27783 31.7879 4.27783 22.0001C4.27783 12.2124 12.2124 4.27783 22.0001 4.27783C31.7879 4.27783 39.7223 12.2124 39.7223 22.0001C39.7223 22.8791 39.6582 23.7431 39.5348 24.5877C37.9598 23.7176 36.149 23.2223 34.2223 23.2223C28.1471 23.2223 23.2223 28.1471 23.2223 34.2223C23.2223 36.149 23.7176 37.9598 24.5877 39.5348Z"
          fill="currentColor"
          fillOpacity="0.3"
        />
        <path
          d="M23.9434 39.6172C23.3054 39.6881 22.6552 39.7223 22.0001 39.7223C12.2125 39.7223 4.27783 31.7876 4.27783 22.0001C4.27783 12.2125 12.2125 4.27783 22.0001 4.27783C31.7876 4.27783 39.7223 12.2125 39.7223 22.0001C39.7223 22.6576 39.6856 23.3054 39.6172 23.9434"
          stroke="currentColor"
          strokeWidth="2.64"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M39.7223 22.0003C39.7223 17.9499 31.7876 14.667 22.0001 14.667C12.2125 14.667 4.27783 17.9499 4.27783 22.0003C4.27783 26.0508 12.2125 29.3337 22.0001 29.3337C22.5721 29.3337 23.1343 29.3214 23.6916 29.2994"
          stroke="currentColor"
          strokeWidth="2.64"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M29.2992 23.7087C29.3212 23.1465 29.3334 22.5769 29.3334 22.0001C29.3334 12.2125 26.0505 4.27783 22.0001 4.27783C17.9496 4.27783 14.6667 12.2125 14.6667 22.0001C14.6667 31.7876 17.9496 39.7223 22.0001 39.7223"
          stroke="currentColor"
          strokeWidth="2.64"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M34.2223 39.7227C37.2598 39.7227 39.7223 37.2601 39.7223 34.2227C39.7223 31.1852 37.2598 28.7227 34.2223 28.7227C31.1848 28.7227 28.7223 31.1852 28.7223 34.2227C28.7223 37.2601 31.1848 39.7227 34.2223 39.7227Z"
          stroke="currentColor"
          strokeWidth="2.64"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M38.1089 38.1089L42.1667 42.1667"
          stroke="currentColor"
          strokeWidth="2.64"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}
