import NextLink from 'next/link';
import * as NavigationMenu from '@radix-ui/react-navigation-menu';
import { useState } from 'react';
import { Icons } from '../Icons';
import { Button } from '../button';
import { MarqueeComponent } from '../marquee';

function Link({ isMobile, href, ...props }) {
  if (isMobile) {
    return <NextLink href={href} {...props} />;
  }

  return (
    <NextLink href={href} passHref legacyBehavior>
      <NavigationMenu.Link {...props} />
    </NextLink>
  );
}

function LinkList({ title, description, button, links, isMobile }) {
  return (
    <div className="flex flex-col items-start gap-[30px] rounded-3xl border border-grey-800 py-5 px-4 lg:gap-[25px] lg:rounded-none lg:border-0 lg:border-l lg:border-grey-900 lg:px-0 lg:py-0 lg:first:border-l-0 xl:px-[30px]">
      <div className="flex flex-col items-start gap-[25px] self-stretch px-4 lg:px-0">
        {title || description ? (
          <div className="flex flex-col items-start gap-2 self-stretch">
            {title ? (
              <p className="font-aeonik text-base font-medium leading-5 tracking-tight text-primary-light antialiased">
                {title}
              </p>
            ) : null}
            {description ? (
              <p className="font-aeonik text-sm text-grey-400 antialiased">
                {description}
              </p>
            ) : null}
          </div>
        ) : null}

        <ul className="flex flex-col items-start">
          {links?.map((item, index) => {
            return (
              <li key={`link-${index}`}>
                <Link href={item?.link?.url || '#'} isMobile={isMobile}>
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a className="block py-1.5 font-aeonik text-sm font-medium text-primary-light antialiased transition-opacity hover:opacity-75">
                    {item.title}
                  </a>
                </Link>
              </li>
            );
          })}
        </ul>
      </div>
      {button?.url && button?.text ? (
        <Button
          link={button}
          className="h-11 self-stretch border-none px-3 text-sm font-medium leading-[1.125rem] lg:h-8 lg:text-xs"
        />
      ) : null}
    </div>
  );
}

function IconLinkList({ title, description, links, isMobile }) {
  return (
    <div className="flex flex-col items-start gap-[25px] rounded-3xl border border-grey-800 py-5 px-4 lg:gap-[25px] lg:rounded-none lg:border-0 lg:border-l lg:border-grey-900 lg:px-0 lg:py-0 lg:first:border-l-0 xl:px-[30px]">
      {title || description ? (
        <div className="flex flex-col items-start gap-2 self-stretch px-3 lg:px-0">
          {title ? (
            <p className="font-aeonik text-base font-medium leading-5 tracking-tight text-primary-light antialiased">
              {title}
            </p>
          ) : null}
          {description ? (
            <p className="font-aeonik text-sm text-grey-400 antialiased">
              {description}
            </p>
          ) : null}
        </div>
      ) : null}
      <ul className="flex flex-col gap-2 self-stretch lg:gap-1">
        {links?.map((item, index) => {
          const {
            icon,
            title: listItemTitle,
            description: listItemDescription,
            link,
          } = item || {};

          return (
            <li key={index}>
              <Link href={link?.url || '#'} isMobile={isMobile}>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a className="flex items-start gap-3 self-stretch rounded-[10px] px-3 py-[10px] transition-colors hover:bg-grey-900">
                  <Icons
                    icon={icon}
                    className="h-5 w-5 shrink-0 text-primary-light"
                  />
                  <div className="flex flex-col items-start gap-1">
                    {listItemTitle ? (
                      <p className="font-aeonik text-sm font-medium antialiased">
                        {listItemTitle}
                      </p>
                    ) : null}
                    {listItemDescription ? (
                      <p className="font-aeoink max-w-[200px] text-xs text-grey-400 antialiased">
                        {listItemDescription}
                      </p>
                    ) : null}
                  </div>
                </a>
              </Link>
            </li>
          );
        })}
      </ul>
    </div>
  );
}

function ImageLinkList({ title, description, links, button, isMobile }) {
  return (
    <div className="flex flex-col items-start gap-[25px] rounded-3xl border border-grey-800 py-5 px-4 lg:gap-[25px] lg:rounded-none lg:border-0 lg:border-l lg:border-grey-900 lg:px-0 lg:py-0 lg:first:border-l-0 xl:px-[30px]">
      {title || description ? (
        <div className="flex flex-col items-start gap-2 self-stretch px-3 lg:px-0">
          {title ? (
            <p className="font-aeonik text-base font-medium leading-5 tracking-tight text-primary-light antialiased">
              {title}
            </p>
          ) : null}
          {description ? (
            <p className="font-aeonik text-sm text-grey-400 antialiased">
              {description}
            </p>
          ) : null}
        </div>
      ) : null}
      <ul className="flex flex-col items-start gap-1 self-stretch lg:gap-1">
        {links?.map((item, index) => {
          const {
            image,
            title: listItemTitle,
            description: listItemDescription,
            link,
          } = item || {};

          return (
            <li key={index}>
              <Link href={link?.url || '#'} isMobile={isMobile}>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a className="flex items-start gap-3 self-stretch px-3 py-1.5 transition-opacity hover:opacity-75 lg:px-0">
                  <div className="flex h-6 w-6 items-center justify-center overflow-hidden rounded-full bg-white">
                    {image?.src ? (
                      <img
                        src={image.src}
                        alt={image.alt || listItemTitle}
                        className="object-cover"
                      />
                    ) : null}
                  </div>
                  <div className="flex flex-col items-start gap-1">
                    {listItemTitle ? (
                      <p className="font-aeonik text-sm font-medium antialiased">
                        {listItemTitle}
                      </p>
                    ) : null}
                    {listItemDescription ? (
                      <p className="font-aeoink max-w-[200px] text-xs text-grey-400 antialiased">
                        {listItemDescription}
                      </p>
                    ) : null}
                  </div>
                </a>
              </Link>
            </li>
          );
        })}
      </ul>
      {button?.url && button?.text ? (
        <Button
          link={button}
          className="h-11 self-stretch border-none px-3 text-sm font-medium leading-[1.125rem] lg:h-8 lg:text-xs"
        />
      ) : null}
    </div>
  );
}

function LinkGrid({ links, isMobile }) {
  return (
    <div className="grid auto-rows-fr grid-cols-2 gap-x-3 gap-y-[10px] self-stretch p-4 lg:col-span-2 lg:grid-cols-6 lg:content-start lg:gap-x-5 lg:gap-y-5 lg:p-0">
      {links?.map((item, index) => {
        let classes = 'lg:col-span-2';
        if (index === links.length - 1 && links.length % 2) {
          classes += ' col-span-2';
        }
        if (index === 0 && links.length % 3 === 1) {
          classes += ' lg:col-span-6';
        } else if (index < 2 && links.length % 3 === 2) {
          classes += ' lg:col-span-3';
        }

        return (
          <div key={`link-grid-${index}`} className={classes}>
            <Link href={item.link?.url || '#'} isMobile={isMobile}>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a className="flex h-full flex-col items-start justify-between self-stretch rounded-[20px] bg-grey-900 transition-colors hover:bg-grey-800">
                <div className="p-[15px]">
                  {item.icon ? (
                    <Icons icon={item.icon} className="h-6 w-6" />
                  ) : null}
                </div>
                <div className="p-[15px]">
                  {item.title ? (
                    <p className="font-aeonik text-base font-medium text-primary-light antialiased">
                      {item.title}
                    </p>
                  ) : null}
                </div>
              </a>
            </Link>
          </div>
        );
      })}
    </div>
  );
}

function LinkCards({ title, description, links, isMobile }) {
  return (
    <div className="flex flex-col items-start gap-[25px] rounded-3xl border border-grey-800 py-5 px-4 lg:col-span-2 lg:rounded-none lg:border-0 lg:border-l lg:border-grey-900 lg:px-0 lg:py-0 lg:first:border-l-0">
      {title || description ? (
        <div className="flex max-w-[255px] flex-col items-start gap-2 self-stretch px-3 lg:px-0">
          {title ? (
            <p className="font-aeonik text-base font-medium leading-5 tracking-tight text-primary-light antialiased">
              {title}
            </p>
          ) : null}
          {description ? (
            <p className="font-aeonik text-sm text-grey-400 antialiased">
              {description}
            </p>
          ) : null}
        </div>
      ) : null}
      <div className="flex flex-col items-start gap-5 self-stretch lg:flex-row">
        {links?.map((item, index) => {
          const {
            link,
            icon,
            title: heading,
            description: itemDescription,
            image,
          } = item || {};
          return (
            <Link
              href={link?.url || '#'}
              isMobile={isMobile}
              key={`link-cards-${index}`}
            >
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a
                className="relative flex h-[250px] flex-col gap-3 self-stretch overflow-hidden rounded-[20px] bg-grey-100 bg-cover bg-center bg-no-repeat pb-[25px] transition-colors hover:bg-grey-200"
                style={{
                  backgroundImage: image?.src
                    ? `url(${image?.src})`
                    : undefined,
                }}
              >
                {icon ? (
                  <div
                    className={`absolute top-[25px] left-[25px] flex h-10 w-10 items-center justify-center rounded-lg 
                      ${icon === 'storefront' ? 'bg-primary-green-500' : ''}
                      ${icon === 'shop' ? 'bg-primary-blue' : ''}`}
                  >
                    <Icons icon={icon} className="h-9 self-end" />
                  </div>
                ) : null}

                {heading || itemDescription ? (
                  <div className="mt-auto flex flex-col gap-1 px-[25px]">
                    {heading ? (
                      <p className="font-aeonik text-base font-medium leading-[1.375rem] text-primary-dark antialiased">
                        {heading}
                      </p>
                    ) : null}
                    {itemDescription ? (
                      <p className="font-aeonik text-sm text-grey-600 antialiased">
                        {itemDescription}
                      </p>
                    ) : null}
                  </div>
                ) : null}
              </a>
            </Link>
          );
        })}
      </div>
    </div>
  );
}

function ContextSwitcher({ contexts, isMobile }) {
  const [contextState, setContextState] = useState(0);
  return (
    <div className="flex flex-col items-start self-stretch lg:col-span-4 lg:flex-row lg:gap-[50px]">
      <div className="flex flex-col items-start gap-1.5 self-stretch px-2 py-4 lg:w-[15%] lg:px-1.5 lg:py-1.5">
        <div className="flex h-12 items-start gap-1.5 self-stretch rounded-full bg-grey-900 p-1.5 transition-colors hover:bg-grey-800">
          {contexts?.map((context, index) => {
            return (
              <button
                key={`menu-context-${index}`}
                type="button"
                className={`flex flex-1 items-center justify-center gap-1.5 self-stretch rounded-full p-1 font-aeonik text-sm font-medium tracking-tight antialiased transition-colors ${
                  index === contextState
                    ? 'bg-grey-300 text-primary-dark'
                    : 'text-grey-300'
                } `}
                onClick={() => {
                  setContextState(index);
                }}
              >
                {context.title}
              </button>
            );
          })}
        </div>
      </div>
      {contexts?.[contextState]?.cards ? (
        <div className="flex flex-1 flex-col items-start gap-5 self-stretch px-2 py-4 lg:col-span-2 lg:grid lg:grid-cols-2 lg:py-0">
          {contexts?.[contextState]?.cards?.map((card, index) => {
            let gradient;
            switch (card.gradient) {
              case 'blue-to-green':
                gradient =
                  'linear-gradient(180deg, rgba(13, 13, 13, 0.00) 0%, rgba(13, 13, 13, 0.50) 100%), radial-gradient(153.25% 99.97% at 49.96% 0%, #0000F5 0%, #00A57B 60.15%)';
                break;
              case 'purple-to-black':
                gradient =
                  'linear-gradient(180deg, rgba(13, 13, 13, 0.00) 0%, rgba(13, 13, 13, 0.50) 100%), radial-gradient(153.25% 99.97% at 49.96% 0%, rgba(212, 68, 241, 0.90) 0%, rgba(24, 6, 25, 0.90) 100%)';
                break;
              default:
                gradient =
                  'linear-gradient(180deg, rgba(13, 13, 13, 0.00) 0%, rgba(13, 13, 13, 0.50) 100%), radial-gradient(153.25% 99.97% at 49.96% 0%, #00BE8E 0%, #2970FF 50.16%, #0D0D0D 100%)';
            }
            const topLogos = [];
            const bottomLogos = [];
            if (card.marquee?.length) {
              const half = Math.ceil(card.marquee.length / 2);
              topLogos.push(...card.marquee.slice(0, half));
              bottomLogos.push(...card.marquee.slice(half));
            }
            return (
              <Link
                href={card.link?.url || '#'}
                isMobile={isMobile}
                key={`context-card-${index}`}
              >
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a
                  className="relative flex min-h-[275px] flex-col self-stretch rounded-2xl bg-cover bg-center bg-no-repeat transition-opacity hover:opacity-75"
                  style={{
                    backgroundImage:
                      card.image?.src && gradient && !card.marquee?.length
                        ? `url(${card.image.src}), ${gradient}`
                        : gradient,
                  }}
                >
                  {card.icon ? (
                    <div className="absolute top-[25px] left-[25px] flex h-11 w-11 items-center justify-center rounded-lg text-primary-light">
                      <Icons icon={card.icon} className="h-full w-full" />
                    </div>
                  ) : null}
                  {card.marquee?.length ? (
                    <div className="flex flex-grow flex-col justify-center gap-6">
                      <MarqueeComponent
                        logoMarquee={topLogos}
                        imageClasses="pill mx-4 h-8 py-2 px-3.5 rounded-full"
                        seconds="30"
                      />
                      <MarqueeComponent
                        logoMarquee={bottomLogos}
                        imageClasses="pill mx-4 h-8 py-2 px-3.5 rounded-full"
                        direction="reverse"
                        seconds="30"
                      />
                    </div>
                  ) : null}
                  {card.title || card.description ? (
                    <div className="mt-auto flex flex-col items-start gap-1 px-[25px] pb-[30px]">
                      {card.title ? (
                        <p className="font-aeonik text-base font-medium text-primary-light antialiased">
                          {card.title}
                        </p>
                      ) : null}
                      {card.description ? (
                        <p className="font-aeonik text-sm text-grey-200 antialiased">
                          {card.description}
                        </p>
                      ) : null}
                    </div>
                  ) : null}
                </a>
              </Link>
            );
          })}
        </div>
      ) : null}
      {contexts?.[contextState]?.list ? (
        <div className="self-stretch p-2 lg:w-[21%] lg:border-l lg:border-grey-900 lg:p-0">
          <LinkList
            title={contexts?.[contextState]?.list?.title}
            description={contexts?.[contextState]?.list?.description}
            button={contexts?.[contextState]?.list?.button}
            links={contexts?.[contextState]?.list?.links}
            isMobile={isMobile}
          />
        </div>
      ) : null}
    </div>
  );
}

export function MenuItems({ menuItems, isMobile }) {
  return menuItems?.map((subMenuItem) => {
    const {
      title: subMenuTitle,
      description: subMenuDescription,
      button,
      links,
      contexts,
      _template: subMenuType,
    } = subMenuItem || {};

    if (subMenuType === 'spacer') return <div />;

    if (subMenuType === 'imageLink') {
      const { description, subheading, Image, link } = subMenuItem;

      return (
        <div className="">
          <p
            className={`special-1 text-brand no-underline ${
              subMenuTitle ? 'mb-2 h-5' : ''
            }`}
          >
            {subMenuTitle}
          </p>

          <Link href={link?.url || '#'} isMobile={isMobile}>
            {/* eslint-disable-next-line jsx-a11y/anchor-has-content, jsx-a11y/anchor-is-valid */}
            <a
              src={Image.src}
              alt={description}
              className="block aspect-video rounded-lg bg-cover bg-center bg-no-repeat"
              style={{
                backgroundImage: `url("${Image.src}")`,
              }}
            />
          </Link>

          <div className="py-3">
            <Link href={link?.url || '#'} isMobile={isMobile}>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a className="body-2-em">{subheading}</a>
            </Link>

            <p className="caption-2 text-white-600">{description}</p>
          </div>
        </div>
      );
    }

    if (subMenuType === 'linkList') {
      return (
        <LinkList
          title={subMenuTitle}
          description={subMenuDescription}
          button={button}
          links={links}
          isMobile={isMobile}
        />
      );
    }

    if (subMenuType === 'iconLinkList') {
      return (
        <IconLinkList
          title={subMenuTitle}
          description={subMenuDescription}
          links={links}
          isMobile={isMobile}
        />
      );
    }

    if (subMenuType === 'imageLinkList') {
      return (
        <ImageLinkList
          title={subMenuTitle}
          description={subMenuDescription}
          links={links}
          button={button}
          isMobile={isMobile}
        />
      );
    }

    if (subMenuType === 'linkGrid') {
      return <LinkGrid links={links} isMobile={isMobile} />;
    }

    if (subMenuType === 'linkCards') {
      return (
        <LinkCards
          title={subMenuTitle}
          description={subMenuDescription}
          links={links}
          isMobile={isMobile}
        />
      );
    }

    if (subMenuType === 'contextSwitcher') {
      return <ContextSwitcher contexts={contexts} isMobile={isMobile} />;
    }

    return null;
  });
}
