import React from 'react';

import { Link } from '../../snippets/Link';

export function ComparePlansHeader({ headers, isFooter }) {
  return (
    <thead>
      <tr>
        {headers?.map((header, index) => {
          const {
            heading: tableHeading,
            description,
            price,
            unit,
            button,
          } = { ...header };

          return (
            <th className="h-[30px] py-8 text-center" key={index}>
              <div className="flex h-full flex-col justify-end">
                <h3 className="text-base">{tableHeading}</h3>
                <div className="mt-2 flex h-full flex-1 flex-col justify-end">
                  <h4 className="my-2 text-xs font-normal text-white-700">
                    {description}
                  </h4>
                  <div
                    className={`flex gap-x-1 ${
                      index === 0
                        ? 'items-end text-left'
                        : 'items-end justify-center'
                    }`}
                  >
                    <span
                      className={`font-semibold text-primary-green-500 ${
                        index === 0 ? 'text-lg' : 'leading-1 text-[32px]'
                      } ${index === 0 && isFooter ? 'hidden' : ''}`}
                    >
                      {price}
                    </span>
                    {unit && (
                      <>
                        <span className="text-sm font-normal text-white-700">
                          /
                        </span>
                        <span className="text-sm font-normal text-white-700">
                          {unit}
                        </span>
                      </>
                    )}
                  </div>

                  {isFooter && button?.link?.text && (
                    <Link
                      className={`${button?.type} mx-4 my-6 ${
                        button?.type === 'btn-primary' ? 'text-black' : ''
                      }`}
                      href={button?.link?.url || ''}
                      newTab={button?.link?.newTab}
                    >
                      {button?.link?.text}
                    </Link>
                  )}
                </div>
              </div>
            </th>
          );
        })}
      </tr>
    </thead>
  );
}

ComparePlansHeader.displayName = 'ComparePlansHeader';
