export function CheckCircleFill(props) {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="circle-check-3 1">
        <g id="Group">
          <path
            id="Vector"
            d="M10.5 1.11108C5.59886 1.11108 1.61108 5.09886 1.61108 9.99997C1.61108 14.9011 5.59886 18.8889 10.5 18.8889C15.4011 18.8889 19.3889 14.9011 19.3889 9.99997C19.3889 5.09886 15.4011 1.11108 10.5 1.11108ZM14.8855 7.37886C12.82 8.91442 11.0689 11.0344 9.6822 13.6778C9.53886 13.9522 9.25442 14.1244 8.94442 14.1244C8.63664 14.1278 8.34664 13.95 8.20331 13.6722C7.56442 12.4311 6.84997 11.4222 6.01997 10.5878C5.69553 10.2622 5.69664 9.73331 6.02331 9.40886C6.34775 9.08442 6.87664 9.08442 7.2022 9.4122C7.83886 10.0522 8.41331 10.7766 8.93886 11.6033C10.3155 9.33108 11.9766 7.46331 13.8922 6.03997C14.2611 5.76664 14.7833 5.8422 15.0578 6.2122C15.3322 6.58108 15.2555 7.10442 14.8855 7.37886Z"
            fill="currentColor"
          />
        </g>
      </g>
    </svg>
  );
}
