import { useState } from 'react';
import { Logo } from '../../icons/logo';
import { Button } from '../../snippets/button';
import { Icons } from '../../snippets/Icons';
import { Schema } from './GridSteps.schema';

export function GridSteps({ cms }) {
  const [activeContext, setActiveContext] = useState(0);
  const {
    icon,
    heading,
    contexts = [],
    footerHeading,
    footerButton,
    footerImages = [],
  } = cms || {};
  return (
    <div className="bg-primary-light">
      <div className="mx-auto flex max-w-8xl flex-col gap-10 px-5 py-[60px] lg:gap-[60px] lg:px-[100px] lg:pt-[100px] lg:pb-[150px]">
        <div className="flex flex-col gap-5 lg:px-5">
          {icon ? (
            <div className="flex items-center gap-1 self-start rounded-lg bg-grey-100 pr-1 lg:rounded-xl">
              <Logo
                className="h-10 w-10 lg:h-12 lg:w-12"
                style={{ color: '#030303' }}
              />
              <div
                className={`flex h-8 w-8 items-center justify-center rounded-lg  lg:h-10 lg:w-10 lg:rounded-[10px] ${
                  icon === 'storefront'
                    ? 'bg-primary-green-500'
                    : 'bg-primary-blue'
                }`}
              >
                <Icons icon={icon} className="h-[30px] self-end lg:h-[38px]" />
              </div>
            </div>
          ) : null}
          {contexts?.length > 1 ? (
            <div className="flex h-12 gap-1.5 rounded-full bg-grey-900 p-1.5 lg:w-[250px] lg:items-start lg:self-start">
              {contexts?.map((context, index) => {
                return (
                  <button
                    key={`menu-context-${index}`}
                    type="button"
                    className={`flex flex-1 items-center justify-center gap-1.5 self-stretch whitespace-nowrap rounded-full p-1 font-aeonik text-sm font-medium tracking-tight antialiased transition-colors ${
                      index === activeContext
                        ? 'bg-grey-50 text-primary-dark'
                        : 'text-grey-300'
                    } `}
                    onClick={() => {
                      setActiveContext(index);
                    }}
                  >
                    {context.title}
                  </button>
                );
              })}
            </div>
          ) : null}
          {heading ? (
            <h2 className="font-aeonik text-4xl font-normal text-primary-dark antialiased lg:text-[3.5rem] lg:leading-[3.75rem]">
              {heading}
            </h2>
          ) : null}
        </div>
        <div className="flex flex-col gap-10 lg:gap-5">
          {contexts[activeContext]?.steps?.length ? (
            <div className="grid auto-rows-fr grid-cols-1 gap-5 lg:grid-cols-3">
              {contexts[activeContext].steps.map((step, index) => (
                <div
                  key={`grid-step-${index}`}
                  className="group flex flex-col justify-between gap-4 rounded-3xl bg-grey-50 p-5 transition-colors hover:cursor-pointer hover:bg-grey-100 lg:p-[25px]"
                >
                  <div className="flex h-8 w-8 items-center justify-center rounded-full bg-grey-100 font-aeonik text-xl font-medium leading-6 text-primary-light antialiased transition-colors group-hover:bg-primary-blue">
                    {index + 1}
                  </div>
                  <div className="flex items-end gap-[15px]">
                    {step.description ? (
                      <p className="font-aeonik text-2xl leading-7 text-primary-dark antialiased">
                        {step.description}
                      </p>
                    ) : null}
                    {step?.button?.link?.url ? (
                      <Button
                        link={step.button.link}
                        className="ml-auto h-9 flex-shrink-0 whitespace-nowrap py-[5px] text-sm leading-6"
                      />
                    ) : null}
                  </div>
                </div>
              ))}
            </div>
          ) : null}
          {footerHeading || footerButton?.link?.url ? (
            <div className="flex flex-col items-start gap-2.5 rounded-3xl bg-grey-50 p-5 lg:flex-row lg:items-center lg:gap-[30px] lg:p-[25px]">
              {footerImages.length ? (
                <div className="flex flex-row-reverse items-center">
                  {footerImages.map((image, index) => (
                    <div
                      className="-ml-2.5 flex h-10 w-10 shrink-0 items-center justify-center overflow-hidden rounded-full border-2 border-primary-light"
                      key={`footer-image-${index}`}
                    >
                      <img
                        src={image.src.src}
                        alt={image.src.alt || ''}
                        className="h-full w-full object-cover"
                      />
                    </div>
                  ))}
                </div>
              ) : null}
              {footerHeading ? (
                <h3 className="font-aeonik text-2xl font-normal leading-[1.625rem] text-primary-dark antialiased">
                  {footerHeading}
                </h3>
              ) : null}
              {footerButton?.link?.url ? (
                <Button
                  link={footerButton.link}
                  className="mt-2.5 h-9 flex-shrink-0 whitespace-nowrap py-[5px] text-sm leading-6 lg:mt-0 lg:ml-auto"
                />
              ) : null}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}

GridSteps.displayName = 'GridSteps';
GridSteps.Schema = Schema;
