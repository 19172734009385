export function CaretDown(props) {
  return (
    <svg
      width="32"
      height="33"
      viewBox="0 0 32 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M26.7076 12.8863L16.7076 22.8863C16.6147 22.9793 16.5044 23.053 16.383 23.1033C16.2616 23.1537 16.1315 23.1796 16.0001 23.1796C15.8687 23.1796 15.7385 23.1537 15.6171 23.1033C15.4957 23.053 15.3854 22.9793 15.2926 22.8863L5.29257 12.8863C5.10493 12.6986 4.99951 12.4441 4.99951 12.1788C4.99951 11.9134 5.10493 11.6589 5.29257 11.4713C5.48021 11.2836 5.7347 11.1782 6.00007 11.1782C6.26543 11.1782 6.51993 11.2836 6.70757 11.4713L16.0001 20.765L25.2926 11.4713C25.3855 11.3784 25.4958 11.3047 25.6172 11.2544C25.7386 11.2041 25.8687 11.1782 26.0001 11.1782C26.1315 11.1782 26.2616 11.2041 26.383 11.2544C26.5044 11.3047 26.6147 11.3784 26.7076 11.4713C26.8005 11.5642 26.8742 11.6745 26.9245 11.7959C26.9747 11.9173 27.0006 12.0474 27.0006 12.1788C27.0006 12.3102 26.9747 12.4403 26.9245 12.5617C26.8742 12.6831 26.8005 12.7934 26.7076 12.8863Z"
        fill="currentColor"
      />
    </svg>
  );
}
