import NextLink from 'next/link';

import { useSettings } from '@backpackjs/storefront';

import { Promobar } from './promobar';
import { DesktopMenu } from './desktop-menu';
import { DesktopRightMenu } from './desktop-right-menu';
import { MobileMenu } from './mobile-menu';
import { usePromobar } from '../../hooks';

import { LogoWithName } from '../../icons/logoWithName';

export function Header() {
  const settings = useSettings();
  const {
    promobarAutoHide,
    promobarDisabled,
    promobarHidden,
    promobarVisibleHeight,
    promoBarRef,
  } = usePromobar();

  return (
    <>
      <Promobar
        promobarAutoHide={promobarAutoHide}
        promobarDisabled={promobarDisabled}
        promobarHidden={promobarHidden}
        ref={promoBarRef}
      />
      <header
        className="px-contained sticky z-20 bg-black"
        style={{
          top: promobarAutoHide ? 0 : promobarVisibleHeight,
        }}
      >
        <div className="relative mx-auto flex h-[72px] max-w-7xl items-center justify-between lg:grid lg:h-[65px] lg:grid-cols-3">
          {/* Desktop Mega Menu */}
          <div className="hidden lg:flex">
            <DesktopMenu
              promobarVisibleHeight={promobarVisibleHeight}
              settings={settings?.header}
            />
          </div>

          <div className="lg:justify-self-center">
            <NextLink href="/" passHref>
              {/* Pack Logo  */}
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a className="relative flex items-center gap-2" title="Pack">
                <LogoWithName />
              </a>
            </NextLink>
          </div>

          {/* Desktop Menu Right */}
          <DesktopRightMenu />

          <MobileMenu
            promobarVisibleHeight={promobarVisibleHeight}
            menu={settings?.header?.menu}
            ctas={settings?.header?.ctas}
          />
        </div>
      </header>
    </>
  );
}
