import { IconSettings } from '../../settings/icons';

export function Schema() {
  return {
    label: 'Video Hero',
    key: 'video-hero',
    fields: [
      {
        label: 'Heading',
        name: 'heading',
        component: 'text',
        defaultValue: 'Meet Pack',
      },
      {
        label: 'Subheading',
        name: 'subheading',
        component: 'textarea',
        defaultValue:
          'Build blazing-fast storefronts on Hydrogen that are flexible and easy to manage.',
      },
      {
        label: 'Video',
        name: 'video',
        component: 'group',
        fields: [
          {
            label: 'Video URL',
            name: 'url',
            component: 'text',
            defaultValue:
              'https://cdn.shopify.com/videos/c/o/v/2f7e38f2ed2a4bb6b9cb4ec32a035e73.mp4',
          },
          {
            label: 'Video Poster Image',
            name: 'posterImage',
            component: 'image',
          },
          {
            label: 'Video Title',
            name: 'title',
            component: 'text',
            defaultValue: 'Meet Pack',
          },
          {
            label: 'Chapters',
            name: 'chapters',
            component: 'group-list',
            itemProps: {
              label: '{{item.title}}',
            },
            fields: [
              {
                label: 'Title',
                name: 'title',
                component: 'text',
              },
              {
                label: 'Timestamp',
                name: 'timestamp',
                component: 'text',
                description: 'Ex: MM:SS or M:SS',
              },
              {
                label: 'Subchapters',
                name: 'subchapters',
                component: 'group-list',
                itemProps: {
                  label: '{{item.title}}',
                },
                fields: [
                  {
                    label: 'Title',
                    name: 'title',
                    component: 'text',
                  },
                  {
                    label: 'Timestamp',
                    name: 'timestamp',
                    component: 'text',
                  },
                ],
              },
            ],
            defaultValue: [
              {
                title: 'Introduction',
                timestamp: '0:00',
              },
              {
                title: 'Logging into the CMS',
                timestamp: '1:23',
                subchapters: [
                  {
                    title: 'Syncing your products simply with',
                    timestamp: '2:05',
                  },
                  {
                    title: 'Adding Meta tags to your account',
                    timestamp: '3:27',
                  },
                ],
              },
              {
                title: 'Supported Integrations',
                timestamp: '4:19',
                subchapters: [
                  {
                    title: 'Github Connecting is seamsless',
                    timestamp: '4:55',
                  },
                  {
                    title: '1000’s of APIs to choose from right',
                    timestamp: '5:16',
                  },
                  {
                    title: 'Another subtopic would go here',
                    timestamp: '6:28',
                  },
                ],
              },
              {
                title: 'Shopify on steriods',
                timestamp: '8:53',
                subchapters: [
                  {
                    title: 'Github Connecting is seamsless',
                    timestamp: '9:12',
                  },
                  {
                    title: '1000’s of APIs to choose from right',
                    timestamp: '10:16',
                  },
                  {
                    title: 'Another subtopic would go here',
                    timestamp: '12:28',
                  },
                ],
              },
              {
                title: 'Infinite speed performance',
                timestamp: '13:05',
                subchapters: [
                  {
                    title: 'Github Connecting is seamsless',
                    timestamp: '13:42',
                  },
                  {
                    title: '1000’s of APIs to choose from right',
                    timestamp: '14:16',
                  },
                  {
                    title: 'Another subtopic would go here',
                    timestamp: '16:28',
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        label: 'Buttons',
        name: 'buttons',
        component: 'group-list',
        fields: [
          {
            label: 'Type',
            name: 'type',
            component: 'select',
            options: [
              { label: 'Primary', value: 'btn-primary' },
              { label: 'Primary Inverse', value: 'btn-primary-inverse' },
              { label: 'Outline', value: 'btn-outline' },
              { label: 'Naked Inverse', value: 'btn-naked-inverse' },
            ],
          },
          {
            label: 'Link',
            name: 'link',
            component: 'link',
          },
          IconSettings,
          {
            label: 'Icon Position',
            name: 'iconPosition',
            component: 'select',
            options: [
              { label: 'Left', value: 'left' },
              { label: 'Right', value: 'right' },
            ],
          },
        ],
        itemProps: {
          label: '{{item.link.text}}',
        },
        validate: {
          maxItems: 2,
        },
        defaultItem: {
          type: 'btn-primary',
          link: { text: 'Get Started', url: '' },
          icon: '',
        },
        defaultValue: [
          {
            type: 'btn-primary',
            link: { text: 'Get Started', url: '' },
            icon: '',
          },
          {
            type: 'btn-outline',
            link: { text: 'Book a demo', url: '' },
            icon: 'clock',
          },
        ],
      },
    ],
  };
}
