import { useEffect, useState } from 'react';
import * as NavigationMenu from '@radix-ui/react-navigation-menu';
import { MenuItems } from './menu-items';
import { Icons } from '../Icons';
import { Link } from '../Link';

export function DesktopMenu({ promobarVisibleHeight, settings }) {
  const [navItem, setNavItem] = useState('');
  const { menu, menuCtas } = settings || {};

  useEffect(() => {
    const closeNav = () => {
      setNavItem('');
    };

    window.addEventListener('scroll', closeNav);
    return () => {
      window.removeEventListener('scroll', closeNav);
    };
  }, []);

  return (
    <NavigationMenu.Root value={navItem} onValueChange={setNavItem}>
      <NavigationMenu.List className="hidden items-center lg:-ml-3 lg:flex">
        {menu?.map((menuItem, index) => {
          const type = menuItem?._template;
          const title = menuItem?.title;
          const isActive = title === navItem;

          if (type === 'link') {
            return (
              <NavigationMenu.Item key={`desktopMenu-${index}`}>
                <NavigationMenu.Link
                  className={`flex h-7 items-center justify-center rounded-full py-0 px-3 font-aeonik text-xs leading-[1.125rem] no-underline antialiased transition-colors hover:bg-grey-800 hover:text-primary-light ${
                    // eslint-disable-next-line no-nested-ternary
                    navItem !== ''
                      ? isActive
                        ? 'text-primary-light'
                        : 'text-grey-400'
                      : 'text-primary-light'
                  }`}
                  href={menuItem?.link?.url}
                >
                  {title}
                </NavigationMenu.Link>
              </NavigationMenu.Item>
            );
          }

          if (type === 'menu') {
            const menuItems = menuItem?.megaMenuItems;
            return (
              <NavigationMenu.Item key={`desktopMenu-${index}`} value={title}>
                <NavigationMenu.Trigger
                  className={`flex h-7 items-center justify-center rounded-full py-0 px-3 font-aeonik text-xs leading-[1.125rem] no-underline antialiased transition-colors hover:bg-grey-800 hover:text-primary-light ${
                    // eslint-disable-next-line no-nested-ternary
                    navItem !== ''
                      ? isActive
                        ? 'text-primary-light'
                        : 'text-grey-400'
                      : 'text-primary-light'
                  }`}
                >
                  {title}
                </NavigationMenu.Trigger>

                <NavigationMenu.Content
                  style={{
                    top: `${promobarVisibleHeight + 65}px`,
                  }}
                  className="fixed left-0 right-0 z-20 w-full bg-black/95 backdrop-blur-[12.5px]"
                >
                  <div className="border-y border-y-grey-900">
                    <div className="mx-auto grid w-full max-w-[1400px] grid-cols-4 gap-[50px] px-8 py-10 xl:px-[60px]">
                      <MenuItems
                        menuItems={menuItems}
                        closeNav={() => setNavItem('')}
                      />
                    </div>
                  </div>
                  <div className="mx-auto flex h-20 max-w-[1400px] items-center justify-center gap-1.5 px-8 xl:px-[60px]">
                    {menuCtas?.signUpLink?.url ? (
                      <div className="flex items-center justify-center gap-[5px] font-aeonik text-xs font-medium leading-[1.125rem] text-grey-400 no-underline antialiased">
                        {menuCtas?.signUpLabel ? (
                          <span>{menuCtas.signUpLabel}</span>
                        ) : null}
                        <Link
                          className="text-primary-green-500 transition-colors hover:text-primary-green-100"
                          href={menuCtas.signUpLink.url}
                          newTab={menuCtas.signUpLink.newTab}
                        >
                          {menuCtas.signUpLink.text}
                        </Link>
                      </div>
                    ) : null}
                    {menuCtas?.demo?.url ? (
                      <Link
                        className="flex items-center justify-center gap-1.5 px-3 font-aeonik text-xs font-medium leading-[1.125rem] text-grey-400 no-underline antialiased transition-colors hover:text-primary-light"
                        href={menuCtas.demo.url}
                        newTab={menuCtas.demo.newTab}
                      >
                        <Icons
                          icon="video"
                          className="h-[18px] w-[18px] text-primary-green-500"
                        />
                        {menuCtas.demo.text}
                      </Link>
                    ) : null}
                    {menuCtas?.call?.url ? (
                      <Link
                        className="flex items-center justify-center gap-1.5 px-3 font-aeonik text-xs font-medium leading-[1.125rem] text-grey-400 no-underline antialiased transition-colors hover:text-primary-light"
                        href={menuCtas.call.url}
                        newTab={menuCtas.call.newTab}
                      >
                        <Icons
                          icon="calendarUser"
                          className="h-[18px] w-[18px] text-primary-green-500"
                        />
                        {menuCtas.call.text}
                      </Link>
                    ) : null}
                    <div className="ml-auto flex items-center gap-1.5 rounded-full bg-grey-300 py-[3px] px-3 backdrop-blur-sm">
                      <div className="-ml-2 flex h-[26px] w-[26px] items-center justify-center rounded-full bg-primary-dark p-1">
                        <Icons icon="shopify" />
                      </div>
                      <p className="font-aeonik text-xs font-medium text-primary-dark antialiased">
                        Built for Shopify, powered by Hydrogen
                      </p>
                    </div>
                  </div>
                </NavigationMenu.Content>
              </NavigationMenu.Item>
            );
          }
          return null;
        })}
      </NavigationMenu.List>
    </NavigationMenu.Root>
  );
}
