export function Handshake(props) {
  return (
    <svg
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g opacity="0.65" clipPath="url(#clip0_2211_96802)">
        <path
          d="M0 15.2775C0 13.9274 1.09441 12.833 2.44444 12.833H4.26311C5.61315 12.833 6.70756 13.9274 6.70756 15.2775V29.9441C6.70756 31.2942 5.61315 32.3886 4.26311 32.3886H2.44444C1.09441 32.3886 0 31.2942 0 29.9441V15.2775Z"
          fill="currentColor"
          fillOpacity="0.3"
        />
        <path
          d="M37.2975 15.2775C37.2975 13.9274 38.3919 12.833 39.7419 12.833H41.5606C42.9107 12.833 44.005 13.9274 44.005 15.2775V29.9441C44.005 31.2942 42.9107 32.3886 41.5606 32.3886H39.7419C38.3919 32.3886 37.2975 31.2942 37.2975 29.9441V15.2775Z"
          fill="currentColor"
          fillOpacity="0.3"
        />
        <path
          d="M30.1766 28.7222H37.2777"
          stroke="currentColor"
          strokeWidth="2.64"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M21.4599 11.4154L20.8952 10.6503C20.2034 9.71651 19.1108 9.16406 17.9472 9.16406H14.3832C13.4348 9.16406 12.523 9.53317 11.8385 10.1907L7.27475 14.5907C7.10364 14.7545 6.91297 14.8914 6.70764 14.999"
          stroke="currentColor"
          strokeWidth="2.64"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.72229 28.7229H9.80229C10.543 28.7229 11.2445 29.0602 11.709 29.6371L14.3563 32.9347C15.8132 34.7484 18.3456 35.2862 20.4136 34.218L28.4167 30.0796C31.1325 28.6764 31.9074 25.1516 30.0325 22.7389L26.0041 17.5518"
          stroke="currentColor"
          strokeWidth="2.64"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M37.2973 15.0038C37.0895 14.8963 36.8989 14.7594 36.7278 14.5932L32.164 10.1932C31.4795 9.53562 30.5678 9.1665 29.6193 9.1665H25.2022C24.2147 9.1665 23.2687 9.56495 22.5769 10.2738L16.478 16.5267C15.2607 17.7734 15.2729 19.7681 16.5049 21.0001C17.5975 22.0927 19.316 22.2418 20.5822 21.3545L27.5 16.5023"
          stroke="currentColor"
          strokeWidth="2.64"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1.83337 12.833H4.27782C5.62715 12.833 6.72226 13.9281 6.72226 15.2775V29.9441C6.72226 31.2935 5.62715 32.3886 4.27782 32.3886H1.83337"
          stroke="currentColor"
          strokeWidth="2.64"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M42.1667 12.833H39.7223C38.3729 12.833 37.2778 13.9281 37.2778 15.2775V29.9441C37.2778 31.2935 38.3729 32.3886 39.7223 32.3886H42.1667"
          stroke="currentColor"
          strokeWidth="2.64"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2211_96802">
          <rect width="44" height="44" fill="currentColor" />
        </clipPath>
      </defs>
    </svg>
  );
}
