export function CheckCircleFillLine(props) {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="Frame" clipPath="url(#clip0_2410_69362)">
        <g id="Group">
          <path
            id="Vector"
            d="M19.389 10.8333H16.889C16.429 10.8333 16.0557 10.46 16.0557 9.99996C16.0557 9.53996 16.429 9.16663 16.889 9.16663H19.389C19.849 9.16663 20.2223 9.53996 20.2223 9.99996C20.2223 10.46 19.849 10.8333 19.389 10.8333Z"
            fill="currentColor"
          />
          <path
            id="Vector_2"
            d="M4.11117 10.8333H1.61117C1.15117 10.8333 0.777832 10.46 0.777832 9.99996C0.777832 9.53996 1.15117 9.16663 1.61117 9.16663H4.11117C4.57117 9.16663 4.9445 9.53996 4.9445 9.99996C4.9445 10.46 4.57117 10.8333 4.11117 10.8333Z"
            fill="currentColor"
          />
          <path
            id="Vector_3"
            d="M10.5001 2.77777C6.51783 2.77777 3.27783 6.01777 3.27783 9.99999C3.27783 13.9822 6.51783 17.2222 10.5001 17.2222C14.4823 17.2222 17.7223 13.9822 17.7223 9.99999C17.7223 6.01777 14.4823 2.77777 10.5001 2.77777ZM13.9423 8.05444L10.1678 12.9622C10.0212 13.1522 9.80005 13.27 9.56117 13.2867C9.54228 13.2867 9.5245 13.2878 9.50672 13.2878C9.28672 13.2878 9.0745 13.2 8.91783 13.0433L7.13005 11.2555C6.8045 10.93 6.8045 10.4022 7.13005 10.0767C7.45561 9.7511 7.98339 9.7511 8.30894 10.0767L9.42561 11.1933L12.6223 7.03777C12.9023 6.67222 13.4267 6.60555 13.7901 6.88444C14.1556 7.16555 14.2234 7.68999 13.9423 8.05444Z"
            fill="currentColor"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_2410_69362">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
