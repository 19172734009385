import { Schema } from './TextHeading.schema';

import { Section } from '../../snippets';

export function TextHeading({ cms }) {
  const { heading, subheading } = cms;
  return (
    <Section cms={cms}>
      <div className="my-8 mx-auto max-w-7xl px-4 text-center md:my-24 md:px-8">
        {heading ? (
          <h1 className="tracking-tight selection:bg-brand md:text-[56px]">
            {heading}
          </h1>
        ) : null}
        {subheading ? (
          <p className="sub-heading mt-4 text-white-700 selection:bg-brand selection:text-white">
            {subheading}
          </p>
        ) : null}
      </div>
    </Section>
  );
}

TextHeading.displayName = 'TextHeading';
TextHeading.Schema = Schema;
