import { useSettings } from '@backpackjs/storefront';
import { MarqueeComponent } from '../../snippets/marquee';
import { Schema } from './ValueLogos.schema';

export function ValueLogos({ cms }) {
  const settings = useSettings();
  const logoArray = settings?.logos?.logoMarquee;
  const { heading, values = [] } = cms || {};

  return (
    <div className="flex flex-col gap-10 bg-primary-light py-[60px] lg:gap-[60px] lg:py-[120px]">
      <div className="mx-auto max-w-8xl">
        <div className="flex flex-col gap-10 px-5 lg:gap-[60px] lg:px-[100px]">
          {heading ? (
            <h2 className="text-center font-aeonik text-4xl font-normal text-primary-dark antialiased lg:text-[3.5rem] lg:leading-[3.75rem]">
              {heading}
            </h2>
          ) : null}
          {values?.length > 0 ? (
            <div className="flex flex-col items-start gap-5 lg:flex-row">
              {values.map(({ value, label }, index) => (
                <div
                  key={index}
                  className="group flex w-full flex-grow flex-col items-start justify-end gap-4 p-5 hover:cursor-pointer lg:p-[25px]"
                >
                  <p className="font-aeonik text-[3.5rem] leading-none text-primary-dark antialiased transition-colors group-hover:text-primary-green-500">
                    {value}
                  </p>
                  <p className="font-aeonik text-base leading-5 text-grey-600 antialiased">
                    {label}
                  </p>
                </div>
              ))}
            </div>
          ) : null}
        </div>
      </div>
      <MarqueeComponent
        logoMarquee={logoArray}
        imageClasses="mx-8 h-5 brightness-0"
        enableHover={false}
      />
    </div>
  );
}

ValueLogos.displayName = 'ValueLogos';
ValueLogos.Schema = Schema;
