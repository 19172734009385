import { forwardRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { A11y, EffectFade, Autoplay, Navigation } from 'swiper';
import { useSettings } from '@backpackjs/storefront';
import { Link } from '../Link';
import { Markdown } from '../markdown';
import { Icons } from '../Icons';

export const Promobar = forwardRef(
  ({ promobarAutoHide, promobarDisabled, promobarHidden }, ref) => {
    const settings = useSettings();
    const { promobar } = { ...settings?.header };
    const { delay, effect, enabled, messages, speed } = {
      ...promobar,
    };

    const swiperParams = {
      autoplay: {
        delay: delay || 5000,
        disableOnInteraction: false,
      },
      direction: effect === 'slide-vertical' ? 'vertical' : 'horizontal',
      effect: effect?.split('-')[0] || 'fade',
      fadeEffect: {
        crossFade: true,
      },
      loop: messages?.length > 1,
      modules: [A11y, Autoplay, EffectFade, Navigation],
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      speed: speed || 500,
      style: {
        '--swiper-navigation-size': '12px',
      },
    };

    return (
      <div
        className={`overflow-hidden transition-[height] ease-out ${
          promobarHidden || promobarDisabled
            ? 'duration-[50ms]'
            : 'duration-300'
        } ${promobarAutoHide ? '' : 'sticky top-0'}`}
        ref={ref}
      >
        {enabled && messages?.length ? (
          <Swiper
            {...swiperParams}
            className="swiper-wrapper-center relative flex h-full items-center"
          >
            {messages.map(({ message, link, additionalDetails }, index) => {
              return (
                <SwiperSlide key={index} className="px-2 pt-2">
                  <div className="promobar-markdown box-content flex h-[25px] items-center justify-center rounded-full bg-grey-900 py-1.5 px-5 text-center font-aeonik text-2xs leading-none text-primary-light md:py-1 md:text-xs md:leading-6">
                    <Link
                      aria-label={message}
                      className="flex select-none flex-col items-center justify-center gap-1.5 md:flex-row md:gap-[15px]"
                      draggable={false}
                      href={link.url}
                      type={link.type}
                      newTab={link.newTab}
                      passHref
                    >
                      <Markdown>{message}</Markdown>
                      {additionalDetails && (
                        <div className="flex items-center gap-[15px]">
                          {additionalDetails?.map(
                            ({ icon, text }, detailIndex) => {
                              return (
                                <div
                                  className="flex items-center justify-center gap-1 md:gap-2"
                                  key={`promobar-detail-${detailIndex}`}
                                >
                                  {icon ? (
                                    <Icons
                                      icon={icon}
                                      className="h-3 w-3 text-primary-green-500 md:h-4 md:w-4"
                                    />
                                  ) : null}
                                  {text ? <span>{text}</span> : null}
                                </div>
                              );
                            }
                          )}
                        </div>
                      )}
                    </Link>
                  </div>
                </SwiperSlide>
              );
            })}

            {/* {messages?.length >= 2 &&
            <div>
              <button
                aria-label="See previous slide"
                className="swiper-button-prev !left-4 md:!left-8 xl:!left-12"
                type="button"
              />

              <button
                aria-label="See next slide"
                className="swiper-button-next !right-4 md:!right-8 xl:!right-12"
                type="button"
              />
            </div>
          } */}
          </Swiper>
        ) : null}
      </div>
    );
  }
);

Promobar.displayName = 'Promobar';
