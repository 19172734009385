export function Schema() {
  return {
    label: 'Simple Page Header',
    key: 'simple-page-header',
    fields: [
      {
        label: 'Page Header',
        name: 'pageHeader',
        component: 'text',
      },
      {
        label: 'Page Description',
        name: 'pageDescription',
        component: 'text',
      },
    ],
  };
}
