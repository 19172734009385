import { useCallback, useEffect, useState } from 'react';

function timeToSeconds(timeStr) {
  if (!timeStr) return null;
  // Split the time string into minutes and seconds
  const [minutesStr, secondsStr] = timeStr.split(':');

  // Parse the minutes and seconds as integers
  const minutes = parseInt(minutesStr, 10);
  const seconds = parseInt(secondsStr, 10);

  // Calculate the total number of seconds
  const totalSeconds = minutes * 60 + seconds;

  return totalSeconds;
}

function secondsToMinutesAndSeconds(totalSeconds) {
  if (typeof totalSeconds !== 'number' || totalSeconds < 0) {
    return 'Invalid input';
  }

  const minutes = Math.floor(totalSeconds / 60);
  const seconds = totalSeconds % 60;

  return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
}

function isActiveChapter(arr, i, threshold) {
  const currentIndexValue = timeToSeconds(arr[i]?.timestamp);
  const nextIndexValue = timeToSeconds(arr[i + 1]?.timestamp);

  if (
    (currentIndexValue <= threshold && nextIndexValue === null) ||
    (currentIndexValue <= threshold && threshold < nextIndexValue)
  ) {
    return true;
  }

  return false;
}

function transformChapters(chapters, currentTime) {
  const arr = chapters.map((chapter) => {
    const updatedChapter = {
      ...chapter,
      isActive: false,
      subchapters: chapter?.subchapters?.map((item) => ({
        ...item,
        isActive: false,
      })),
    };
    return updatedChapter;
  });

  const threshold = timeToSeconds(currentTime);

  for (let i = 0; i < arr.length; i++) {
    // finds active chapter
    const chapterIsActive = isActiveChapter(arr, i, threshold);
    if (chapterIsActive) {
      arr[i].isActive = true;
      const { subchapters } = arr[i];

      // if chapter contains subchapters, find active subchapter
      if (subchapters?.length > 0) {
        for (let j = 0; j < subchapters.length; j++) {
          const subChapterIsActive = isActiveChapter(subchapters, j, threshold);
          if (subChapterIsActive) {
            subchapters[j].isActive = true;
          }
        }
      }
    }
  }
  return arr;
}

export const useVideoHero = (videoEl, chapters) => {
  const [removePosterOverlay, setRemovePosterOvelay] = useState(false);
  const [showTimeline, setShowTimeline] = useState(true);
  const [videoCurrentTime, setVideoCurrentTime] = useState('0:00');
  const [videoProgressPercentage, setVideoProgressPercentage] = useState(0);
  const [transformedChapters, setTransformedChapters] = useState([]);

  const handleVideoJump = useCallback(
    (value) => {
      if (!value || !videoEl?.current) return;
      setRemovePosterOvelay(true);
      const seconds = timeToSeconds(value);

      videoEl.current?.play();
      videoEl.current?.pause();
      videoEl.current.currentTime = seconds;
      videoEl.current?.play();
    },
    [videoEl]
  );

  useEffect(() => {
    const trackVideoTime = () => {
      const currentTime = videoEl?.current?.currentTime;
      const duration = videoEl?.current?.duration;
      if (currentTime) {
        const convertedTime = secondsToMinutesAndSeconds(
          Math.floor(currentTime)
        );
        setVideoCurrentTime(convertedTime);
        setVideoProgressPercentage((currentTime / duration) * 100);
      }
    };

    ['timeupdate', 'seeking'].forEach((evt) =>
      videoEl?.current?.addEventListener(evt, trackVideoTime)
    );
    return () => {
      ['timeupdate', 'seeking'].forEach((evt) =>
        videoEl?.current?.removeEventListener(evt, trackVideoTime)
      );
    };
  }, []);

  // set Active Chapters
  useEffect(() => {
    const activeChapters = transformChapters(chapters, videoCurrentTime);
    setTransformedChapters(activeChapters);
  }, [videoCurrentTime, chapters]);

  return {
    handleVideoJump,
    removePosterOverlay,
    setRemovePosterOvelay,
    showTimeline,
    setShowTimeline,
    transformedChapters,
    videoCurrentTime,
    videoProgressPercentage,
  };
};
