export function ConnectedBlocks(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      {...props}
    >
      <path
        d="M5.75 2.93652H3.25C2.69772 2.93652 2.25 3.38424 2.25 3.93652V6.43652C2.25 6.98881 2.69772 7.43652 3.25 7.43652H5.75C6.30228 7.43652 6.75 6.98881 6.75 6.43652V3.93652C6.75 3.38424 6.30228 2.93652 5.75 2.93652Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.75 11.9365H12.25C11.6977 11.9365 11.25 12.3842 11.25 12.9365V15.4365C11.25 15.9888 11.6977 16.4365 12.25 16.4365H14.75C15.3023 16.4365 15.75 15.9888 15.75 15.4365V12.9365C15.75 12.3842 15.3023 11.9365 14.75 11.9365Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.75 4.93652H11.75C12.855 4.93652 13.75 5.83152 13.75 6.93652V8.93652"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.25 14.4365H6.25C5.145 14.4365 4.25 13.5415 4.25 12.4365V10.4365"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
