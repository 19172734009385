export function TwoWay(props) {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="Frame">
        <g id="Group">
          <path
            id="Vector"
            d="M18.8892 10.8398C18.8892 10.2698 18.8303 9.71426 18.7269 9.17315L8.1236 9.17315L8.9236 9.97315C9.24916 10.2987 9.24916 10.8265 8.9236 11.152C8.59805 11.4776 8.07027 11.4776 7.74472 11.152L5.52249 8.92982C5.36027 8.76759 5.27805 8.55426 5.27805 8.34093C5.27805 8.12759 5.35916 7.91426 5.52249 7.75204L7.74472 5.52982C8.07027 5.20426 8.59805 5.20426 8.9236 5.52982C9.24916 5.85537 9.24916 6.38315 8.9236 6.7087L8.1236 7.5087L18.2347 7.50871C16.9125 4.25315 13.7225 1.95093 10.0003 1.95093C5.09916 1.95093 1.11138 5.9387 1.11138 10.8398C1.11138 11.4098 1.17027 11.9654 1.2736 12.5065L11.8769 12.5065L11.0769 11.7065C10.7514 11.3809 10.7514 10.8531 11.0769 10.5276C11.4025 10.202 11.9303 10.202 12.2558 10.5276L14.478 12.7498C14.8036 13.0754 14.8036 13.6031 14.478 13.9287L12.2558 16.1509C11.9303 16.4765 11.4025 16.4765 11.0769 16.1509C10.9147 15.9887 10.8325 15.7754 10.8325 15.562C10.8325 15.3487 10.9136 15.1354 11.0769 14.9731L11.8769 14.1731L1.76471 14.1731C3.08694 17.4265 6.27694 19.7287 9.99916 19.7287C14.9003 19.7287 18.888 15.7409 18.888 10.8398L18.8892 10.8398Z"
            fill="currentColor"
          />
        </g>
      </g>
    </svg>
  );
}
