import { useCallback, useState, useEffect } from 'react';

import { Icons } from '../../snippets/Icons';

import { useGlobalContext } from '../../contexts';

export function TestimonialMasonryModal({ item, testimonials }) {
  const {
    actions: { closeOverlay },
  } = useGlobalContext();

  const [currentSlide, setCurrentSlide] = useState(item || {});
  const [previousSlide, setPreviousSlide] = useState(null);
  const [nextSlide, setNextSlide] = useState(null);

  const updateSlide = useCallback(
    (direction) => {
      if (direction === 'previous') {
        const activeSlide = testimonials[currentSlide.index - 1];
        if (activeSlide) {
          setCurrentSlide({ ...activeSlide, index: currentSlide.index - 1 });
        }
      } else {
        const activeSlide = testimonials[currentSlide.index + 1];
        if (activeSlide) {
          setCurrentSlide({ ...activeSlide, index: currentSlide.index + 1 });
        }
      }
    },
    [testimonials, currentSlide]
  );

  useEffect(() => {
    if (currentSlide) {
      setPreviousSlide(testimonials[currentSlide.index - 1]);
      setNextSlide(testimonials[currentSlide.index + 1]);
    }
  }, [testimonials, currentSlide]);

  useEffect(() => {
    const onKeyDown = (e) => {
      if (e.keyCode === 39) {
        updateSlide('next');
      } else if (e.keyCode === 37) {
        updateSlide('previous');
      }
    };

    window.addEventListener('keydown', onKeyDown);
    return () => {
      window.removeEventListener('keydown', onKeyDown);
    };
  }, [updateSlide]);

  const { quote, expandedQuote, profile, name, title, date } = {
    ...currentSlide,
  };

  return (
    <div className="relative flex flex-col flex-wrap md:flex-row">
      <div
        className="hidden h-auto w-[75px] md:block"
        role="button"
        aria-hidden="true"
        onClick={closeOverlay}
      />

      <div className="flex-1 rounded-[20px] border border-grey-900 bg-[#060606] p-6">
        <blockquote className="mb-10 md:mb-[80px]">
          <p className="text-sm font-light text-[#ffffffbf] sm:text-base md:text-[32px] md:leading-[1.3]">
            {expandedQuote || quote}
          </p>
        </blockquote>

        <div className="flex gap-[10px] md:items-end">
          {profile?.src && (
            <div className="flex h-12 w-12 overflow-hidden rounded-full">
              <img className="object-cover" src={profile.src} alt={name} />
            </div>
          )}

          <div className="flex min-w-fit flex-col">
            {name && <div className="text-[15px] leading-6">{name}</div>}

            <div className="flex items-center">
              {title && (
                <p className="text-[13px] leading-6 text-[#ffffffa6]">
                  {title}
                </p>
              )}

              {date && (
                <>
                  <span className="mx-2 text-base font-normal leading-6 text-[#ffffffa6]">
                    •
                  </span>
                  <p className="text-[13px] leading-6 text-[#ffffffa6]">
                    {date}
                  </p>
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="mt-4 flex gap-x-2">
        <button
          className={`btn-outline left-0 top-[calc(50%-28px)] h-14 w-14 border-grey-900 bg-[#060606] p-0 md:absolute ${
            previousSlide ? '' : 'cursor-not-allowed opacity-30'
          }`}
          type="button"
          onClick={() => {
            if (previousSlide) updateSlide('previous');
          }}
          aria-label="Previous"
        >
          <Icons icon="arrowLeft" className="h-6 w-6" />
        </button>

        <button
          className={`btn-outline right-0 top-[calc(50%-28px)] h-14 w-14 border-grey-900 bg-[#060606] p-0 md:absolute ${
            nextSlide ? '' : 'cursor-not-allowed opacity-30'
          }`}
          type="button"
          onClick={() => {
            if (nextSlide) updateSlide('next');
          }}
          aria-label="Next"
        >
          <Icons icon="arrowLeft" className="h-6 w-6 rotate-180" />
        </button>
      </div>

      <div
        className="hidden h-auto w-[75px] md:block"
        role="button"
        aria-hidden="true"
        onClick={closeOverlay}
      />
    </div>
  );
}

TestimonialMasonryModal.displayName = 'TestimonialMasonryModal';
