import { useEffect, useState } from 'react';

import { Schema } from './FeatureTabs.schema';
import { Icons } from '../../snippets/Icons';

import { Lotties } from '../../snippets/Lotties';
import { Markdown } from '../../snippets/markdown';

import { Link } from '../../snippets/Link';
import { Section } from '../../snippets';
import { Logo } from '../../icons/logo';
import { Button } from '../../snippets/button';

export function FeatureTabs({ cms }) {
  const {
    icon,
    alignment,
    heading,
    superheading,
    cta,
    tabs,
    footerHeading,
    footerLink,
    footerBg,
  } = cms;
  const [activeTab, setActiveTab] = useState(0);
  const defaultSeconds = 5;

  useEffect(() => {
    if (!tabs || tabs.length === 0) return () => null;
    const seconds = parseInt(tabs[activeTab].timer, 10) || defaultSeconds;
    const timer = setTimeout(() => {
      setActiveTab(activeTab === tabs.length - 1 ? 0 : activeTab + 1);
    }, seconds * 1000);
    return () => {
      clearTimeout(timer);
    };
  }, [activeTab]);

  const tabWithLargestAspectRatio = tabs?.reduce((carry, tab, index) => {
    const aspectRatio = (tab?.image?.width || 0) / (tab?.image?.height || 0);

    if (aspectRatio > carry) {
      return index;
    }
    return carry;
  }, 0);

  if (!tabs || tabs.length === 0) return null;

  return (
    <Section cms={cms}>
      <div className="mx-auto max-w-8xl px-5 py-[60px] lg:px-[120px] lg:py-[100px]">
        <div className="mx-auto flex max-w-7xl flex-col gap-10 lg:gap-[60px]">
          <div className="flex items-start justify-between gap-10">
            <div
              className={`flex flex-col gap-[25px] ${
                alignment === 'left' ? 'max-w-[716px] text-left' : 'text-center'
              }`}
            >
              {icon ? (
                <div className="flex items-center gap-1 self-start rounded-lg bg-primary-light pr-1 lg:rounded-xl">
                  <Logo
                    className="h-10 w-10 lg:h-12 lg:w-12"
                    style={{ color: '#030303' }}
                  />
                  <div
                    className={`flex h-8 w-8 items-center justify-center rounded-lg  lg:h-10 lg:w-10 lg:rounded-[10px] ${
                      icon === 'storefront'
                        ? 'bg-primary-green-500'
                        : 'bg-primary-blue'
                    }`}
                  >
                    <Icons
                      icon={icon}
                      className="h-[30px] self-end lg:h-[38px]"
                    />
                  </div>
                </div>
              ) : null}

              {superheading && (
                <h3 className="sub-heading text-brand">{superheading}</h3>
              )}

              {heading ? (
                <h2 className="text-4xl font-normal leading-[2.375rem] text-primary-light antialiased lg:text-[3.5rem] lg:leading-[3.625rem]">
                  {heading}
                </h2>
              ) : null}
            </div>

            {cta?.link?.url && alignment === 'left' ? (
              <Button
                link={cta.link}
                icon={cta.icon}
                iconPosition={cta.iconPosition}
                className="hidden lg:flex"
              />
            ) : null}
          </div>

          <div className="flex flex-col gap-10 lg:gap-[30px]">
            <div className="black-02-gradient overflow-hidden rounded-3xl md:pt-8">
              <div
                className={`pointer-events-none relative ${
                  tabs[activeTab].fullWidth ? '' : 'md:mx-14'
                }`}
              >
                {tabs?.map((tab, index) => {
                  const isActive = index === activeTab;

                  if (tab?.lottie && isActive) {
                    return <Lotties lottie={tab?.lottie} play loop={false} />;
                  }

                  if (tab.image?.src && !tab?.lottie) {
                    return (
                      <img
                        className={`w-full ${
                          isActive ? 'opacity-1' : 'opacity-0'
                        } ${
                          tabWithLargestAspectRatio !== index
                            ? 'absolute inset-0'
                            : ''
                        }`}
                        key={index}
                        src={tab.image.src}
                        alt={tab.image.alt}
                      />
                    );
                  }

                  return null;
                })}
              </div>
            </div>

            <nav className="flex flex-col gap-5 md:flex-row md:gap-[30px]">
              {tabs.map((tab, index) => {
                const isActive = index === activeTab;
                return (
                  <button
                    key={`featureTabDesktop-${index}`}
                    type="button"
                    className={`${
                      isActive ? 'bg-grey-800' : 'bg-grey-900'
                    } flex w-full flex-col gap-4 rounded-3xl p-5 text-left transition-colors duration-300 hover:bg-grey-800 lg:p-[25px]`}
                    onClick={() => setActiveTab(index)}
                  >
                    <Icons icon={tab.icon} className="h-8 w-8 text-grey-300" />

                    {tab.label ? (
                      <p className="mt-auto font-aeonik text-xl font-medium leading-6 text-primary-light antialiased">
                        {tab.label}
                      </p>
                    ) : null}

                    <Markdown classes="[&_*]:!my-0 font-aeonik text-sm font-normal text-grey-300 max-sm:max-w-[75%] [&_strong]:font-medium antialiased">
                      {tab.description}
                    </Markdown>
                  </button>
                );
              })}
            </nav>

            <nav className="hidden justify-center md:flex">
              <div className="inline-flex justify-center gap-2 rounded-lg bg-[rgba(255,255,255,.10)] p-2">
                {tabs.map((tab, index) => {
                  const isActive = index === activeTab;
                  return (
                    <button
                      key={`featureTabDesktop-${index}`}
                      type="button"
                      className="flex w-[30px] flex-col gap-5"
                      onClick={() => setActiveTab(index)}
                    >
                      <div className="progress-bar-light progress-bar-slim mt-auto">
                        {isActive ? (
                          <span
                            className="progress-bar-inner progress-bar-slim progress-bar-animation"
                            style={{
                              animationDuration: `${
                                tab.timer || defaultSeconds
                              }s`,
                              animationIterationcount: tab?.lottie
                                ? 1
                                : 'infinite',
                            }}
                          />
                        ) : null}
                      </div>
                    </button>
                  );
                })}
              </div>
            </nav>
          </div>

          <Link
            className="brand-light-gradient group container hidden overflow-hidden rounded-3xl"
            href={footerLink.url || '/'}
            newTab={footerLink?.newTab}
          >
            <div
              className="flex w-full items-center bg-cover bg-right bg-no-repeat py-[51px] lg:bg-contain"
              style={{
                backgroundImage: footerBg?.src ? `url(${footerBg.src})` : null,
              }}
            >
              <div className="flex max-w-[278px] flex-col gap-4">
                {footerHeading ? (
                  <h2 className="text-[34px] leading-10 tracking-tight text-black">
                    {footerHeading}
                  </h2>
                ) : null}
                {footerLink.text ? (
                  <div className="flex items-center gap-2 text-primary-green-600">
                    <span className="text-base font-semibold">
                      {footerLink.text}
                    </span>
                    <Icons icon="arrowRight" className="h-5 w-5" />
                  </div>
                ) : null}
              </div>
            </div>
          </Link>

          {cta?.link?.url ? (
            <Button
              link={cta.link}
              icon={cta.icon}
              iconPosition={cta.iconPosition}
              className="lg:hidden"
            />
          ) : null}
        </div>
      </div>
    </Section>
  );
}

FeatureTabs.displayName = 'FeatureTabs';
FeatureTabs.Schema = Schema;
