export default {
  label: 'Logo Carousel',
  name: 'logos',
  component: 'group',
  description: 'Logos for the logo carousel used throughout the site',
  fields: [
    {
      label: 'Logos Marquee',
      name: 'logoMarquee',
      component: 'group-list',
      itemProps: {
        label: '{{item.imageAlt}}',
      },
      fields: [
        {
          label: 'Logo',
          name: 'imageLogo',
          component: 'image',
        },
        {
          label: 'Image Alt Text',
          name: 'imageAlt',
          component: 'text',
        },
      ],
    },
  ],
};
