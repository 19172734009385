export function Schema() {
  return {
    label: 'Email Signup',
    key: 'email-signup',
    fields: [
      {
        label: 'Heading',
        name: 'heading',
        component: 'text',
        defaultValue: 'Email Signup Heading',
      },
      {
        label: 'Description',
        name: 'description',
        component: 'textarea',
        defaultValue: 'Email Signup Description',
      },
      {
        label: 'Hubspot ID',
        name: 'formId',
        component: 'text',
        defaultValue: 'hubspot-form-id',
      },
      {
        label: 'Button Label',
        name: 'label',
        component: 'text',
        defaultValue: 'Subscribe',
      },
      {
        label: 'Form Submission Redirect',
        name: 'formRedirect',
        component: 'text',
        description: 'URL to page. Leave blank to not redirect.',
      },
      {
        label: 'Privacy Policy Text',
        name: 'policyText',
        component: 'text',
        defaultValue: 'We care about your data in our ',
      },
      {
        label: 'Privacy Policy Link',
        name: 'policyLink',
        component: 'link',
        defaultValue: {
          text: 'privacy policy',
          url: '',
        },
      },
    ],
  };
}
