import { createRef, useState, useEffect, useRef } from 'react';
import { useInView } from 'react-intersection-observer';
import { Schema } from './FeatureSteps.schema';
import { Icons } from '../../snippets/Icons';
import { FeatureStepsMain } from './FeatureStepsMain';
import { FeatureStepsNavItem } from './FeatureStepsNavItem';
import { Lotties } from '../../snippets/Lotties';

import { Section } from '../../snippets';
import { Button } from '../../snippets/button';

export function FeatureSteps({ cms }) {
  const { steps, ...mainProps } = cms;

  const [activeStepIndex, setActiveStepIndex] = useState(0);
  const [showNav, setShowNav] = useState(false);

  const stepsRefs = useRef(
    steps?.reduce((carry, _, index) => {
      return {
        ...carry,
        [index]: createRef(),
      };
    }, {}) || null
  );

  const navRef = useRef();
  const containerRef = useRef();

  const { ref: viewRef, inView } = useInView({
    triggerOnce: false,
    threshold: 0.3,
  });

  useEffect(() => {
    const toggleNav = () => {
      const containerBottomPos =
        containerRef.current.getBoundingClientRect().height +
        containerRef.current.getBoundingClientRect().top;

      const navTopPos = navRef.current.getBoundingClientRect().top;
      const hideNav = window.innerHeight >= containerBottomPos;
      setShowNav(navTopPos === 0 && !hideNav);
    };

    const showActiveStep = () => {
      let activeIndex = null;
      Object.keys(stepsRefs.current)
        .reverse()
        .forEach((key) => {
          const step = stepsRefs.current[key];
          if (!step?.current || activeIndex) return;
          const stepTopPos = step.current.getBoundingClientRect().top;
          if (stepTopPos === 0) {
            activeIndex = key;
            return;
          }

          if (stepTopPos < 0) {
            activeIndex = key;
          }
        });
      setActiveStepIndex(activeIndex ? parseInt(activeIndex, 10) : 0);
    };

    const onScroll = () => {
      if (!stepsRefs?.current) return;
      toggleNav();
      showActiveStep();
    };

    ['resize', 'scroll'].forEach((event) => {
      window.addEventListener(event, onScroll);
    });
    return () => {
      ['resize', 'scroll'].forEach((event) => {
        window.removeEventListener(event, onScroll);
      });
    };
  }, []);

  return (
    <Section cms={cms}>
      <div className="flex flex-col gap-[60px] bg-white py-[60px] px-5">
        <FeatureStepsMain props={mainProps} />
        <div className="mx-auto max-w-7xl">
          <div
            ref={navRef}
            className={`px-contained sticky top-0 z-[2] hidden gap-2.5 pt-[100px] transition-opacity duration-300 lg:flex 2xl:px-0 ${
              showNav ? 'opacity-100' : 'opacity-0'
            }`}
            style={{
              marginBottom: 'calc(100vh - 140px)',
            }}
          >
            {mainProps.hideStepsIndicator
              ? null
              : steps?.map(({ stepName }, index) => {
                  return (
                    <FeatureStepsNavItem
                      activeStepIndex={activeStepIndex}
                      containerRef={containerRef}
                      index={index}
                      key={index}
                      stepName={stepName}
                      stepsRefs={stepsRefs}
                    />
                  );
                })}
          </div>

          <ul
            ref={containerRef}
            className="relative flex flex-col gap-[60px] lg:mt-[-100vh] 2xl:px-0"
          >
            {steps?.map(
              (
                {
                  heading,
                  description,
                  imageAlt,
                  image,
                  lottie,
                  listItems,
                  insights,
                },
                index
              ) => {
                return (
                  <li
                    className="top-0 transition-opacity duration-300 max-lg:!opacity-100 lg:sticky"
                    ref={stepsRefs?.current[index]}
                    style={{
                      opacity: index === activeStepIndex ? 1 : 0,
                      pointerEvents:
                        index === activeStepIndex ? 'auto' : 'none',
                    }}
                  >
                    <div
                      className="grid grid-cols-1 items-center gap-x-12 text-black lg:h-screen lg:min-h-[600px] lg:grid-cols-2 lg:pt-[32.5px]"
                      key={index}
                      ref={index === 0 ? viewRef : undefined}
                    >
                      <div className="flex flex-col gap-y-6 lg:max-w-[511px] lg:pl-10 lg:pr-5">
                        {heading && (
                          <h2 className="h2 text-[28px] text-primary-dark md:text-[40px]">
                            {heading}
                          </h2>
                        )}
                        {description && (
                          <p className="font-aeonik text-[20px] antialiased md:text-[22px]">
                            {description}
                          </p>
                        )}

                        {listItems?.length > 0 && (
                          <ul className="flex flex-col gap-3">
                            {listItems.map((item, itemIndex) => {
                              return (
                                <li
                                  className="flex items-center gap-3"
                                  key={itemIndex}
                                >
                                  {item.icon && (
                                    <Icons
                                      icon={item.icon}
                                      className="h-6 w-6 shrink-0 text-primary-dark"
                                    />
                                  )}
                                  <p className="font-aeonik leading-[1.375rem] text-grey-600 antialiased">
                                    {item.description}
                                  </p>
                                </li>
                              );
                            })}
                          </ul>
                        )}

                        {insights?.length > 0 && (
                          <ul className="flex flex-wrap justify-center gap-5 max-lg:-mx-5 lg:justify-start lg:gap-12">
                            {insights.map((insight, insightsIndex) => {
                              return (
                                <div
                                  key={`insights-${insightsIndex}`}
                                  className="flex max-w-[135px] flex-col"
                                >
                                  <h3 className="h2 mb-2 whitespace-nowrap text-[40px] md:text-[44px]">
                                    {insight.heading}
                                  </h3>
                                  <p className="mb-3 whitespace-pre-line text-base lg:text-sm">
                                    {insight.description}
                                  </p>
                                </div>
                              );
                            })}
                          </ul>
                        )}
                      </div>

                      {/* eslint-disable-next-line no-nested-ternary */}
                      {lottie ? (
                        <div className="max-lg:mt-10">
                          {((index > 0 && index === activeStepIndex) ||
                            (index === 0 && inView)) && (
                            <Lotties
                              className="mx-auto overflow-hidden rounded-[30px] bg-grey-50 max-lg:max-w-[600px]"
                              lottie={lottie}
                              play
                              loop={false}
                            />
                          )}
                        </div>
                      ) : image?.mediaType === 'VIDEO' ? (
                        <video
                          className="mx-auto w-full overflow-hidden rounded-[30px] max-lg:max-w-[600px]"
                          autoPlay
                          muted
                          playsInline
                          loop
                        >
                          <source src={image.src} type="video/mp4" />
                        </video>
                      ) : (
                        <picture className="max-lg:mt-10">
                          <img
                            className="mx-auto w-full overflow-hidden rounded-[30px] max-lg:max-w-[600px]"
                            src={image?.src}
                            alt={imageAlt}
                          />
                        </picture>
                      )}
                    </div>
                  </li>
                );
              }
            )}
            {/* extra child to show last step a little longer as your scroll down */}
            <li className="pointer-events-none sticky top-0 hidden lg:block">
              <div className="grid h-screen min-h-[600px] grid-cols-2 items-center gap-x-12 text-black lg:pt-[32.5px]" />
            </li>
          </ul>
        </div>

        {mainProps?.cta?.link?.url ? (
          <Button
            link={mainProps.cta.link}
            icon={mainProps.cta.icon}
            iconPosition={mainProps.cta.iconPosition}
            className="lg:hidden"
          />
        ) : null}
      </div>
    </Section>
  );
}

FeatureSteps.displayName = 'FeatureSteps';
FeatureSteps.Schema = Schema;
