import { useEffect } from 'react';
import { getCalApi } from '@calcom/embed-react';
import { sendGTMEvent } from '@next/third-parties/google';

import { Icons } from '../../snippets/Icons';
import { Schema } from './FeatureImage.schema';
import { FadeIn } from '../../animations/FadeIn';

import { Link } from '../../snippets/Link';
import { Section } from '../../snippets';

export function FeatureImage({ cms }) {
  const { heading, subheading, imageAlt, imageMobile, imageDesktop, buttons } =
    cms;

  useEffect(() => {
    (async function () {
      const cal = await getCalApi();
      cal('ui', {
        theme: 'dark',
        styles: {
          branding: { brandColor: '#000000' },
        },
      });

      cal('on', {
        action: 'bookingSuccessful',
        callback: (e) => {
          // `data` is properties for the event.
          // `type` is the name of the action
          // `namespace` is the Cal namespace for which the event is fired

          const { data } = e.detail;

          sendGTMEvent({
            event: 'calendar_booking_successful',
            bookingId: data?.booking?.uid,
          });
        },
      });
    })();
  }, []);

  return (
    <Section cms={cms}>
      <div className="flex flex-col px-0 pt-16 pb-8 md:px-20 md:pt-[100px] md:pb-[60px]">
        <div className="mb-8 px-4 md:mb-[60px]">
          {heading && (
            <FadeIn>
              <h2 className="text-center selection:bg-brand">{heading}</h2>
            </FadeIn>
          )}
          {subheading && (
            <FadeIn>
              <p className="sub-heading mt-3 whitespace-pre-line text-center selection:bg-brand md:mt-5 md:whitespace-normal">
                {subheading}
              </p>
            </FadeIn>
          )}
        </div>
        {(imageDesktop || imageMobile) && (
          <div className="flex justify-center md:order-2">
            <FadeIn>
              <picture className="w-full">
                {imageDesktop && imageMobile && (
                  <source
                    srcSet={imageDesktop.src}
                    media="(min-width: 768px)"
                  />
                )}
                <img
                  className="w-full"
                  src={imageMobile?.src || imageDesktop?.src}
                  alt={imageAlt}
                />
              </picture>
            </FadeIn>
          </div>
        )}

        {buttons.length > 0 && (
          <div className="mt-9 flex flex-col-reverse gap-3 px-4 md:order-1 md:mt-0 md:mb-16 md:flex-row md:justify-center md:px-0">
            {buttons.map((button, index) => {
              if (button?.scheduleLink?.calId) {
                return (
                  <button
                    type="button"
                    data-cal-link={button?.scheduleLink?.calId}
                    data-cal-config='{"theme":"dark"}'
                    className={button.type}
                  >
                    {button.icon && <Icons icon={button.icon} />}
                    {button?.scheduleLink?.buttonLabel}
                  </button>
                );
              }
              if (button?.link) {
                return (
                  <FadeIn delayAnimationSeconds={index * 50}>
                    <Link
                      className={button.type}
                      key={`featureImageButton-${index}`}
                      href={button.link?.url}
                      newTab={button.link?.newTab}
                    >
                      {button.icon && <Icons icon={button.icon} />}
                      {button.link.text}
                    </Link>
                  </FadeIn>
                );
              }

              return null;
            })}
          </div>
        )}
      </div>
    </Section>
  );
}

FeatureImage.displayName = 'FeatureImage';
FeatureImage.Schema = Schema;
