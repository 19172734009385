export function RefreshDot(props) {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="Frame">
        <g id="Group">
          <path
            id="Vector"
            d="M2.53893 7.60887C2.6856 7.71887 2.86226 7.77776 3.04226 7.77776C3.08004 7.77776 3.11782 7.77554 3.15671 7.76999L6.42782 7.31776C6.88337 7.25443 7.20226 6.83332 7.14004 6.37776C7.07782 5.92221 6.65115 5.60887 6.20004 5.66554L4.57782 5.88999C5.91337 3.9711 8.11004 2.77665 10.5 2.77665C14.4823 2.77665 17.7223 6.01665 17.7223 9.99887C17.7223 10.4589 18.0956 10.8322 18.5556 10.8322C19.0156 10.8322 19.3889 10.4589 19.3889 9.99887C19.3889 5.09776 15.4011 1.10999 10.5 1.10999C7.74893 1.10999 5.20782 2.39665 3.54559 4.49332L3.41559 3.55776C3.35226 3.10221 2.92115 2.79221 2.47559 2.84554C2.02004 2.90887 1.70115 3.32999 1.76337 3.78554L2.2156 7.05776C2.2456 7.27665 2.36226 7.47443 2.53782 7.60887H2.53893Z"
            fill="currentColor"
          />
          <path
            id="Vector_2"
            d="M18.4611 12.3911C18.2844 12.2578 18.0622 12.2022 17.8433 12.23L14.5722 12.6822C14.1166 12.7456 13.7978 13.1667 13.86 13.6222C13.9222 14.0778 14.3522 14.39 14.8 14.3345L16.4211 14.11C15.0866 16.03 12.8922 17.2233 10.5 17.2233C6.51775 17.2233 3.27775 13.9833 3.27775 10.0011C3.27775 9.54112 2.90442 9.16779 2.44442 9.16779C1.98442 9.16779 1.61108 9.54112 1.61108 10.0011C1.61108 14.9022 5.59886 18.89 10.5 18.89C13.2522 18.89 15.7933 17.6045 17.4555 15.5089L17.5844 16.4433C17.6422 16.86 18 17.1622 18.4089 17.1622C18.4466 17.1622 18.4855 17.16 18.5244 17.1545C18.98 17.0911 19.2989 16.67 19.2366 16.2145L18.7844 12.9422C18.7544 12.7233 18.6366 12.5256 18.4611 12.3911Z"
            fill="currentColor"
          />
          <path
            id="Vector_3"
            d="M10.4999 13.0556C12.1874 13.0556 13.5554 11.6876 13.5554 10C13.5554 8.31248 12.1874 6.94446 10.4999 6.94446C8.81235 6.94446 7.44434 8.31248 7.44434 10C7.44434 11.6876 8.81235 13.0556 10.4999 13.0556Z"
            fill="currentColor"
          />
        </g>
      </g>
    </svg>
  );
}
