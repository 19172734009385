export function MagicWandSquare(props) {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="square-wand-sparkle 1">
        <g id="Group">
          <path
            id="Vector"
            d="M13.5487 2.76886L12.4976 2.41886L12.1465 1.36663C12.0331 1.02663 11.4698 1.02663 11.3565 1.36663L11.0054 2.41886L9.95426 2.76886C9.78426 2.82552 9.6687 2.98441 9.6687 3.16441C9.6687 3.34441 9.78426 3.5033 9.95426 3.55997L11.0054 3.90997L11.3565 4.96219C11.4131 5.13219 11.572 5.24663 11.7509 5.24663C11.9298 5.24663 12.0898 5.13108 12.1454 4.96219L12.4965 3.90997L13.5476 3.55997C13.7176 3.5033 13.8331 3.34441 13.8331 3.16441C13.8331 2.98441 13.7176 2.82552 13.5476 2.76886H13.5487Z"
            fill="currentColor"
          />
          <path
            id="Vector_2"
            d="M19.0099 6.65557L17.6066 6.18779L17.1388 4.78446C16.9866 4.33112 16.2366 4.33112 16.0844 4.78446L15.6166 6.18779L14.2133 6.65557C13.9866 6.73112 13.8333 6.94335 13.8333 7.18224C13.8333 7.42112 13.9866 7.63335 14.2133 7.7089L15.6166 8.17668L16.0844 9.58001C16.1599 9.80668 16.3733 9.96001 16.6121 9.96001C16.851 9.96001 17.0633 9.80668 17.1399 9.58001L17.6077 8.17668L19.011 7.7089C19.2377 7.63335 19.391 7.42112 19.391 7.18224C19.391 6.94335 19.2366 6.73112 19.0099 6.65557Z"
            fill="currentColor"
          />
          <path
            id="Vector_3"
            d="M17.4444 2.77775C17.9047 2.77775 18.2778 2.40465 18.2778 1.94442C18.2778 1.48418 17.9047 1.11108 17.4444 1.11108C16.9842 1.11108 16.6111 1.48418 16.6111 1.94442C16.6111 2.40465 16.9842 2.77775 17.4444 2.77775Z"
            fill="currentColor"
          />
          <path
            id="Vector_4"
            d="M15.2221 17.7777H9.7054C9.2454 17.7777 8.87207 17.4044 8.87207 16.9444C8.87207 16.4844 9.2454 16.111 9.7054 16.111H15.2221C15.9876 16.111 16.611 15.4877 16.611 14.7222V11.7955C16.611 11.3355 16.9843 10.9622 17.4443 10.9622C17.9043 10.9622 18.2776 11.3355 18.2776 11.7955V14.7222C18.2776 16.4066 16.9076 17.7777 15.2221 17.7777Z"
            fill="currentColor"
          />
          <path
            id="Vector_5"
            d="M3.5555 11.6278C3.0955 11.6278 2.72217 11.2545 2.72217 10.7945V5.27778C2.72217 3.59223 4.09328 2.22223 5.77772 2.22223H7.72439C8.18439 2.22223 8.55772 2.59556 8.55772 3.05556C8.55772 3.51556 8.18439 3.8889 7.72439 3.8889H5.77772C5.01217 3.8889 4.38883 4.51223 4.38883 5.27778V10.7945C4.38883 11.2545 4.0155 11.6278 3.5555 11.6278Z"
            fill="currentColor"
          />
          <path
            id="Vector_6"
            d="M12.1522 8.99885L11.5022 8.34885C10.7689 7.61329 9.48775 7.61329 8.7522 8.34885L1.62664 15.4722C1.25886 15.84 1.05664 16.3277 1.05664 16.8466C1.05664 17.3655 1.25886 17.8544 1.62664 18.2211L2.27664 18.8711C2.64442 19.2388 3.1322 19.4411 3.6522 19.4411C4.1722 19.4411 4.65997 19.2388 5.02664 18.8711L12.1522 11.7455C12.52 11.3777 12.7222 10.89 12.7222 10.3711C12.7222 9.85218 12.52 9.36329 12.1522 8.99662V8.99885ZM10.9733 10.57L8.91108 12.6322L7.86775 11.5888L9.92997 9.52663C10.0011 9.45662 10.0833 9.44551 10.1266 9.44551C10.17 9.44551 10.2522 9.45551 10.3233 9.52663L10.9744 10.1777C11.0444 10.2477 11.0555 10.3311 11.0555 10.3733C11.0555 10.4155 11.0444 10.4988 10.9744 10.57H10.9733Z"
            fill="currentColor"
          />
        </g>
      </g>
    </svg>
  );
}
