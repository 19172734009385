export function Schema() {
  return {
    label: 'Value Logos',
    key: 'value-logos',
    fields: [
      {
        label: 'Heading',
        name: 'heading',
        component: 'text',
      },
      {
        label: 'Values',
        name: 'values',
        component: 'group-list',
        itemProps: {
          label: '{{item.value}}',
        },
        fields: [
          {
            label: 'Value',
            name: 'value',
            component: 'text',
          },
          {
            label: 'Label',
            name: 'label',
            component: 'text',
          },
        ],
      },
    ],
  };
}
