import { Icons } from './Icons';

export function Badge({ text, icon, badgeColor, textColor }) {
  if (!text) return null;

  return (
    <div
      className="flex h-8 items-center gap-2 rounded-full py-1 px-4"
      style={{
        background: badgeColor || '#e6e6e6',
        color: textColor || '#1a1a1a',
      }}
    >
      {icon ? <Icons icon={icon} className="h-[18px] w-[18px]" /> : null}
      <span className="font-aeonik text-base font-normal leading-6">
        {text}
      </span>
    </div>
  );
}
