import { IconSettings } from './icons';

export default {
  label: 'Footer',
  name: 'footer',
  component: 'group',
  description: 'Menu, social, legal, email marketing settings',
  fields: [
    {
      label: 'Menu',
      name: 'menu',
      component: 'group',
      description: 'Menu items',
      fields: [
        {
          label: 'Menu Items',
          name: 'menuItems',
          component: 'group-list',
          itemProps: {
            label: '{{item.title}}',
          },
          fields: [
            {
              label: 'Menu Item Title',
              name: 'title',
              component: 'text',
            },
            {
              label: 'Menu Item Links',
              name: 'links',
              component: 'group-list',
              itemProps: {
                label: '{{item.link.text}}',
              },
              fields: [
                {
                  label: 'Link',
                  name: 'link',
                  component: 'link',
                },
              ],
            },
          ],
        },
      ],
    },
    {
      label: 'Buttons',
      name: 'buttons',
      component: 'group-list',
      fields: [
        {
          label: 'Type',
          name: 'type',
          component: 'select',
          options: [
            { label: 'Primary', value: 'btn-primary' },
            { label: 'Outline', value: 'btn-outline' },
          ],
        },
        {
          label: 'Link',
          name: 'link',
          component: 'link',
        },
        IconSettings,
      ],
      itemProps: {
        label: '{{item.link.text}}',
      },
      validate: {
        maxItems: 2,
      },
      defaultItem: {
        type: 'btn-primary',
        link: { text: 'Get Started', url: '' },
        icon: '',
      },
      defaultValue: [
        {
          type: 'btn-outline',
          link: { text: 'Book a demo', url: '' },
          icon: 'clock',
        },
        {
          type: 'btn-primary',
          link: { text: 'Get Started', url: '' },
          icon: '',
        },
      ],
    },
    {
      label: 'Legal',
      name: 'legal',
      component: 'group',
      description: 'Legal links, copyright notice',
      fields: [
        {
          label: 'Legal Links',
          name: 'links',
          component: 'group-list',
          itemProps: {
            label: '{{item.link.text}}',
          },
          fields: [
            {
              label: 'Link',
              name: 'link',
              component: 'link',
            },
          ],
          defaultValue: [
            {
              link: {
                text: 'Terms',
                url: '/pages/terms-conditions',
              },
            },
            {
              link: {
                text: 'Privacy',
                url: '/pages/privacy-policy',
              },
            },
            {
              link: {
                text: 'Cookies',
                url: '/pages/cookie-policy',
              },
            },
          ],
        },
        {
          label: 'Site Copyright Notice',
          name: 'copyrightNotice',
          component: 'text',
          defaultValue: 'Pack Digital. All rights reserved.',
        },
      ],
    },
  ],
};
