export function Medal(props) {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="medal 1" clipPath="url(#clip0_2410_69404)">
        <g id="Group">
          <path
            id="Vector"
            d="M8.94996 5.15556L6.93219 1.78667C6.6833 1.37 6.22663 1.11111 5.74108 1.11111H2.31441C1.81552 1.11111 1.35219 1.38111 1.10552 1.81556C0.858853 2.25 0.866631 2.78667 1.1233 3.21334L4.15663 8.29334C5.28108 6.70778 6.97885 5.55667 8.94996 5.15556Z"
            fill="currentColor"
          />
          <path
            id="Vector_2"
            d="M19.8945 1.81559C19.6478 1.38114 19.1845 1.11115 18.6856 1.11115H15.2589C14.7723 1.11115 14.3156 1.37003 14.0678 1.78559L12.05 5.15559C14.0256 5.5567 15.7278 6.71226 16.8523 8.30337L19.8767 3.21448C20.1334 2.78559 20.14 2.25003 19.8945 1.81559Z"
            fill="currentColor"
          />
          <path
            id="Vector_3"
            d="M10.5 6.66669C7.13114 6.66669 4.38892 9.4078 4.38892 12.7778C4.38892 16.1478 7.13114 18.8889 10.5 18.8889C13.8689 18.8889 16.6111 16.1478 16.6111 12.7778C16.6111 9.4078 13.8689 6.66669 10.5 6.66669ZM13.11 12.4511L12.2078 13.3311L12.4211 14.5734C12.4567 14.7822 12.3711 14.9922 12.2 15.1167C12.1034 15.1867 11.9889 15.2234 11.8734 15.2234C11.7845 15.2234 11.6967 15.2022 11.6156 15.1589L10.5 14.5734L9.38447 15.1589C9.1978 15.2567 8.97114 15.2411 8.80003 15.1167C8.62892 14.9922 8.54336 14.7822 8.57892 14.5734L8.79225 13.3311L7.89003 12.4511C7.73781 12.3034 7.68336 12.0822 7.74892 11.8811C7.81447 11.68 7.98892 11.5345 8.19669 11.5034L9.44336 11.3222L10.0011 10.1922C10.1878 9.81224 10.8111 9.81224 10.9967 10.1922L11.5545 11.3222L12.8011 11.5034C13.01 11.5334 13.1845 11.68 13.2489 11.8811C13.3145 12.0822 13.26 12.3034 13.1078 12.4511H13.11Z"
            fill="currentColor"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_2410_69404">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
