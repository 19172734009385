export function Sliders(props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.25 7C10.25 4.92893 11.9289 3.25 14 3.25C15.8142 3.25 17.3275 4.53832 17.675 6.25H21C21.4142 6.25 21.75 6.58579 21.75 7C21.75 7.41421 21.4142 7.75 21 7.75H17.675C17.3275 9.46168 15.8142 10.75 14 10.75C11.9289 10.75 10.25 9.07107 10.25 7ZM14 4.75C15.2426 4.75 16.25 5.75736 16.25 7C16.25 8.24264 15.2426 9.25 14 9.25C12.7574 9.25 11.75 8.24264 11.75 7C11.75 5.75736 12.7574 4.75 14 4.75ZM3 6.25C2.58579 6.25 2.25 6.58579 2.25 7C2.25 7.41421 2.58579 7.75 3 7.75H8C8.41421 7.75 8.75 7.41421 8.75 7C8.75 6.58579 8.41421 6.25 8 6.25H3ZM3 16.25C2.58579 16.25 2.25 16.5858 2.25 17C2.25 17.4142 2.58579 17.75 3 17.75H6.32501C6.67247 19.4617 8.18578 20.75 10 20.75C12.0711 20.75 13.75 19.0711 13.75 17C13.75 14.9289 12.0711 13.25 10 13.25C8.18578 13.25 6.67247 14.5383 6.32501 16.25H3ZM7.75 17C7.75 18.2426 8.75736 19.25 10 19.25C11.2426 19.25 12.25 18.2426 12.25 17C12.25 15.7574 11.2426 14.75 10 14.75C8.75736 14.75 7.75 15.7574 7.75 17ZM16 16.25C15.5858 16.25 15.25 16.5858 15.25 17C15.25 17.4142 15.5858 17.75 16 17.75H21C21.4142 17.75 21.75 17.4142 21.75 17C21.75 16.5858 21.4142 16.25 21 16.25H16Z"
        fill="currentColor"
      />
    </svg>
  );
}
