export function Samples(props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.33317 2.6665H4.99984C3.71317 2.6665 2.6665 3.71317 2.6665 4.99984V17.3332C2.6665 19.5385 4.46117 21.3332 6.6665 21.3332C8.87184 21.3332 10.6665 19.5385 10.6665 17.3332V4.99984C10.6665 3.71317 9.61984 2.6665 8.33317 2.6665ZM6.6665 18.3332C6.1145 18.3332 5.6665 17.8852 5.6665 17.3332C5.6665 16.7812 6.1145 16.3332 6.6665 16.3332C7.2185 16.3332 7.6665 16.7812 7.6665 17.3332C7.6665 17.8852 7.2185 18.3332 6.6665 18.3332Z"
        fill="currentColor"
      />
      <path
        d="M18.216 8.14131L15.8586 5.78398C15.012 4.93598 13.5613 4.91198 12.6653 5.69731V16.9893L18.2146 11.44C18.656 10.9986 18.8986 10.4133 18.8986 9.79064C18.8986 9.16798 18.6573 8.58131 18.216 8.14131Z"
        fill="currentColor"
      />
      <path
        d="M19.1373 13.3462L11.1506 21.3329H19C20.2866 21.3329 21.3333 20.2862 21.3333 18.9995V15.6662C21.3333 14.4275 20.3586 13.4195 19.1373 13.3462Z"
        fill="currentColor"
      />
    </svg>
  );
}
