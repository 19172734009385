export function Key(props) {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.5303 2.96967C20.8232 3.26256 20.8232 3.73744 20.5303 4.03033L20.0607 4.5L22.5303 6.96967C22.8232 7.26256 22.8232 7.73744 22.5303 8.03033L19.0303 11.5303C18.7374 11.8232 18.2626 11.8232 17.9697 11.5303L15.5 9.06066L11.5617 12.999C12.3069 13.9686 12.75 15.1826 12.75 16.5C12.75 19.6756 10.1756 22.25 7 22.25C3.82436 22.25 1.25 19.6756 1.25 16.5C1.25 13.3244 3.82436 10.75 7 10.75C8.31745 10.75 9.53141 11.1931 10.501 11.9383L14.9697 7.46967L18.4697 3.96967C18.4698 3.96956 18.4699 3.96945 18.47 3.96934L19.4697 2.96967C19.7626 2.67678 20.2374 2.67678 20.5303 2.96967ZM16.5607 8L18.5 9.93934L20.9393 7.5L19 5.56066L16.5607 8ZM7 12.25C4.65279 12.25 2.75 14.1528 2.75 16.5C2.75 18.8472 4.65279 20.75 7 20.75C9.34721 20.75 11.25 18.8472 11.25 16.5C11.25 14.1528 9.34721 12.25 7 12.25Z"
        fill="currentColor"
      />
    </svg>
  );
}
