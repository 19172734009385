export function Bars(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="45"
      height="44"
      viewBox="0 0 45 44"
      fill="none"
      {...props}
    >
      <path
        d="M23.1094 4.88867H21.8872C19.5246 4.88867 17.6094 6.8039 17.6094 9.16645V34.8331C17.6094 37.1957 19.5246 39.1109 21.8872 39.1109H23.1094C25.4719 39.1109 27.3872 37.1957 27.3872 34.8331V9.16645C27.3872 6.8039 25.4719 4.88867 23.1094 4.88867Z"
        fill="currentColor"
      />
      <path
        d="M9.66406 4.88867H8.44184C6.07929 4.88867 4.16406 6.8039 4.16406 9.16645V22.6109C4.16406 24.9734 6.07929 26.8887 8.44184 26.8887H9.66406C12.0266 26.8887 13.9418 24.9734 13.9418 22.6109V9.16645C13.9418 6.8039 12.0266 4.88867 9.66406 4.88867Z"
        fill="currentColor"
      />
      <path
        d="M36.5547 4.88867H35.3325C32.9699 4.88867 31.0547 6.8039 31.0547 9.16645V15.2776C31.0547 17.6401 32.9699 19.5553 35.3325 19.5553H36.5547C38.9172 19.5553 40.8325 17.6401 40.8325 15.2776V9.16645C40.8325 6.8039 38.9172 4.88867 36.5547 4.88867Z"
        fill="currentColor"
      />
    </svg>
  );
}
