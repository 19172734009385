export function Schema() {
  return {
    label: 'Text Heading',
    key: 'text-heading',
    fields: [
      {
        label: 'Heading',
        name: 'heading',
        component: 'text',
        defaultValue: 'Page Heading',
      },
      {
        label: 'Subheading',
        name: 'subheading',
        component: 'textarea',
      },
    ],
  };
}
