import { useState } from 'react';
import Expand from 'react-expand-animated';
import { Schema } from './Accordions.schema';
import { Icons } from '../../snippets/Icons';
import { Markdown } from '../../snippets/markdown';

import { Section } from '../../snippets';

export function Accordions({ cms }) {
  const { accordions, description, heading } = cms;

  return (
    <Section cms={cms}>
      <div className="mx-auto max-w-8xl">
        <div className="flex flex-col gap-[35px] px-5 py-[60px] lg:gap-[50px] lg:px-[100px] lg:py-[120px]">
          {heading ? (
            <h4 className="text-center font-aeonik text-[2.875rem] font-normal leading-[3rem] text-primary-light lg:text-[3.5rem] lg:leading-[3.75rem]">
              {heading}
            </h4>
          ) : null}
          {description ? (
            <p className="sub-heading selection:text-white md:mx-auto md:max-w-3xl md:text-center md:text-white-700">
              {description}
            </p>
          ) : null}

          <ul className="mx-auto grid max-w-[1000px] grid-cols-1 gap-5 lg:gap-[30px]">
            {accordions?.map((accordion, index) => {
              return (
                <li
                  key={index}
                  className="border-b border-grey-800 pb-5 lg:px-2.5 lg:pb-[30px]"
                >
                  <Accordion accordion={accordion} />
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </Section>
  );
}

function Accordion({ accordion }) {
  const { description, defaultOpen, header } = accordion;
  const [isOpen, setIsOpen] = useState(defaultOpen);

  return (
    <div className="flex flex-col">
      <button
        aria-label={`${isOpen ? 'Close' : 'Open'} accordion for ${header}`}
        className="relative flex w-full text-left"
        onClick={() => setIsOpen(!isOpen)}
        type="button"
      >
        <p className="flex-1 pr-8 font-aeonik text-xl leading-6 text-primary-light lg:pr-14 lg:text-2xl lg:leading-7">
          {header}
        </p>
        <div className="absolute top-0 right-0 flex h-8 items-center justify-center px-2 text-grey-300">
          <Icons icon="plus" />
        </div>
      </button>

      <Expand open={isOpen} duration={300}>
        <div className="mt-5 lg:mt-[30px] lg:pr-14">
          <Markdown classes="font-aeonik text-base leading-snug text-grey-300 lg:text-lg lg:leading-6">
            {description}
          </Markdown>
        </div>
      </Expand>
    </div>
  );
}

Accordions.displayName = 'Accordions';
Accordions.Schema = Schema;
