import { useArticle } from '@backpackjs/storefront';
import { Schema } from './ArticleHeaderHeading.schema';

import { Section } from '../../snippets';

export function ArticleHeaderHeading({ cms }) {
  const article = useArticle();
  const { authors } = cms;

  if (!article) return null;

  const author = authors?.find((a) => {
    return a.name === article.author;
  });

  return (
    <Section cms={cms}>
      <div className="grid grid-cols-3">
        <div className="col-span-2 flex flex-col gap-6">
          <h1 className="text-5xl selection:bg-black selection:text-brand">
            {article.title}
          </h1>
          <p className="text-xl">{article.description}</p>
          <div className="flex items-center gap-4">
            {author?.image ? (
              <div className="h-14 w-14 overflow-hidden rounded-full shadow-[0px_0px_0px_1px_rgba(0,0,0,0.08)_inset]">
                <img src={author.image.src} alt={article.author} />
              </div>
            ) : null}
            <div>
              <p className="text-lg font-semibold">{article.author}</p>
              {author?.title ? (
                <p className="text-base">{author.title}</p>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
}

ArticleHeaderHeading.displayName = 'ArticleHeaderHeading';
ArticleHeaderHeading.Schema = Schema;
