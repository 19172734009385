/* eslint-disable no-nested-ternary */
import { useInView } from 'react-intersection-observer';
import React from 'react';
import { Icons } from '../../snippets/Icons';
import { Lotties } from '../../snippets/Lotties';

import { Link } from '../../snippets/Link';
import { Button } from '../../snippets/button';

export function FeatureStepsMain({ props }) {
  const {
    heading,
    description,
    watchVideoDescription,
    watchVideoText,
    watchVideoImage,
    watchVideoLink,
    lottie,
    image,
    imageAlt,
    cta,
  } = { ...props };

  const { ref, inView } = useInView({
    rootMargin: '0px',
    triggerOnce: false,
    threshold: 0.3,
  });

  return (
    <div>
      <div className="border-border relative mx-auto max-w-7xl md:pt-[150px]">
        {/* <div className="feature-steps-main-gradient pointer-events-none" /> */}

        <div
          className={`grid grid-cols-1 items-center gap-5 border-b pb-12 text-black  md:gap-12 md:pb-[120px] lg:grid-cols-2 lg:px-0 ${
            lottie || image?.src ? 'lg:grid-cols-2' : 'lg:grid-cols-3'
          }`}
        >
          <div
            className={`flex flex-col gap-5 md:gap-9 ${
              lottie || image?.src ? '' : 'col-span-2'
            }`}
          >
            {heading ? (
              <h2 className="whitespace-pre-line text-[36px] font-normal text-primary-dark md:text-[56px]">
                {heading
                  .replace('\\n', '\n')
                  .split('|')
                  .map((text, index) => (
                    <span
                      key={`feature-steps-heading-${index}}`}
                      className={index % 2 === 1 ? 'text-grey-400' : ''}
                    >
                      {text}
                    </span>
                  ))}
              </h2>
            ) : null}

            {description ? (
              <p className="text-base text-[rgba(0,0,0,.7)] md:text-[20px]">
                {description}
              </p>
            ) : null}

            <div className="hidden items-center gap-5 lg:flex">
              {watchVideoImage?.src ? (
                <Link
                  className="relative flex w-[100px] items-center justify-center overflow-hidden rounded-lg"
                  href={watchVideoLink?.url || ''}
                  newTab={watchVideoLink?.newTab}
                >
                  <img src={watchVideoImage?.src} alt={watchVideoText} />
                  <div className="absolute inset-0 h-full w-full bg-[rgba(0,0,0,0.3)]" />
                  <Icons
                    icon="playButton"
                    className="absolute z-[1] h-8 w-8 text-brand"
                  />
                </Link>
              ) : null}

              {watchVideoText || watchVideoDescription ? (
                <div className="flex flex-col">
                  {watchVideoText ? (
                    <p className="text-base text-[rgba(0,0,0,.6)]">
                      {watchVideoText}
                    </p>
                  ) : null}

                  {watchVideoDescription ? (
                    <p className="text-base text-[rgba(0,0,0,.5)]">
                      {watchVideoDescription}
                    </p>
                  ) : null}
                </div>
              ) : null}
            </div>
          </div>

          <div
            ref={ref}
            className={
              !lottie && !image?.src ? 'self-start justify-self-end' : ''
            }
          >
            {lottie ? (
              <div>
                {inView && <Lotties lottie={lottie} play loop={false} />}
              </div>
            ) : image?.src ? (
              <picture>
                <img src={image?.src} alt={imageAlt} />
              </picture>
            ) : cta?.link?.url ? (
              <Button
                link={cta.link}
                icon={cta.icon}
                iconPosition={cta.iconPosition}
                className="hidden lg:flex"
              />
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}

FeatureStepsMain.displayName = 'FeatureStepsMain';
