import { useBlog, useRouter } from '@backpackjs/storefront';
import { Schema } from './BlogGrid.schema';
import { Icons } from '../../snippets/Icons';

import { Link } from '../../snippets/Link';
import { Section } from '../../snippets';

export function BlogGrid({ cms }) {
  const blog = useBlog();
  const router = useRouter();
  const { heading, linkEnabled } = cms;
  const page = router?.query?.page || 1;

  if (!blog) return null;

  const articlesPerPage = 6;
  const startIndex = (page - 1) * articlesPerPage;
  const articles = blog.articles.slice(
    startIndex,
    startIndex + articlesPerPage
  );

  const articleArray = blog?.articles;
  const sortedArticles =
    blog?.handle === 'partners' || blog?.handle === 'integrations'
      ? [...articleArray].sort((a, b) => {
          if (a.title.toLowerCase() < b.title.toLowerCase()) {
            return -1;
          }
          if (a.title.toLowerCase() > b.title.toLowerCase()) {
            return 1;
          }
          return 0;
        })
      : articles;

  const badgeClasses = {
    blog: 'badge-blog-green',
    'case studies': 'badge-blog-purple',
    tutorials: 'badge-blog-cyan',
    news: 'badge-blog-pink',
  };

  const showPagination =
    blog?.handle !== 'partners' && blog?.handle !== 'integrations';
  const showDates =
    blog?.handle !== 'partners' && blog?.handle !== 'integrations';
  const tempDisableLinks =
    blog?.handle === 'partners' || blog?.handle === 'integrations';

  return (
    <Section cms={cms}>
      <div className="my-16 px-4">
        <div className="mx-auto flex max-w-7xl flex-col gap-16">
          <div className="flex flex-col gap-8">
            {heading ? (
              <h2 className="font-inter text-[32px] leading-5">{heading}</h2>
            ) : null}{' '}
            {articles.length > 0 && (
              <div className="grid gap-x-8 gap-y-12 md:grid-cols-2 lg:grid-cols-3">
                {sortedArticles?.map((article) => {
                  const url = `/articles/${article.handle}`;
                  const date = new Date(
                    article.publishedAt || article.createdAt
                  );
                  const { category, tags } = article;
                  const badgeClass =
                    badgeClasses?.[category?.toLowerCase()] ||
                    'badge-blog-green';
                  const badgeColorTag = tags
                    ?.find((tag) => tag?.includes('badge::'))
                    ?.split('::')?.[1];

                  return (
                    <div key={article.id} className="flex flex-col gap-6">
                      <div>
                        {linkEnabled && !tempDisableLinks ? (
                          <Link href={url}>
                            <img
                              src={article.seo.image}
                              alt={article.title}
                              className="rounded-2xl"
                            />
                          </Link>
                        ) : (
                          <img src={article.seo.image} alt={article.title} />
                        )}
                      </div>
                      <div className="flex flex-col gap-2">
                        {category && (
                          <div
                            className={`flex max-w-fit rounded-2xl border px-2.5 py-0.5 ${
                              badgeColorTag !== undefined
                                ? `badge-blog-${badgeColorTag}`
                                : badgeClass
                            }`}
                          >
                            <span className="self-center pb-0.5 align-middle font-inter text-sm font-medium capitalize leading-[140%]">
                              {category}
                            </span>
                          </div>
                        )}
                        <h3 className="font-inter text-2xl">
                          {linkEnabled && !tempDisableLinks ? (
                            <Link href={url} passHref>
                              <a href={url}>{article.title}</a>
                            </Link>
                          ) : (
                            article.title
                          )}
                        </h3>
                        {article.excerpt || article?.seo?.description ? (
                          <p className="text-base text-white-700">
                            {article.excerpt.substring(0, 90) ||
                              article?.seo?.description.substring(0, 90)}
                            {article.excerpt.length > 90 ||
                            article?.seo?.description > 90
                              ? '...'
                              : ''}
                          </p>
                        ) : null}
                        {showDates && (
                          <p className="text-sm font-semibold">
                            {article.author ? `${article.author} • ` : ''}
                            {date.toLocaleDateString('en-US', {
                              day: 'numeric',
                            })}{' '}
                            {date.toLocaleDateString('en-US', {
                              month: 'short',
                            })}{' '}
                            {date.toLocaleDateString('en-US', {
                              year: 'numeric',
                            })}
                          </p>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
          {showPagination && blog.articles.length > articlesPerPage ? (
            <Pagination
              url={`/blogs/${blog.handle}`}
              page={page}
              max={Math.ceil(blog.articles.length / articlesPerPage)}
            />
          ) : null}
        </div>
      </div>
    </Section>
  );
}

function Pagination({ url, page, max }) {
  const currentPage = parseInt(page, 10);
  const previousUrl =
    currentPage - 1 === 1 ? url : `${url}?page=${currentPage - 1}`;
  const nextUrl = `${url}?page=${currentPage + 1}`;
  const buttonClasses = 'group flex gap-2 text-sm font-semibold text-white-700';

  return (
    <div className="flex items-center justify-between gap-8 pt-5">
      {currentPage === 1 ? (
        <div className={buttonClasses}>
          <Icons icon="arrowLeft" className="h-5 w-5" />
          <span className="sr-only md:not-sr-only">Previous</span>
        </div>
      ) : (
        <Link className={buttonClasses} href={previousUrl}>
          <Icons icon="arrowLeft" className="h-5 w-5" />
          <span className="sr-only md:not-sr-only">Previous</span>
        </Link>
      )}
      <ol className="flex flex-wrap gap-[2px]">
        {[...Array(max)].map((item, index) => {
          const pageUrl = index === 0 ? url : `${url}/?page=${index + 1}`;
          return (
            <li
              key={`blog-pagination-${index}`}
              className={`${
                currentPage === index + 1
                  ? 'flex h-10 w-10 items-center justify-center bg-brand text-black'
                  : 'text-white-700'
              } rounded-lg text-sm font-medium`}
            >
              {currentPage === index + 1 ? (
                <span>{index + 1}</span>
              ) : (
                <Link
                  className="flex h-10 w-10 items-center justify-center"
                  href={pageUrl}
                >
                  {index + 1}
                </Link>
              )}
            </li>
          );
        })}
      </ol>
      {currentPage === max ? (
        <div className={buttonClasses}>
          <span className="sr-only md:not-sr-only">Next</span>
          <Icons icon="arrowRight" className="h-5 w-5" />
        </div>
      ) : (
        <Link className={buttonClasses} href={nextUrl}>
          <span className="sr-only md:not-sr-only">Next</span>
          <Icons icon="arrowRight" className="h-5 w-5" />
        </Link>
      )}
    </div>
  );
}

BlogGrid.displayName = 'BlogGrid';
BlogGrid.Schema = Schema;
