export const IconSettings = {
  label: 'Icon',
  name: 'icon',
  component: 'select',
  options: [
    {
      label: 'Select Icon',
      value: '',
    },
    {
      label: 'Align Left',
      value: 'alignLeft',
    },
    {
      label: 'Arrow Right',
      value: 'arrowRight',
    },
    {
      label: 'Atom',
      value: 'atom',
    },
    {
      label: 'Bars',
      value: 'bars',
    },
    {
      label: 'Bars with X Axis',
      value: 'barsXAxis',
    },
    {
      label: 'Book',
      value: 'book',
    },
    {
      label: 'Books',
      value: 'books',
    },
    {
      label: 'Boundary',
      value: 'boundary',
    },
    {
      label: 'Brackets',
      value: 'brackets',
    },
    {
      label: 'Broom',
      value: 'broom',
    },
    {
      label: 'Calendar',
      value: 'calendar',
    },
    {
      label: 'Calendar with User',
      value: 'calendarUser',
    },
    {
      label: 'Camera',
      value: 'camera',
    },
    {
      label: 'Check Circle Fill',
      value: 'checkCircleFill',
    },
    {
      label: 'Check Circle Fill Line',
      value: 'checkCircleFillLine',
    },
    {
      label: 'Chevron Down',
      value: 'chevronDown',
    },
    {
      label: 'Circles',
      value: 'circles',
    },
    {
      label: 'Clock',
      value: 'clock',
    },
    {
      label: 'Components',
      value: 'components',
    },
    {
      label: 'Connected Blocks',
      value: 'connectedBlocks',
    },
    {
      label: 'Copy',
      value: 'copy',
    },
    {
      label: 'Dashboard',
      value: 'dashboard',
    },
    {
      label: 'Devices',
      value: 'devices',
    },
    {
      label: 'Disabled',
      value: 'disabled',
    },
    {
      label: 'Dot With Arrows',
      value: 'dotWithArrows',
    },
    {
      label: 'Document',
      value: 'document',
    },
    {
      label: 'Edit',
      value: 'edit',
    },
    {
      label: 'Elements',
      value: 'elements',
    },
    {
      label: 'Elements Plus',
      value: 'elementsPlus',
    },
    {
      label: 'File',
      value: 'file',
    },
    {
      label: 'Globe',
      value: 'globe',
    },
    {
      label: 'Graph',
      value: 'graph',
    },
    {
      label: 'Globe Search',
      value: 'globeSearch',
    },
    {
      label: 'Grid',
      value: 'grid',
    },
    {
      label: 'Grid Fill',
      value: 'gridFill',
    },
    {
      label: 'Hamburger',
      value: 'hamburger',
    },
    {
      label: 'Hammer',
      value: 'hammer',
    },
    {
      label: 'Handshake',
      value: 'handshake',
    },
    {
      label: 'Horizontal Chart',
      value: 'horizontalChart',
    },
    {
      label: 'Key',
      value: 'key',
    },
    {
      label: 'Laptop',
      value: 'laptop',
    },
    {
      label: 'Layers',
      value: 'layers',
    },
    {
      label: 'Layers (Filled)',
      value: 'layersFilled',
    },
    {
      label: 'Lightning',
      value: 'lightning',
    },
    {
      label: 'Lightning Speed',
      value: 'lightningSpeed',
    },
    {
      label: 'Link',
      value: 'link',
    },
    {
      label: 'Linked Dots',
      value: 'linkedDots',
    },
    {
      label: 'Linked Triangle',
      value: 'linkedTriangle',
    },
    {
      label: 'Loading',
      value: 'loading',
    },
    {
      label: 'Magic Wand Square',
      value: 'magicWandSquare',
    },
    {
      label: 'Medal',
      value: 'medal',
    },
    {
      label: 'Modules',
      value: 'modules',
    },
    {
      label: 'Monitor',
      value: 'monitor',
    },
    {
      label: 'Move',
      value: 'move',
    },
    {
      label: 'New File',
      value: 'newFile',
    },
    {
      label: 'Palette',
      value: 'palette',
    },
    {
      label: 'Play',
      value: 'play',
    },
    {
      label: 'Play Overlay',
      value: 'playOverlay',
    },
    {
      label: 'Refresh',
      value: 'refresh',
    },
    {
      label: 'Refresh Dot',
      value: 'refreshDot',
    },
    {
      label: 'Rocket',
      value: 'rocket',
    },
    {
      label: 'Route',
      value: 'route',
    },
    {
      label: 'Samples',
      value: 'samples',
    },
    {
      label: 'Script',
      value: 'script',
    },
    {
      label: 'Select',
      value: 'select',
    },
    {
      label: 'Send',
      value: 'send',
    },
    {
      label: 'Share',
      value: 'share',
    },
    {
      label: 'Shop',
      value: 'shop',
    },
    {
      label: 'Shopify',
      value: 'shopify',
    },
    {
      label: 'Shopping Bag',
      value: 'shoppingBag',
    },
    {
      label: 'Shopping Cart',
      value: 'shoppingCart',
    },
    {
      label: 'Sliders',
      value: 'sliders',
    },
    {
      label: 'Slides',
      value: 'slides',
    },
    {
      label: 'Stack',
      value: 'stack',
    },
    {
      label: 'Stopwatch',
      value: 'stopwatch',
    },
    {
      label: 'Storefront',
      value: 'storefront',
    },
    {
      label: 'Target',
      value: 'target',
    },
    {
      label: 'Terminal',
      value: 'terminal',
    },
    {
      label: 'Toggle',
      value: 'toggle',
    },
    {
      label: 'Toggles',
      value: 'toggles',
    },
    {
      label: 'Two Way',
      value: 'twoWay',
    },
    {
      label: 'Union',
      value: 'union',
    },
    {
      label: 'User Check',
      value: 'userCheck',
    },
    {
      label: 'Users',
      value: 'users',
    },
    {
      label: 'User Star',
      value: 'userStar',
    },
    {
      label: 'Vial',
      value: 'vial',
    },
    {
      label: 'Verified',
      value: 'verified',
    },
    {
      label: 'Video',
      value: 'video',
    },
    {
      label: 'X',
      value: 'x',
    },
  ],
};
