export function Schema() {
  return {
    label: 'Quote',
    key: 'quote',
    fields: [
      {
        label: 'Quote text',
        name: 'quote',
        component: 'textarea',
      },
      {
        label: 'Author',
        name: 'author',
        component: 'text',
      },
      {
        label: 'Title',
        name: 'title',
        component: 'text',
      },
      {
        label: 'Author picture',
        name: 'image',
        component: 'image',
      },
    ],
  };
}
