export function Graph(props) {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="Frame">
        <g id="Group">
          <path
            id="Vector"
            d="M5.66084 12.9598L7.55195 9.59871C7.67973 9.37093 7.9064 9.2176 8.16528 9.18204C8.42528 9.14982 8.68306 9.23426 8.86751 9.41871L10.9886 11.5398L12.2075 10.0243C12.0108 9.69204 11.8897 9.30982 11.8897 8.89649C11.8897 7.96649 12.4653 7.16982 13.2786 6.83982V3.06204H4.6664C2.98084 3.06204 1.61084 4.43204 1.61084 6.1176V7.84204L5.66084 12.9598Z"
            fill="currentColor"
          />
          <path
            id="Vector_2"
            d="M16.3332 3.06201H14.9443V6.83868C15.7577 7.16979 16.3332 7.96534 16.3332 8.89534C16.3332 9.82534 15.7577 10.622 14.9443 10.952V18.6176H16.3332C18.0188 18.6176 19.3888 17.2476 19.3888 15.562V6.11757C19.3888 4.43201 18.0188 3.06201 16.3332 3.06201Z"
            fill="currentColor"
          />
          <path
            id="Vector_3"
            d="M13.2776 11.3509L11.7043 13.3065C11.5565 13.4909 11.3365 13.6032 11.0999 13.6165C10.8643 13.6265 10.6332 13.5409 10.4654 13.3732L8.46431 11.372L6.5032 14.8587C6.36653 15.1009 6.11875 15.2587 5.84209 15.2809C5.81986 15.282 5.79875 15.2832 5.77653 15.2832C5.5232 15.2832 5.28208 15.1676 5.1232 14.9665L1.60986 10.5265V15.5598C1.60986 17.2454 2.97986 18.6154 4.66542 18.6154H13.2765V11.3487L13.2776 11.3509Z"
            fill="currentColor"
          />
        </g>
      </g>
    </svg>
  );
}
