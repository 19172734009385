export function HorizontalChart(props) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.8333 2.22217H4.16664C2.48108 2.22217 1.11108 3.59217 1.11108 5.27772V14.7222C1.11108 16.4077 2.48108 17.7777 4.16664 17.7777H15.8333C17.5189 17.7777 18.8889 16.4077 18.8889 14.7222V5.27772C18.8889 3.59217 17.5189 2.22217 15.8333 2.22217ZM14.1666 8.61106C14.1666 9.07106 13.7933 9.44439 13.3333 9.44439H10.2778C9.81775 9.44439 9.44442 9.07106 9.44442 8.61106C9.44442 8.15106 9.81775 7.77772 10.2778 7.77772H13.3333C13.7933 7.77772 14.1666 8.15106 14.1666 8.61106ZM4.99997 16.1111H4.16664C3.40108 16.1111 2.77775 15.4877 2.77775 14.7222V5.27772C2.77775 4.51217 3.40108 3.88883 4.16664 3.88883H4.99997V16.1111ZM7.77775 5.83328C7.77775 5.37328 8.15108 4.99995 8.61108 4.99995H10.2778C10.7378 4.99995 11.1111 5.37328 11.1111 5.83328C11.1111 6.29328 10.7378 6.66661 10.2778 6.66661H8.61108C8.15108 6.66661 7.77775 6.29328 7.77775 5.83328ZM12.5 14.9999H10.2778C9.81775 14.9999 9.44442 14.6266 9.44442 14.1666C9.44442 13.7066 9.81775 13.3333 10.2778 13.3333H12.5C12.96 13.3333 13.3333 13.7066 13.3333 14.1666C13.3333 14.6266 12.96 14.9999 12.5 14.9999ZM15.2778 12.2222H11.9444C11.4844 12.2222 11.1111 11.8488 11.1111 11.3888C11.1111 10.9288 11.4844 10.5555 11.9444 10.5555H15.2778C15.7378 10.5555 16.1111 10.9288 16.1111 11.3888C16.1111 11.8488 15.7378 12.2222 15.2778 12.2222Z"
        fill="currentColor"
      />
    </svg>
  );
}
