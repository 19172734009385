export function LinkedDots(props) {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="Frame" clipPath="url(#clip0_2369_551)">
        <g id="Group">
          <path
            id="Vector"
            d="M17.3054 6.18645L10.9165 2.49201C10.6609 2.34312 10.3387 2.34312 10.0831 2.49201L3.69425 6.18645C3.43648 6.33534 3.27759 6.6109 3.27759 6.90756V14.772C3.27759 15.0698 3.43648 15.3442 3.69425 15.4931L10.0831 19.1876C10.2109 19.262 10.3554 19.2998 10.4998 19.2998C10.6443 19.2998 10.7887 19.262 10.9165 19.1876L17.3054 15.4931C17.5631 15.3442 17.722 15.0687 17.722 14.772V6.90756C17.722 6.60979 17.5631 6.33534 17.3054 6.18645ZM11.3331 4.65867L15.262 6.9309L11.3331 9.34979V4.65867ZM8.90981 10.8409L4.94425 13.282V8.39867L8.90981 10.8398V10.8409ZM5.73759 6.9309L9.66648 4.65867V9.34756L5.73759 6.92867V6.9309ZM9.66648 12.3331V17.0231L5.73759 14.7509L9.66648 12.3331ZM11.3331 12.3331L15.262 14.752L11.3331 17.0242V12.3342V12.3331ZM12.0898 10.8409L16.0554 8.39979V13.2809L12.0898 10.8398V10.8409Z"
            fill="currentColor"
          />
          <path
            id="Vector_2"
            d="M10.4998 13.062C11.7271 13.062 12.722 12.0671 12.722 10.8398C12.722 9.61251 11.7271 8.61758 10.4998 8.61758C9.27251 8.61758 8.27759 9.61251 8.27759 10.8398C8.27759 12.0671 9.27251 13.062 10.4998 13.062Z"
            fill="currentColor"
          />
          <path
            id="Vector_3"
            d="M4.11089 9.17314C5.33819 9.17314 6.33312 8.17822 6.33312 6.95092C6.33312 5.72362 5.33819 4.7287 4.11089 4.7287C2.88359 4.7287 1.88867 5.72362 1.88867 6.95092C1.88867 8.17822 2.88359 9.17314 4.11089 9.17314Z"
            fill="currentColor"
          />
          <path
            id="Vector_4"
            d="M4.11089 16.9509C5.33819 16.9509 6.33312 15.956 6.33312 14.7287C6.33312 13.5014 5.33819 12.5065 4.11089 12.5065C2.88359 12.5065 1.88867 13.5014 1.88867 14.7287C1.88867 15.956 2.88359 16.9509 4.11089 16.9509Z"
            fill="currentColor"
          />
          <path
            id="Vector_5"
            d="M16.8887 9.17314C18.116 9.17314 19.1109 8.17822 19.1109 6.95092C19.1109 5.72362 18.116 4.7287 16.8887 4.7287C15.6614 4.7287 14.6665 5.72362 14.6665 6.95092C14.6665 8.17822 15.6614 9.17314 16.8887 9.17314Z"
            fill="currentColor"
          />
          <path
            id="Vector_6"
            d="M16.8887 16.9509C18.116 16.9509 19.1109 15.956 19.1109 14.7287C19.1109 13.5014 18.116 12.5065 16.8887 12.5065C15.6614 12.5065 14.6665 13.5014 14.6665 14.7287C14.6665 15.956 15.6614 16.9509 16.8887 16.9509Z"
            fill="currentColor"
          />
          <path
            id="Vector_7"
            d="M10.4998 5.56203C11.7271 5.56203 12.722 4.56711 12.722 3.33981C12.722 2.11251 11.7271 1.11758 10.4998 1.11758C9.27251 1.11758 8.27759 2.11251 8.27759 3.33981C8.27759 4.56711 9.27251 5.56203 10.4998 5.56203Z"
            fill="currentColor"
          />
          <path
            id="Vector_8"
            d="M10.4998 20.562C11.7271 20.562 12.722 19.5671 12.722 18.3398C12.722 17.1125 11.7271 16.1176 10.4998 16.1176C9.27251 16.1176 8.27759 17.1125 8.27759 18.3398C8.27759 19.5671 9.27251 20.562 10.4998 20.562Z"
            fill="currentColor"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_2369_551">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(0.5 0.839844)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
