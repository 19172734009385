import { Schema } from './ImageQuote.schema';

import { Section } from '../../snippets';

export function ImageQuote({ cms }) {
  const { image, imageAlt, quote, author, title, logo } = cms;
  return (
    <Section cms={cms}>
      <div className="mb-16 flex flex-col items-center gap-8 md:flex-row md:gap-32">
        {image ? (
          <div className="shrink-0">
            <img
              src={image.src}
              alt={imageAlt}
              className="rounded-2xl"
              srcSet={`${image.src} 2x`}
            />
          </div>
        ) : null}
        <blockquote className="flex flex-col gap-6">
          {logo ? (
            <div>
              <img src={logo.src} alt="" className="h-12" />
            </div>
          ) : null}
          {quote ? (
            <p className="text-[32px] font-medium leading-[1.4em]">{quote}</p>
          ) : null}
          <footer>
            {author ? (
              <cite className="text-lg font-medium not-italic">{author}</cite>
            ) : null}
            {title ? <p className="break-all">{title}</p> : null}
          </footer>
        </blockquote>
      </div>
    </Section>
  );
}

ImageQuote.displayName = 'ImageQuote';
ImageQuote.Schema = Schema;
