export function CalendarUser(props) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.75 2.75V0.75"
        stroke="currentColor"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.25 2.75V0.75"
        stroke="currentColor"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.25 6.25H15.75"
        stroke="currentColor"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.641 12.75C15.1933 12.75 15.641 12.3023 15.641 11.75C15.641 11.1977 15.1933 10.75 14.641 10.75C14.0887 10.75 13.641 11.1977 13.641 11.75C13.641 12.3023 14.0887 12.75 14.641 12.75Z"
        stroke="currentColor"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.75 8.447V4.75C15.75 3.646 14.855 2.75 13.75 2.75H4.25C3.145 2.75 2.25 3.646 2.25 4.75V13.25C2.25 14.354 3.145 15.25 4.25 15.25H9.924"
        stroke="currentColor"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.7421 17.25C17.0821 17.25 17.3361 16.913 17.2241 16.592C16.8511 15.52 15.8411 14.75 14.6411 14.75C13.4411 14.75 12.4311 15.52 12.0581 16.592C11.9461 16.913 12.2001 17.25 12.5401 17.25H16.7421Z"
        stroke="currentColor"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
