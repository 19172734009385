export function Schema() {
  return {
    label: 'Image',
    key: 'image',
    fields: [
      {
        label: 'Image',
        name: 'image',
        component: 'image',
      },
      {
        label: 'Image Alt',
        name: 'imageAlt',
        component: 'text',
      },
      {
        label: 'Show source text and link?',
        name: 'showSource',
        component: 'toggle',
        defaultValue: false,
      },
      {
        label: 'Source text',
        name: 'sourceText',
        component: 'text',
        defaultValue: 'Image courtesy of ',
      },
      {
        label: 'Source Link',
        name: 'sourceLink',
        component: 'link',
        defaultValue: {
          text: 'Source Website',
        },
      },
    ],
  };
}
