import { IconSettings } from '../../settings/icons';

export function Schema() {
  return {
    label: 'Feature Image',
    key: 'feature-image',
    fields: [
      {
        label: 'Heading',
        name: 'heading',
        component: 'text',
        defaultValue: 'Feature Image Heading',
      },
      {
        label: 'Subheading',
        name: 'subheading',
        component: 'textarea',
        defaultValue: 'Feature image subheading.',
      },
      {
        label: 'Image (Mobile)',
        name: 'imageMobile',
        component: 'image',
      },
      {
        label: 'Image (Desktop)',
        name: 'imageDesktop',
        component: 'image',
      },
      {
        label: 'Image Alt Text',
        name: 'imageAlt',
        component: 'text',
      },
      {
        label: 'Buttons',
        name: 'buttons',
        component: 'group-list',
        fields: [
          {
            label: 'Type',
            name: 'type',
            component: 'select',
            options: [
              { label: 'Primary', value: 'btn-primary' },
              { label: 'Outline', value: 'btn-outline' },
            ],
          },
          {
            label: 'Link',
            name: 'link',
            component: 'link',
          },
          {
            label: 'Schedule Link',
            name: 'scheduleLink',
            component: 'group',
            fields: [
              {
                label: 'Cal.com ID',
                name: 'calId',
                description:
                  'Add a Cal.com ID for this button to open a cal modal',
                component: 'text',
              },
              {
                label: 'Button Label',
                name: 'buttonLabel',
                description: 'Label for the button',
                component: 'text',
              },
            ],
          },
          IconSettings,
        ],
        itemProps: {
          label: '{{item.link.text}}',
        },
        validate: {
          maxItems: 2,
        },
        defaultItem: {
          type: 'btn-primary',
          link: { text: 'Get Started', url: '' },
          icon: '',
        },
        defaultValue: [
          {
            type: 'btn-primary',
            link: { text: 'Get Started', url: '' },
            icon: '',
          },
          {
            type: 'btn-outline',
            link: { text: 'Book a demo', url: '' },
            icon: 'clock',
          },
        ],
      },
    ],
  };
}
