export function Disabled(props) {
  return (
    <svg
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="Dash">
        <rect x="0.666748" width="18" height="18" rx="9" fill="#2B2E33" />
        <path
          id="Icon"
          d="M5.86792 9H13.8679"
          stroke="black"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}
