export function Schema() {
  return {
    label: 'Image Quote',
    key: 'image-quote',
    fields: [
      {
        label: 'Image',
        name: 'image',
        component: 'image',
      },
      {
        label: 'Image Alt',
        name: 'imageAlt',
        component: 'text',
      },
      {
        label: 'Quote',
        name: 'quote',
        component: 'textarea',
      },
      {
        label: 'Author',
        name: 'author',
        component: 'text',
      },
      {
        label: 'Title',
        name: 'title',
        component: 'text',
      },
      {
        label: 'Logo',
        name: 'logo',
        component: 'image',
      },
    ],
  };
}
