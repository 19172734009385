import React from 'react';
import { Icons } from '../../snippets/Icons';

import { ToolTip } from '../../snippets/tooltip';

export function PlanHeroFeatures({ features, isBonus }) {
  const { heading, items } = { ...features };

  if (!heading && !items?.length) return null;

  return (
    <div>
      {heading && (
        <h5
          className={`mb-6 text-sm font-normal ${
            isBonus ? 'text-brand' : 'text-white-700'
          }`}
        >
          {heading}
        </h5>
      )}

      {items?.length > 0 && (
        <ul className="flex flex-col gap-2">
          {items.map((item, index) => {
            const {
              text,
              description,
              isBeta,
              isComingSoon,
              primaryDisclaimerText,
              secondaryDisclaimerText,
            } = { ...item };

            return (
              <li className="flex" key={index}>
                <div className="flex w-6">
                  <Icons
                    icon={`${isBonus ? 'lightning' : 'checkmark'}`}
                    className={`relative text-brand ${
                      isBonus ? 'top-0.5' : 'top-1.5'
                    }`}
                  />
                </div>

                <div>
                  <div className="flex items-center gap-x-2">
                    <p className="text-sm">{text}</p>
                    {(isComingSoon || isBeta) && (
                      <span className="text-2xs text-primary-green-500">
                        {isComingSoon ? 'Coming Soon' : 'Beta'}
                      </span>
                    )}
                    {description && (
                      <ToolTip text={text} description={description} />
                    )}
                  </div>

                  {(primaryDisclaimerText || secondaryDisclaimerText) && (
                    <div className="mt-1 flex gap-1 text-[11px] leading-none">
                      {primaryDisclaimerText && (
                        <span className="text-brand">
                          {primaryDisclaimerText}
                        </span>
                      )}
                      {secondaryDisclaimerText && (
                        <span className="text-white-700">
                          {secondaryDisclaimerText}
                        </span>
                      )}
                    </div>
                  )}
                </div>
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
}

PlanHeroFeatures.displayName = 'PlanHeroFeatures';
