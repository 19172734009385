import HubspotForm from 'react-hubspot-form';

import { Schema } from './ContactForm.schema';

import { Section } from '../../snippets';

export function ContactForm({ cms }) {
  const { heading, description, image, imageAlt, enableMaxWidth } = cms;

  return (
    <Section cms={cms}>
      <div
        className={`grid md:grid-cols-3 lg:grid-cols-2 ${
          enableMaxWidth ? 'mx-auto max-w-7xl px-4' : ''
        }`}
      >
        <div
          className={`md:col-span-2 lg:col-span-1 ${
            enableMaxWidth ? 'md:pr-4 lg:pr-24' : 'p-4 lg:p-24'
          }`}
        >
          {heading ? <h1 className="mb-5">{heading}</h1> : null}
          {description ? <p>{description}</p> : null}
          <div id="contactFormHubspot" className="py-12">
            <HubspotForm
              portalId="22338248"
              formId="a6e8a99e-3ecf-4f58-b21e-b0c41613edad"
              css=""
              cssRequired=""
            />
          </div>
        </div>
        {image ? (
          <div className="flex">
            <img
              className="h-full w-full object-cover object-left"
              src={image.src}
              alt={imageAlt}
            />
          </div>
        ) : null}
      </div>
    </Section>
  );
}

ContactForm.displayName = 'ContactForm';
ContactForm.Schema = Schema;
