export function Schema() {
  return {
    label: 'Feature Email Signup',
    key: 'feature-email-signup',
    fields: [
      {
        label: 'Heading',
        name: 'heading',
        component: 'text',
        defaultValue: 'Feature Email Signup Heading',
      },
      {
        label: 'Subheading',
        name: 'subheading',
        component: 'textarea',
        defaultValue: 'Feature email signup subheading.',
      },
      {
        label: 'Hubspot ID',
        name: 'formId',
        component: 'text',
      },
      {
        label: 'Button Label',
        name: 'buttonLabel',
        component: 'text',
        defaultValue: 'Get Started',
      },
      {
        label: 'Form Submission Redirect',
        name: 'formRedirect',
        component: 'text',
        description: 'URL to page. Leave blank to not redirect.',
      },
      {
        label: 'Image (Mobile)',
        name: 'imageMobile',
        component: 'image',
      },
      {
        label: 'Image (Desktop)',
        name: 'imageDesktop',
        component: 'image',
      },
      {
        label: 'Image Alt Text',
        name: 'imageAlt',
        component: 'text',
      },
    ],
  };
}
