export function LogoWithName(props) {
  return (
    <svg
      width="99"
      height="26"
      viewBox="0 0 99 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="Pack Logo White">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.44979 0.9695C3.32382 0.767433 3.44307 0.548941 3.68084 0.544799C4.70606 0.52694 7.57137 0.492838 14.2354 0.501337L24.0598 0.513814C24.8428 0.514808 25.5721 0.913794 25.9962 1.57339L32.98 12.4353C33.128 12.6655 32.9638 12.9685 32.6905 12.9696L29.6668 12.9815C27.5778 12.9898 26.0114 13.0173 26.0114 13.0457C26.0114 13.0729 26.8972 14.4736 27.9798 16.1582L29.6064 18.6891C29.7542 18.9192 29.59 19.2223 29.3167 19.2234L15.2024 19.2769L17.1576 22.32C17.8525 23.4017 18.4805 24.3974 18.8442 24.9768C18.988 25.2059 18.8246 25.5 18.5544 25.5L13.0468 25.4982C12.2628 25.4976 11.5326 25.0982 11.1084 24.4375L5.80056 16.1717C3.10378 11.972 0.802839 8.34671 0.0518947 7.15488C-0.0755349 6.95263 0.0432999 6.74129 0.282031 6.74129H3.4394C5.45473 6.74129 7.10359 6.71438 7.10359 6.71438C7.10359 6.71438 6.22723 5.3243 5.15619 3.65817C4.42572 2.52189 3.78691 1.51033 3.44979 0.9695ZM9.18855 9.9227C8.10282 8.23288 7.21448 6.79579 7.21448 6.79579C7.21448 6.79579 9.81844 6.74129 14.5528 6.74129C21.4732 6.74129 21.8958 6.74688 21.9735 6.83901C22.0837 6.96955 25.9005 12.9217 25.9005 12.9628C25.9005 12.9806 22.5845 12.9951 18.5316 12.9951H11.1626L9.18855 9.9227Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M42.8572 22.6545V3.17857H50.3432C54.2392 3.17857 56.7995 5.51568 56.7995 9.16046C56.7995 12.8052 54.2392 15.1702 50.3432 15.1702H45.2783V22.6545H42.8572ZM50.1484 5.40439H45.2783V12.9444H50.1205C52.6808 12.9444 54.3227 11.4698 54.3227 9.16046C54.3227 6.85117 52.7086 5.40439 50.1484 5.40439Z"
          fill="currentColor"
        />
        <path
          d="M85.8756 22.6545V3.17857H88.1576V16.0049L95.2818 8.46489H98.0926L92.5546 14.3355L98.4821 22.6545H95.7271L91.024 15.977L88.1576 18.9262V22.6545H85.8756Z"
          fill="currentColor"
        />
        <path
          d="M77.7523 22.8214C73.6058 22.8214 70.8786 20.0113 70.8786 15.5875C70.8786 11.2472 73.6893 8.29796 77.808 8.29796C81.2866 8.29796 83.4851 10.2456 84.0695 13.3617H81.6762C81.2588 11.4698 79.8674 10.329 77.7802 10.329C75.0529 10.329 73.2162 12.4992 73.2162 15.5875C73.2162 18.6758 75.0529 20.7904 77.7802 20.7904C79.8117 20.7904 81.2031 19.6218 81.6484 17.8133H84.0695C83.513 20.8738 81.2031 22.8214 77.7523 22.8214Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M69.141 13.4173C69.141 10.1064 67.0538 8.29796 63.436 8.29796C60.0131 8.29796 57.7589 9.9395 57.425 12.7218H59.7069C59.9852 11.275 61.321 10.329 63.3247 10.329C65.551 10.329 66.859 11.4419 66.859 13.2782V14.252H62.6847C58.9556 14.252 56.9797 15.8379 56.9797 18.6758C56.9797 21.2634 59.0947 22.8214 62.2116 22.8214C64.6605 22.8214 66.0798 21.7085 66.9703 20.4287L67.1651 22.6545H69.141V13.4173ZM66.859 16.1718V16.8674C66.859 19.2323 65.2171 20.8738 62.3785 20.8738C60.4862 20.8738 59.2895 19.9279 59.2895 18.5367C59.2895 16.923 60.4305 16.1718 62.462 16.1718H66.859Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}
