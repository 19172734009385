export function Boundary(props) {
  return (
    <svg
      width="32"
      height="33"
      viewBox="0 0 32 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="Frame" clipPath="url(#clip0_2369_652)">
        <g id="Group">
          <path
            id="Vector"
            d="M6.66659 23.9507C5.93059 23.9507 5.33325 23.3534 5.33325 22.6174V11.0618C5.33325 10.3258 5.93059 9.72852 6.66659 9.72852C7.40259 9.72852 7.99992 10.3258 7.99992 11.0618V22.6174C7.99992 23.3534 7.40259 23.9507 6.66659 23.9507Z"
            fill="currentColor"
          />
          <path
            id="Vector_2"
            d="M15.278 27.5064H10.222C9.48601 27.5064 8.88867 26.9091 8.88867 26.1731C8.88867 25.4371 9.48601 24.8397 10.222 24.8397H15.278C16.014 24.8397 16.6113 25.4371 16.6113 26.1731C16.6113 26.9091 16.014 27.5064 15.278 27.5064Z"
            fill="currentColor"
          />
          <path
            id="Vector_3"
            d="M25.3331 17.4512C24.5971 17.4512 23.9998 16.8538 23.9998 16.1178V11.0618C23.9998 10.3258 24.5971 9.72852 25.3331 9.72852C26.0691 9.72852 26.6664 10.3258 26.6664 11.0618V16.1178C26.6664 16.8538 26.0691 17.4512 25.3331 17.4512Z"
            fill="currentColor"
          />
          <path
            id="Vector_4"
            d="M21.7776 8.83964H10.222C9.48601 8.83964 8.88867 8.24231 8.88867 7.50631C8.88867 6.77031 9.48601 6.17297 10.222 6.17297H21.7776C22.5136 6.17297 23.1109 6.77031 23.1109 7.50631C23.1109 8.24231 22.5136 8.83964 21.7776 8.83964Z"
            fill="currentColor"
          />
          <path
            id="Vector_5"
            d="M8.12073 22.173H5.21228C3.80629 22.173 2.6665 23.3128 2.6665 24.7188V27.6272C2.6665 29.0332 3.80629 30.173 5.21228 30.173H8.12073C9.52672 30.173 10.6665 29.0332 10.6665 27.6272V24.7188C10.6665 23.3128 9.52672 22.173 8.12073 22.173Z"
            fill="currentColor"
          />
          <path
            id="Vector_6"
            d="M8.12097 3.50635H5.21253C3.80653 3.50635 2.66675 4.64613 2.66675 6.05213V8.96057C2.66675 10.3666 3.80653 11.5063 5.21253 11.5063H8.12097C9.52696 11.5063 10.6667 10.3666 10.6667 8.96057V6.05213C10.6667 4.64613 9.52696 3.50635 8.12097 3.50635Z"
            fill="currentColor"
          />
          <path
            id="Vector_7"
            d="M26.7875 3.50635H23.879C22.473 3.50635 21.3333 4.64613 21.3333 6.05213V8.96057C21.3333 10.3666 22.473 11.5063 23.879 11.5063H26.7875C28.1935 11.5063 29.3333 10.3666 29.3333 8.96057V6.05213C29.3333 4.64613 28.1935 3.50635 26.7875 3.50635Z"
            fill="currentColor"
          />
          <path
            id="Vector_8"
            d="M30.7466 21.4085L18.5617 16.957C17.8577 16.701 17.0897 16.8699 16.56 17.3996C16.0302 17.9294 15.8595 18.6974 16.1173 19.4014L20.5689 31.5863C20.8444 32.3401 21.5609 32.8396 22.3609 32.8396C22.3733 32.8396 22.3875 32.8396 22.4 32.8396C23.2142 32.8236 23.9289 32.2903 24.1778 31.5134L25.7529 26.5907L30.6755 25.0156C31.4524 24.7667 31.984 24.0521 32.0017 23.2379C32.0195 22.4236 31.5164 21.6876 30.7502 21.4067L30.7466 21.4085Z"
            fill="currentColor"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_2369_652">
          <rect
            width="32"
            height="32"
            fill="currentColor"
            transform="translate(0 0.8396)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
