export function Laptop(props) {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.5 4.25H7.448C6.54952 4.24997 5.8003 4.24995 5.20552 4.32991C4.57773 4.41432 4.01093 4.59999 3.55546 5.05546C3.09999 5.51093 2.91432 6.07773 2.82991 6.70552C2.74994 7.3003 2.74997 8.04952 2.75 8.948L2.75 9V16.25H1.83333C1.23502 16.25 0.75 16.735 0.75 17.3333C0.75 19.2203 2.27969 20.75 4.16667 20.75H20.8333C22.7203 20.75 24.25 19.2203 24.25 17.3333C24.25 16.735 23.765 16.25 23.1667 16.25H22.25V9V8.94801C22.25 8.04953 22.2501 7.3003 22.1701 6.70552C22.0857 6.07773 21.9 5.51093 21.4445 5.05546C20.9891 4.59999 20.4223 4.41432 19.7945 4.32991C19.1997 4.24995 18.4505 4.24997 17.552 4.25H17.5H7.5ZM20.75 16.25V9C20.75 8.03599 20.7484 7.38843 20.6835 6.90539C20.6214 6.44393 20.5142 6.24643 20.3839 6.11612C20.2536 5.9858 20.0561 5.87858 19.5946 5.81654C19.1116 5.75159 18.464 5.75 17.5 5.75H7.5C6.53599 5.75 5.88843 5.75159 5.40539 5.81654C4.94393 5.87858 4.74643 5.9858 4.61612 6.11612C4.4858 6.24643 4.37858 6.44393 4.31654 6.90539C4.25159 7.38843 4.25 8.03599 4.25 9V16.25H20.75ZM3.5 17.75H2.29542C2.48566 18.6082 3.25121 19.25 4.16667 19.25H20.8333C21.7488 19.25 22.5143 18.6082 22.7046 17.75H21.5H3.5Z"
        fill="currentColor"
      />
    </svg>
  );
}
