import { useState } from 'react';

import { Link } from '../../snippets/Link';

import { Icons } from '../../snippets/Icons';
import { Schema } from './ArticleSidebarNewsletter.schema';

import { Section } from '../../snippets';

export function ArticleSidebarNewsletter({ cms }) {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const { formId, heading, description, privacyUrl } = cms;

  function handleChange(e) {
    setEmail(e.target.value);
  }

  async function handleSubmit(e) {
    e.preventDefault();
    if (loading || !email) return;
    setLoading(true);
    try {
      const resp = await fetch('/api/subscribe', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email,
          formId,
        }),
      });
      const respJson = await resp.json();
      if (!respJson.success) throw new Error('Could not subscribe');
      setSuccess(true);
      setTimeout(() => {
        setSuccess(false);
      }, 3000);
    } catch (err) {
      setSuccess(false);
    } finally {
      setLoading(false);
    }
  }

  if (!formId) return null;

  return (
    <Section cms={cms}>
      <div className="flex flex-col gap-8 rounded-2xl border border-primary-green-100 bg-off-white p-8 shadow-sm">
        <div className="h-14 w-14 rounded-xl border border-primary-green-100 bg-white p-[14px] text-brand shadow-sm">
          <Icons icon="send" />
        </div>
        {heading || description ? (
          <div className="flex flex-col gap-2">
            {heading ? (
              <p className="text-2xl font-semibold">{heading}</p>
            ) : null}
            {description ? <p className="text-base">{description}</p> : null}
          </div>
        ) : null}
        <form className="flex flex-col gap-4" onSubmit={handleSubmit}>
          <input
            type="email"
            className="input-email-white"
            placeholder="Enter your email"
            value={email}
            onChange={handleChange}
            required
          />
          {privacyUrl ? (
            <p className="text-sm">
              Read about our{' '}
              <Link className="underline" href={privacyUrl}>
                privacy policy
              </Link>
              .
            </p>
          ) : null}
          <button
            type="submit"
            className="btn-primary w-full text-white"
            disabled={loading}
          >
            {/* eslint-disable-next-line no-nested-ternary */}
            {loading ? 'Loading...' : success ? 'Subscribed' : 'Subscribe'}
          </button>
        </form>
      </div>
    </Section>
  );
}

ArticleSidebarNewsletter.displayName = 'ArticleSidebarNewsletter';
ArticleSidebarNewsletter.Schema = Schema;
