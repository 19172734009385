import { useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Schema } from './TestimonialCarousel.schema';
import { Icons } from '../../snippets/Icons';

import { Link } from '../../snippets/Link';
import { Section } from '../../snippets';

export function TestimonialCarousel({ cms }) {
  const { slides } = cms;
  const testimonialRef = useRef(null);
  const imageRef = useRef(null);

  function handleNext() {
    if (!testimonialRef?.current?.swiper || !imageRef?.current?.swiper) return;
    testimonialRef.current.swiper.slideNext();
    imageRef.current.swiper.slideNext();
  }
  function handlePrev() {
    if (!testimonialRef?.current?.swiper || !imageRef?.current?.swiper) return;
    testimonialRef.current.swiper.slidePrev();
    imageRef.current.swiper.slidePrev();
  }

  if (!slides?.length) return null;

  return (
    <Section cms={cms}>
      <div className="mx-auto max-w-7xl py-16 md:grid md:grid-cols-2 md:gap-16 md:px-4">
        <div className="flex flex-col gap-4 px-4 md:px-0 lg:relative lg:gap-0">
          <Swiper
            ref={testimonialRef}
            slidesPerView={1}
            loop
            draggable={false}
            className="h-full w-full"
          >
            {slides.map((slide, index) => {
              const quoteLength = slide?.quote?.length;

              return (
                <SwiperSlide
                  key={`testimonial-carousel-testimonial-${index}`}
                  className="flex h-full w-full flex-col"
                >
                  <div className="mb-8 flex flex-col items-start gap-6 md:gap-12">
                    {slide.logo?.src ? (
                      <img
                        src={slide.logo.src}
                        alt={slide.logoAlt}
                        srcSet={`${slide.logo.src} 2x`}
                      />
                    ) : null}
                    <div className="flex flex-col gap-6">
                      {slide.quote ? (
                        <blockquote>
                          <p
                            className={`font-medium text-white-600 ${
                              quoteLength > 475 ? 'lg:text-xl' : 'lg:text-2xl'
                            }`}
                          >
                            {slide.quote}
                          </p>
                        </blockquote>
                      ) : null}
                      {slide.link?.url && slide.link?.text ? (
                        <Link
                          className="text-link group inline-flex items-center gap-2"
                          href={slide?.link?.url}
                          newTab={slide?.link?.newTab}
                        >
                          {slide.link.text}
                          <Icons icon="arrowRight" />
                        </Link>
                      ) : null}
                    </div>
                  </div>
                  <footer className="flex h-full w-full items-start gap-2 overflow-hidden md:items-end md:gap-4">
                    {slide.profile?.src ? (
                      <div className="flex h-14 w-14 shrink-0 overflow-hidden rounded-full">
                        <img src={slide.profile.src} alt={slide.name} />
                      </div>
                    ) : null}
                    <div className="flex min-w-fit flex-col gap-1">
                      {slide.name ? (
                        <cite className="font-semibold not-italic">
                          {slide.name}
                        </cite>
                      ) : null}
                      {slide.title ? (
                        <p className="text-base text-white-600">
                          {slide.title}
                        </p>
                      ) : null}
                    </div>
                  </footer>
                </SwiperSlide>
              );
            })}
          </Swiper>
          <div className="grid grid-cols-[56px,_56px] gap-2 bg-black lg:absolute lg:bottom-0 lg:right-0 lg:z-10">
            <button
              className="btn-outline h-14 p-0"
              type="button"
              onClick={handlePrev}
              aria-label="Previous"
            >
              <Icons icon="arrowLeft" className="h-6 w-6" />
            </button>
            <button
              className="btn-outline h-14 p-0"
              type="button"
              onClick={handleNext}
              aria-label="Next"
            >
              <Icons icon="arrowRight" className="h-6 w-6" />
            </button>
          </div>
        </div>
        <div className="mt-8 h-full w-full items-start md:mt-0 md:flex md:flex-col">
          <Swiper
            ref={imageRef}
            slidesPerView={1}
            loop
            draggable={false}
            className="w-full lg:h-full"
          >
            {slides.map((slide, index) => {
              return (
                <SwiperSlide
                  key={`testimonial-carousel-image-${index}`}
                  className="flex aspect-square h-full w-full"
                >
                  {slide.image ? (
                    <img
                      src={slide.image.src}
                      alt={slide.imageAlt}
                      className="flex h-full w-full object-cover"
                    />
                  ) : (
                    <div />
                  )}
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </div>
    </Section>
  );
}

TestimonialCarousel.displayName = 'TestimonialCarousel';
TestimonialCarousel.Schema = Schema;
