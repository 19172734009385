import { useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Icons } from '../../snippets/Icons';
import { Schema } from './TestimonialBlock.schema';

import { ToggleButtons } from '../../snippets/toggle-buttons';
import { MarqueeComponent } from '../../snippets/marquee';

import { Link } from '../../snippets/Link';
import { Section } from '../../snippets';

export function TestimonialBlock({ cms }) {
  const [contextState, setContextState] = useState(0);
  const { contexts, buttons } = cms;

  if (!contexts || !contexts.length) return null;

  return (
    <Section cms={cms}>
      <div className="px-contained relative py-[150px]">
        <div className="testimonial-block-gradient pointer-events-none" />

        <div className="mx-auto max-w-7xl">
          {contexts.length > 1 && (
            <div className="mb-5 flex justify-center">
              <ToggleButtons
                contexts={contexts}
                contextState={contextState}
                setContextState={setContextState}
              />
            </div>
          )}

          <h2 className="mb-8 text-center text-white selection:bg-brand md:mb-12">
            {contexts[contextState].heading}
          </h2>

          {/* {contexts[contextState].logoMarquee?.length > 0 && (
        <div className="my-8 md:my-0 md:mb-16 md:mt-12">
          <div className="hidden justify-center gap-[50px] lg:flex">
            {contexts[contextState].logoMarquee?.map((marqueeLogo, index) => {
              return (
                <img
                  key={`logos-${index}`}
                  className="h-6"
                  src={marqueeLogo?.imageLogo?.src}
                  alt={marqueeLogo?.imageAlt}
                />
              );
            })}
          </div>
          <div className="py-6 md:py-20 lg:hidden">
            <MarqueeComponent
              logoMarquee={contexts[contextState].logoMarquee}
              imageClasses="mx-12 h-6"
            />
          </div>
        </div>
      )} */}

          {contexts[contextState].testimonials?.length > 0 && (
            <div className="grid grid-cols-1 gap-5 md:mx-auto md:grid-cols-2 md:gap-[18px] lg:grid-cols-3">
              {contexts[contextState].testimonials.map((testimonial, index) => {
                const {
                  author,
                  backgroundImage,
                  company,
                  logo,
                  logoAlt,
                  logoHeight,
                  quote,
                } = {
                  ...testimonial,
                };

                return (
                  <div
                    key={index}
                    className="black-02-gradient hover:black-05-gradient flex flex-col rounded-[20px] p-5 transition-colors duration-300"
                  >
                    <div className="relative mb-4 flex h-[200px] items-center justify-center overflow-hidden rounded-[16px]">
                      <img
                        className="h-full w-full object-cover"
                        src={backgroundImage?.src}
                        alt={author}
                      />
                      <div className="absolute inset-0 h-full w-full bg-[rgba(0,0,0,0.6)]" />
                      {logo?.src && (
                        <img
                          className="absolute left-5 top-5 invert"
                          src={logo?.src}
                          alt={logoAlt}
                          style={{
                            height: `${logoHeight || 24}px`,
                          }}
                        />
                      )}
                    </div>

                    <blockquote className="mb-10">
                      <p className="text-base font-light leading-[1.6] text-[#ffffffbf]">
                        {quote}
                      </p>
                    </blockquote>

                    <div className="mt-auto flex flex-col gap-0.5 pb-0.5">
                      <h3 className="text-sm font-medium text-[rgba(255,255,255,0.9)]">
                        {author}
                      </h3>
                      <h4 className="text-sm font-normal text-[rgba(255,255,255,0.5)]">
                        {company}
                      </h4>
                    </div>
                  </div>
                );
              })}
            </div>
          )}

          {buttons.length > 0 && (
            <div className="mt-9 flex flex-col-reverse gap-3 md:order-1 md:mt-16 md:flex-row md:justify-center">
              {buttons.map((button, index) => {
                return (
                  <Link
                    className={`${button.type} ${
                      button.type === 'btn-primary' ? 'text-white' : ''
                    }`}
                    key={`testimonialBlockButton-${index}`}
                    href={button.link?.url}
                    newTab={button?.link?.newTab}
                  >
                    {button.icon && <Icons icon={button.icon} />}
                    {button.link.text}
                  </Link>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </Section>
  );
}

TestimonialBlock.displayName = 'TestimonialBlock';
TestimonialBlock.Schema = Schema;
