import { useState } from 'react';

export function MarqueeComponent({
  logoMarquee,
  classes = '',
  imageClasses = 'mx-10 h-7',
  direction = 'normal',
  enableHover = true,
  seconds = 80,
}) {
  const [isHovered, setIsHovered] = useState(false);
  if (!logoMarquee?.length) return null;

  return (
    <div
      className={`relative flex w-full items-center overflow-x-hidden ${classes}`}
    >
      <div
        className="flex"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {[...Array(4).keys()].map((_, index) => {
          return (
            <div
              key={index}
              className="relative flex shrink-0 overflow-x-hidden"
              style={{
                animation: `scroll ${seconds}s infinite linear`,
                animationDirection: direction,
                animationPlayState: isHovered ? 'paused' : 'running',
              }}
            >
              {logoMarquee.map((marqueeLogo, i) => {
                return (
                  <MarqueeImage
                    marqueeLogo={marqueeLogo}
                    index={i}
                    imageClasses={imageClasses}
                    enableHover={enableHover}
                  />
                );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
}

MarqueeComponent.displayName = 'MarqueeComponent';

function MarqueeImage({
  index,
  imageClasses,
  marqueeLogo,
  enableHover = true,
}) {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <img
      key={index}
      className={`${imageClasses} transition-all ${
        isHovered && enableHover ? 'invert' : ''
      }`}
      src={marqueeLogo?.imageLogo?.src}
      alt={marqueeLogo?.imageAlt}
      onMouseEnter={() => (enableHover ? setIsHovered(true) : null)}
      onMouseLeave={() => (enableHover ? setIsHovered(false) : null)}
    />
  );
}
