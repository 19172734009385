export function Schema({ article }) {
  if (!article) return null;

  return {
    label: 'Article Sidebar Newsletter',
    key: 'article-sidebar-newsletter',
    fields: [
      {
        label: 'Hubspot ID',
        name: 'formId',
        component: 'text',
      },
      {
        label: 'Heading',
        name: 'heading',
        component: 'text',
        defaultValue: 'Weekly newsletter',
      },
      {
        label: 'Description',
        name: 'description',
        component: 'textarea',
        defaultValue:
          'No spam. Just the latest releases and tips, interesting articles, and product updates in your inbox every week.',
      },
      {
        label: 'Privacy Policy URL',
        name: 'privacyUrl',
        component: 'text',
        defaultValue: '/pages/privacy-policy',
      },
    ],
  };
}
