import { useState, useEffect, useRef } from 'react';
import { useRouter, useSettings } from '@backpackjs/storefront';
import Confetti from 'react-confetti';
import { Schema } from './MainHero.schema';
import { FadeIn } from '../../animations/FadeIn';

import { ToggleButtons } from '../../snippets/toggle-buttons';
import { MarqueeComponent } from '../../snippets/marquee';

import { Section } from '../../snippets';

export function MainHero({ cms }) {
  const { formId, buttonLabel, formRedirect, hideLogoMarquee } = cms;
  const [contextState, setContextState] = useState(0);
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [loadingVideo, setLoadingVideo] = useState(false);
  const settings = useSettings();
  const router = useRouter();
  const videoDesktopRef = useRef(null);
  const videoMobileRef = useRef(null);

  function handleChange(e) {
    setEmail(e.target.value);
  }

  const [copySuccess, setCopySuccess] = useState('');
  const [showConfetti, setShowConfetti] = useState(false);

  async function handleCopy(value) {
    try {
      // Using the Clipboard API to copy text
      await navigator.clipboard.writeText(value);
      setCopySuccess('Copied to clipboard!!');
      setShowConfetti(true);
    } catch (err) {
      setCopySuccess('Failed to copy!');
    }

    setTimeout(() => {
      setCopySuccess('');
      setShowConfetti(false);
    }, 2500);
  }

  async function handleSubmit(e) {
    e.preventDefault();
    if (loading || !email) return;
    setLoading(true);
    try {
      const resp = await fetch('/api/subscribe', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email,
          formId,
        }),
      });
      const respJson = await resp.json();
      if (!respJson.success) throw new Error('Could not subscribe');
      setSuccess(true);
      if (formRedirect) {
        try {
          const redirectUrl = new URL(formRedirect);
          window.location = redirectUrl.href;
        } catch (err) {
          router.push(formRedirect);
        }
      }
      setTimeout(() => {
        setSuccess(false);
      }, 3000);
    } catch (err) {
      setSuccess(false);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    setLoadingVideo(true);
    setTimeout(() => {
      videoDesktopRef?.current?.load();
      videoMobileRef?.current?.load();
      videoDesktopRef?.current?.play();
      videoMobileRef?.current?.play();
      setLoadingVideo(false);
    }, 300);
  }, [contextState]);

  return (
    <Section cms={cms}>
      <div className="northern-lights-gradient px-contained pt-6 pb-20 md:pb-[100px] md:pt-20">
        <div className="mx-auto max-w-7xl space-y-7">
          {/* Context Toggle */}
          {cms?.heroContexts?.length > 1 && (
            <FadeIn>
              <div className="flex justify-center">
                <ToggleButtons
                  contexts={cms?.heroContexts}
                  contextState={contextState}
                  setContextState={setContextState}
                  onClick={() => {
                    if (loadingVideo) {
                      return null;
                    }
                  }}
                />
              </div>
            </FadeIn>
          )}

          {/* Heading */}
          <FadeIn delayAnimationSeconds={75}>
            <h1 className="h1 mx-auto max-w-3xl text-center transition-all selection:bg-brand max-md:text-[44px]">
              {cms.heroContexts?.[contextState]?.heading}
            </h1>
          </FadeIn>

          {/* Subheading */}
          <FadeIn delayAnimationSeconds={150}>
            <p className="body-1 md:sub-heading mx-auto max-w-3xl text-center leading-[140%] text-white-600 selection:bg-brand selection:text-white md:px-0">
              {cms.heroContexts?.[contextState]?.subheading}
            </p>
          </FadeIn>

          {/* Email newsletter sign up */}
          {cms.heroContexts?.[contextState]?.enableEmailForm && formId ? (
            <form
              className="mx-auto flex flex-col justify-center gap-3 transition-all md:flex-row md:px-0"
              onSubmit={handleSubmit}
            >
              <FadeIn delayAnimationSeconds={225}>
                <div className="input-group">
                  <input
                    type="email"
                    className="input-email md:!pr-[135px]"
                    placeholder="Enter your email"
                    value={email}
                    onChange={handleChange}
                    required
                  />
                  <button
                    type="submit"
                    className="btn-primary"
                    disabled={loading}
                  >
                    {/* eslint-disable-next-line no-nested-ternary */}
                    {loading ? 'Loading...' : success ? 'Sent!' : buttonLabel}
                  </button>
                </div>
              </FadeIn>
            </form>
          ) : null}

          {cms.heroContexts?.[contextState]?.enableCopyPaste &&
          cms.heroContexts?.[contextState]?.copyPasteText ? (
            <button
              type="button"
              className="btn-primary text-sub-heading mx-auto rounded-full py-2 font-medium"
              onClick={() => {
                handleCopy(cms.heroContexts?.[contextState]?.copyPasteValue);
              }}
            >
              {showConfetti && (
                <Confetti
                  drawShape={(ctx) => {
                    ctx.beginPath();
                    for (let i = 0; i < 22; i++) {
                      const angle = 0.35 * i;
                      const x = (0.2 + 1.5 * angle) * Math.cos(angle);
                      const y = (0.2 + 1.5 * angle) * Math.sin(angle);
                      ctx.lineTo(x, y);
                    }
                    ctx.stroke();
                    ctx.closePath();
                  }}
                />
              )}
              {!copySuccess ? (
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.75 1.75H3.75C2.64543 1.75 1.75 2.64543 1.75 3.75V10.75C1.75 11.8546 2.64543 12.75 3.75 12.75H10.75C11.8546 12.75 12.75 11.8546 12.75 10.75V3.75C12.75 2.64543 11.8546 1.75 10.75 1.75Z"
                    stroke="black"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M14.9995 5.39502C15.7325 5.69202 16.2495 6.41002 16.2495 7.25002V14.25C16.2495 15.355 15.3545 16.25 14.2495 16.25H7.24953C6.41053 16.25 5.69153 15.733 5.39453 15"
                    stroke="black"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              ) : (
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.75 9.5L4.5 14.25L12.5 4"
                    stroke="black"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M8.50586 13.308L9.24986 14.25L17.2499 4"
                    stroke="black"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              )}
              {!copySuccess
                ? cms.heroContexts?.[contextState]?.copyPasteText
                : copySuccess}
            </button>
          ) : null}

          {/* Hero Video */}
          {cms.heroContexts?.[contextState]?.videoDesktop ||
          cms.heroContexts?.[contextState]?.videoMobile ? (
            <FadeIn delayAnimationSeconds={300} threshold={0}>
              <div className="flex justify-center md:order-2 md:pt-[52px]">
                <div className="flex justify-center rounded-lg bg-[rgba(255,255,255,0.1)] p-1 md:order-3 md:rounded-[28px] md:p-[14px]">
                  <div
                    className={`aspect-video overflow-hidden rounded shadow-sm transition-opacity duration-300 md:rounded-[15px] ${
                      loadingVideo ? 'opacity-0' : 'opacity-100'
                    }`}
                  >
                    {cms.heroContexts?.[contextState]?.videoDesktop ? (
                      <video
                        ref={videoDesktopRef}
                        className="you-cursor hidden h-full w-full object-cover md:block"
                        poster={
                          cms.heroContexts?.[contextState]?.imageDesktop?.src
                        }
                        autoPlay
                        muted
                        playsInline
                        loop
                      >
                        <source
                          src={cms.heroContexts?.[contextState]?.videoDesktop}
                          type="video/mp4"
                        />
                      </video>
                    ) : null}
                    {cms.heroContexts?.[contextState]?.videoMobile ? (
                      <video
                        ref={videoMobileRef}
                        className="you-cursor h-full w-full object-cover md:hidden"
                        poster={
                          cms.heroContexts?.[contextState]?.imageMobile?.src
                        }
                        autoPlay
                        muted
                        playsInline
                        loop
                      >
                        <source
                          src={cms.heroContexts?.[contextState]?.videoMobile}
                          type="video/mp4"
                        />
                      </video>
                    ) : null}
                  </div>
                </div>
              </div>
            </FadeIn>
          ) : null}

          {/* Hero Image */}
          {!cms.heroContexts?.[contextState]?.videoMobile &&
            !cms.heroContexts?.[contextState]?.videoDesktop &&
            (cms.heroContexts?.[contextState]?.imageDesktop ||
              cms.heroContexts?.[contextState]?.imageMobile) && (
              <div className="flex justify-center px-4 md:order-2 md:pt-[52px]">
                <picture className="w-full max-w-7xl">
                  {cms.heroContexts?.[contextState]?.imageDesktop &&
                    cms.heroContexts?.[contextState]?.imageMobile && (
                      <source
                        srcSet={
                          cms.heroContexts?.[contextState]?.imageDesktop?.src
                        }
                        media="(min-width: 768px)"
                      />
                    )}

                  <img
                    className="w-full"
                    src={
                      cms.heroContexts?.[contextState]?.imageDesktop?.src ||
                      cms.heroContexts?.[contextState]?.imageMobile?.src
                    }
                    alt={cms.heroContexts?.[contextState]?.imageAlt}
                  />
                </picture>
              </div>
            )}

          {!hideLogoMarquee && (
            <div className="py-6 md:py-20">
              <MarqueeComponent logoMarquee={settings?.logos?.logoMarquee} />
            </div>
          )}
        </div>
      </div>
    </Section>
  );
}

MainHero.displayName = 'MainHero';
MainHero.Schema = Schema;
