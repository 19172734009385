export function Document(props) {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20.3335 3.16699H14.6668C13.6175 3.16699 12.6695 3.61099 12.0002 4.31899C11.3308 3.60966 10.3828 3.16699 9.3335 3.16699H3.66683C2.38016 3.16699 1.3335 4.21366 1.3335 5.50033V18.167C1.3335 19.4537 2.38016 20.5003 3.66683 20.5003H9.3335C10.2522 20.5003 11.0002 21.2483 11.0002 22.167C11.0002 22.719 11.4482 23.167 12.0002 23.167C12.5522 23.167 13.0002 22.719 13.0002 22.167C13.0002 21.2483 13.7482 20.5003 14.6668 20.5003H20.3335C21.6202 20.5003 22.6668 19.4537 22.6668 18.167V5.50033C22.6668 4.21366 21.6202 3.16699 20.3335 3.16699ZM20.6668 18.167C20.6668 18.351 20.5175 18.5003 20.3335 18.5003H14.6668C14.0642 18.5003 13.5028 18.6603 13.0002 18.919V6.83366C13.0002 5.91499 13.7482 5.16699 14.6668 5.16699H20.3335C20.5175 5.16699 20.6668 5.31633 20.6668 5.50033V18.167Z"
        fill="currentColor"
      />
    </svg>
  );
}
