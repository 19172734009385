import { useBlog } from '@backpackjs/storefront';
import { Link } from '../../snippets/Link';

import { Schema } from './FeaturedArticle.schema';

import { Section } from '../../snippets';

export function FeaturedArticle({ cms }) {
  const blog = useBlog();
  const { handle } = cms;
  const article = blog?.articles.find((item) => {
    if (!handle) return true;
    return item.handle === handle;
  });

  if (!article) return null;

  const date = new Date(article.publishedAt || article.createdAt);
  const url = `/articles/${article.handle}`;

  const { category } = article;

  const badgeClasses = {
    blog: 'badge-blog-green',
    'case studies': 'badge-blog-purple',
    tutorials: 'badge-blog-cyan',
    news: 'badge-blog-pink',
  };

  const badgeClass =
    badgeClasses?.[category?.toLowerCase()] || 'badge-blog-green';

  const badgeColorTag = article?.tags
    ?.find((tag) => tag?.includes('badge::'))
    ?.split('::')?.[1];

  return (
    <Section cms={cms}>
      <div className="px-4">
        <div className="mx-auto my-16 grid max-w-7xl gap-8 md:grid-cols-3">
          <div className="md:col-span-2">
            <Link aria-label={article.title} href={url}>
              <img
                src={article.seo.image}
                alt={article.title}
                className="rounded-2xl"
              />
            </Link>
          </div>
          <div className="flex flex-col gap-3">
            {category && (
              <div
                className={`mb-1 flex max-w-fit rounded-2xl border px-2.5 py-0.5 ${
                  badgeColorTag !== undefined
                    ? `badge-blog-${badgeColorTag}`
                    : badgeClass
                }`}
              >
                <span className="self-center pb-0.5 align-middle font-inter text-sm font-medium capitalize leading-[140%]">
                  {category}
                </span>
              </div>
            )}
            <h2 className="text-3xl font-semibold">
              <Link href={url} passHref>
                <a href={url}>{article.title}</a>
              </Link>
            </h2>
            {article.excerpt || article?.seo?.description ? (
              <p className="text-base text-white-700">
                {article.excerpt || article?.seo?.description}
              </p>
            ) : null}
            <p className="text-sm font-semibold">
              {article.author ? `${article.author} • ` : ''}
              {date.toLocaleDateString('en-US', { day: 'numeric' })}{' '}
              {date.toLocaleDateString('en-US', { month: 'short' })}{' '}
              {date.toLocaleDateString('en-US', { year: 'numeric' })}
            </p>
          </div>
        </div>
      </div>
    </Section>
  );
}

FeaturedArticle.displayName = 'FeaturedArticle';
FeaturedArticle.Schema = Schema;
