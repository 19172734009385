import { useState } from 'react';
import { Icons } from '../../snippets/Icons';
import { Schema } from './FeatureAccordion.schema';

import { Section } from '../../snippets';

export function FeatureAccordion({ cms }) {
  const [openIndex, setOpenIndex] = useState(0);
  const { accordions, badgeLabel, icon, description, heading } = cms;
  return (
    <Section cms={cms}>
      <div className="flex flex-col items-center pt-16 md:pt-24">
        {badgeLabel ? (
          <div className="badge-wrapper mb-3">
            {icon ? <Icons icon={icon} className="badge-icon" /> : null}
            <span className="badge-label">{badgeLabel}</span>
          </div>
        ) : null}

        {heading ? (
          <h2 className="mb-3 px-4 text-center selection:bg-brand md:mx-auto md:mb-5 md:max-w-6xl">
            {heading}
          </h2>
        ) : null}

        {description ? (
          <p className="mb-3 px-4 text-center text-base selection:bg-brand selection:text-white md:mx-auto md:max-w-3xl md:text-xl md:text-white-700">
            {description}
          </p>
        ) : null}

        {accordions?.length > 0 ? (
          <>
            <div className="md:hidden">
              <div className="mt-16 grid w-full gap-16">
                {accordions.map((accordion, index) => {
                  return (
                    <div key={`featureAccordion-${index}`} className="md:py-12">
                      <div className="px-4 md:px-0">
                        {accordion.icon ? (
                          <div className="badge-gradient mb-4 flex h-10 w-10 items-center justify-center rounded-[10px]">
                            <Icons icon={accordion.icon} className="h-6 w-6" />
                          </div>
                        ) : null}

                        {accordion.heading ? (
                          <h3 className="mb-4 font-inter text-2xl font-semibold leading-8">
                            {accordion.heading}
                          </h3>
                        ) : null}

                        {accordion.description ? (
                          <p className="mb-4 text-base text-white-700">
                            {accordion.description}
                          </p>
                        ) : null}

                        <List items={accordion.listItems} />

                        <Insights insights={accordion.insights} />

                        <InlineImage
                          heading={accordion.inlineImageHeading}
                          image={accordion.inlineImage}
                          text={accordion.inlineImageText}
                        />
                      </div>

                      {(accordion.imageDesktop || accordion.image) && (
                        <div
                          className={`${
                            accordion.imageType === 'center' ? 'px-4' : 'pl-4'
                          } mt-8`}
                        >
                          <picture>
                            {accordion.imageDesktop && accordion.image && (
                              <source
                                srcSet={accordion.imageDesktop.src}
                                media="(min-width: 768px)"
                              />
                            )}
                            <img
                              className="w-full"
                              src={
                                accordion.image?.src ||
                                accordion.imageDesktop?.src
                              }
                              alt={accordion.imageAlt}
                            />
                          </picture>
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>

            <div className="hidden w-full md:mt-16 md:grid md:grid-cols-[1fr_1fr]  md:pl-12">
              <div className="relative w-full max-w-xl self-center justify-self-center px-8 before:absolute before:top-0 before:left-0 before:h-full before:rounded-full before:border-x-4 before:border-brand before:opacity-[15%]">
                {accordions.map((accordion, index) => {
                  return (
                    <div
                      key={`desktopAccordion-${index}`}
                      className={`${
                        openIndex === index
                          ? 'my-8 before:absolute before:top-1/2 before:-left-8 before:h-full before:-translate-y-1/2 before:rounded-full before:border-x-4 before:border-brand before:opacity-100 first-of-type:mt-0 last-of-type:mb-0'
                          : ''
                      } relative`}
                    >
                      <button
                        aria-label={`Open accordion for ${accordion.heading}`}
                        className="flex w-full items-center py-[10px] text-left"
                        onClick={() => setOpenIndex(index)}
                        type="button"
                      >
                        <p
                          className={`body-1-em leading-[30px] ${
                            openIndex === index ? '' : 'text-[#52615D]'
                          }`}
                        >
                          {accordion.heading}
                        </p>
                        <div
                          className={`flex h-[30px] w-[30px] -rotate-90 items-center justify-center text-[#52615D] ${
                            openIndex === index ? 'hidden' : ''
                          }`}
                        >
                          <Icons icon="caretDown" className="h-full w-full" />
                        </div>
                      </button>
                      <div
                        className={`  ${
                          openIndex === index
                            ? 'transition-[max-height, opacity] relative h-auto max-h-[500px] opacity-100 delay-700 duration-500 ease-in-out'
                            : 'transition-[max-height, opacity] relative h-auto max-h-0 overflow-hidden opacity-0 duration-500 ease-in-out'
                        }`}
                      >
                        {accordion.description ? (
                          <p className="mb-4 text-base text-white-700">
                            {accordion.description}
                          </p>
                        ) : null}

                        <List items={accordion.listItems} />

                        <Insights insights={accordion.insights} />

                        <InlineImage
                          heading={accordion.inlineImageHeading}
                          image={accordion.inlineImage}
                          text={accordion.inlineImageText}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
              <div>
                {accordions.map((accordion, index) => {
                  if (!accordion.imageDesktop && !accordion.image) return null;
                  return (
                    <div
                      className={`${openIndex === index ? '' : 'hidden'} ${
                        accordion.imageTypeDesktop === 'center' ? 'pr-16' : ''
                      }`}
                    >
                      <picture>
                        {accordion.imageDesktop && accordion.image && (
                          <source
                            srcSet={`${accordion.imageDesktop.src} 2x`}
                            media="(min-width: 768px)"
                          />
                        )}
                        <img
                          className={`${
                            accordion.imageTypeDesktop === 'center'
                              ? ''
                              : 'w-full object-right'
                          }`}
                          src={
                            accordion.image?.src || accordion.imageDesktop?.src
                          }
                          alt={accordion.imageAlt}
                        />
                      </picture>
                    </div>
                  );
                })}
              </div>
            </div>
          </>
        ) : null}
      </div>
    </Section>
  );
}

function List({ items }) {
  if (!items?.length) return null;
  return (
    <ul className="my-8 flex flex-col gap-2 last-of-type:mb-0">
      {items.map((item, index) => {
        return (
          <li
            key={`list-item-${index}`}
            className="flex items-center gap-2 text-base font-medium"
          >
            <div className="flex h-4 w-4 shrink-0 justify-center text-brand">
              <Icons icon="checkmark" className="h-full w-full" />
            </div>
            {item.description}
          </li>
        );
      })}
    </ul>
  );
}

function Insights({ insights }) {
  if (!insights?.length) return null;
  return (
    <div className="my-8 flex gap-8 last-of-type:mb-0">
      {insights.map((insight, index) => {
        return (
          <div
            key={`insights-${index}`}
            className="flex w-full max-w-[115px] flex-col"
          >
            {insight.heading ? (
              <h4 className="mb-2 whitespace-nowrap text-3xl font-semibold">
                {insight.heading}
              </h4>
            ) : null}
            {insight.description ? (
              <p className="caption-1 mb-3 whitespace-pre-line text-white-700">
                {insight.description}
              </p>
            ) : null}
            {insight.barPercentage ? (
              <div className="progress-bar mt-auto">
                <span
                  className="progress-bar-inner"
                  style={{
                    width: `${insight.barPercentage}%`,
                  }}
                />
              </div>
            ) : null}
          </div>
        );
      })}
    </div>
  );
}

function InlineImage({ heading, image, text }) {
  if (!image) return null;
  return (
    <div>
      {heading ? (
        <h4 className="mb-4 text-lg leading-6 text-brand">{heading}</h4>
      ) : null}
      <div className="flex items-center">
        <img
          src={image.src}
          srcSet={`${image.src} 2x`}
          alt=""
          className="mr-2"
        />
        {text ? <span className="text-base font-medium">{text}</span> : null}
      </div>
    </div>
  );
}

FeatureAccordion.displayName = 'FeatureAccordion';
FeatureAccordion.Schema = Schema;
