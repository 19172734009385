import { IconSettings } from '../../settings/icons';

export function Schema() {
  return {
    label: 'Media',
    key: 'media',
    fields: [
      {
        label: 'Badge',
        name: 'badge',
        component: 'group',
        fields: [
          IconSettings,
          {
            label: 'Text',
            name: 'text',
            component: 'text',
          },
        ],
      },
      {
        label: 'Heading',
        name: 'heading',
        component: 'text',
        defaultValue: 'Media Heading',
      },
      {
        label: 'Desktop Image',
        name: 'imageDesktop',
        component: 'image',
      },
      {
        label: 'Mobile Image',
        name: 'imageMobile',
        component: 'image',
      },
      {
        label: 'Desktop Video URL',
        name: 'videoDesktop',
        component: 'text',
      },
      {
        label: 'Mobile Video URL',
        name: 'videoMobile',
        component: 'text',
      },
    ],
  };
}
