export function Schema() {
  return {
    label: 'Grid Steps',
    key: 'grid-steps',
    fields: [
      {
        label: 'Icon',
        name: 'icon',
        component: 'select',
        defaultValue: 'shop',
        options: [
          {
            label: 'None',
            value: '',
          },
          {
            label: 'Shop',
            value: 'shop',
          },
          {
            label: 'Storefront',
            value: 'storefront',
          },
        ],
      },
      {
        label: 'Heading',
        name: 'heading',
        component: 'text',
        defaultValue: "Here's how it works:",
      },
      {
        label: 'Contexts',
        name: 'contexts',
        component: 'group-list',
        itemProps: {
          label: '{{item.title}}',
        },
        description: 'Context switcher shows when there are 2 contexts',
        maxItems: 2,
        fields: [
          {
            label: 'Title',
            name: 'title',
            component: 'text',
          },
          {
            label: 'Steps',
            name: 'steps',
            component: 'group-list',
            itemProps: {
              label: '{{item.description}}',
            },
            fields: [
              {
                label: 'Description',
                name: 'description',
                component: 'text',
              },
              {
                label: 'Button',
                name: 'button',
                component: 'group',
                fields: [
                  {
                    label: 'Link',
                    name: 'link',
                    component: 'link',
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        label: 'Footer Heading',
        name: 'footerHeading',
        component: 'text',
      },
      {
        label: 'Footer Images',
        name: 'footerImages',
        component: 'group-list',
        itemProps: {
          label: '{{item.altText}}',
        },
        fields: [
          {
            label: 'Image',
            name: 'src',
            component: 'image',
            parse: ({ previewSrc }) => previewSrc,
          },
        ],
      },
      {
        label: 'Footer Button',
        name: 'footerButton',
        component: 'group',
        fields: [
          {
            label: 'Link',
            name: 'link',
            component: 'link',
          },
        ],
      },
    ],
  };
}
