import { useEffect, useState } from 'react';
import * as Accordion from '@radix-ui/react-accordion';
import { useRouter } from '@backpackjs/storefront';
import { Icons } from '../Icons';
import { MenuItems } from './menu-items';

import { Link } from '../Link';

export function MobileMenu({ promobarVisibleHeight, menu, ctas }) {
  const [menuOpen, setMenuOpen] = useState(false);
  const router = useRouter();

  const handleMenuOpen = () => {
    setMenuOpen((state) => !state);

    if (menuOpen) {
      document.body.classList.remove('scroll-lock');
    } else {
      document.body.classList.add('scroll-lock');
    }
  };

  useEffect(() => {
    setMenuOpen(false);
    document.body.classList.remove('scroll-lock');
  }, [router]);

  return (
    <div className="block lg:hidden">
      <button
        type="button"
        className={`box-content border-none bg-transparent p-2 ${
          menuOpen ? 'h-4 w-4' : 'h-7 w-7'
        }`}
        onClick={handleMenuOpen}
      >
        {menuOpen ? (
          <Icons icon="x" className="w-full" />
        ) : (
          <Icons icon="hamburger" className="w-full" />
        )}
      </button>

      {/* Mobile Slide Menu */}
      <div
        style={{
          top: `${promobarVisibleHeight + 72}px`,
        }}
        className={`fixed inset-0 overflow-scroll bg-black py-6 transition-transform ease-in-out no-scrollbar ${
          menuOpen ? 'translate-x-0' : 'translate-x-full'
        }
        `}
      >
        <div className="container flex h-full flex-col">
          <Accordion.Root type="single" collapsible className="space-y-2">
            {menu?.map((menuItem, index) => {
              const type = menuItem?._template;
              const title = menuItem?.title;
              if (type === 'link') {
                return (
                  <Accordion.Item key={`mobileMenu-${index}`} value={title}>
                    <Link
                      className="body-1-em inline-block py-3 text-xl font-semibold leading-[150%]"
                      href={menuItem?.link?.url || '#'}
                      newTab={menuItem?.link?.newTab}
                    >
                      {title}
                    </Link>
                  </Accordion.Item>
                );
              }

              if (type === 'menu') {
                const menuItems = menuItem?.megaMenuItems;

                return (
                  <Accordion.Item key={`mobileMenu-${index}`} value={title}>
                    <Accordion.AccordionTrigger className="AccordionTrigger">
                      <span className="py-3 font-aeonik text-xl font-medium leading-[120%] text-primary-light antialiased">
                        {title}
                      </span>
                      <Icons
                        icon="chevronDown"
                        className="AccordionChevron h-8 w-8 text-grey-300"
                      />
                    </Accordion.AccordionTrigger>

                    <Accordion.AccordionContent className="space-y-6 py-4">
                      <MenuItems menuItems={menuItems} isMobile />
                    </Accordion.AccordionContent>
                  </Accordion.Item>
                );
              }

              return null;
            })}
          </Accordion.Root>
          <div className="mt-auto flex flex-col items-center gap-8 py-6 px-4">
            <div className="flex items-center gap-1.5 rounded-full bg-grey-300 py-[3px] px-3 backdrop-blur-sm">
              <div className="-ml-2 flex h-[26px] w-[26px] items-center justify-center rounded-full bg-primary-dark p-1">
                <Icons icon="shopify" />
              </div>
              <p className="font-aeonik text-xs font-medium text-primary-dark antialiased">
                Built for Shopify, powered by Hydrogen
              </p>
            </div>

            <div className="flex flex-col gap-2 self-stretch">
              {ctas?.demo?.url ? (
                <Link
                  className="flex h-11 items-center justify-center gap-1.5 px-3 font-aeonik text-sm font-medium leading-[1.125rem] text-grey-400 antialiased"
                  href={ctas.demo.url}
                  newTab={ctas.demo?.newTab}
                >
                  <Icons
                    icon="video"
                    className="h-[18px] w-[18px] text-primary-green-500"
                  />
                  {ctas.demo.text}
                </Link>
              ) : null}

              {ctas?.login?.url ? (
                <Link
                  className="flex h-11 items-center justify-center gap-1.5 self-stretch rounded-[10px] bg-grey-900 px-3 font-aeonik text-sm font-medium leading-[1.125rem] text-white antialiased"
                  href={ctas.login.url}
                  newTab={ctas.login.newTab}
                >
                  {ctas.login.text}
                </Link>
              ) : null}

              {ctas?.getStarted?.url ? (
                <Link
                  className="flex h-11 items-center justify-center gap-1.5 self-stretch rounded-[10px] border border-grey-800 px-3 font-aeonik text-sm font-medium leading-[1.125rem] text-grey-400 antialiased"
                  href={ctas.getStarted.url}
                  newTab={ctas.getStarted.newTab}
                >
                  {ctas.getStarted.text}
                </Link>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
