export function Schema() {
  return {
    label: 'Markdown',
    key: 'markdown',
    fields: [
      {
        label: 'Content',
        name: 'content',
        component: 'markdown',
      },
      {
        label: 'Max Content Width',
        name: 'maxContentWidth',
        component: 'select',
        options: [
          { label: '384px', value: '384px' },
          { label: '768px', value: '768px' },
          { label: '100%', value: '100%' },
        ],
        defaultValue: '100%',
      },
    ],
  };
}
