export function LayersFilled(props) {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20.9132 6.39734L13.0879 2.27734C12.4066 1.916 11.5932 1.91734 10.9132 2.27734L3.08655 6.39734C2.42789 6.744 2.01855 7.42267 2.01855 8.16667C2.01855 8.91067 2.42789 9.58934 3.08655 9.936L10.9119 14.056C11.2519 14.236 11.6266 14.3267 11.9999 14.3267C12.3732 14.3267 12.7466 14.2373 13.0866 14.0573L20.9132 9.936C21.5719 9.58934 21.9812 8.91067 21.9812 8.16667C21.9812 7.42267 21.5719 6.744 20.9132 6.39734Z"
        fill="currentColor"
      />
      <path
        d="M20.9812 11.5C20.4292 11.5 19.9812 11.948 19.9812 12.5L12.1546 16.6213C12.0586 16.672 11.9439 16.672 11.8452 16.6213L4.01855 12.5C4.01855 11.948 3.57055 11.5 3.01855 11.5C2.46655 11.5 2.01855 11.948 2.01855 12.5C2.01855 13.2453 2.42789 13.9227 3.08655 14.2693L10.9119 18.3893C11.2519 18.5693 11.6266 18.66 11.9999 18.66C12.3732 18.66 12.7466 18.5707 13.0866 18.3907L20.9132 14.2693C21.5719 13.9227 21.9812 13.244 21.9812 12.5C21.9812 11.948 21.5332 11.5 20.9812 11.5Z"
        fill="currentColor"
      />
      <path
        d="M20.9812 15.8335C20.4292 15.8335 19.9812 16.2815 19.9812 16.8335L12.1546 20.9548C12.0586 21.0055 11.9439 21.0055 11.8452 20.9548L4.01855 16.8335C4.01855 16.2815 3.57055 15.8335 3.01855 15.8335C2.46655 15.8335 2.01855 16.2815 2.01855 16.8335C2.01855 17.5788 2.42789 18.2562 3.08655 18.6028L10.9119 22.7228C11.2519 22.9028 11.6266 22.9935 11.9999 22.9935C12.3732 22.9935 12.7466 22.9042 13.0866 22.7242L20.9132 18.6028C21.5719 18.2562 21.9812 17.5775 21.9812 16.8335C21.9812 16.2815 21.5332 15.8335 20.9812 15.8335Z"
        fill="currentColor"
      />
    </svg>
  );
}
