import React from 'react';
import { Icons } from '../../snippets/Icons';

import { Link } from '../../snippets/Link';

export function FeaturedTestimonial({ featuredTestimonial, quote }) {
  const { logo, logoAlt, image, imageAlt, link } = {
    ...featuredTestimonial,
  };

  return (
    <>
      <div className="relative mb-4 flex h-[190px] items-center justify-center overflow-hidden rounded-2xl">
        <img className="object-cover" src={image?.src} alt={imageAlt} />
        <div className="absolute inset-0 h-full w-full bg-[rgba(0,0,0,0.6)]" />
        {logo?.src && (
          <img className="absolute w-[70%]" src={logo?.src} alt={logoAlt} />
        )}
      </div>

      <blockquote className="mb-10">
        <p className="text-base font-light leading-[1.6] text-[#ffffffbf]">
          {quote}
        </p>
      </blockquote>

      {link?.url && link?.text && (
        <Link
          className="group flex items-center text-black"
          href={link.url}
          newTab={link?.newTab}
        >
          <span className="mr-2">{link.text}</span>
          <Icons icon="arrowRight" className="h-5 w-5" />
        </Link>
      )}
    </>
  );
}

FeaturedTestimonial.displayName = 'FeaturedTestimonial';
