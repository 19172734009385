import { IconSettings } from '../../settings/icons';
import { LottieSettings } from '../../settings/lotties';

export function Schema() {
  return {
    label: 'Feature Tabs',
    key: 'feature-tabs',
    fields: [
      {
        label: 'Icon',
        name: 'icon',
        component: 'select',
        defaultValue: '',
        options: [
          {
            label: 'None',
            value: '',
          },
          {
            label: 'Shop',
            value: 'shop',
          },
          {
            label: 'Storefront',
            value: 'storefront',
          },
        ],
      },
      {
        label: 'Alignment',
        name: 'alignment',
        component: 'select',
        defaultValue: 'center',
        options: [
          {
            label: 'Left',
            value: 'left',
          },
          {
            label: 'Center',
            value: 'center',
          },
        ],
      },
      {
        label: 'Superheading',
        name: 'superheading',
        component: 'text',
        defaultValue: 'Platform',
      },
      {
        label: 'Heading',
        name: 'heading',
        component: 'text',
        defaultValue: 'One place to build, publish and deploy.',
      },
      {
        label: 'CTA',
        name: 'cta',
        component: 'group',
        fields: [
          IconSettings,
          {
            label: 'Icon Position',
            name: 'iconPosition',
            component: 'select',
            options: [
              { label: 'Left', value: 'left' },
              { label: 'Right', value: 'right' },
            ],
          },
          {
            label: 'Link',
            name: 'link',
            component: 'link',
          },
        ],
        defaultValue: {
          iconPosition: 'right',
        },
      },
      {
        label: 'Tabs',
        name: 'tabs',
        component: 'group-list',
        itemProps: {
          label: '{{item.label}}',
        },
        fields: [
          {
            label: 'Label',
            name: 'label',
            component: 'text',
          },
          {
            label: 'Description',
            name: 'description',
            component: 'markdown',
            description: 'Bolding any text will be rendered with white text',
          },
          IconSettings,
          LottieSettings,
          {
            label: 'Timer',
            name: 'timer',
            component: 'number',
            description: 'Number in seconds for how long tab should be active.',
            defaultValue: '5',
          },
          {
            label: 'Desktop Image',
            name: 'image',
            component: 'image',
          },
          {
            label: 'Full width desktop image?',
            name: 'fullWidth',
            component: 'toggle',
            defaultValue: false,
          },
          {
            label: 'Mobile Image',
            name: 'imageMobile',
            component: 'image',
          },
        ],
        defaultValue: [
          {
            label: 'Visual Headless CMS',
            icon: 'elementsPlus',
            timer: '5',
            fullWidth: false,
          },
          {
            label: 'Developer Tools',
            icon: 'terminal',
            timer: '5',
            fullWidth: false,
          },
          {
            label: 'CRO Tools',
            icon: 'dashboard',
            timer: '5',
            fullWidth: true,
          },
        ],
      },
      {
        label: 'Footer Heading',
        name: 'footerHeading',
        component: 'text',
        defaultValue: 'Integrates with your entire stack',
      },
      {
        label: 'Footer Link',
        name: 'footerLink',
        component: 'link',
        defaultValue: {
          text: 'See all integrations',
          url: '/',
        },
      },
      {
        label: 'Footer Background Image',
        name: 'footerBg',
        component: 'image',
      },
    ],
  };
}
