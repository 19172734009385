import { IconSettings } from '../../settings/icons';

export function Schema() {
  return {
    label: 'Feature Image Insights',
    key: 'feature-image-insights',
    fields: [
      {
        label: 'Heading',
        name: 'heading',
        component: 'text',
        defaultValue: 'Feature Image Insights Heading',
      },
      {
        label: 'Subheading',
        name: 'subheading',
        component: 'textarea',
        defaultValue: 'Feature image Insights subheading.',
      },
      {
        label: 'Image (Mobile)',
        name: 'imageMobile',
        component: 'image',
      },
      {
        label: 'Image (Desktop)',
        name: 'imageDesktop',
        component: 'image',
      },
      {
        label: 'Image Alt Text',
        name: 'imageAlt',
        component: 'text',
      },
      {
        label: 'Email Signup',
        name: 'emailSignup',
        component: 'group',
        fields: [
          {
            label: 'Hubspot ID',
            name: 'formId',
            component: 'text',
          },
          {
            label: 'Enable Email Form',
            name: 'enableEmailForm',
            component: 'toggle',
            defaultValue: false,
          },
          {
            label: 'Hubspot Button Label',
            name: 'buttonLabel',
            component: 'text',
            defaultValue: 'Get Started',
          },
          {
            label: 'Form Submission Redirect',
            name: 'formRedirect',
            component: 'text',
            description: 'URL to page. Leave blank to not redirect.',
          },
        ],
      },
      {
        label: 'Buttons',
        name: 'buttons',
        component: 'group-list',
        fields: [
          {
            label: 'Type',
            name: 'type',
            component: 'select',
            options: [
              { label: 'Primary', value: 'btn-primary' },
              { label: 'Outline', value: 'btn-outline' },
            ],
          },
          {
            label: 'Link',
            name: 'link',
            component: 'link',
          },
          IconSettings,
        ],
        itemProps: {
          label: '{{item.link.text}}',
        },
        validate: {
          maxItems: 2,
        },
        defaultItem: {
          type: 'btn-primary',
          link: { text: 'Get Started', url: '' },
          icon: '',
        },
        defaultValue: [
          {
            type: 'btn-primary',
            link: { text: 'Get Started', url: '' },
            icon: '',
          },
          {
            type: 'btn-outline',
            link: { text: 'Book a demo', url: '' },
            icon: 'clock',
          },
        ],
      },
      {
        label: 'Insights',
        name: 'insights',
        component: 'group-list',
        fields: [
          {
            label: 'Heading',
            name: 'heading',
            component: 'text',
          },
          {
            label: 'Description',
            name: 'description',
            component: 'textarea',
          },
          {
            label: 'Bar Percentage',
            name: 'barPercentage',
            component: 'number',
          },
        ],
        itemProps: {
          label: '{{item.heading}}',
        },
        validate: {
          maxItems: 4,
        },
        defaultItem: {
          heading: '1x',
          description: 'Insight 1',
          barPercentage: 25,
        },
        defaultValue: [
          {
            heading: '1x',
            description: 'Insight 1',
            barPercentage: 25,
          },
          {
            heading: '2x',
            description: 'Insight 2',
            barPercentage: 50,
          },
          {
            heading: '3x',
            description: 'Insight 3',
            barPercentage: 75,
          },
          {
            heading: '4x',
            description: 'Insight 4',
            barPercentage: 100,
          },
        ],
      },
    ],
  };
}
