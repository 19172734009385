export function Book(props) {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.4451 2.25H11.5H13.5H13.5549C14.9225 2.24998 16.0248 2.24996 16.8918 2.36652C17.7919 2.48754 18.5497 2.74643 19.1517 3.34835C19.7536 3.95027 20.0125 4.70814 20.1335 5.60825C20.25 6.47521 20.25 7.57753 20.25 8.94512V8.94512V8.94513V9V16V16.5V17L20.25 17.052V17.052C20.25 17.9505 20.2501 18.6997 20.1701 19.2945C20.0857 19.9223 19.9 20.4891 19.4445 20.9445C18.9891 21.4 18.4223 21.5857 17.7945 21.6701C17.1997 21.7501 16.4505 21.75 15.552 21.75H15.5H8C6.20508 21.75 4.75 20.2949 4.75 18.5V9V8.94513C4.74998 7.57754 4.74996 6.47522 4.86652 5.60825C4.98754 4.70814 5.24643 3.95027 5.84835 3.34835C6.45027 2.74643 7.20814 2.48754 8.10825 2.36652C8.97522 2.24996 10.0775 2.24998 11.4451 2.25ZM6.25 18.5C6.25 19.4665 7.0335 20.25 8 20.25H15.5C16.464 20.25 17.1116 20.2484 17.5946 20.1835C18.0561 20.1214 18.2536 20.0142 18.3839 19.8839C18.5142 19.7536 18.6214 19.5561 18.6835 19.0946C18.7484 18.6116 18.75 17.964 18.75 17V16.75H8C7.0335 16.75 6.25 17.5335 6.25 18.5ZM6.25 15.7609V9C6.25 7.56458 6.25159 6.56347 6.35315 5.80812C6.4518 5.07435 6.63225 4.68577 6.90901 4.40901C7.18577 4.13225 7.57435 3.9518 8.30812 3.85315C9.06347 3.75159 10.0646 3.75 11.5 3.75H13.5C14.9354 3.75 15.9365 3.75159 16.6919 3.85315C17.4257 3.9518 17.8142 4.13225 18.091 4.40901C18.3678 4.68577 18.5482 5.07435 18.6469 5.80812C18.7484 6.56347 18.75 7.56459 18.75 9V15.25H8C7.35568 15.25 6.75515 15.4375 6.25 15.7609ZM8.75 7C8.75 6.58579 9.08579 6.25 9.5 6.25H15.5C15.9142 6.25 16.25 6.58579 16.25 7C16.25 7.41421 15.9142 7.75 15.5 7.75H9.5C9.08579 7.75 8.75 7.41421 8.75 7Z"
        fill="currentColor"
      />
    </svg>
  );
}
