export function Route(props) {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.3333 3.75C17.6429 3.75 17.0833 4.30964 17.0833 5C17.0833 5.69036 17.6429 6.25 18.3333 6.25C19.0236 6.25 19.5833 5.69036 19.5833 5C19.5833 4.30964 19.0236 3.75 18.3333 3.75ZM15.5833 5C15.5833 3.48122 16.8145 2.25 18.3333 2.25C19.852 2.25 21.0833 3.48122 21.0833 5C21.0833 6.51878 19.852 7.75 18.3333 7.75C16.8145 7.75 15.5833 6.51878 15.5833 5ZM6.33325 17.75C5.6429 17.75 5.08325 18.3096 5.08325 19C5.08325 19.6904 5.6429 20.25 6.33325 20.25C7.02361 20.25 7.58325 19.6904 7.58325 19C7.58325 18.3096 7.02361 17.75 6.33325 17.75ZM8.97974 18.25C8.65327 17.0957 7.59203 16.25 6.33325 16.25C4.81447 16.25 3.58325 17.4812 3.58325 19C3.58325 20.5188 4.81447 21.75 6.33325 21.75C7.59203 21.75 8.65327 20.9043 8.97974 19.75H16.8333C19.1805 19.75 21.0833 17.8472 21.0833 15.5C21.0833 13.1528 19.1805 11.25 16.8333 11.25H7.83325C6.31447 11.25 5.08325 10.0188 5.08325 8.5C5.08325 6.98122 6.31447 5.75 7.83325 5.75H10.5226L9.80292 6.46967C9.51003 6.76256 9.51003 7.23744 9.80292 7.53033C10.0958 7.82322 10.5707 7.82322 10.8636 7.53033L12.8636 5.53033C13.1565 5.23744 13.1565 4.76256 12.8636 4.46967L10.8636 2.46967C10.5707 2.17678 10.0958 2.17678 9.80292 2.46967C9.51003 2.76256 9.51003 3.23744 9.80292 3.53033L10.5226 4.25H7.83325C5.48604 4.25 3.58325 6.15279 3.58325 8.5C3.58325 10.8472 5.48604 12.75 7.83325 12.75H16.8333C18.352 12.75 19.5833 13.9812 19.5833 15.5C19.5833 17.0188 18.352 18.25 16.8333 18.25H8.97974Z"
        fill="currentColor"
      />
    </svg>
  );
}
