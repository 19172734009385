export function Union(props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.25 6C2.25 3.92893 3.92893 2.25 6 2.25C7.81422 2.25 9.32753 3.53832 9.67499 5.25H12C12.4142 5.25 12.75 5.58579 12.75 6C12.75 6.41421 12.4142 6.75 12 6.75H9.67499C9.32753 8.46168 7.81422 9.75 6 9.75C3.92893 9.75 2.25 8.07107 2.25 6ZM6 3.75C7.24264 3.75 8.25 4.75736 8.25 6C8.25 7.24264 7.24264 8.25 6 8.25C4.75736 8.25 3.75 7.24264 3.75 6C3.75 4.75736 4.75736 3.75 6 3.75ZM6.75 12C6.75 11.5858 6.41421 11.25 6 11.25C5.58579 11.25 5.25 11.5858 5.25 12L5.25 14.325C3.53832 14.6725 2.25 16.1858 2.25 18C2.25 20.0711 3.92893 21.75 6 21.75C8.07107 21.75 9.75 20.0711 9.75 18C9.75 16.1858 8.46168 14.6725 6.75 14.325V12ZM18.75 9.67499C20.4617 9.32753 21.75 7.81422 21.75 6C21.75 3.92893 20.0711 2.25 18 2.25C15.9289 2.25 14.25 3.92893 14.25 6C14.25 7.81422 15.5383 9.32753 17.25 9.67499V12C17.25 12.4142 17.5858 12.75 18 12.75C18.4142 12.75 18.75 12.4142 18.75 12V9.67499ZM18 8.25C19.2426 8.25 20.25 7.24264 20.25 6C20.25 4.75736 19.2426 3.75 18 3.75C16.7574 3.75 15.75 4.75736 15.75 6C15.75 7.24264 16.7574 8.25 18 8.25ZM12 17.25C11.5858 17.25 11.25 17.5858 11.25 18C11.25 18.4142 11.5858 18.75 12 18.75H14.325C14.6725 20.4617 16.1858 21.75 18 21.75C20.0711 21.75 21.75 20.0711 21.75 18C21.75 15.9289 20.0711 14.25 18 14.25C16.1858 14.25 14.6725 15.5383 14.325 17.25H12ZM15.75 18C15.75 19.2426 16.7574 20.25 18 20.25C19.2426 20.25 20.25 19.2426 20.25 18C20.25 16.7574 19.2426 15.75 18 15.75C16.7574 15.75 15.75 16.7574 15.75 18ZM6 20.25C7.24264 20.25 8.25 19.2426 8.25 18C8.25 16.7574 7.24264 15.75 6 15.75C4.75736 15.75 3.75 16.7574 3.75 18C3.75 19.2426 4.75736 20.25 6 20.25Z"
        fill="currentColor"
      />
    </svg>
  );
}
