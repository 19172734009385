import { IconSettings } from '../../settings/icons';

export function Schema() {
  return {
    label: 'Highlighted Slides',
    key: 'highlighted-slides',
    fields: [
      {
        label: 'Icon',
        name: 'icon',
        component: 'select',
        defaultValue: 'shop',
        options: [
          {
            label: 'None',
            value: '',
          },
          {
            label: 'Shop',
            value: 'shop',
          },
          {
            label: 'Storefront',
            value: 'storefront',
          },
        ],
      },
      {
        label: 'Heading',
        name: 'heading',
        component: 'text',
        defaultValue: 'Meet Pack Shops',
      },
      {
        label: 'Description',
        name: 'description',
        component: 'textarea',
        defaultValue:
          'Your innovation playground for meeting shoppers where they are.',
      },
      {
        label: 'Button',
        name: 'button',
        component: 'group',
        fields: [
          {
            label: 'Link',
            name: 'link',
            component: 'link',
          },
          IconSettings,
          {
            label: 'Icon Position',
            name: 'iconPosition',
            component: 'select',
            defaultValue: 'left',
            options: [
              { label: 'Left', value: 'left' },
              { label: 'Right', value: 'right' },
            ],
          },
        ],
      },
      {
        label: 'Slides',
        name: 'slides',
        component: 'group-list',
        itemProps: {
          label: '{{item.heading}}',
        },
        fields: [
          {
            label: 'Heading',
            name: 'heading',
            component: 'text',
          },
          {
            label: 'Image',
            name: 'image',
            component: 'image',
          },
          {
            label: 'Video URL',
            name: 'video',
            component: 'text',
          },
        ],
      },
    ],
  };
}
