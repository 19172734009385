export function Share(props) {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.325 6.25L9 6.25C7.43146 6.25 6.12244 7.36118 5.8172 8.8393C7.49529 9.21226 8.75 10.7096 8.75 12.5C8.75 14.2904 7.49529 15.7877 5.8172 16.1607C6.12245 17.6388 7.43145 18.75 9 18.75L14.325 18.75C14.6725 17.0383 16.1858 15.75 18 15.75C20.0711 15.75 21.75 17.4289 21.75 19.5C21.75 21.5711 20.0711 23.25 18 23.25C16.1858 23.25 14.6725 21.9617 14.325 20.25L9 20.25C6.60912 20.25 4.63081 18.4836 4.29896 16.1846C2.56295 15.8563 1.25 14.3315 1.25 12.5C1.25 10.6685 2.56295 9.1437 4.29896 8.81541C4.63081 6.51643 6.60912 4.75 9 4.75L14.325 4.75C14.6725 3.03832 16.1858 1.75 18 1.75C20.0711 1.75 21.75 3.42893 21.75 5.5C21.75 7.57107 20.0711 9.25 18 9.25C16.1858 9.25 14.6725 7.96168 14.325 6.25ZM5 10.25C3.75736 10.25 2.75 11.2574 2.75 12.5C2.75 13.7426 3.75736 14.75 5 14.75C6.24264 14.75 7.25 13.7426 7.25 12.5C7.25 11.2574 6.24264 10.25 5 10.25ZM20.25 5.5C20.25 6.74264 19.2426 7.75 18 7.75C16.7574 7.75 15.75 6.74264 15.75 5.5C15.75 4.25736 16.7574 3.25 18 3.25C19.2426 3.25 20.25 4.25736 20.25 5.5ZM15.75 19.5018L15.75 19.5C15.75 18.2574 16.7574 17.25 18 17.25C19.2426 17.25 20.25 18.2574 20.25 19.5C20.25 20.7426 19.2426 21.75 18 21.75C16.758 21.75 15.751 20.7436 15.75 19.5018Z"
        fill="currentColor"
      />
    </svg>
  );
}
