export function Send(props) {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.2498 15.7502L24.4998 3.5002M12.3986 16.1329L15.4647 24.0173C15.7349 24.7118 15.8699 25.0591 16.0645 25.1605C16.2332 25.2484 16.4342 25.2485 16.603 25.1608C16.7977 25.0597 16.9332 24.7126 17.2041 24.0183L24.8928 4.31593C25.1374 3.68922 25.2597 3.37587 25.1928 3.17563C25.1347 3.00174 24.9982 2.86528 24.8243 2.80719C24.6241 2.7403 24.3107 2.86258 23.684 3.10715L3.98167 10.7959C3.28741 11.0668 2.94029 11.2023 2.83913 11.397C2.75143 11.5658 2.75155 11.7668 2.83944 11.9355C2.94083 12.1301 3.28812 12.2651 3.98269 12.5352L11.867 15.6014C12.008 15.6562 12.0785 15.6836 12.1379 15.7259C12.1905 15.7635 12.2365 15.8095 12.274 15.8621C12.3164 15.9215 12.3438 15.992 12.3986 16.1329Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
