export function Grid(props) {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.66658 2.24999H7.61458H7.61456C6.71609 2.24996 5.96688 2.24993 5.3721 2.3299C4.74431 2.4143 4.17751 2.59997 3.72204 3.05544C3.26657 3.51091 3.0809 4.07771 2.99649 4.70551C2.91653 5.30029 2.91655 6.04951 2.91658 6.94799L2.91658 6.99999V8.98896L2.9165 8.99999L2.91658 9.01101V17L2.91658 17.052C2.91655 17.9505 2.91653 18.6997 2.99649 19.2945C3.0809 19.9223 3.26657 20.4891 3.72204 20.9445C4.17751 21.4 4.74431 21.5857 5.3721 21.6701C5.96689 21.75 6.71611 21.75 7.61459 21.75H7.66658L12.6666 21.75L17.6666 21.75H17.7186C18.6171 21.75 19.3663 21.75 19.9611 21.6701C20.5889 21.5857 21.1557 21.4 21.6111 20.9445C22.0666 20.4891 22.2523 19.9223 22.3367 19.2945C22.4166 18.6997 22.4166 17.9505 22.4166 17.052V17V6.99999V6.94799C22.4166 6.04951 22.4166 5.30029 22.3367 4.70551C22.2523 4.07771 22.0666 3.51091 21.6111 3.05544C21.1557 2.59997 20.5889 2.4143 19.9611 2.3299C19.3663 2.24993 18.6171 2.24996 17.7186 2.24999H17.7186H17.6666H12.6666H7.66658ZM4.41658 17V9.74999H11.9166V14.9889C11.9165 14.9926 11.9165 14.9963 11.9165 15C11.9165 15.0037 11.9165 15.0074 11.9166 15.011V20.25H7.66658C6.70257 20.25 6.05501 20.2484 5.57198 20.1835C5.11051 20.1214 4.91302 20.0142 4.7827 19.8839C4.65238 19.7536 4.54516 19.5561 4.48312 19.0946C4.41818 18.6116 4.41658 17.964 4.41658 17ZM13.4166 15.75V20.25H17.6666C18.6306 20.25 19.2782 20.2484 19.7612 20.1835C20.2227 20.1214 20.4202 20.0142 20.5505 19.8839C20.6808 19.7536 20.788 19.5561 20.85 19.0946C20.915 18.6116 20.9166 17.964 20.9166 17V15.75H13.4166ZM20.9166 14.25V6.99999C20.9166 6.03598 20.915 5.38842 20.85 4.90538C20.788 4.44392 20.6808 4.24642 20.5505 4.1161C20.4202 3.98579 20.2227 3.87856 19.7612 3.81652C19.2782 3.75158 18.6306 3.74999 17.6666 3.74999H13.4166L13.4166 14.25H20.9166ZM4.41658 8.24999H11.9166V3.74999H7.66658C6.70257 3.74999 6.05501 3.75158 5.57198 3.81652C5.11051 3.87856 4.91302 3.98579 4.7827 4.1161C4.65238 4.24642 4.54516 4.44392 4.48312 4.90538C4.41818 5.38842 4.41658 6.03598 4.41658 6.99999V8.24999Z"
        fill="currentColor"
      />
    </svg>
  );
}
