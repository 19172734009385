import { Schema } from './Markdown.schema';
import { Markdown as MarkdownSnippet } from '../../snippets/markdown';

import { Section } from '../../snippets';

export function Markdown({ cms }) {
  const { content, maxContentWidth } = cms;
  if (!content) return null;
  return (
    <Section cms={cms}>
      <div className="container">
        <MarkdownSnippet style={{ maxWidth: maxContentWidth }}>
          {content}
        </MarkdownSnippet>
      </div>
    </Section>
  );
}

Markdown.displayName = 'Markdown';
Markdown.Schema = Schema;
