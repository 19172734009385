import { Children, cloneElement } from 'react';
import { useInView } from 'react-intersection-observer';

export function FadeIn({
  children,
  desktopDirection = 'up',
  mobileDirection = 'up',
  delayAnimationSeconds = 0,
  rootMargin = '0px',
  triggerOnce = true,
  threshold = 0.15,
  ...props
}) {
  const { ref, inView } = useInView({
    rootMargin: rootMargin || 0,
    triggerOnce,
    threshold: threshold || 0,
  });

  const mobileTranslateClass = () => {
    if (mobileDirection === 'left') {
      return `${inView ? 'translate-x-0' : 'translate-x-5'}`;
    }
    if (mobileDirection === 'right') {
      return `${inView ? 'translate-x-5' : 'translate-x-0'}`;
    }
    if (mobileDirection === 'bottom') {
      return `${inView ? 'translate-y-5' : 'translate-y-0'}`;
    }
    return `${inView ? 'translate-y-0' : 'translate-y-5'}`;
  };

  const desktopTranslateClass = () => {
    if (desktopDirection === 'left') {
      return `${inView ? 'md:translate-x-0' : 'md:translate-x-5'}`;
    }
    if (desktopDirection === 'right') {
      return `${inView ? 'md:translate-x-5' : 'md:translate-x-0'}`;
    }
    if (desktopDirection === 'bottom') {
      return `${inView ? 'md:translate-y-5' : 'md:translate-y-0'}`;
    }
    return `${inView ? 'md:translate-y-0' : 'md:translate-y-5'}`;
  };

  const delayAnimationClass = (seconds) => {
    switch (seconds) {
      case 0:
        return 'delay-[0ms]';
      case 25:
        return 'delay-[25ms]';
      case 50:
        return 'delay-[50ms]';
      case 75:
        return 'delay-[75ms]';
      case 100:
        return 'delay-[100ms]';
      case 125:
        return 'delay-[125ms]';
      case 150:
        return 'delay-[150ms]';
      case 175:
        return 'delay-[175ms]';
      case 200:
        return 'delay-[200ms]';
      case 225:
        return 'delay-[225ms]';
      case 250:
        return 'delay-[250ms]';
      case 275:
        return 'delay-[275ms]';
      case 300:
        return 'delay-[300ms]';
      case 325:
        return 'delay-[325ms]';
      case 350:
        return 'delay-[350ms]';
      case 375:
        return 'delay-[375ms]';
      case 400:
        return 'delay-[400ms]';
      default:
        return seconds;
    }
  };

  const modifiedClassName = `transition-all duration-300 ${
    inView ? `opacity-100` : 'opacity-0'
  } ${desktopTranslateClass()} ${mobileTranslateClass()} ${delayAnimationClass(
    delayAnimationSeconds
  )}`;

  const modifiedChildren = Children.map(children, (child) => {
    return cloneElement(child, {
      className: `${modifiedClassName} ${child?.props?.className || ''}`,
      ref,
      ...props,
    });
  });

  return modifiedChildren;
}

FadeIn.displayName = 'FadeIn';
