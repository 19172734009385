import { IconSettings } from '../../settings/icons';

export function Schema() {
  return {
    label: 'Hover Cards',
    key: 'hover-cards',
    fields: [
      {
        label: 'Heading',
        name: 'heading',
        component: 'text',
        defaultValue: 'Here’s what fast-moving brands are up against:',
      },
      {
        label: 'Cards',
        name: 'cards',
        component: 'group-list',
        fields: [
          IconSettings,
          {
            label: 'Card color',
            name: 'cardColor',
            component: 'color',
          },
          {
            label: 'Heading',
            name: 'heading',
            component: 'text',
          },
          {
            label: 'Description',
            name: 'description',
            component: 'textarea',
          },
          {
            label: 'Profile name',
            name: 'profileName',
            component: 'text',
          },
          {
            label: 'Profile description',
            name: 'profileDescription',
            component: 'text',
          },
          {
            label: 'Profile image',
            name: 'profileImage',
            component: 'image',
          },
        ],
        itemProps: {
          label: '{{item.heading}}',
        },
      },
      {
        label: 'Grid Columns',
        name: 'gridColumns',
        component: 'select',
        options: [
          {
            label: '1',
            value: '1',
          },
          {
            label: '2',
            value: '2',
          },
          {
            label: '3',
            value: '3',
          },
          {
            label: '4',
            value: '4',
          },
        ],
        defaultValue: '4',
        description: 'Applies to desktop only. Mobile will be 1 column',
      },
    ],
  };
}
