import { Schema } from './Quote.schema';

import { Section } from '../../snippets';

export function Quote({ cms }) {
  const { quote, author, title, image } = cms;

  if (!quote) return null;

  return (
    <Section cms={cms}>
      <blockquote className="my-12 flex flex-col gap-8 border-l-2 border-l-brand pl-5">
        <p className="text-2xl font-medium italic">{quote}</p>
        <footer className="flex items-center gap-4">
          {image ? (
            <div className="h-12 w-12 rounded-full shadow-[0px_0px_0px_1px_rgba(0,0,0,0.08)_inset]">
              <img src={image.src} alt={author} />
            </div>
          ) : null}
          <div>
            {author ? (
              <cite className="text-lg font-semibold">{author}</cite>
            ) : null}
            {title ? <p className="text-base">{title}</p> : null}
          </div>
        </footer>
      </blockquote>
    </Section>
  );
}

Quote.displayName = 'Quote';
Quote.Schema = Schema;
