export function Link(props) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.47106 12.2428L7.52825 13.1856C6.2265 14.4873 4.11595 14.4873 2.8142 13.1856C1.51245 11.8838 1.51245 9.77329 2.8142 8.47154L3.75701 7.52874M12.2423 8.47154L13.1851 7.52874C14.4868 6.22699 14.4868 4.11644 13.1851 2.81469C11.8834 1.51294 9.7728 1.51294 8.47106 2.81469L7.52825 3.7575M5.66632 10.3335L10.333 5.66679"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
