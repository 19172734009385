import { useEffect, useState } from 'react';
import { Logo } from '../../icons/logo';
import { Button } from '../../snippets/button';
import { Icons } from '../../snippets/Icons';
import { Schema } from './HighlightedSlides.schema';

const DEFAULT_SECONDS = 5;

export function HighlightedSlides({ cms }) {
  const [activeIndex, setActiveIndex] = useState(0);
  const { icon, heading, description, button, slides } = cms || {};

  useEffect(() => {
    if (!slides || slides.length === 0) return () => null;
    const seconds = DEFAULT_SECONDS;
    const timer = setTimeout(() => {
      setActiveIndex(activeIndex === slides.length - 1 ? 0 : activeIndex + 1);
    }, seconds * 1000);
    return () => {
      clearTimeout(timer);
    };
  }, [activeIndex]);

  return (
    <div className="bg-primary-dark">
      <div className="mx-auto flex max-w-8xl flex-col gap-10 py-[60px] px-5 lg:gap-[60px] lg:py-[120px] lg:px-[100px]">
        <div className="flex items-start justify-between">
          <div className="flex max-w-[600px] flex-col items-start gap-5 lg:gap-[25px] lg:px-10">
            {icon ? (
              <div className="flex items-center gap-1 rounded-lg bg-primary-light pr-1 lg:rounded-xl">
                <Logo
                  className="h-10 w-10 lg:h-12 lg:w-12"
                  style={{ color: '#030303' }}
                />
                <div
                  className={`flex h-8 w-8 items-center justify-center rounded-lg  lg:h-10 lg:w-10 lg:rounded-[10px] ${
                    icon === 'storefront'
                      ? 'bg-primary-green-500'
                      : 'bg-primary-blue'
                  }`}
                >
                  <Icons
                    icon={icon}
                    className="h-[30px] self-end lg:h-[38px]"
                  />
                </div>
              </div>
            ) : null}
            {heading ? (
              <h2 className="font-aeonik text-[2.875rem] font-normal leading-none text-primary-light antialiased lg:text-[4rem] lg:leading-[4.125rem]">
                {heading}
              </h2>
            ) : null}
            {description ? (
              <p className="font-aeonik text-xl leading-6 text-grey-300 antialiased lg:text-2xl lg:leading-[1.875rem]">
                {description}
              </p>
            ) : null}
          </div>
          {button?.link?.url ? (
            <div className="hidden px-10 lg:block">
              <Button
                link={button.link}
                icon={button.icon}
                iconPosition={button.iconPosition}
              />
            </div>
          ) : null}
        </div>
        {slides && slides.length > 0 && (
          <div className="flex flex-col items-center gap-[30px]">
            <div className="grid w-full grid-cols-1 gap-[30px] lg:flex">
              {slides.map((slide, i) => (
                <button
                  key={`highlighted-slide-${i}`}
                  className={`pointer-events-none col-start-1 row-start-1 flex w-full flex-col items-center justify-between gap-[15px] rounded-3xl bg-grey-900 p-[30px] transition-opacity lg:pointer-events-auto ${
                    activeIndex !== i ? 'opacity-0 lg:opacity-[0.35]' : ''
                  }`}
                  type="button"
                  onClick={() => setActiveIndex(i)}
                >
                  {slide.heading ? (
                    <h3 className="text-center font-aeonik text-2xl font-normal leading-7 text-primary-light antialiased lg:whitespace-pre-line">
                      {slide.heading.replace('\\n', '\n')}
                    </h3>
                  ) : null}
                  {slide.image?.src || slide.video ? (
                    <div className="mx-auto lg:px-10">
                      <div className="h-[565px] w-[260px] overflow-hidden rounded-[25px] border border-grey-300 bg-primary-blue">
                        {slide.video || slide.image?.mediaType === 'VIDEO' ? (
                          <video
                            className="h-full w-full object-cover"
                            autoPlay
                            muted
                            playsInline
                            loop
                          >
                            <source
                              src={slide.video || slide.image?.src}
                              type={slide.image?.format || 'video/mp4'}
                            />
                          </video>
                        ) : (
                          <img
                            className="h-full w-full object-cover"
                            src={slide.image.src}
                            alt={slide.image.altText}
                          />
                        )}
                      </div>
                    </div>
                  ) : null}
                </button>
              ))}
            </div>
            <nav className="flex justify-center">
              <div className="inline-flex justify-center gap-2 rounded-full bg-grey-800 p-2">
                {slides.map((slide, index) => {
                  const isActive = index === activeIndex;
                  return (
                    <button
                      key={`highlighted-slide-nav-${index}`}
                      type="button"
                      className="flex w-11 flex-col gap-5"
                      onClick={() => setActiveIndex(index)}
                    >
                      <div className="progress-bar-light progress-bar-slim mt-auto">
                        {isActive ? (
                          <span
                            className="progress-bar-inner progress-bar-slim progress-bar-animation"
                            style={{
                              animationDuration: `${DEFAULT_SECONDS}s`,
                              animationIterationcount: 'infinite',
                            }}
                          />
                        ) : null}
                      </div>
                    </button>
                  );
                })}
              </div>
            </nav>
          </div>
        )}
        {button?.link?.url ? (
          <div className="lg:hidden">
            <Button
              link={button.link}
              icon={button.icon}
              iconPosition={button.iconPosition}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
}

HighlightedSlides.displayName = 'HighlightedSlides';
HighlightedSlides.Schema = Schema;
