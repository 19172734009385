import { useRef, useEffect, useState } from 'react';

import { Icons } from './Icons';

export function ToolTip({ text, description }) {
  const toolTipRef = useRef();
  const toolTipIconRef = useRef();

  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    function repositionTooltip(event) {
      const offsetRight = toolTipRef?.current?.getBoundingClientRect()?.right;
      const width = toolTipRef?.current?.getBoundingClientRect()?.width;
      const windowWidth = window.innerWidth;
      const distance = windowWidth - offsetRight;

      if (event) {
        setIsHovered(true);
      }

      if (distance < 0) {
        toolTipRef.current.style.right = `${distance < -20 ? -40 : distance}px`;
      } else if (distance > width / 2) {
        toolTipRef.current.style.right = `-${width / 2}px`;
      }
    }

    repositionTooltip();

    toolTipIconRef?.current?.addEventListener('mouseenter', repositionTooltip);
    return () => {
      toolTipIconRef?.current?.removeEventListener(
        'mouseenter',
        repositionTooltip
      );
    };
  }, []);

  return (
    <div className="relative">
      <div
        className={`cursor-pointer ${
          isHovered ? 'icon-feature-tooltip-active' : 'icon-feature-tooltip'
        }`}
        onMouseLeave={() => {
          setIsHovered(false);
        }}
        ref={toolTipIconRef}
      >
        <Icons icon="info" className="text-[#2B2E33]" />
      </div>
      <div
        className={`${
          isHovered ? 'feature-tooltip-active' : 'feature-tooltip'
        }`}
        ref={toolTipRef}
        onMouseEnter={() => {
          setIsHovered(true);
        }}
        onMouseLeave={() => {
          setIsHovered(false);
        }}
      >
        <p className="mb-2 text-xs font-medium">{text}</p>
        <p className="text-xs font-normal">{description}</p>
      </div>
    </div>
  );
}

ToolTip.displayName = 'ToolTip';
