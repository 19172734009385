import { useSettings } from '@backpackjs/storefront';
import { Schema } from './LogoMarquee.schema';

import { MarqueeComponent } from '../../snippets/marquee';

import { Section } from '../../snippets';

function splitArrayIntoChunks(array) {
  const chunkSize = 8;
  const result = [];

  for (let i = 0; i < array.length; i += chunkSize) {
    result.push(array.slice(i, i + chunkSize));
  }

  return result;
}

export function LogoMarquee({ cms }) {
  const { heading, partners = [], bgColor, logoColor } = cms;
  const settings = useSettings();

  const logoArray = settings?.logos?.logoMarquee;

  const splitArrays = logoArray ? splitArrayIntoChunks(logoArray) : [];

  if (settings?.logos?.logoMarquee?.length > 0) {
    return (
      <Section cms={cms}>
        <div
          className="py-[60px] md:py-20"
          style={{
            backgroundColor: bgColor || 'var(--black)',
          }}
        >
          {(heading || partners?.length > 0) && (
            <div className="mb-7 flex flex-col items-center justify-center gap-5 md:mb-12 md:flex-row md:gap-9">
              {heading && (
                <h3 className="sub-heading px-12 text-center text-[rgba(255,255,255,0.5)] max-md:order-2 md:px-0">
                  {heading}
                </h3>
              )}
              {partners?.length > 0 && (
                <ul className="flex">
                  {partners.map(({ name, image }, index) => {
                    if (!image?.src) return null;
                    return (
                      <li className="-mx-1" key={index}>
                        <img
                          className="h-10 w-10 rounded-full border-2 border-[#9AE4D9]"
                          src={image?.src}
                          alt={name}
                        />
                      </li>
                    );
                  })}
                </ul>
              )}
            </div>
          )}

          {/* for mobile */}
          <div className="flex flex-col gap-10 md:hidden">
            <MarqueeComponent
              logoMarquee={logoArray}
              imageClasses={`mx-8 h-7 ${logoColor || 'invert-[50%]'}`}
            />
          </div>

          <div className="md:px-contained flex flex-col gap-10 max-md:hidden">
            {splitArrays?.map((arr, arrIndex) => {
              return (
                <ul
                  className="mx-auto flex max-w-7xl items-center justify-center gap-x-20"
                  key={arrIndex}
                >
                  {arr?.slice(0, 8).map((logo, index) => {
                    return (
                      <li className="max-w-[150px]" key={index}>
                        <img
                          className={`w-full ${logoColor || 'invert-[50%]'}`}
                          src={logo?.imageLogo?.src}
                          alt={logo?.imageAlt}
                        />
                      </li>
                    );
                  })}
                </ul>
              );
            })}
          </div>
        </div>
      </Section>
    );
  }

  return null;
}

LogoMarquee.displayName = 'LogoMarquee';
LogoMarquee.Schema = Schema;
