import { IconSettings } from '../../settings/icons';
import { LottieSettings } from '../../settings/lotties';

export function Schema() {
  return {
    label: 'Feature Steps',
    key: 'feature-steps',
    fields: [
      {
        label: 'Heading',
        name: 'heading',
        component: 'text',
        description:
          'Wrap emphasized text in pipes (|), e.g. "An |emphasized| example"',
        defaultValue:
          'Getting started with Pack is extremely easy and intuitive.',
      },
      {
        label: 'Description',
        name: 'description',
        component: 'text',
        defaultValue:
          'Meet the only Digital Experience Platform (DXP) that gives growing brands every tool they need to build.',
      },
      {
        label: 'Watch Video Image',
        name: 'watchVideoImage',
        component: 'image',
      },
      {
        label: 'Watch Video Heading',
        name: 'watchVideoText',
        component: 'text',
        defaultValue: 'Watch the video',
      },
      {
        label: 'Watch Video Description',
        name: 'watchVideoDescription',
        component: 'text',
        defaultValue: 'See how it works',
      },
      {
        label: 'Watch Video Link',
        name: 'watchVideoLink',
        component: 'link',
      },
      LottieSettings,
      {
        label: 'Main Image',
        name: 'image',
        component: 'image',
      },
      {
        label: 'Main Image Alt',
        name: 'imageAlt',
        component: 'text',
        defaultValue: 'Pack x Hydrogen + Integrations',
      },
      {
        label: 'CTA',
        name: 'cta',
        component: 'group',
        fields: [
          IconSettings,
          {
            label: 'Icon Position',
            name: 'iconPosition',
            component: 'select',
            options: [
              { label: 'Left', value: 'left' },
              { label: 'Right', value: 'right' },
            ],
          },
          {
            label: 'Link',
            name: 'link',
            component: 'link',
          },
        ],
        defaultValue: {
          iconPosition: 'right',
        },
      },
      {
        label: 'Hide Steps Indicator?',
        name: 'hideStepsIndicator',
        component: 'toggle',
        defaultValue: false,
      },
      {
        label: 'Steps',
        name: 'steps',
        component: 'group-list',
        itemProps: {
          label: '{{item.stepName}}',
        },
        fields: [
          {
            label: 'Step Name',
            name: 'stepName',
            component: 'text',
          },
          {
            label: 'Heading',
            name: 'heading',
            component: 'text',
          },
          {
            label: 'Description',
            name: 'description',
            component: 'textarea',
          },
          LottieSettings,
          {
            label: 'Image Alt',
            name: 'imageAlt',
            component: 'text',
          },
          {
            label: 'Image',
            name: 'image',
            component: 'image',
          },
          {
            label: 'List Items',
            name: 'listItems',
            component: 'group-list',
            itemProps: {
              label: '{{item.description}}',
            },
            fields: [
              IconSettings,
              {
                label: 'Description',
                name: 'description',
                component: 'textarea',
              },
            ],
            defaultItem: {
              description: 'List Item',
            },
          },
          {
            label: 'Insights',
            name: 'insights',
            component: 'group-list',
            fields: [
              {
                label: 'Heading',
                name: 'heading',
                component: 'text',
              },
              {
                label: 'Description',
                name: 'description',
                component: 'textarea',
              },
            ],
            itemProps: {
              label: '{{item.heading}}',
            },
            validate: {
              maxItems: 3,
            },
            defaultItem: {
              heading: '1x',
              description: 'Insight 1',
            },
          },
        ],
        defaultItem: {
          stepName: 'Step One',
        },
        defaultValue: [
          {
            stepName: 'Step One',
            heading: 'Connect to Shopify',
            description: 'Syncing with Shopify is a snap.',
            imageAlt: 'Syncing Shopify',
            image: {
              src: 'https://cdn.shopify.com/s/files/1/0830/5750/8663/files/feature-step-one.png?v=1717717440',
            },
            listItems: [
              {
                icon: 'key',
                description: 'Grant Pack access to your Shopify store',
              },
              {
                icon: 'refresh',
                description: 'Grant Pack access to your Shopify store',
              },
              {
                icon: 'share',
                description: 'Connect your Hydrogen storefront',
              },
            ],
          },
          {
            stepName: 'Step Two',
            heading: 'Build your storefront',
            description: 'Stop waiting on developers.',
            imageAlt: 'Building storefront',
            image: {
              src: 'https://cdn.shopify.com/s/files/1/0830/5750/8663/files/feature-step-two.png?v=1717717446',
            },
            listItems: [
              {
                icon: 'elementPlus',
                description:
                  'Build pages in minutes with intuitive drag and drop',
              },
              {
                icon: 'stack',
                description: 'Enrich your PDPs with copy-paste integrations',
              },
              {
                icon: 'monitor',
                description: 'Edit, live-preview, schedule, and publish',
              },
              {
                icon: 'userCheck',
                description: 'No developer required',
              },
              {
                icon: 'atom',
                description: 'Connect with endless 3rd party integrations',
              },
            ],
          },
          {
            stepName: 'Step Three',
            heading: 'Move faster as a team',
            description:
              'While there are a lot of factors that impact conversion rate, site speed tends to be one of them. Tap into your next wave of growth with Pack.',
            imageAlt: 'Collaborate and move faster as a team',
            image: {
              src: 'https://cdn.shopify.com/s/files/1/0830/5750/8663/files/feature-step-three.png?v=1717717443',
            },
            insights: [
              {
                heading: '2-3x',
                description: 'Your site speed',
              },
              {
                heading: '+4x',
                description: 'Fewer solutions to manage',
              },
              {
                heading: '5-26%',
                description: 'Higher conversion rate',
              },
            ],
          },
        ],
      },
    ],
  };
}
