export function Slides(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="45"
      height="44"
      viewBox="0 0 45 44"
      fill="none"
      {...props}
    >
      <path
        d="M35.5851 17.1116H9.9184C7.55585 17.1116 5.64062 19.0268 5.64062 21.3893V34.8338C5.64062 37.1963 7.55585 39.1116 9.9184 39.1116H35.5851C37.9476 39.1116 39.8628 37.1963 39.8628 34.8338V21.3893C39.8628 19.0268 37.9476 17.1116 35.5851 17.1116Z"
        fill="currentColor"
      />
      <path
        d="M14.806 6.11149H30.6949C31.7069 6.11149 32.5282 5.29016 32.5282 4.27816C32.5282 3.26616 31.7069 2.44482 30.6949 2.44482H14.806C13.794 2.44482 12.9727 3.26616 12.9727 4.27816C12.9727 5.29016 13.794 6.11149 14.806 6.11149Z"
        fill="currentColor"
      />
      <path
        d="M11.1419 13.4447H34.3642C35.3762 13.4447 36.1975 12.6234 36.1975 11.6114C36.1975 10.5994 35.3762 9.77808 34.3642 9.77808H11.1419C10.1299 9.77808 9.30859 10.5994 9.30859 11.6114C9.30859 12.6234 10.1299 13.4447 11.1419 13.4447Z"
        fill="currentColor"
      />
    </svg>
  );
}
