import { useState, useEffect, useRef } from 'react';
import { useSettings } from '@backpackjs/storefront';

export function usePromobar() {
  const settings = useSettings();

  const promoBarRef = useRef();
  const [promobarVisibleHeight, setPromobarVisibleHeight] = useState(48);
  const [promobarHidden, setPromobarHidden] = useState(null);

  const promobar = settings?.header?.promobar;
  const promobarDisabled =
    !!promobar && (!promobar.enabled || !promobar.messages?.length);

  useEffect(() => {
    const handleResize = () => {
      const height = promoBarRef?.current?.getBoundingClientRect()?.height;
      const yPos = promoBarRef?.current?.getBoundingClientRect()?.y;

      if (height) {
        setPromobarVisibleHeight(height + yPos <= 0 ? 0 : height + yPos);
      }
    };

    handleResize();

    ['resize', 'scroll'].forEach((event) => {
      window.addEventListener(event, handleResize);
    });

    return () => {
      ['resize', 'scroll'].forEach((event) => {
        window.removeEventListener(event, handleResize);
      });
    };
  }, []);

  return {
    promobarAutoHide: promobar?.autohide,
    promobarDisabled,
    promobarHidden,
    promobarVisibleHeight:
      promobarDisabled || promobarHidden ? 0 : promobarVisibleHeight,
    promoBarRef,
  };
}
