export function Info(props) {
  return (
    <svg
      width="14"
      height="16"
      viewBox="0 0 14 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="SVG">
        <path
          id="Vector"
          d="M7 1.875C5.37555 1.875 3.81763 2.52031 2.66897 3.66897C1.52031 4.81763 0.875 6.37555 0.875 8C0.875 9.62445 1.52031 11.1824 2.66897 12.331C3.81763 13.4797 5.37555 14.125 7 14.125C8.62445 14.125 10.1824 13.4797 11.331 12.331C12.4797 11.1824 13.125 9.62445 13.125 8C13.125 6.37555 12.4797 4.81763 11.331 3.66897C10.1824 2.52031 8.62445 1.875 7 1.875ZM7 4.5C7.17405 4.5 7.34097 4.56914 7.46404 4.69221C7.58711 4.81528 7.65625 4.9822 7.65625 5.15625C7.65625 5.3303 7.58711 5.49722 7.46404 5.62029C7.34097 5.74336 7.17405 5.8125 7 5.8125C6.82595 5.8125 6.65903 5.74336 6.53596 5.62029C6.41289 5.49722 6.34375 5.3303 6.34375 5.15625C6.34375 4.9822 6.41289 4.81528 6.53596 4.69221C6.65903 4.56914 6.82595 4.5 7 4.5ZM8.75 11.5551H5.25V10.5699H6.50825V8.05512H5.6875V7.06987H7.49262V10.5699H8.75V11.5551Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}
