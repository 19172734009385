import React from 'react';

export function Section({ cms, children, ...props }) {
  return (
    <div
      data-comp={Section.displayName}
      data-comp-id={cms?.clientId || cms?.cmsId}
      {...props}
    >
      {children}
    </div>
  );
}

Section.displayName = 'Section';
