export function Hamburger(props) {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="menu$">
        <g id="Icon">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.33325 7.00016C2.33325 6.35583 2.85559 5.8335 3.49992 5.8335H24.4999C25.1442 5.8335 25.6666 6.35583 25.6666 7.00016C25.6666 7.64449 25.1442 8.16683 24.4999 8.16683H3.49992C2.85559 8.16683 2.33325 7.64449 2.33325 7.00016Z"
            fill="currentColor"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.33325 14.0002C2.33325 13.3558 2.85559 12.8335 3.49992 12.8335H24.4999C25.1443 12.8335 25.6666 13.3558 25.6666 14.0002C25.6666 14.6445 25.1443 15.1668 24.4999 15.1668H3.49992C2.85559 15.1668 2.33325 14.6445 2.33325 14.0002Z"
            fill="currentColor"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.33325 21.0002C2.33325 20.3558 2.85559 19.8335 3.49992 19.8335H24.4999C25.1443 19.8335 25.6666 20.3558 25.6666 21.0002C25.6666 21.6445 25.1443 22.1668 24.4999 22.1668H3.49992C2.85559 22.1668 2.33325 21.6445 2.33325 21.0002Z"
            fill="currentColor"
          />
        </g>
      </g>
    </svg>
  );
}
