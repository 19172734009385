import { Icons } from '../../snippets/Icons';
import { Schema } from './ValueProps.schema';

import { Section } from '../../snippets';

export function ValueProps({ cms }) {
  const { heading, valueProps = [] } = cms;
  let desktopGridClass = 'lg:grid-cols-4';
  if (valueProps.length % 3 === 0) {
    desktopGridClass = 'lg:grid-cols-3';
  }
  return (
    <Section cms={cms}>
      <div className="relative mx-auto max-w-8xl px-5 py-[60px] lg:px-[100px] lg:py-[120px]">
        {heading && (
          <h2 className="mx-auto mb-10 max-w-2xl text-center text-4xl tracking-tight antialiased selection:bg-brand lg:mb-[60px] lg:text-[3.5rem] lg:leading-[3.75rem]">
            {heading}
          </h2>
        )}
        <ul
          className={`sm:grid sm:grid-cols-2 sm:gap-8 lg:gap-x-5 lg:gap-y-[25px] ${desktopGridClass}`}
        >
          {valueProps.map(
            (
              {
                heading: valuePropHeading,
                isBeta,
                isComingSoon,
                description,
                icon,
                iconColor,
                iconColorHover,
              },
              index
            ) => {
              return (
                <li
                  className="value-prop mx-auto flex w-full flex-col items-start gap-4 p-[25px] hover:cursor-pointer"
                  key={index}
                  style={{
                    '--value-prop-icon-color': iconColor || '#b3b3b3',
                    '--value-prop-icon-color-hover':
                      iconColorHover || '#2970ff',
                  }}
                >
                  <Icons
                    icon={icon}
                    className="value-prop-icon h-8 w-8 transition-colors"
                  />
                  <div className="flex gap-3">
                    <p className="flex-1 font-aeonik text-xl font-medium text-primary-light antialiased">
                      {valuePropHeading}
                    </p>
                    {(isComingSoon || isBeta) && (
                      <div className="relative -top-[2px]">
                        <span className="black-02-gradient rounded-2xl border border-[rgba(255,255,255,0.1)] px-3 py-1 text-xs text-[rgba(255,255,255,0.8)]">
                          {isComingSoon ? 'Coming Soon' : 'Beta'}
                        </span>
                      </div>
                    )}
                  </div>
                  <p className="font-aeonik text-sm text-grey-300 antialiased">
                    {description}
                  </p>
                </li>
              );
            }
          )}
        </ul>

        <div className="value-props-gradient pointer-events-none" />
      </div>
    </Section>
  );
}

ValueProps.displayName = 'ValueProps';
ValueProps.Schema = Schema;
