export function Dashboard(props) {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.9933 6.72071C4.95086 7.97122 4.27144 9.53493 4.11688 11.25H5.33325C5.74747 11.25 6.08325 11.5858 6.08325 12C6.08325 12.4142 5.74747 12.75 5.33325 12.75H4.11688C4.23454 14.0556 4.65639 15.2736 5.31101 16.3324C6.40768 15.0505 7.99823 14.2934 9.68626 14.2518C9.94485 13.3351 10.6668 12.6127 11.5833 12.3535V9C11.5833 8.58579 11.919 8.25 12.3333 8.25C12.7475 8.25 13.0833 8.58579 13.0833 9V12.3535C13.9997 12.6127 14.7217 13.3351 14.9802 14.2518C16.6683 14.2934 18.2588 15.0505 19.3555 16.3324C20.0101 15.2736 20.432 14.0556 20.5496 12.75H19.3333C18.919 12.75 18.5833 12.4142 18.5833 12C18.5833 11.5858 18.919 11.25 19.3333 11.25H20.5496C20.3951 9.53501 19.7157 7.97135 18.6733 6.72087L17.8635 7.53043C17.5705 7.82327 17.0957 7.82318 16.8028 7.53023C16.51 7.23729 16.5101 6.76241 16.803 6.46957L17.6127 5.66018C16.3622 4.61767 14.7984 3.93819 13.0833 3.78363V5C13.0833 5.41421 12.7475 5.75 12.3333 5.75C11.919 5.75 11.5833 5.41421 11.5833 5V3.78363C9.86819 3.93819 8.30447 4.61761 7.05396 5.66005L7.86358 6.46967C8.15648 6.76256 8.15648 7.23744 7.86358 7.53033C7.57069 7.82322 7.09582 7.82322 6.80292 7.53033L5.9933 6.72071ZM12.3333 13.75C11.6429 13.75 11.0833 14.3096 11.0833 15C11.0833 15.6904 11.6429 16.25 12.3333 16.25C13.0236 16.25 13.5833 15.6904 13.5833 15C13.5833 14.3096 13.0236 13.75 12.3333 13.75ZM6.35717 17.4207C7.17152 16.4027 8.38938 15.7949 9.68744 15.7524C10.0147 16.9054 11.0753 17.75 12.3333 17.75C13.5912 17.75 14.6518 16.9054 14.9791 15.7524C16.2771 15.7949 17.495 16.4027 18.3093 17.4207L18.4243 17.5644C16.9158 19.2148 14.7454 20.25 12.3333 20.25C9.9211 20.25 7.75074 19.2148 6.2422 17.5644L6.35717 17.4207ZM22.0833 12C22.0833 6.61522 17.718 2.25 12.3333 2.25C6.94848 2.25 2.58325 6.61522 2.58325 12C2.58325 17.3848 6.94848 21.75 12.3333 21.75C17.718 21.75 22.0833 17.3848 22.0833 12Z"
        fill="currentColor"
      />
    </svg>
  );
}
