export function Target(props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.0002 22.6668C6.11883 22.6668 1.3335 17.8815 1.3335 12.0002C1.3335 6.11883 6.11883 1.3335 12.0002 1.3335C17.8815 1.3335 22.6668 6.11883 22.6668 12.0002C22.6668 17.8815 17.8815 22.6668 12.0002 22.6668ZM12.0002 3.3335C7.2215 3.3335 3.3335 7.2215 3.3335 12.0002C3.3335 16.7788 7.2215 20.6668 12.0002 20.6668C16.7788 20.6668 20.6668 16.7788 20.6668 12.0002C20.6668 7.2215 16.7788 3.3335 12.0002 3.3335Z"
        fill="currentColor"
      />
      <path
        d="M12.0002 18.6668C8.32416 18.6668 5.3335 15.6762 5.3335 12.0002C5.3335 8.32416 8.32416 5.3335 12.0002 5.3335C15.6762 5.3335 18.6668 8.32416 18.6668 12.0002C18.6668 15.6762 15.6762 18.6668 12.0002 18.6668ZM12.0002 7.3335C9.42683 7.3335 7.3335 9.42683 7.3335 12.0002C7.3335 14.5735 9.42683 16.6668 12.0002 16.6668C14.5735 16.6668 16.6668 14.5735 16.6668 12.0002C16.6668 9.42683 14.5735 7.3335 12.0002 7.3335Z"
        fill="currentColor"
      />
      <path
        d="M12.0002 14.6668C13.4729 14.6668 14.6668 13.4729 14.6668 12.0002C14.6668 10.5274 13.4729 9.3335 12.0002 9.3335C10.5274 9.3335 9.3335 10.5274 9.3335 12.0002C9.3335 13.4729 10.5274 14.6668 12.0002 14.6668Z"
        fill="currentColor"
      />
    </svg>
  );
}
