export function UserStar(props) {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="Frame" clipPath="url(#clip0_2410_69355)">
        <g id="Group">
          <path
            id="Vector"
            d="M10.4999 12.7778C8.24772 12.7778 6.24661 14.2367 5.52661 16.3289C6.89661 17.4067 8.62106 18.0555 10.4999 18.0555C12.3788 18.0555 14.1022 17.4078 15.4722 16.33C14.7422 14.2289 12.7466 12.7778 10.4999 12.7778Z"
            fill="currentColor"
          />
          <path
            id="Vector_2"
            d="M10.5 18.8889C5.59886 18.8889 1.61108 14.9011 1.61108 9.99997C1.61108 5.09886 5.59886 1.11108 10.5 1.11108C11.1122 1.11108 11.7289 1.17553 12.3355 1.30331C12.7855 1.39775 13.0744 1.83997 12.98 2.28997C12.8844 2.73997 12.4355 3.03108 11.9922 2.93442C11.4989 2.83108 10.9966 2.77775 10.4989 2.77775C6.51664 2.77775 3.27664 6.01775 3.27664 9.99997C3.27664 13.9822 6.51664 17.2222 10.4989 17.2222C14.4811 17.2222 17.7211 13.9822 17.7211 9.99997C17.7211 9.48886 17.6633 8.96775 17.55 8.44997C17.4511 8.00108 17.7355 7.55553 18.1844 7.45664C18.6311 7.35553 19.0777 7.6422 19.1777 8.09108C19.3177 8.72553 19.3878 9.36775 19.3878 9.99886C19.3878 14.9 15.4 18.8878 10.4989 18.8878L10.5 18.8889Z"
            fill="currentColor"
          />
          <path
            id="Vector_3"
            d="M20.0433 2.65334L18.3599 2.09112L17.7988 0.407784C17.6166 -0.13666 16.7155 -0.13666 16.5333 0.407784L15.9721 2.09112L14.2888 2.65334C14.0166 2.74334 13.8333 2.99889 13.8333 3.28556C13.8333 3.57223 14.0166 3.82778 14.2888 3.91778L15.9721 4.48001L16.5333 6.16334C16.6244 6.43556 16.8799 6.61889 17.1666 6.61889C17.4533 6.61889 17.7077 6.43556 17.7999 6.16334L18.361 4.48001L20.0444 3.91778C20.3166 3.82778 20.4999 3.57223 20.4999 3.28556C20.4999 2.99889 20.3155 2.74445 20.0433 2.65334Z"
            fill="currentColor"
          />
          <path
            id="Vector_4"
            d="M10.4999 11.6667C12.1874 11.6667 13.5554 10.2986 13.5554 8.6111C13.5554 6.92356 12.1874 5.55554 10.4999 5.55554C8.81235 5.55554 7.44434 6.92356 7.44434 8.6111C7.44434 10.2986 8.81235 11.6667 10.4999 11.6667Z"
            fill="currentColor"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_2410_69355">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
