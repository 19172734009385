export function GridFill(props) {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="Frame">
        <g id="Group">
          <path
            id="Vector"
            d="M15.2222 2.22223H5.77772C4.09217 2.22223 2.72217 3.59223 2.72217 5.27778V14.7222C2.72217 16.4078 4.09217 17.7778 5.77772 17.7778H15.2222C16.9077 17.7778 18.2777 16.4078 18.2777 14.7222V5.27778C18.2777 3.59223 16.9077 2.22223 15.2222 2.22223ZM9.94439 13.6111C9.94439 14.0711 9.57106 14.4445 9.11106 14.4445H6.88883C6.42883 14.4445 6.0555 14.0711 6.0555 13.6111V11.3889C6.0555 10.9289 6.42883 10.5556 6.88883 10.5556H9.11106C9.57106 10.5556 9.94439 10.9289 9.94439 11.3889V13.6111ZM9.94439 8.61112C9.94439 9.07112 9.57106 9.44445 9.11106 9.44445H6.88883C6.42883 9.44445 6.0555 9.07112 6.0555 8.61112V6.3889C6.0555 5.9289 6.42883 5.55556 6.88883 5.55556H9.11106C9.57106 5.55556 9.94439 5.9289 9.94439 6.3889V8.61112ZM14.9444 13.6111C14.9444 14.0711 14.5711 14.4445 14.1111 14.4445H11.8888C11.4288 14.4445 11.0555 14.0711 11.0555 13.6111V6.3889C11.0555 5.9289 11.4288 5.55556 11.8888 5.55556H14.1111C14.5711 5.55556 14.9444 5.9289 14.9444 6.3889V13.6111Z"
            fill="currentColor"
          />
        </g>
      </g>
    </svg>
  );
}
