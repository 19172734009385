export function Script(props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.7276 4.1819C14.8281 3.78005 14.5837 3.37285 14.1819 3.27239C13.7801 3.17193 13.3729 3.41625 13.2724 3.81809L9.27239 19.8181C9.17193 20.2199 9.41625 20.6271 9.8181 20.7276C10.2199 20.8281 10.6271 20.5837 10.7276 20.1819L14.7276 4.1819ZM6.53033 7.46968C6.82322 7.76258 6.82322 8.23745 6.53033 8.53034L3.06066 12L6.53033 15.4697C6.82322 15.7626 6.82322 16.2375 6.53033 16.5303C6.23744 16.8232 5.76256 16.8232 5.46967 16.5303L1.46967 12.5303C1.17678 12.2375 1.17678 11.7626 1.46967 11.4697L5.46967 7.46968C5.76256 7.17679 6.23744 7.17679 6.53033 7.46968ZM17.4697 7.46968C17.7626 7.17679 18.2374 7.17679 18.5303 7.46968L22.5303 11.4697C22.8232 11.7626 22.8232 12.2375 22.5303 12.5303L18.5303 16.5303C18.2374 16.8232 17.7626 16.8232 17.4697 16.5303C17.1768 16.2375 17.1768 15.7626 17.4697 15.4697L20.9393 12L17.4697 8.53034C17.1768 8.23745 17.1768 7.76258 17.4697 7.46968Z"
        fill="currentColor"
      />
    </svg>
  );
}
