export function Stopwatch(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      {...props}
    >
      <path
        d="M23.8351 7.43643V4.88932H27.5017C28.5137 4.88932 29.3351 4.06799 29.3351 3.05599C29.3351 2.04399 28.5137 1.22266 27.5017 1.22266H16.5017C15.4897 1.22266 14.6684 2.04399 14.6684 3.05599C14.6684 4.06799 15.4897 4.88932 16.5017 4.88932H20.1684V7.43643C11.5933 8.35555 4.89062 15.6302 4.89062 24.4449C4.89062 33.8804 12.5662 41.556 22.0017 41.556C31.4373 41.556 39.1128 33.8804 39.1128 24.4449C39.1128 15.6302 32.4102 8.3531 23.8351 7.43643ZM23.2973 25.7429C22.9404 26.0998 22.4711 26.2807 22.0017 26.2807C21.5324 26.2807 21.0631 26.1022 20.7062 25.7429L15.0888 20.1255C14.3726 19.4093 14.3726 18.2482 15.0888 17.532C15.8051 16.8158 16.9662 16.8158 17.6824 17.532L23.2997 23.1493C24.016 23.8655 24.016 25.0267 23.2997 25.7429H23.2973Z"
        fill="currentColor"
      />
      <path
        d="M39.7216 13.4448C39.2523 13.4448 38.7829 13.2664 38.4261 12.9071L33.5372 8.01817C32.8209 7.30195 32.8209 6.14084 33.5372 5.42462C34.2534 4.7084 35.4145 4.7084 36.1307 5.42462L41.0196 10.3135C41.7358 11.0297 41.7358 12.1908 41.0196 12.9071C40.6627 13.264 40.1909 13.4448 39.7216 13.4448Z"
        fill="currentColor"
      />
    </svg>
  );
}
