/* eslint-disable import/extensions */
import Lottie from 'react-lottie-player';

import visuallyHeadlessJson from '../lotties/01_VisualHeadless_V3.json';
import developerToolsJson from '../lotties/02_DeveloperTools_V2.json';
import croToolsJson from '../lotties/03_CROTools_V5.json';
import buildYourStorefrontJson from '../lotties/Build_Your_Storefront.json';
import moveFasterAsATeamJson from '../lotties/Move_Faster_As_A_Team.json';
import packHydrogenTreeJson from '../lotties/Pack_Hydrogen_Tree.json';
import testAndOptimizeJson from '../lotties/Test-Optimize.json';
import syncingShopifyJson from '../lotties/Syncing-Shopify.json';
import shareAssetsAndLearningJson from '../lotties/Share-Assets-Learnings.json';
import hydrogenCustomizerJson from '../lotties/Hydrogen-Customizer-Cond.json';
import buildYourStorefrontV2Json from '../lotties/Build-your-storefront-v2.json';
import bustYourBottleneckJson from '../lotties/Bust-your-bottleneck.json';

export function Lotties({ lottie, style, ...rest }) {
  // some lotties need to have negative margins to reduce whitespace.
  // for some reason those lottie files are not preserving aspect ratio
  // https://github.com/mifi/react-lottie-player/issues/55 doesn't work

  switch (lottie) {
    case 'visuallyHeadless':
      return (
        <Lottie
          animationData={visuallyHeadlessJson}
          id="lottie-visually-headless"
          style={{
            ...style,
            marginTop: '-8.5%',
            aspectRatio: '2/1',
          }}
          {...rest}
        />
      );
    case 'developerTools':
      return (
        <Lottie
          animationData={developerToolsJson}
          id="lottie-developer-tools"
          style={{
            ...style,
            marginTop: '-8.5%',
            aspectRatio: '2/1',
          }}
          {...rest}
        />
      );
    case 'croTools':
      return (
        <Lottie
          animationData={croToolsJson}
          id="lottie-cro-tools"
          style={{
            ...style,
            marginTop: '-8.5%',
            aspectRatio: '2/1',
          }}
          {...rest}
        />
      );
    case 'buildYourStorefront':
      return (
        <Lottie
          animationData={buildYourStorefrontJson}
          id="lottie-build-your-storefront"
          style={{
            ...style,
            // marginTop: '-8.5%',
            // aspectRatio: '2/1',
          }}
          {...rest}
        />
      );
    case 'moveFasterAsATeam':
      return (
        <Lottie
          animationData={moveFasterAsATeamJson}
          id="lottie-move-faster-as-a-team"
          style={{
            ...style,
            // marginTop: '-8.5%',
            // aspectRatio: '2/1',
          }}
          {...rest}
        />
      );
    case 'packHydrogenTree':
      return (
        <Lottie
          animationData={packHydrogenTreeJson}
          id="lottie-pack-hydrogen-tree"
          style={{
            ...style,
            // marginTop: '-8.5%',
            // aspectRatio: '2/1',
          }}
          {...rest}
        />
      );
    case 'testAndOptimize':
      return (
        <Lottie
          animationData={testAndOptimizeJson}
          id="lottie-test-and-optimize"
          style={{
            ...style,
            // marginTop: '-8.5%',
            // aspectRatio: '2/1',
          }}
          {...rest}
        />
      );
    case 'syncingShopify':
      return (
        <Lottie
          animationData={syncingShopifyJson}
          id="lottie-syncing-shopify"
          style={{
            ...style,
            // marginTop: '-8.5%',
            // aspectRatio: '2/1',
          }}
          {...rest}
        />
      );
    case 'shareAssetsAndLearning':
      return (
        <Lottie
          animationData={shareAssetsAndLearningJson}
          id="lottie-share-assets-and-learning"
          style={{
            ...style,
            // marginTop: '-8.5%',
            // aspectRatio: '2/1',
          }}
          {...rest}
        />
      );
    case 'hydrogenCustomizer':
      return (
        <Lottie
          animationData={hydrogenCustomizerJson}
          id="lottie-hydrogen-customizer"
          style={{
            ...style,
            marginBottom: '-8.5%',
            aspectRatio: '2/1',
          }}
          {...rest}
        />
      );
    case 'buildYourStorefrontV2':
      return (
        <Lottie
          animationData={buildYourStorefrontV2Json}
          id="lottie-build-your-storefront-v2"
          style={{
            ...style,
            // marginTop: '-8.5%',
            // aspectRatio: '2/1',
          }}
          {...rest}
        />
      );
    case 'bustYourBottleneck':
      return (
        <Lottie
          animationData={bustYourBottleneckJson}
          id="lottie-bust-your-bottleneck"
          style={{
            ...style,
            // marginTop: '-8.5%',
            // aspectRatio: '2/1',
          }}
          {...rest}
        />
      );
    default:
      return null;
  }
}
