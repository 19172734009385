import { useState } from 'react';
import { useRouter } from '@backpackjs/storefront';
import { Icons } from '../../snippets/Icons';
import { Schema } from './FeatureImageInsights.schema';

import { Link } from '../../snippets/Link';
import { Section } from '../../snippets';

export function FeatureImageInsights({ cms }) {
  const {
    heading,
    subheading,
    imageAlt,
    imageMobile,
    imageDesktop,
    buttons,
    insights,
    emailSignup,
  } = cms;

  const {
    enableEmailForm,
    formId,
    formRedirect,
    buttonLabel: formButtonLabel,
  } = { ...emailSignup };

  const router = useRouter();

  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  function handleChange(e) {
    setEmail(e.target.value);
  }

  async function handleSubmit(e) {
    e.preventDefault();
    if (loading || !email) return;
    setLoading(true);
    try {
      const resp = await fetch('/api/subscribe', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email,
          formId,
        }),
      });
      const respJson = await resp.json();
      if (!respJson.success) throw new Error('Could not subscribe');
      setSuccess(true);
      if (formRedirect) {
        try {
          const redirectUrl = new URL(formRedirect);
          window.location = redirectUrl.href;
        } catch (err) {
          router.push(formRedirect);
        }
      }
      setTimeout(() => {
        setSuccess(false);
      }, 3000);
    } catch (err) {
      setSuccess(false);
    } finally {
      setLoading(false);
    }
  }

  return (
    <Section cms={cms}>
      <div className="northern-lights-gradient px-4 pt-16 md:pt-[100px]">
        <div className="mx-auto flex max-w-7xl flex-col">
          <div className="mb-8 px-4">
            {heading && (
              <h2 className="h1 text-center selection:bg-brand">{heading}</h2>
            )}
            {subheading && (
              <p className="sub-heading mt-3 whitespace-pre-line text-center text-[rgba(255,255,255,0.6)] selection:bg-brand selection:text-white md:mt-5 md:whitespace-normal">
                {subheading}
              </p>
            )}
          </div>

          {insights.length > 0 && (
            <div className="mx-auto mb-9 grid grid-cols-[repeat(2,_minmax(0,_120px))] gap-x-8 gap-y-5 md:order-2 md:mx-0 md:mb-[60px] md:flex md:justify-center md:gap-[50px]">
              {insights.map((insight, index) => {
                return (
                  <div
                    key={`featureImageInsight-${index}`}
                    className="flex w-full max-w-[145px] flex-col"
                  >
                    <h2 className="mb-3 whitespace-nowrap">
                      {insight.heading}
                    </h2>
                    <p className="mb-3 whitespace-pre-line text-base text-[rgba(255,255,255,0.6)]">
                      {insight.description}
                    </p>
                    {/* <div className="progress-bar mt-auto h-[15px]">
                  <span
                    className="progress-bar-inner h-[15px]"
                    style={{
                      width: `${insight.barPercentage}%`,
                    }}
                  />
                </div> */}
                  </div>
                );
              })}
            </div>
          )}

          {/* Email newsletter sign up */}
          {enableEmailForm && formId && (
            <form
              className="mx-auto mb-9 flex w-full flex-col items-center justify-center gap-3 px-4 transition-all md:mb-[60px] md:flex-row md:px-0"
              onSubmit={handleSubmit}
            >
              <div className="input-group">
                <input
                  type="email"
                  className="input-email md:!pr-[135px]"
                  placeholder="Enter your email"
                  value={email}
                  onChange={handleChange}
                  required
                />
                <button
                  type="submit"
                  className="btn-primary"
                  disabled={loading}
                >
                  {/* eslint-disable-next-line no-nested-ternary */}
                  {loading ? 'Loading...' : success ? 'Sent!' : formButtonLabel}
                </button>
              </div>
            </form>
          )}

          {buttons.length > 0 && (
            <div className="mb-9 flex flex-col-reverse gap-3 px-4 md:order-1 md:mt-0 md:mb-[60px] md:flex-row md:justify-center md:px-0">
              {buttons.map((button, index) => {
                return (
                  <Link
                    className={button.type}
                    key={`featureImageInsightButton-${index}`}
                    href={button.link.url}
                    newTab={button.link?.newTab}
                  >
                    {button.icon && <Icons icon={button.icon} />}
                    {button.link.text}
                  </Link>
                );
              })}
            </div>
          )}

          {(imageDesktop || imageMobile) && (
            <div className="flex justify-center rounded-t-lg bg-[rgba(255,255,255,0.1)] p-1 pb-0 md:order-3 md:rounded-t-[28px] md:p-[14px] md:pb-0">
              <picture className="w-full">
                {imageDesktop && imageMobile && (
                  <source
                    srcSet={imageDesktop.src}
                    media="(min-width: 768px)"
                  />
                )}
                <img
                  className="w-full"
                  src={imageMobile?.src || imageDesktop?.src}
                  alt={imageAlt}
                />
              </picture>
            </div>
          )}
        </div>
      </div>
    </Section>
  );
}

FeatureImageInsights.displayName = 'FeatureImageInsights';
FeatureImageInsights.Schema = Schema;
