export function Clock(props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_2010_28129)">
        <path
          d="M12.4997 0.62915C10.3241 0.62915 8.19747 1.27427 6.38858 2.48293C4.5797 3.69159 3.16984 5.4095 2.3373 7.41942C1.50477 9.42935 1.28694 11.641 1.71136 13.7747C2.13578 15.9085 3.1834 17.8684 4.72173 19.4067C6.26006 20.9451 8.22001 21.9927 10.3537 22.4171C12.4875 22.8415 14.6991 22.6237 16.7091 21.7912C18.719 20.9586 20.4369 19.5488 21.6455 17.7399C22.8542 15.931 23.4993 13.8043 23.4993 11.6288C23.4962 8.71246 22.3364 5.91645 20.2742 3.85428C18.212 1.79211 15.416 0.63223 12.4997 0.62915ZM12.4997 20.9362C10.6588 20.9362 8.85934 20.3903 7.32875 19.3676C5.79815 18.3449 4.6052 16.8913 3.90074 15.1906C3.19629 13.4899 3.01197 11.6185 3.3711 9.81303C3.73023 8.00757 4.61667 6.34914 5.91834 5.04748C7.22 3.74582 8.87842 2.85937 10.6839 2.50024C12.4893 2.14112 14.3607 2.32543 16.0615 3.02989C17.7622 3.73434 19.2158 4.9273 20.2385 6.45789C21.2612 7.98849 21.8071 9.78798 21.8071 11.6288C21.8043 14.0964 20.8228 16.4622 19.0779 18.207C17.333 19.9519 14.9673 20.9334 12.4997 20.9362ZM19.2687 11.6288C19.2687 11.8532 19.1795 12.0684 19.0209 12.2271C18.8622 12.3858 18.647 12.4749 18.4226 12.4749H12.4997C12.2753 12.4749 12.06 12.3858 11.9014 12.2271C11.7427 12.0684 11.6535 11.8532 11.6535 11.6288V5.70592C11.6535 5.48151 11.7427 5.26629 11.9014 5.10761C12.06 4.94893 12.2753 4.85979 12.4997 4.85979C12.7241 4.85979 12.9393 4.94893 13.098 5.10761C13.2566 5.26629 13.3458 5.48151 13.3458 5.70592V10.7827H18.4226C18.647 10.7827 18.8622 10.8718 19.0209 11.0305C19.1795 11.1892 19.2687 11.4044 19.2687 11.6288Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_2010_28129">
          <rect
            width="23.5"
            height="22.4783"
            fill="white"
            transform="translate(0.75 0.760864)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
