export function Schema() {
  return {
    label: 'Testimonial Masonry',
    key: 'testimonial-masonry',
    fields: [
      {
        label: 'Heading',
        name: 'heading',
        component: 'text',
        defaultValue: 'Don’t take our word for it. Here’s what customers say:',
      },
      {
        label: 'Testimonials',
        name: 'testimonials',
        component: 'group-list',
        itemProps: {
          label: '{{item.name}}',
        },
        fields: [
          {
            label: 'Featured Testimonial',
            name: 'featuredTestimonial',
            component: 'group',
            description:
              'Content for featured testimonial: enable, bg gradient selector, logo, image, link',
            fields: [
              {
                label: 'Enable',
                name: 'enable',
                description: '',
                component: 'toggle',
                defaultValue: false,
              },
              {
                label: 'Logo',
                name: 'logo',
                component: 'image',
              },
              {
                label: 'Logo Alt',
                name: 'logoAlt',
                component: 'text',
              },
              {
                label: 'Image',
                name: 'image',
                component: 'image',
              },
              {
                label: 'Image Alt',
                name: 'imageAlt',
                component: 'text',
              },
              {
                label: 'Link',
                name: 'link',
                component: 'link',
                defaultValue: {
                  text: 'Read More',
                  url: '/',
                },
              },
            ],
          },
          {
            label: 'Quote',
            name: 'quote',
            component: 'textarea',
          },
          {
            label: 'Profile Image',
            name: 'profile',
            component: 'image',
          },
          {
            label: 'Name',
            name: 'name',
            component: 'text',
          },
          {
            label: 'Title',
            name: 'title',
            component: 'text',
          },
          {
            label: 'Date (Optional)',
            name: 'date',
            component: 'text',
          },
        ],
        defaultItem: {
          quote:
            '“I can not express enough the value of having Pack as a partner to power our headless infrastructure.”',
          profile: {
            src: 'https://cdn.shopify.com/s/files/1/0830/5750/8663/files/avatar.png?v=1696027097',
          },
          name: 'Kristen Prince',
          title: '@kp34_',
        },
      },
    ],
  };
}
