import { useRef } from 'react';
import Expand from 'react-expand-animated';

export function FeatureStepsNavItem({
  activeStepIndex,
  containerRef,
  index,
  stepName,
  stepsRefs,
}) {
  const stepNameRef = useRef();

  const handleStepJump = () => {
    const stepEl = stepsRefs?.current?.[index];
    const stepElBoundingClient = stepEl.current?.getBoundingClientRect();

    const getPosition = () => {
      const containerTopPos =
        window.scrollY +
        (containerRef?.current?.getBoundingClientRect()?.top || 0);

      if (index === 0)
        return containerTopPos + (stepElBoundingClient?.height || 0);

      return (
        containerTopPos + (index + 1) * (stepElBoundingClient?.height || 0)
      );
    };

    window.scrollTo({
      top: getPosition(),
    });
  };

  return (
    <button
      className="flex items-center gap-2.5"
      onClick={() => handleStepJump()}
      type="button"
    >
      <span
        className={`feature-step-nav-item text-white ${
          index === activeStepIndex
            ? 'opacity-100'
            : 'opacity-60 hover:opacity-100'
        }`}
      >
        {index + 1}
      </span>
      <Expand
        className="flex-1"
        open={index === activeStepIndex}
        transitions={['width', 'opacity']}
        duration={300}
        styles={{
          open: {
            width:
              stepNameRef?.current?.getBoundingClientRect()?.width || 'auto',
            height: 'auto',
          },
          close: { width: 0, height: 'auto' },
        }}
      >
        <span
          ref={stepNameRef}
          className="whitespace-nowrap pr-12 text-[22px] font-medium tracking-[-0.22px] text-black"
        >
          {stepName}
        </span>
      </Expand>
    </button>
  );
}

FeatureStepsNavItem.displayName = 'FeatureStepsNavItem';
