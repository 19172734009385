import { IconSettings } from './icons';

const linkList = {
  label: 'Link List',
  name: 'linkList',
  itemProps: {
    label: '{{item.title}}',
  },
  fields: [
    {
      component: 'text',
      label: 'Title',
      name: 'title',
    },
    {
      component: 'textarea',
      label: 'Description',
      name: 'description',
    },
    {
      component: 'link',
      name: 'button',
      label: 'Button Link',
    },
    {
      component: 'group-list',
      name: 'links',
      label: 'Links',
      itemProps: {
        label: '{{item.title}}',
      },
      fields: [
        {
          component: 'text',
          name: 'title',
          label: 'Title',
        },
        {
          component: 'link',
          name: 'link',
          label: 'Link',
        },
      ],
    },
  ],
};

export default {
  label: 'Header',
  name: 'header',
  component: 'group',
  description: 'Header menu for navigation and mobile menu',
  fields: [
    {
      label: 'Promobar',
      name: 'promobar',
      component: 'group',
      description: 'Enable, messages, colors, slider settings',
      fields: [
        {
          label: 'Enabled',
          name: 'enabled',
          component: 'toggle',
          toggleLabels: {
            true: 'On',
            false: 'Off',
          },
          defaultValue: true,
        },
        {
          label: 'Autohide',
          name: 'autohide',
          component: 'toggle',
          description:
            'Hides promobar after scrolling away from top of the page',
          toggleLabels: {
            true: 'On',
            false: 'Off',
          },
          defaultValue: false,
        },
        {
          label: 'Effect Between Transitions',
          name: 'effect',
          component: 'select',
          description: 'Refresh page to observe change',
          options: [
            { label: 'Fade', value: 'fade' },
            { label: 'Horizontal Slide', value: 'slide-horizontal' },
            { label: 'Vertical Slide', value: 'slide-vertical' },
          ],
          defaultValue: 'fade',
        },
        {
          label: 'Autoplay Delay',
          name: 'delay',
          component: 'number',
          description: 'Delay between transitions (in ms)',
          defaultValue: 5000,
        },
        {
          label: 'Speed',
          name: 'speed',
          component: 'number',
          description: 'Duration of transition between slides (in ms)',
          defaultValue: 500,
        },
        {
          label: 'Messages',
          name: 'messages',
          component: 'group-list',
          itemProps: {
            label: '{{item.message}}',
          },
          fields: [
            {
              label: 'Message',
              name: 'message',
              component: 'markdown',
            },
            {
              label: 'Link (optional)',
              name: 'link',
              component: 'link',
              description: 'Link wrapping entire message',
            },
            {
              label: 'Additional Details',
              name: 'additionalDetails',
              component: 'group-list',
              itemProps: {
                label: '{{item.text}}',
              },
              fields: [
                IconSettings,
                {
                  label: 'Text',
                  name: 'text',
                  component: 'text',
                },
              ],
              defaultItem: {
                text: 'Learn more',
              },
            },
          ],
          defaultItem: {
            message: 'Optimize your site for conversions ∙ **Learn more**',
            link: { url: '/', text: '' },
          },
        },
      ],
    },
    {
      label: 'Menu',
      name: 'menu',
      component: 'blocks',
      description: 'Menu links or mega menu navigation',
      templates: {
        menu: {
          label: 'Mega Menu',
          name: 'menuMega',
          itemProps: {
            label: '{{item.title}}',
          },
          fields: [
            {
              label: 'Title',
              name: 'title',
              component: 'text',
            },
            {
              label: 'Mega Menu Items',
              name: 'megaMenuItems',
              component: 'blocks',
              description: 'Menu item types for the mega menu',
              templates: {
                iconLinkList: {
                  label: 'Icon Link List',
                  name: 'iconLinkList',
                  itemProps: {
                    label: '{{item.title}}',
                  },
                  fields: [
                    {
                      component: 'text',
                      label: 'Title',
                      name: 'title',
                    },
                    {
                      component: 'textarea',
                      label: 'Description',
                      name: 'description',
                    },
                    {
                      component: 'group-list',
                      name: 'links',
                      label: 'Links',
                      itemProps: {
                        label: '{{item.title}}',
                      },
                      fields: [
                        IconSettings,
                        {
                          component: 'text',
                          name: 'title',
                          label: 'Title',
                        },
                        {
                          component: 'text',
                          name: 'description',
                          label: 'Description',
                        },
                        {
                          component: 'link',
                          name: 'link',
                          label: 'Link',
                        },
                      ],
                    },
                  ],
                },
                imageLink: {
                  label: 'Image Link',
                  name: 'imageLink',
                  itemProps: {
                    label: '{{item.title}}',
                  },
                  fields: [
                    {
                      component: 'text',
                      name: 'title',
                      label: 'Title',
                    },
                    {
                      component: 'image',
                      name: 'Image',
                      label: 'Image',
                    },
                    {
                      component: 'text',
                      name: 'subheading',
                      label: 'Sub Heading',
                    },
                    {
                      component: 'text',
                      name: 'description',
                      label: 'Description',
                    },
                    {
                      component: 'link',
                      name: 'link',
                      label: 'Link',
                    },
                  ],
                },
                imageLinkList: {
                  label: 'Image Link List',
                  name: 'imageLinkList',
                  itemProps: {
                    label: '{{item.title}}',
                  },
                  fields: [
                    {
                      component: 'text',
                      label: 'Title',
                      name: 'title',
                    },
                    {
                      component: 'textarea',
                      label: 'Description',
                      name: 'description',
                    },
                    {
                      component: 'link',
                      name: 'button',
                      label: 'Button Link',
                    },
                    {
                      component: 'group-list',
                      name: 'links',
                      label: 'Links',
                      itemProps: {
                        label: '{{item.title}}',
                      },
                      fields: [
                        {
                          component: 'image',
                          name: 'image',
                          label: 'Image',
                          description: 'Image should be around 48x48',
                        },
                        {
                          component: 'text',
                          name: 'title',
                          label: 'Title',
                        },
                        {
                          component: 'text',
                          name: 'description',
                          label: 'Description',
                        },
                        {
                          component: 'link',
                          name: 'link',
                          label: 'Link',
                        },
                      ],
                    },
                  ],
                },
                linkList,
                linkGrid: {
                  label: 'Link Grid',
                  name: 'linkGrid',
                  fields: [
                    {
                      component: 'group-list',
                      name: 'links',
                      label: 'Links',
                      itemProps: {
                        label: '{{item.title}}',
                      },
                      fields: [
                        IconSettings,
                        {
                          component: 'text',
                          name: 'title',
                          label: 'Title',
                        },
                        {
                          component: 'link',
                          name: 'link',
                          label: 'Link',
                        },
                      ],
                    },
                  ],
                },
                linkCards: {
                  label: 'Link Cards',
                  name: 'linkCards',
                  itemProps: {
                    label: '{{item.title}}',
                  },
                  fields: [
                    {
                      component: 'text',
                      label: 'Title',
                      name: 'title',
                    },
                    {
                      component: 'textarea',
                      label: 'Description',
                      name: 'description',
                    },
                    {
                      component: 'group-list',
                      name: 'links',
                      label: 'Links',
                      itemProps: {
                        label: '{{item.title}}',
                      },
                      fields: [
                        {
                          label: 'Icon',
                          name: 'icon',
                          component: 'select',
                          options: [
                            {
                              label: 'None',
                              value: '',
                            },
                            {
                              label: 'Shop',
                              value: 'shop',
                            },
                            {
                              label: 'Storefront',
                              value: 'storefront',
                            },
                          ],
                        },
                        {
                          component: 'text',
                          name: 'title',
                          label: 'Title',
                        },
                        {
                          component: 'text',
                          name: 'description',
                          label: 'Description',
                        },
                        {
                          component: 'link',
                          name: 'link',
                          label: 'Link',
                        },
                        {
                          label: 'Image',
                          name: 'image',
                          component: 'image',
                          description: 'Background image that will cover card',
                        },
                      ],
                    },
                  ],
                },
                contextSwitcher: {
                  label: 'Context Switcher',
                  name: 'contextSwitcher',
                  fields: [
                    {
                      component: 'group-list',
                      name: 'contexts',
                      label: 'Contexts',
                      itemProps: {
                        label: '{{item.title}}',
                      },
                      fields: [
                        {
                          component: 'text',
                          name: 'title',
                          label: 'Title',
                        },
                        {
                          component: 'group-list',
                          name: 'cards',
                          label: 'Cards',
                          itemProps: {
                            label: '{{item.title}}',
                          },
                          fields: [
                            {
                              component: 'select',
                              name: 'gradient',
                              label: 'Gradient',
                              defaultValue: 'blue-to-black',
                              options: [
                                {
                                  label: 'Blue to black',
                                  value: 'blue-to-black',
                                },
                                {
                                  label: 'Blue to green',
                                  value: 'blue-to-green',
                                },
                                {
                                  label: 'Purple to black',
                                  value: 'purple-to-black',
                                },
                              ],
                            },
                            IconSettings,
                            {
                              component: 'image',
                              name: 'image',
                              label: 'Image',
                              description:
                                'Background image that will cover card',
                            },
                            {
                              component: 'text',
                              name: 'title',
                              label: 'Title',
                            },
                            {
                              component: 'textarea',
                              name: 'description',
                              label: 'Description',
                            },
                            {
                              component: 'link',
                              name: 'link',
                              label: 'Link',
                            },
                            {
                              component: 'group-list',
                              name: 'marquee',
                              label: 'Marquee',
                              description:
                                'This setting will disable the background image if set',
                              itemProps: {
                                label: '{{item.title}}',
                              },
                              fields: [
                                {
                                  component: 'text',
                                  name: 'title',
                                  label: 'Title',
                                },
                                {
                                  component: 'image',
                                  name: 'imageLogo',
                                  label: 'Image',
                                },
                              ],
                            },
                          ],
                        },
                        {
                          ...linkList,
                          component: 'group',
                          name: 'list',
                        },
                      ],
                    },
                  ],
                },
                spacer: {
                  label: 'Spacer',
                  name: 'spacer',
                },
              },
            },
          ],
        },
        link: {
          label: 'Link',
          name: 'menuLink',
          itemProps: {
            label: '{{item.title}}',
          },
          fields: [
            {
              label: 'Title',
              name: 'title',
              component: 'text',
            },
            {
              label: 'Link',
              name: 'link',
              component: 'link',
            },
          ],
        },
      },
    },
    {
      label: 'CTAs',
      name: 'ctas',
      component: 'group',
      description: 'Links for right side CTAs',
      fields: [
        {
          label: 'Get Started',
          name: 'getStarted',
          component: 'link',
          defaultValue: {
            text: 'Get Started',
          },
        },
        {
          label: 'Demo',
          name: 'demo',
          component: 'link',
          defaultValue: {
            text: 'Book a Demo',
          },
        },
        {
          label: 'Login',
          name: 'login',
          component: 'link',
          defaultValue: {
            text: 'Login',
          },
        },
      ],
    },
    {
      label: 'In Menu CTAs',
      name: 'menuCtas',
      component: 'group',
      description: 'Links for in menu CTAs',
      fields: [
        {
          label: 'Sign Up Label',
          name: 'signUpLabel',
          component: 'text',
          defaultValue: 'Ready to get started?',
        },
        {
          label: 'Sign Up Link',
          name: 'signUpLink',
          component: 'link',
          defaultValue: {
            text: 'Sign up for free',
          },
        },
        {
          label: 'Demo',
          name: 'demo',
          component: 'link',
          defaultValue: {
            text: 'Watch a Demo',
          },
        },
        {
          label: 'Call',
          name: 'call',
          component: 'link',
          defaultValue: {
            text: 'Book a call',
          },
        },
      ],
    },
  ],
};
