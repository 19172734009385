export function Logo(props) {
  return (
    <svg
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.2363 8.56811C10.0065 8.5721 9.8913 8.78284 10.013 8.97772C10.3388 9.49935 10.956 10.475 11.6618 11.5709C12.6967 13.1778 13.5435 14.5185 13.5435 14.5185C13.5435 14.5185 11.9503 14.5445 10.003 14.5445C8.58569 14.5445 7.52278 14.5445 6.9522 14.5445C6.72153 14.5445 6.6067 14.7483 6.72983 14.9434C7.45542 16.0929 9.67869 19.5894 12.2844 23.6399L17.4131 31.6121C17.823 32.2493 18.5285 32.6345 19.2861 32.6351L21.6501 32.6369H24.6077C24.8688 32.6369 25.0267 32.3533 24.8877 32.1322C24.5363 31.5734 23.9295 30.6131 23.258 29.5698L21.3689 26.6348L28.493 26.6078L35.0067 26.5832C35.2708 26.5822 35.4295 26.2899 35.2866 26.0679L33.715 23.6269C32.6689 22.0022 31.813 20.6512 31.813 20.625C31.813 20.5975 33.3265 20.571 35.345 20.5631L38.2666 20.5516C38.5307 20.5505 38.6893 20.2582 38.5464 20.0362L35.009 14.5445L31.7983 9.56016C31.3885 8.924 30.6839 8.53918 29.9272 8.53823L20.4345 8.52619C13.9955 8.51799 11.2269 8.55089 10.2363 8.56811ZM13.6506 14.5971C13.6506 14.5971 14.509 15.9831 15.558 17.6129L17.4655 20.5762H24.5857C28.5017 20.5762 31.7058 20.5621 31.7058 20.545C31.7058 20.5054 28.0178 14.7647 27.9114 14.6387C27.8363 14.5499 27.428 14.5445 20.7412 14.5445C16.1667 14.5445 13.6506 14.5971 13.6506 14.5971Z"
        fill="currentColor"
      />
    </svg>
  );
}
