export function Books(props) {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="books-2 1" clipPath="url(#clip0_2410_69393)">
        <g id="Group">
          <path
            id="Vector"
            d="M18.9878 14.7567L16.1723 5.74332C16.0178 5.24777 15.6801 4.8411 15.2201 4.59999C14.7589 4.35888 14.2334 4.3111 13.7378 4.46555L12.1678 4.95555V4.16666C12.1678 3.09444 11.2956 2.22221 10.2234 2.22221H8.00117C6.92894 2.22221 6.05672 3.09444 6.05672 4.16666V4.47221C5.96561 4.45888 5.8745 4.44444 5.77894 4.44444H4.66783C3.59561 4.44444 2.72339 5.31666 2.72339 6.38888V15.8333C2.72339 16.9055 3.59561 17.7778 4.66783 17.7778H5.77894C6.19228 17.7778 6.5745 17.6455 6.89006 17.4244C7.20561 17.6455 7.58783 17.7778 8.00117 17.7778H10.2234C11.2956 17.7778 12.1678 16.9055 12.1678 15.8333V11.5522L13.6856 16.4133C13.9456 17.2444 14.7156 17.7778 15.5423 17.7778C15.7345 17.7778 15.9301 17.7489 16.1212 17.6889L17.7123 17.1922C18.2078 17.0367 18.6145 16.6989 18.8545 16.2389C19.0945 15.7789 19.1434 15.2522 18.9878 14.7567ZM7.72228 4.16666C7.72228 4.01332 7.84672 3.88888 8.00006 3.88888H10.2223C10.3756 3.88888 10.5001 4.01332 10.5001 4.16666V7.22221H7.72228V4.16666ZM4.66672 6.1111H5.77783C5.93117 6.1111 6.05561 6.23555 6.05561 6.38888V8.88888H4.38894V6.38888C4.38894 6.23555 4.51339 6.1111 4.66672 6.1111ZM12.6423 6.55221L14.2345 6.05555C14.3278 6.02555 14.4056 6.05555 14.4456 6.07555C14.4834 6.09555 14.5512 6.14332 14.5812 6.23888L15.3178 8.59666L13.1967 9.25888L12.4601 6.90221C12.4145 6.75555 12.4967 6.59999 12.6423 6.55444V6.55221Z"
            fill="currentColor"
          />
          <path
            id="Vector_2"
            d="M19.3889 17.7778H1.61117C1.15117 17.7778 0.777832 17.4044 0.777832 16.9444C0.777832 16.4844 1.15117 16.1111 1.61117 16.1111H19.3889C19.8489 16.1111 20.2223 16.4844 20.2223 16.9444C20.2223 17.4044 19.8489 17.7778 19.3889 17.7778Z"
            fill="currentColor"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_2410_69393">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
