export function Schema({ article }) {
  if (!article) return null;

  return {
    label: 'Article Header Image',
    key: 'article-header-image',
    fields: [
      {
        label: 'Show author and publish date',
        name: 'showArticleDetails',
        component: 'toggle',
      },
    ],
  };
}
