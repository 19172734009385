export function Schema() {
  return {
    label: 'Two Column Text',
    key: 'two-column-text',
    fields: [
      {
        label: 'Heading',
        name: 'heading',
        component: 'text',
      },
      {
        label: 'Sub-heading',
        name: 'subheading',
        component: 'text',
      },
      {
        label: 'Description',
        name: 'description',
        component: 'textarea',
      },
      {
        label: 'Content',
        name: 'content',
        component: 'markdown',
      },
    ],
  };
}
