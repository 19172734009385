export function ArrowLeftButton(props) {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="arrow-left-s-line">
        <rect
          y="0.0869141"
          width="20"
          height="20"
          rx="4"
          fill="#161719"
          fillOpacity="0.8"
        />
        <path
          id="Vector"
          d="M9.20433 10.086L12.9168 13.7985L11.8563 14.859L7.08333 10.086L11.8563 5.31299L12.9168 6.37349L9.20433 10.086Z"
          fill="#00BE8E"
        />
      </g>
    </svg>
  );
}
