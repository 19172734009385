export function Refresh(props) {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.25 12.5C2.25 7.11682 6.58419 2.75 12 2.75C15.2226 2.75 17.6366 4.0959 19.2301 5.42383C19.6171 5.74627 19.9569 6.06863 20.25 6.37217V4C20.25 3.58579 20.5858 3.25 21 3.25C21.4142 3.25 21.75 3.58579 21.75 4V8.5C21.75 8.91421 21.4142 9.25 21 9.25H17C16.5858 9.25 16.25 8.91421 16.25 8.5C16.25 8.08579 16.5858 7.75 17 7.75H19.4824C19.1637 7.39199 18.7593 6.98401 18.2699 6.57617C16.8634 5.4041 14.7774 4.25 12 4.25C7.41581 4.25 3.75 7.94205 3.75 12.5C3.75 12.9142 3.41421 13.25 3 13.25C2.58579 13.25 2.25 12.9142 2.25 12.5ZM21 11.75C21.4142 11.75 21.75 12.0858 21.75 12.5C21.75 17.8848 17.3848 22.25 12 22.25C9.25451 22.25 7.082 20.87 5.63227 19.5556C5.30412 19.2581 5.00955 18.9609 4.75 18.6789V21C4.75 21.4142 4.41421 21.75 4 21.75C3.58579 21.75 3.25 21.4142 3.25 21V16.5C3.25 16.0858 3.58579 15.75 4 15.75H8.06448C8.47869 15.75 8.81448 16.0858 8.81448 16.5C8.81448 16.9142 8.47869 17.25 8.06448 17.25H5.48953C5.79687 17.6142 6.18242 18.0297 6.63981 18.4444C7.94744 19.63 9.77493 20.75 12 20.75C16.5563 20.75 20.25 17.0563 20.25 12.5C20.25 12.0858 20.5858 11.75 21 11.75Z"
        fill="currentColor"
      />
    </svg>
  );
}
