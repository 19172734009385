import { useEffect, useRef } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import remarkBreaks from 'remark-breaks';
import rehypeRaw from 'rehype-raw';

export function Markdown({ isTwoColumns, classes = '', style, children }) {
  const markdownRef = useRef();

  const scrollTo = ({ el, event }) => {
    const href = el.getAttribute('href');
    const isAnchorLink = href?.startsWith('#') && href?.length > 1;
    if (isAnchorLink) {
      event.preventDefault();
      const element = document.querySelector(
        `[data-comp-id="${href?.replace('#', '')}"]`
      );
      if (isAnchorLink && !!element) {
        window.scroll({
          behavior: 'smooth',
          left: 0,
          top: element.offsetTop - 100,
        });
      }
    }
  };

  /*
    Adds click event listeners to all anchor tags with hashtag to scroll
    to a section on the page
  */

  useEffect(() => {
    markdownRef?.current?.querySelectorAll('a')?.forEach((el) => {
      el.addEventListener('click', (event) => scrollTo({ el, event }));
    });

    return () => {
      markdownRef?.current?.querySelectorAll('a')?.forEach((el) => {
        el.removeEventListener('click', (event) => scrollTo({ el, event }));
      });
    };
  }, [JSON.stringify(children)]);

  return (
    <div
      className={`${
        isTwoColumns
          ? '[&>:last-child]:mb-0] gap-16 md:columns-2 [&>:first-child]:mt-0'
          : ''
      } ${classes} markdown`}
      ref={markdownRef}
      style={style}
    >
      <ReactMarkdown
        remarkPlugins={[remarkGfm, remarkBreaks]}
        rehypePlugins={[rehypeRaw]}
      >
        {children}
      </ReactMarkdown>
    </div>
  );
}
