export function Schema() {
  return {
    label: 'Feature Carousel',
    key: 'feature-carousel',
    fields: [
      {
        label: 'Heading',
        name: 'heading',
        component: 'text',
        defaultValue: 'Feature Carousel Heading',
      },
      {
        label: 'Slides',
        name: 'slides',
        component: 'group-list',
        fields: [
          {
            label: 'Slide Type',
            name: 'type',
            component: 'select',
            options: [
              {
                label: 'Single',
                value: 'single',
              },
              {
                label: 'Double',
                value: 'double',
              },
              {
                label: 'Double with half image',
                value: 'double-half',
              },
            ],
          },
          {
            label: 'Link',
            name: 'link',
            component: 'link',
          },
          {
            label: 'Image',
            name: 'image',
            component: 'image',
          },
          {
            label: 'Image Alt Text',
            name: 'imageAlt',
            component: 'text',
          },
          {
            label: 'Image Position',
            name: 'imagePosition',
            component: 'select',
            options: [
              {
                label: 'Center',
                value: 'center',
              },
              {
                label: 'Fill',
                value: 'fill',
              },
            ],
          },
          {
            label: 'Half Image',
            name: 'halfImage',
            component: 'image',
            description: 'Only used for "Double with half image" type.',
          },
          {
            label: 'Half Image Alt Text',
            name: 'halfImageAlt',
            component: 'text',
          },
        ],
        itemProps: {
          label: '{{item.link.text}}',
        },
        defaultItem: {
          type: 'single',
          link: { text: 'Feature Item', url: '' },
          imagePosition: 'center',
        },
        defaultValue: [
          {
            type: 'single',
            link: { text: 'Feature Item Single', url: '' },
            imagePosition: 'center',
          },
          {
            type: 'double',
            link: { text: 'Feature Item Double', url: '' },
            imagePosition: 'center',
          },
          {
            type: 'single',
            link: { text: 'Feature Item Filled', url: '' },
            imagePosition: 'fill',
          },
        ],
      },
    ],
  };
}
