export function Lightning(props) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="Frame">
        <g id="Group">
          <path
            id="Vector"
            d="M11.901 7.10051C11.7574 6.82928 11.4794 6.66051 11.1764 6.66051H8.56178L9.05716 2.75602C9.09735 2.44527 8.92278 2.15193 8.63358 2.04277C8.34504 1.93293 8.02357 2.04009 7.85361 2.30195L4.13558 8.04214C3.96891 8.29932 3.95508 8.62815 4.09869 8.89939C4.24229 9.17063 4.52029 9.3394 4.82332 9.3394H7.43793L6.94255 13.2439C6.90236 13.5546 7.07693 13.848 7.36613 13.9571C7.44189 13.9859 7.52028 14 7.59801 14C7.81409 14 8.02094 13.8915 8.1461 13.6986L11.8648 7.95776C12.0315 7.70059 12.0446 7.37175 11.901 7.10051Z"
            fill="currentColor"
          />
        </g>
      </g>
    </svg>
  );
}
