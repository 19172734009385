export function Move(props) {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="arrows-up-right-down-left 1">
        <g id="Group">
          <path
            id="Vector"
            d="M18 9.16667H11.3333V2.5H9.66667V9.16667H3V10.8333H9.66667V17.5H11.3333V10.8333H18V9.16667Z"
            fill="currentColor"
          />
          <path
            id="Vector_2"
            d="M12.9999 5.83333C12.7866 5.83333 12.5733 5.75222 12.411 5.58889L10.4999 3.67778L8.58883 5.58889C8.26327 5.91444 7.73549 5.91444 7.40994 5.58889C7.08438 5.26333 7.08438 4.73555 7.40994 4.41L9.90994 1.91C10.2355 1.58444 10.7633 1.58444 11.0888 1.91L13.5888 4.41C13.9144 4.73555 13.9144 5.26333 13.5888 5.58889C13.4266 5.75111 13.2133 5.83333 12.9999 5.83333Z"
            fill="currentColor"
          />
          <path
            id="Vector_3"
            d="M10.5 18.3333C10.2867 18.3333 10.0734 18.2522 9.91116 18.0889L7.41116 15.5889C7.0856 15.2633 7.0856 14.7356 7.41116 14.41C7.73671 14.0844 8.26449 14.0844 8.59005 14.41L10.5012 16.3211L12.4123 14.41C12.7378 14.0844 13.2656 14.0844 13.5912 14.41C13.9167 14.7356 13.9167 15.2633 13.5912 15.5889L11.0912 18.0889C10.9289 18.2511 10.7156 18.3333 10.5023 18.3333H10.5Z"
            fill="currentColor"
          />
          <path
            id="Vector_4"
            d="M5.50005 13.3333C5.28671 13.3333 5.07338 13.2522 4.91116 13.0889L2.41116 10.5889C2.0856 10.2633 2.0856 9.73555 2.41116 9.41L4.91116 6.91C5.23671 6.58444 5.76449 6.58444 6.09005 6.91C6.4156 7.23555 6.4156 7.76333 6.09005 8.08889L4.17894 10L6.09005 11.9111C6.4156 12.2367 6.4156 12.7644 6.09005 13.09C5.92783 13.2522 5.71449 13.3344 5.50116 13.3344L5.50005 13.3333Z"
            fill="currentColor"
          />
          <path
            id="Vector_5"
            d="M15.5 13.3333C15.2867 13.3333 15.0734 13.2522 14.9112 13.0889C14.5856 12.7633 14.5856 12.2356 14.9112 11.91L16.8223 9.99888L14.9112 8.08777C14.5856 7.76222 14.5856 7.23444 14.9112 6.90889C15.2367 6.58333 15.7645 6.58333 16.09 6.90889L18.59 9.40889C18.9156 9.73444 18.9156 10.2622 18.59 10.5878L16.09 13.0878C15.9278 13.25 15.7145 13.3322 15.5012 13.3322L15.5 13.3333Z"
            fill="currentColor"
          />
        </g>
      </g>
    </svg>
  );
}
