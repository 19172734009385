export function LightningSpeed(props) {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="bolt-speed 1" clipPath="url(#clip0_2410_69433)">
        <g id="Group">
          <path
            id="Vector"
            d="M19.6368 8.36782C19.4434 8.00337 19.0668 7.77782 18.6557 7.77782H14.3112L15.109 2.39337C15.1857 1.88226 14.9012 1.39448 14.4201 1.2067C13.9379 1.02115 13.4001 1.18893 13.1101 1.6167L7.11344 10.4923C6.88344 10.8334 6.86011 11.2723 7.05455 11.6356C7.24789 11.9989 7.62344 12.2245 8.03566 12.2245H12.3801L11.5812 17.6112C11.5068 18.1223 11.7901 18.61 12.2723 18.7967C12.4034 18.8478 12.539 18.8723 12.6723 18.8723C13.0279 18.8723 13.3701 18.6978 13.5801 18.3867L19.5757 9.51337C19.8068 9.17226 19.8301 8.73337 19.6368 8.37004V8.36782Z"
            fill="currentColor"
          />
          <path
            id="Vector_2"
            d="M4.94444 10.8333H1.33333C0.873333 10.8333 0.5 10.46 0.5 9.99996C0.5 9.53996 0.873333 9.16663 1.33333 9.16663H4.94444C5.40444 9.16663 5.77778 9.53996 5.77778 9.99996C5.77778 10.46 5.40444 10.8333 4.94444 10.8333Z"
            fill="currentColor"
          />
          <path
            id="Vector_3"
            d="M6.33314 7.22221H4.11092C3.65092 7.22221 3.27759 6.84888 3.27759 6.38888C3.27759 5.92888 3.65092 5.55554 4.11092 5.55554H6.33314C6.79314 5.55554 7.16648 5.92888 7.16648 6.38888C7.16648 6.84888 6.79314 7.22221 6.33314 7.22221Z"
            fill="currentColor"
          />
          <path
            id="Vector_4"
            d="M6.33314 14.4444H4.11092C3.65092 14.4444 3.27759 14.071 3.27759 13.611C3.27759 13.151 3.65092 12.7777 4.11092 12.7777H6.33314C6.79314 12.7777 7.16648 13.151 7.16648 13.611C7.16648 14.071 6.79314 14.4444 6.33314 14.4444Z"
            fill="currentColor"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_2410_69433">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
