import { IconSettings } from '../../settings/icons';

export function Schema() {
  return {
    label: 'Feature Accordion',
    key: 'feature-accordion',
    fields: [
      {
        label: 'Heading',
        name: 'heading',
        component: 'text',
        defaultValue: 'Features Accordion Heading',
      },
      {
        label: 'Description',
        name: 'description',
        component: 'textarea',
        defaultValue:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras ullamcorper semper purus, vel consectetur massa vestibulum vel.',
      },
      {
        label: 'Badge Label',
        name: 'badgeLabel',
        component: 'text',
        defaultValue: 'Feature Label',
      },
      IconSettings,
      {
        label: 'Accordions',
        name: 'accordions',
        component: 'group-list',
        fields: [
          IconSettings,
          {
            label: 'Heading',
            name: 'heading',
            component: 'text',
          },
          {
            label: 'Description',
            name: 'description',
            component: 'textarea',
            defaultValue:
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras ullamcorper semper purus, vel consectetur massa vestibulum vel.',
          },
          {
            label: 'List Items',
            name: 'listItems',
            component: 'group-list',
            itemProps: {
              label: '{{item.description}}',
            },
            fields: [
              {
                label: 'Description',
                name: 'description',
                component: 'textarea',
              },
            ],
            defaultItem: {
              description: 'List Item',
            },
          },
          {
            label: 'Insights',
            name: 'insights',
            component: 'group-list',
            fields: [
              {
                label: 'Heading',
                name: 'heading',
                component: 'text',
              },
              {
                label: 'Description',
                name: 'description',
                component: 'textarea',
              },
              {
                label: 'Bar Percentage',
                name: 'barPercentage',
                component: 'number',
              },
            ],
            itemProps: {
              label: '{{item.heading}}',
            },
            defaultItem: {
              heading: '1x',
              description: 'Insight 1',
              barPercentage: 25,
            },
          },
          {
            label: 'Inline Image Heading',
            name: 'inlineImageHeading',
            component: 'text',
          },
          {
            label: 'Inline Image',
            name: 'inlineImage',
            component: 'image',
          },
          {
            label: 'Inline Image Text',
            name: 'inlineImageText',
            component: 'text',
          },
          {
            label: 'Image',
            name: 'image',
            component: 'image',
          },
          {
            label: 'Image Type',
            name: 'imageType',
            component: 'select',
            options: [
              {
                label: 'Center',
                value: 'center',
              },
              {
                label: 'To Edge',
                value: 'to-edge',
              },
            ],
            defaultValue: 'center',
          },
          {
            label: 'Image (Desktop)',
            name: 'imageDesktop',
            component: 'image',
          },
          {
            label: 'Image Type (Desktop)',
            name: 'imageTypeDesktop',
            component: 'select',
            options: [
              {
                label: 'Center',
                value: 'center',
              },
              {
                label: 'To Edge',
                value: 'to-edge',
              },
            ],
            defaultValue: 'center',
          },
          {
            label: 'Image Alt',
            name: 'imageAlt',
            component: 'text',
          },
        ],
        itemProps: {
          label: '{{item.heading}}',
        },
        defaultItem: {
          heading: 'Accordion Heading',
        },
      },
    ],
  };
}
