export function Toggle(props) {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="Frame">
        <g id="Group">
          <path
            id="Vector"
            d="M13.833 4.72876H7.16634C6.70634 4.72876 6.33301 5.10209 6.33301 5.56209V5.64654C6.60634 5.6032 6.88079 5.56209 7.16634 5.56209C10.0808 5.56209 12.4441 7.92543 12.4441 10.8399C12.4441 13.7543 10.0808 16.1176 7.16634 16.1176C6.88079 16.1176 6.60634 16.0776 6.33301 16.0332V16.1176C6.33301 16.5776 6.70634 16.951 7.16634 16.951H13.833C17.203 16.951 19.9441 14.2099 19.9441 10.8399C19.9441 7.46987 17.203 4.72876 13.833 4.72876Z"
            fill="currentColor"
          />
          <path
            id="Vector_2"
            d="M7.16653 16.951C3.79653 16.951 1.05542 14.2098 1.05542 10.8398C1.05542 7.46984 3.79653 4.72873 7.16653 4.72873C10.5365 4.72873 13.2776 7.46984 13.2776 10.8398C13.2776 14.2098 10.5365 16.951 7.16653 16.951ZM7.16653 6.3954C4.71542 6.3954 2.72209 8.38873 2.72209 10.8398C2.72209 13.291 4.71542 15.2843 7.16653 15.2843C9.61764 15.2843 11.611 13.291 11.611 10.8398C11.611 8.38873 9.61764 6.3954 7.16653 6.3954Z"
            fill="currentColor"
          />
        </g>
      </g>
    </svg>
  );
}
