export function UserCheck(props) {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 4.25C7.20507 4.25 5.75 5.70507 5.75 7.5C5.75 9.29493 7.20507 10.75 9 10.75C10.7949 10.75 12.25 9.29493 12.25 7.5C12.25 5.70507 10.7949 4.25 9 4.25ZM4.25 7.5C4.25 4.87665 6.37665 2.75 9 2.75C11.6234 2.75 13.75 4.87665 13.75 7.5C13.75 10.1234 11.6234 12.25 9 12.25C6.37665 12.25 4.25 10.1234 4.25 7.5ZM6 16.25C4.20507 16.25 2.75 17.7051 2.75 19.5C2.75 20.1904 3.30964 20.75 4 20.75H14C14.6904 20.75 15.25 20.1904 15.25 19.5C15.25 17.7051 13.7949 16.25 12 16.25H6ZM16.75 19.5C16.75 21.0188 15.5188 22.25 14 22.25H4C2.48122 22.25 1.25 21.0188 1.25 19.5C1.25 16.8766 3.37665 14.75 6 14.75H12C14.6234 14.75 16.75 16.8766 16.75 19.5ZM23.5303 9.03033C23.8232 8.73744 23.8232 8.26256 23.5303 7.96967C23.2374 7.67678 22.7626 7.67678 22.4697 7.96967L19 11.4393L17.5303 9.96967C17.2374 9.67678 16.7626 9.67678 16.4697 9.96967C16.1768 10.2626 16.1768 10.7374 16.4697 11.0303L18.4697 13.0303C18.7626 13.3232 19.2374 13.3232 19.5303 13.0303L23.5303 9.03033Z"
        fill="currentColor"
      />
    </svg>
  );
}
