import { useState } from 'react';
import { Schema } from './HeroCarousel.schema';
import { Button } from '../../snippets/button';
import { Logo } from '../../icons/logo';
import { Icons } from '../../snippets/Icons';

const SCREENS_TO_FILL = 12;
const SECONDS_PER_SCREEN = 5.5;
const SCREEN_WIDTH = 260;
const SCREEN_GAP = 30;

function Screen({ screen }) {
  return (
    <div className="flex flex-col gap-[22px]">
      <div className="h-[565px] w-[260px] flex-shrink-0 overflow-hidden rounded-[30px] border border-grey-300">
        {screen.image?.src ? (
          <img
            className="h-full w-full object-cover"
            src={screen.image.src}
            alt={screen.image.altText || screen.heading}
          />
        ) : null}
      </div>
      {screen.heading ? (
        <p className="text-center font-aeonik antialiased">{screen.heading}</p>
      ) : null}
    </div>
  );
}

export function HeroCarousel({ cms }) {
  const [isHovered, setIsHovered] = useState(false);
  const { icon, heading, description, buttons = [], screens = [] } = cms || {};
  return (
    <div className="hero-carousel-gradient flex flex-col gap-10 pt-[60px] pb-[60px] lg:gap-[60px] lg:pt-20 lg:pb-[100px]">
      <div className="mx-auto flex w-full max-w-8xl flex-col gap-10 px-5 lg:px-[140px]">
        <div className="flex max-w-[699px] flex-col gap-5">
          <div className="flex flex-col items-start gap-5 lg:gap-[15px]">
            {icon ? (
              <div className="flex items-center gap-1 rounded-lg bg-primary-light pr-1 lg:rounded-xl">
                <Logo
                  className="h-10 w-10 lg:h-12 lg:w-12"
                  style={{ color: '#030303' }}
                />
                <div
                  className={`flex h-8 w-8 items-center justify-center rounded-lg  lg:h-10 lg:w-10 lg:rounded-[10px] ${
                    icon === 'storefront'
                      ? 'bg-primary-green-500'
                      : 'bg-primary-blue'
                  }`}
                >
                  <Icons
                    icon={icon}
                    className="h-[30px] self-end lg:h-[38px]"
                  />
                </div>
              </div>
            ) : null}
            {heading ? (
              <h1 className="font-aeonik text-[2.875rem] font-normal leading-none text-primary-light antialiased lg:text-[4rem] lg:leading-[4.25rem]">
                {heading}
              </h1>
            ) : null}
          </div>
          {description ? (
            <p className="max-w-[486px] font-aeonik text-xl leading-6 text-grey-300 antialiased lg:text-2xl">
              {description}
            </p>
          ) : null}
        </div>
        {buttons ? (
          <div className="flex flex-col gap-2 lg:flex-row lg:gap-3">
            {buttons.map((button, i) => (
              <Button
                key={`hero-carousel-button-${i}`}
                link={button.link}
                icon={button.icon}
                iconPosition={button.iconPosition}
                className="border-grey-900 bg-grey-900"
              />
            ))}
          </div>
        ) : null}
      </div>
      {screens.length ? (
        <div
          className="overflow-x-hidden"
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <div
            className="flex gap-[30px]"
            style={{
              animation: `scroll ${
                SECONDS_PER_SCREEN * screens.length
              }s infinite linear`,
              animationPlayState: isHovered ? 'paused' : 'running',
              width: `${(SCREEN_WIDTH + SCREEN_GAP) * screens.length}px`,
            }}
          >
            {screens.map((screen, i) => (
              <Screen key={`original-${i}`} screen={screen} />
            ))}
            {[...Array(Math.ceil(SCREENS_TO_FILL / screens.length)).keys()].map(
              () => (
                <>
                  {screens.map((screen, i) => (
                    <Screen key={`dup-${i}`} screen={screen} />
                  ))}
                </>
              )
            )}
          </div>
        </div>
      ) : null}
    </div>
  );
}

HeroCarousel.displayName = 'HeroCarousel';
HeroCarousel.Schema = Schema;
