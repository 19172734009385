import { IconSettings } from '../../settings/icons';

export function Schema() {
  return {
    label: 'Value Props',
    key: 'value-props',
    fields: [
      {
        label: 'Heading',
        name: 'heading',
        component: 'text',
        defaultValue: 'Your digital experiences, elevated.',
      },
      {
        label: 'Value Props',
        name: 'valueProps',
        component: 'group-list',
        itemProps: {
          label: '{{item.heading}}',
        },
        fields: [
          {
            label: 'Heading',
            name: 'heading',
            component: 'text',
          },
          {
            label: 'Is Beta?',
            name: 'isBeta',
            component: 'toggle',
            defaultValue: false,
          },
          {
            label: 'Is Coming Soon?',
            name: 'isComingSoon',
            component: 'toggle',
            description: 'Overrides Beta Tag',
            defaultValue: false,
          },
          {
            label: 'Description',
            name: 'description',
            component: 'text',
          },
          IconSettings,
          {
            label: 'Icon Color',
            name: 'iconColor',
            component: 'color',
          },
          {
            label: 'Icon Color (Hover)',
            name: 'iconColorHover',
            component: 'color',
          },
        ],
        defaultItem: {
          heading: 'Visual CMS',
          isBeta: false,
          isComingSoon: false,
          description:
            'Keep your visuals consistently on-brand with easily accessible.',
          icon: 'atom',
        },
      },
    ],
  };
}
