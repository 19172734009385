export function Storefront(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="54"
      height="52"
      viewBox="0 0 54 52"
      fill="none"
      {...props}
    >
      <g filter="url(#filter0_d_1579_50620)">
        <path
          d="M20.6662 16.6889H13.4407C12.3014 16.6889 11.5269 16.8898 11.5526 16.2204C11.2339 17.8179 10.5432 22.7083 13.6659 24.1236C14.2096 24.3701 14.8689 24.5111 15.666 24.5111C21.9608 24.5111 20.421 18.974 20.6662 16.6889Z"
          fill="currentColor"
          fillOpacity="0.2"
        />
        <path
          d="M13.4407 16.6889H20.6662H32.6667H39.8921C41.1327 16.6889 41.9408 16.9272 41.754 16.0203C41.7018 15.8225 41.667 15.7111 41.667 15.7111L40.1228 9.22607C39.8506 8.42753 39.0863 7.88892 38.2254 7.88892H15.1074C14.2465 7.88892 13.4822 8.42753 13.21 9.22607L11.6658 15.7111C11.6658 15.7111 11.6167 15.8992 11.5526 16.2204C11.5269 16.8898 12.3014 16.6889 13.4407 16.6889Z"
          fill="currentColor"
          fillOpacity="0.2"
        />
        <path
          d="M32.6667 16.6889H20.6662C20.9026 18.974 21.109 24.5111 26.6664 24.5111C32.2238 24.5111 32.4304 18.9741 32.6667 16.6889Z"
          fill="currentColor"
          fillOpacity="0.2"
        />
        <path
          d="M39.8921 16.6889H32.6667C32.9118 18.9741 30.9186 24.5111 37.6669 24.5111C38.4573 24.5111 39.1174 24.3904 39.6669 24.1773C43.2812 22.7752 42.111 17.3742 41.754 16.0203C41.9408 16.9272 41.1327 16.6889 39.8921 16.6889Z"
          fill="currentColor"
          fillOpacity="0.2"
        />
        <path
          d="M35.6668 37.2223C37.876 37.2223 39.6669 35.4712 39.6669 33.3111V24.1773C39.1174 24.3904 38.4573 24.5111 37.6669 24.5111C30.9186 24.5111 32.9118 18.9741 32.6667 16.6889C32.4304 18.9741 32.2238 24.5111 26.6664 24.5111C21.109 24.5111 20.9026 18.974 20.6662 16.6889C20.421 18.974 21.9608 24.5111 15.666 24.5111C14.8689 24.5111 14.2096 24.3701 13.6659 24.1236V33.3111C13.6659 35.4712 15.4568 37.2223 17.666 37.2223H35.6668Z"
          fill="currentColor"
          fillOpacity="0.2"
        />
        <path
          d="M13.6659 23.5334V24.1236M39.6669 23.5334V24.1773M13.4407 16.6889H39.8921M13.4407 16.6889H20.6662M13.4407 16.6889C12.3014 16.6889 11.5269 16.8898 11.5526 16.2204M39.8921 16.6889H32.6667M39.8921 16.6889C41.1327 16.6889 41.9408 16.9272 41.754 16.0203M41.667 15.7111L40.1228 9.22607C39.8506 8.42753 39.0862 7.88892 38.2254 7.88892H15.1074C14.2465 7.88892 13.4822 8.42753 13.21 9.22607L11.6658 15.7111M41.667 15.7111C41.667 15.7111 41.7018 15.8225 41.754 16.0203M41.667 15.7111C41.7064 15.8267 41.7352 15.9293 41.754 16.0203M11.6658 15.7111C11.6658 15.7111 11.6167 15.8992 11.5526 16.2204M11.6658 15.7111C11.5943 15.9207 11.5578 16.0876 11.5526 16.2204M20.6662 16.6889C20.421 18.974 21.9608 24.5111 15.666 24.5111C14.8689 24.5111 14.2096 24.3701 13.6659 24.1236M20.6662 16.6889C20.9026 18.974 21.109 24.5111 26.6664 24.5111C32.2238 24.5111 32.4304 18.9741 32.6667 16.6889M20.6662 16.6889H32.6667M32.6667 16.6889C32.9118 18.9741 30.9186 24.5111 37.6669 24.5111C38.4573 24.5111 39.1174 24.3904 39.6669 24.1773M11.5526 16.2204C11.2339 17.8179 10.5432 22.7083 13.6659 24.1236M13.6659 24.1236V33.3111C13.6659 35.4712 15.4568 37.2223 17.666 37.2223H35.6668C37.876 37.2223 39.6669 35.4712 39.6669 33.3111V24.1773M41.754 16.0203C42.111 17.3742 43.2813 22.7752 39.6669 24.1773"
          stroke="currentColor"
          strokeWidth="2.5"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_1579_50620"
          x="-1.77778"
          y="-3.51852"
          width="59.5556"
          height="59.5556"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="3.25926" />
          <feGaussianBlur stdDeviation="4.88889" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1579_50620"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1579_50620"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
}
