export function DotWithArrows(props) {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="Frame">
        <g id="Group">
          <path
            id="Vector"
            d="M6.69997 9.13987L3.95553 6.39543H5.83219C6.29219 6.39543 6.66553 6.02209 6.66553 5.56209C6.66553 5.10209 6.29219 4.72876 5.83219 4.72876H1.94442C1.48442 4.72876 1.11108 5.10209 1.11108 5.56209V9.45098C1.11108 9.91098 1.48442 10.2843 1.94442 10.2843C2.40442 10.2843 2.77775 9.91098 2.77775 9.45098V7.57432L5.52219 10.3188C5.68442 10.481 5.89775 10.5632 6.11108 10.5632C6.32442 10.5632 6.53775 10.4821 6.69997 10.3188C7.02553 9.9932 7.02553 9.46543 6.69997 9.13987Z"
            fill="currentColor"
          />
          <path
            id="Vector_2"
            d="M18.0555 4.72876H14.1666C13.7066 4.72876 13.3333 5.10209 13.3333 5.56209C13.3333 6.02209 13.7066 6.39543 14.1666 6.39543H16.0433L13.2989 9.13987C12.9733 9.46543 12.9733 9.9932 13.2989 10.3188C13.4611 10.481 13.6744 10.5632 13.8877 10.5632C14.1011 10.5632 14.3144 10.4821 14.4766 10.3188L17.2211 7.57432V9.45098C17.2211 9.91098 17.5944 10.2843 18.0544 10.2843C18.5144 10.2843 18.8877 9.91098 18.8877 9.45098V5.56209C18.8877 5.10209 18.5155 4.72876 18.0555 4.72876Z"
            fill="currentColor"
          />
          <path
            id="Vector_3"
            d="M10 17.2288C11.9944 17.2288 13.6111 15.612 13.6111 13.6176C13.6111 11.6233 11.9944 10.0065 10 10.0065C8.00567 10.0065 6.38892 11.6233 6.38892 13.6176C6.38892 15.612 8.00567 17.2288 10 17.2288Z"
            fill="currentColor"
          />
        </g>
      </g>
    </svg>
  );
}
