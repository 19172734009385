import { useRouter } from '@backpackjs/storefront';
import { Schema } from './BlogNavigation.schema';

import { Link } from '../../snippets/Link';
import { Section } from '../../snippets';

export function BlogNavigation({ cms }) {
  const { links } = cms;
  const router = useRouter();

  const path = router?.asPath?.split('?')[0];

  if (!links?.length) return null;

  return (
    <Section cms={cms}>
      <div className="my-16 px-4">
        <div className="scrollbar-hide scrollbar-hide::-webkit-scrollbar mx-auto flex max-w-7xl justify-start overflow-x-auto md:overflow-x-hidden">
          <ul className="relative flex gap-4 whitespace-nowrap before:absolute before:bottom-0 before:h-[1px] before:w-full before:bg-grey-900 ">
            {links.map((item, index) => {
              if (!item.link?.url || !item.link?.text) return null;
              const isActive = item.link.url.startsWith(path);

              return (
                <li
                  key={`blog-nav-${index}`}
                  className={`${
                    isActive ? 'border-brand' : 'border-transparent'
                  } z-0 border-b-2 px-1 pb-3 transition-colors hover:border-brand`}
                >
                  <Link
                    className={`${
                      isActive ? 'text-brand' : 'text-white-700'
                    } block text-base font-semibold`}
                    href={item.link?.url}
                    newTab={item.link?.newTab}
                  >
                    {item.link.text}
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </Section>
  );
}

BlogNavigation.displayName = 'BlogNavigation';
BlogNavigation.Schema = Schema;
