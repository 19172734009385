import { Schema } from './Metrics.schema';
import { Icons } from '../../snippets/Icons';

import { Link } from '../../snippets/Link';
import { Section } from '../../snippets';

export function Metrics({ cms }) {
  const { heading, subheading, description, metrics } = cms;

  if (!metrics?.length) return null;

  return (
    <Section cms={cms}>
      <div className="mb-16 flex flex-col gap-12">
        {subheading || heading || description ? (
          <div className="flex flex-col gap-5">
            {subheading || heading ? (
              <div className="flex flex-col gap-3">
                {subheading ? (
                  <p className="text-base font-semibold text-brand">
                    {subheading}
                  </p>
                ) : null}
                {heading ? <h2 className="text-4xl">{heading}</h2> : null}
              </div>
            ) : null}
            {description ? <p className="text-xl">{description}</p> : null}
          </div>
        ) : null}
        <div className="flex flex-col gap-8 md:flex-row">
          {metrics.map((metric) => {
            return (
              <div className="w-full border-l-2 border-l-brand pl-6">
                <div className="flex flex-col gap-5">
                  <div className="flex flex-col gap-1">
                    <div className="text-5xl font-semibold text-brand">
                      {metric.value}
                    </div>
                    <div className="font-semibold">{metric.label}</div>
                  </div>
                  {metric.link?.url ? (
                    <Link
                      className="text-link group mt-auto inline-flex items-center gap-2 text-primary-green-700 hover:gap-4"
                      href={metric.link?.url}
                      newTab={metric?.link?.newTab}
                    >
                      {metric.link.text}
                      <Icons icon="arrowRight" />
                    </Link>
                  ) : null}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </Section>
  );
}

Metrics.displayName = 'Metrics';
Metrics.Schema = Schema;
