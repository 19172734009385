export function Schema() {
  return {
    label: 'HTML',
    key: 'html',
    fields: [
      {
        label: 'HTML',
        name: 'html',
        component: 'html',
      },
    ],
  };
}
