export function Play(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="15"
      viewBox="0 0 14 15"
      fill="none"
      {...props}
    >
      <path
        d="M2.745 1.12815L12.237 6.38415C12.922 6.76315 12.922 7.73715 12.237 8.11615L2.745 13.3722C2.076 13.7432 1.25 13.2642 1.25 12.5062V1.99415C1.25 1.23615 2.075 0.757151 2.745 1.12815Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
