export function Shopify(props) {
  return (
    <svg
      width="16"
      height="18"
      viewBox="0 0 16 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="Symbol.svg" clipPath="url(#clip0_2209_87253)">
        <g id="Group">
          <g id="Group_2">
            <path
              id="Vector"
              d="M10.6054 2.80703C10.6054 2.80703 10.4226 2.85924 10.1222 2.95061C10.07 2.78093 9.99165 2.58515 9.88717 2.37631C9.54763 1.7237 9.03831 1.37129 8.43758 1.37129C8.39841 1.37129 8.35923 1.37129 8.30699 1.38434C8.29393 1.35824 8.26781 1.34519 8.25475 1.31908C7.99357 1.03193 7.65402 0.901411 7.24918 0.914463C6.46562 0.940567 5.68206 1.50181 5.05521 2.50683C4.61119 3.21165 4.27165 4.0992 4.18023 4.79097C3.27914 5.06507 2.65229 5.26085 2.63923 5.2739C2.18215 5.41748 2.16909 5.43053 2.11686 5.86125C2.0385 6.18756 0.850098 15.3894 0.850098 15.3894L10.723 17.0992V2.78093C10.6707 2.79398 10.6316 2.79398 10.6054 2.80703ZM8.32005 3.51185C7.79768 3.66848 7.22306 3.85121 6.66151 4.02089C6.81822 3.40743 7.13165 2.79398 7.49731 2.38936C7.64096 2.24579 7.83685 2.07611 8.05886 1.97169C8.28087 2.44157 8.33311 3.08113 8.32005 3.51185ZM7.26224 1.43655C7.44507 1.43655 7.60179 1.47571 7.73238 1.55402C7.52343 1.65844 7.31448 1.82812 7.11859 2.0239C6.62233 2.55904 6.24361 3.39438 6.0869 4.19057C5.61676 4.33414 5.14663 4.47772 4.71567 4.60824C4.98991 3.36828 6.04772 1.47571 7.26224 1.43655ZM5.7343 8.61527C5.78654 9.45061 7.99357 9.63334 8.12416 11.6042C8.21558 13.1574 7.30142 14.2147 5.98243 14.293C4.38918 14.3974 3.51421 13.4576 3.51421 13.4576L3.85375 12.0219C3.85375 12.0219 4.72873 12.6876 5.43393 12.6353C5.89101 12.6092 6.06078 12.2307 6.04772 11.9697C5.98243 10.8733 4.18023 10.9386 4.0627 9.13735C3.95823 7.63635 4.95074 6.10924 7.14471 5.96567C7.99357 5.91346 8.42453 6.12229 8.42453 6.12229L7.92827 8.00181C7.92827 8.00181 7.36672 7.74077 6.70069 7.79298C5.7343 7.85824 5.72124 8.47169 5.7343 8.61527ZM8.84242 3.35523C8.84242 2.96366 8.79019 2.40241 8.60736 1.93254C9.20809 2.05001 9.49539 2.71567 9.62599 3.12029C9.39092 3.18555 9.12973 3.26386 8.84242 3.35523Z"
              fill="currentColor"
            />
            <path
              id="Vector_2"
              d="M11.25 16.6099L15.3506 15.5919C15.3506 15.5919 13.5876 3.67519 13.5746 3.59687C13.5615 3.51856 13.4962 3.46635 13.4309 3.46635C13.3656 3.46635 12.2164 3.44025 12.2164 3.44025C12.2164 3.44025 11.5112 2.76153 11.25 2.50049V16.6099Z"
              fill="currentColor"
            />
          </g>
        </g>
      </g>
      <defs>
        <clipPath id="clip0_2209_87253">
          <rect
            width="14.3"
            height="16.25"
            fill="currentColor"
            transform="translate(0.850098 0.875)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
