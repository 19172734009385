export function Toggles(props) {
  return (
    <svg
      width="32"
      height="33"
      viewBox="0 0 32 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="Frame">
        <g id="Group">
          <path
            id="Vector"
            d="M16.4446 24.3952C16.4446 22.0663 17.945 20.109 20.025 19.3801C19.961 19.245 19.8846 19.1152 19.7761 19.0085C19.5254 18.7579 19.1877 18.6174 18.8339 18.6174H9.33344C6.14766 18.6174 3.55566 21.2094 3.55566 24.3952C3.55566 27.581 6.14766 30.173 9.33344 30.173H18.8286C19.3583 30.173 19.8081 29.8583 20.0232 29.4085C17.945 28.6814 16.4446 26.7223 16.4446 24.3952Z"
            fill="currentColor"
          />
          <path
            id="Vector_2"
            d="M21.7779 31.0618C18.1014 31.0618 15.1112 28.0716 15.1112 24.3952C15.1112 20.7187 18.1014 17.7285 21.7779 17.7285C25.4543 17.7285 28.4445 20.7187 28.4445 24.3952C28.4445 28.0716 25.4543 31.0618 21.7779 31.0618ZM21.7779 20.3952C19.5717 20.3952 17.7779 22.189 17.7779 24.3952C17.7779 26.6014 19.5717 28.3952 21.7779 28.3952C23.9841 28.3952 25.7779 26.6014 25.7779 24.3952C25.7779 22.189 23.9841 20.3952 21.7779 20.3952Z"
            fill="currentColor"
          />
          <path
            id="Vector_3"
            d="M22.6672 15.0619H13.1703V12.3952H22.6672C24.3827 12.3952 25.7783 10.9997 25.7783 9.28413C25.7783 7.56857 24.3827 6.17301 22.6672 6.17301H13.1703V3.50635H22.6672C25.853 3.50635 28.445 6.09835 28.445 9.28413C28.445 12.4699 25.853 15.0619 22.6672 15.0619Z"
            fill="currentColor"
          />
          <path
            id="Vector_4"
            d="M10.2223 15.9508C6.54589 15.9508 3.55566 12.9605 3.55566 9.2841C3.55566 5.60765 6.54589 2.61743 10.2223 2.61743C13.8988 2.61743 16.889 5.60765 16.889 9.2841C16.889 12.9605 13.8988 15.9508 10.2223 15.9508ZM10.2223 5.2841C8.01611 5.2841 6.22233 7.07788 6.22233 9.2841C6.22233 11.4903 8.01611 13.2841 10.2223 13.2841C12.4286 13.2841 14.2223 11.4903 14.2223 9.2841C14.2223 7.07788 12.4286 5.2841 10.2223 5.2841Z"
            fill="currentColor"
          />
        </g>
      </g>
    </svg>
  );
}
