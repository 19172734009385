export function Video(props) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g>
        <g>
          <path
            d="M12.211 6.98408L9.915 5.59908C9.513 5.35608 9 5.64608 9 6.11508V8.88608C9 9.35608 9.513 9.64508 9.915 9.40208L12.211 8.01708C12.6 7.78208 12.6 7.21908 12.211 6.98408Z"
            fill="currentColor"
          />
          <path
            d="M1.75 6.75V13.25C1.75 14.355 2.645 15.25 3.75 15.25H12.25"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M6.75 12.25L14.25 12.25C15.3546 12.25 16.25 11.3546 16.25 10.25V4.75C16.25 3.64543 15.3546 2.75 14.25 2.75L6.75 2.75C5.64543 2.75 4.75 3.64543 4.75 4.75L4.75 10.25C4.75 11.3546 5.64543 12.25 6.75 12.25Z"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
    </svg>
  );
}
