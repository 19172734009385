export function NewFile(props) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width="20" height="20" fill="none" />
      <g id="Navigation">
        <path
          d="M-2699 -471C-2699 -472.105 -2698.1 -473 -2697 -473H8139C8140.1 -473 8141 -472.105 8141 -471V3502C8141 3503.1 8140.1 3504 8139 3504H-2697C-2698.1 3504 -2699 3503.1 -2699 3502V-471Z"
          fill="none"
        />
        <path
          d="M-2697 -472H8139V-474H-2697V-472ZM8140 -471V3502H8142V-471H8140ZM8139 3503H-2697V3505H8139V3503ZM-2698 3502V-471H-2700V3502H-2698ZM-2697 3503C-2697.55 3503 -2698 3502.55 -2698 3502H-2700C-2700 3503.66 -2698.66 3505 -2697 3505V3503ZM8140 3502C8140 3502.55 8139.55 3503 8139 3503V3505C8140.66 3505 8142 3503.66 8142 3502H8140ZM8139 -472C8139.55 -472 8140 -471.552 8140 -471H8142C8142 -472.657 8140.66 -474 8139 -474V-472ZM-2697 -474C-2698.66 -474 -2700 -472.657 -2700 -471H-2698C-2698 -471.552 -2697.55 -472 -2697 -472V-474Z"
          fill="currentColor"
          fillOpacity="0.1"
        />
        <g id="Nav Platform">
          <g id="Full Menu">
            <rect
              width="1440"
              height="634"
              transform="translate(-780 -234)"
              fill="none"
            />
            <g
              id="MM"
              clipPath="url(#clip0_1817_27053)"
              filter="url(#filter0_dd_1817_27053)"
            >
              <g id="Content">
                <mask id="path-3-inside-1_1817_27053" fill="currentColor">
                  <path d="M-780 -142H660V320H-780V-142Z" />
                </mask>
                <path d="M-780 -142H660V320H-780V-142Z" fill="none" />
                <path
                  d="M-780 -141H660V-143H-780V-141ZM660 319H-780V321H660V319Z"
                  fill="#0D0D0D"
                  mask="url(#path-3-inside-1_1817_27053)"
                />
                <g id="Content_2">
                  <g id="Frame 1321317306">
                    <g id="Frame 1321317291">
                      <mask id="path-5-inside-2_1817_27053" fill="currentColor">
                        <path d="M-42 -102H269V280H-42V-102Z" />
                      </mask>
                      <path
                        d="M-41 280V-102H-43V280H-41Z"
                        fill="none"
                        mask="url(#path-5-inside-2_1817_27053)"
                      />
                      <g id="Frame 1321317305">
                        <g id="Frame 1321317301">
                          <rect
                            x="-12"
                            y="-10"
                            width="257"
                            height="61"
                            rx="10"
                            fill="none"
                          />
                          <g id="Frame 53058">
                            <g id="Frame" clipPath="url(#clip1_1817_27053)">
                              <g id="Group">
                                <path
                                  id="Vector"
                                  d="M16.6666 8.28675C15.6611 8.28675 14.7722 7.64675 14.4533 6.69341L14.1544 5.79786L13.2611 5.49897C12.3099 5.18452 11.6666 4.29452 11.6666 3.28564C11.6666 2.90119 11.7844 2.54675 11.9533 2.22119H5.27772C3.59217 2.22119 2.22217 3.59119 2.22217 5.27675V14.7223C2.22217 16.4079 3.59217 17.7779 5.27772 17.7779H14.7222C16.4077 17.7779 17.7777 16.4079 17.7777 14.7223V7.97452C17.4411 8.16008 17.0688 8.28675 16.6666 8.28675ZM11.8422 8.84452L10.4388 9.31564L9.97106 10.729C9.89439 10.9567 9.68217 11.1112 9.44328 11.1112C9.20439 11.1112 8.99106 10.9567 8.9155 10.729L8.44772 9.31564L7.04439 8.84452C6.81772 8.76897 6.66439 8.55452 6.66439 8.31452C6.66439 8.07452 6.81772 7.86008 7.04439 7.78452L8.44772 7.31341L8.9155 5.90008C9.06772 5.44341 9.81772 5.44341 9.96994 5.90008L10.4377 7.31341L11.8411 7.78452C12.0677 7.86119 12.2211 8.07452 12.2211 8.31452C12.2211 8.55452 12.0677 8.76897 11.8411 8.84452H11.8422ZM13.0555 13.3334C12.5955 13.3334 12.2222 12.9601 12.2222 12.5001C12.2222 12.0401 12.5955 11.6667 13.0555 11.6667C13.5155 11.6667 13.8888 12.0401 13.8888 12.5001C13.8888 12.9601 13.5155 13.3334 13.0555 13.3334Z"
                                  fill="currentColor"
                                />
                                <path
                                  id="Vector_2"
                                  d="M19.5434 2.6534L17.86 2.09118L17.2989 0.407845C17.1167 -0.136599 16.2156 -0.136599 16.0334 0.407845L15.4723 2.09118L13.7889 2.6534C13.5167 2.7434 13.3334 2.99896 13.3334 3.28562C13.3334 3.57229 13.5167 3.82784 13.7889 3.91784L15.4723 4.48007L16.0334 6.1634C16.1245 6.43562 16.38 6.61896 16.6667 6.61896C16.9534 6.61896 17.2078 6.43562 17.3 6.1634L17.8612 4.48007L19.5445 3.91784C19.8167 3.82784 20 3.57229 20 3.28562C20 2.99896 19.8156 2.74451 19.5434 2.6534Z"
                                  fill="currentColor"
                                />
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <defs>
        <filter
          id="filter0_dd_1817_27053"
          x="-792"
          y="-142"
          width="1464"
          height="566"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="2"
            operator="erode"
            in="SourceAlpha"
            result="effect1_dropShadow_1817_27053"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="3" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.03 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1817_27053"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="4"
            operator="erode"
            in="SourceAlpha"
            result="effect2_dropShadow_1817_27053"
          />
          <feOffset dy="12" />
          <feGaussianBlur stdDeviation="8" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.08 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_1817_27053"
            result="effect2_dropShadow_1817_27053"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_1817_27053"
            result="shape"
          />
        </filter>
        <clipPath id="clip0_1817_27053">
          <rect
            width="1440"
            height="542"
            fill="currentColor"
            transform="translate(-780 -142)"
          />
        </clipPath>
        <clipPath id="clip1_1817_27053">
          <rect width="20" height="20" fill="currentColor" />
        </clipPath>
      </defs>
    </svg>
  );
}
