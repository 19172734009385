import { Schema } from './Media.schema';

import { Section } from '../../snippets';
import { Badge } from '../../snippets/badge';

export function Media({ cms }) {
  const {
    badge,
    heading,
    imageDesktop,
    imageMobile,
    videoDesktop,
    videoMobile,
  } = cms;

  return (
    <Section cms={cms}>
      <div className="bg-white">
        <div className="mx-auto flex max-w-8xl flex-col gap-[50px] py-[60px] px-5 lg:gap-[80px] lg:px-10 lg:pt-[120px] lg:pb-[150px]">
          {badge?.text || heading ? (
            <div className="flex flex-col items-start gap-5 lg:px-[100px]">
              <Badge
                text={badge?.text}
                icon={badge?.icon}
                textColor="#1a1a1a"
                badgeColor="#e6e6e6"
              />
              {heading ? (
                <h2 className="font-aeonik text-4xl font-normal tracking-tight text-primary-dark antialiased lg:max-w-[840px] lg:text-5xl lg:leading-[1.125]">
                  {heading}
                </h2>
              ) : null}
            </div>
          ) : null}
          {/* Media Wrapper */}
          <div className="relative flex flex-col px-4 lg:px-[60px]">
            {videoDesktop || videoMobile ? (
              <div className="media-shadow aspect-video overflow-hidden rounded-sm lg:rounded-lg">
                {videoDesktop ? (
                  <video
                    className="hidden h-full w-full object-cover lg:block"
                    poster={imageDesktop?.src}
                    autoPlay
                    muted
                    playsInline
                    loop
                  >
                    <source src={videoDesktop} type="video/mp4" />
                  </video>
                ) : null}
                {videoMobile ? (
                  <video
                    className="h-full w-full object-cover lg:hidden"
                    poster={imageMobile?.src}
                    autoPlay
                    muted
                    playsInline
                    loop
                  >
                    <source src={videoMobile} type="video/mp4" />
                  </video>
                ) : null}
              </div>
            ) : null}

            {/* Hero Image */}
            {!videoMobile && !videoDesktop && (imageDesktop || imageMobile) && (
              <picture>
                {imageDesktop && imageMobile && (
                  <source
                    srcSet={imageDesktop?.src}
                    media="(min-width: 768px)"
                  />
                )}

                <img
                  className="media-shadow w-full overflow-hidden rounded-sm lg:rounded-lg"
                  src={imageMobile?.src || imageDesktop?.src}
                  alt={imageMobile?.alt || imageDesktop?.alt}
                />
              </picture>
            )}
          </div>
        </div>
      </div>
    </Section>
  );
}

Media.displayName = 'Media';
Media.Schema = Schema;
