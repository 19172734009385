import React from 'react';
import { Icons } from '../../snippets/Icons';

import { ToolTip } from '../../snippets/tooltip';

export function ComparePlansRow({ rows }) {
  return rows?.map((row, index) => {
    const { type, primaryKey, values } = { ...row };
    const {
      text: keyLabel,
      isBeta,
      isComingSoon,
      description,
    } = { ...primaryKey };

    return (
      <tr
        className={`${type === 'subheader' ? '' : 'border-t border-grey-900'}`}
        key={index}
      >
        <td className={`py-4 text-xs ${type === 'subheader' ? 'pt-12' : ''}`}>
          <div className="flex items-center gap-x-2">
            <span
              className={`${
                type === 'subheader'
                  ? 'sub-heading whitespace-nowrap font-semibold text-primary-green-500'
                  : ''
              }`}
            >
              {keyLabel}
            </span>
            {(isComingSoon || isBeta) && (
              <span className="rounded-2xl border border-gray-900 bg-[rgba(22,23,25,0.35)] py-1 px-1.5 text-2xs text-primary-green-500">
                {isComingSoon ? 'Coming Soon' : 'Beta'}
              </span>
            )}
            {description && (
              <ToolTip description={description} text={keyLabel} />
            )}
          </div>
        </td>
        {values?.map(({ enabled, customText }, valueIndex) => {
          return (
            <td className="text-center" key={valueIndex}>
              {/* eslint-disable-next-line no-nested-ternary */}
              {customText ? (
                <p className="text-xs">{customText}</p>
              ) : enabled ? (
                <div className="flex justify-center">
                  <Icons icon="greenCircleCheckmark" />
                </div>
              ) : (
                <div className="flex justify-center">
                  <Icons icon="disabled" />
                </div>
              )}
            </td>
          );
        })}
      </tr>
    );
  });
}

ComparePlansRow.displayName = 'ComparePlansRow';
