export function Select(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      {...props}
    >
      <g clipPath="url(#clip0_1731_36823)">
        <path
          d="M14.5846 8.18628H12.0846C11.9266 8.18628 11.7826 8.27528 11.7116 8.41628C11.6416 8.55728 11.6566 8.72628 11.7506 8.85228L13.0006 10.5193C13.0796 10.6243 13.2026 10.6863 13.3336 10.6863C13.4646 10.6863 13.5886 10.6243 13.6666 10.5193L14.9166 8.85228C15.0116 8.72628 15.0266 8.55728 14.9556 8.41628C14.8846 8.27528 14.7406 8.18628 14.5826 8.18628H14.5846Z"
          fill="currentColor"
        />
        <path
          d="M6.12671 11.4542L12.0667 13.6242C12.3167 13.7152 12.3097 14.0722 12.0557 14.1532L9.33671 15.0232L8.46671 17.7422C8.38571 17.9962 8.02871 18.0032 7.93771 17.7532L5.76771 11.8132C5.68571 11.5902 5.90271 11.3732 6.12671 11.4542Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.75 5.43628V10.1163"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.574 12.9363H14.75C15.855 12.9363 16.75 12.0403 16.75 10.9363V7.43628C16.75 6.33228 15.855 5.43628 14.75 5.43628H3.25C2.145 5.43628 1.25 6.33228 1.25 7.43628V10.9363C1.25 12.0403 2.145 12.9363 3.25 12.9363H3.516"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1731_36823">
          <rect
            width="18"
            height="18"
            fill="white"
            transform="translate(0 0.686279)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
