import React from 'react';
import { PlanHeroFeatures } from './PlanHeroFeatures';

import { Link } from '../../snippets/Link';

export function PlanHeroTier({ plan }) {
  const {
    enableBorder,
    borderText,
    heading,
    subheading,
    price,
    unit,
    label,
    features,
    hightlights,
    bonusHightlights,
    button,
  } = {
    ...plan,
  };

  return (
    <div
      className={`transform-[translateZ(0)] flex h-full flex-col rounded-t-2xl ${
        enableBorder ? 'bg-tier-green-gradient' : 'bg-tier-transparent-gradient'
      }`}
    >
      <h5
        className={`flex min-h-[25px] items-center justify-center rounded-t-2xl bg-brand text-xs font-semibold text-black ${
          enableBorder ? 'opacity-100' : 'opacity-0'
        }`}
      >
        {borderText}
      </h5>
      <div className="flex h-full flex-1 flex-col gap-y-6 rounded-2xl bg-black p-4 pb-6">
        <div>
          <h3 className="leading-1 mb-3 text-lg font-semibold tracking-normal">
            {heading}
          </h3>
          <h4 className="text-sm font-normal text-white-700">{subheading}</h4>
        </div>

        <div className="border-t border-b border-gray-900 py-8">
          <div className="mb-6 flex items-end gap-x-1">
            <h3 className="relative top-1 text-5xl text-[44px] font-medium leading-none text-primary-green-500">
              {price}
            </h3>
            {unit && (
              <>
                <span className="text-sm font-normal text-white-700">/</span>
                <span className="text-sm font-normal text-white-700">
                  {unit}
                </span>
              </>
            )}
          </div>

          <div className="mb-6">
            <span className="rounded-2xl border border-gray-900 py-1.5 px-2 text-xs text-brand">
              {label}
            </span>
          </div>

          <PlanHeroFeatures features={features} />
        </div>

        <PlanHeroFeatures features={hightlights} />

        <PlanHeroFeatures features={bonusHightlights} isBonus />

        {button?.link?.text && (
          <div className="mt-auto">
            <Link
              className={`${button?.type}`}
              href={button?.link?.url || ''}
              newTab={button?.link?.newTab}
            >
              {button?.link?.text}
            </Link>
          </div>
        )}
      </div>
    </div>
  );
}

PlanHeroTier.displayName = 'PlanHeroTier';
