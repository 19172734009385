export function Schema() {
  return {
    label: 'Metrics',
    key: 'metrics',
    fields: [
      {
        label: 'Heading',
        name: 'heading',
        component: 'text',
      },
      {
        label: 'Sub-heading',
        name: 'subheading',
        component: 'text',
      },
      {
        label: 'Description',
        name: 'description',
        component: 'textarea',
      },
      {
        label: 'Metrics',
        name: 'metrics',
        component: 'group-list',
        fields: [
          {
            label: 'Value',
            name: 'value',
            component: 'text',
          },
          {
            label: 'Label',
            name: 'label',
            component: 'text',
          },
          {
            label: 'Link',
            name: 'link',
            component: 'link',
          },
        ],
        itemProps: {
          label: '{{item.label}}',
        },
      },
    ],
  };
}
