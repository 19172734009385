import React from 'react';
import Marquee from 'react-fast-marquee';
import { useSettings } from '@backpackjs/storefront';
import { FadeIn } from '../../animations/FadeIn';
import { Schema } from './PlanHero.schema';

import { PlanHeroTier } from './PlanHeroTier';

import { Section } from '../../snippets';

export function PlanHero({ cms }) {
  const { heading, subheading, isBeta, plans } = cms;
  const settings = useSettings();

  return (
    <Section cms={cms}>
      <div className="northern-lights-gradient space-y-7 pt-6 md:pt-20">
        {/* Heading */}
        <FadeIn>
          <div className="mx-auto max-w-3xl px-4 text-center max-md:text-[44px] md:px-0">
            <h1 className="h1 relative inline-block">
              {heading}
              {isBeta && (
                <span className="absolute top-0 -right-[60px] rounded-2xl border border-gray-900 bg-[rgba(22,23,25,0.35)] py-1 px-2.5 font-inter text-sm tracking-normal text-primary-green-500">
                  Beta
                </span>
              )}
            </h1>
          </div>
        </FadeIn>

        {/* Subheading */}
        <FadeIn delayAnimationSeconds={75}>
          <p className="body-1 md:sub-heading mx-auto max-w-3xl px-4 text-center leading-[140%] text-white-600 md:px-0">
            {subheading}
          </p>
        </FadeIn>

        <FadeIn delayAnimationSeconds={150} threshold={0}>
          <div className="mx-auto flex max-w-6xl flex-col justify-center gap-6 px-4 md:order-2 md:flex-row md:pt-[52px]">
            {plans?.map((plan, index) => {
              return (
                <div key={index} className="flex h-auto w-full flex-col">
                  <PlanHeroTier plan={plan} />
                </div>
              );
            })}
          </div>
        </FadeIn>

        <div className="py-6 md:py-10" />

        {/* {settings?.logos?.logoMarquee?.length > 0 && (
        <div className="bg-black py-6 md:py-20">
          <Marquee pauseOnHover autofill className="w-full">
            {settings.logos.logoMarquee.map((marqueeLogo, index) => {
              return (
                <img
                  key={index}
                  className="mx-10 h-7 invert-[50%]"
                  src={marqueeLogo?.imageLogo?.src}
                  alt={marqueeLogo?.imageAlt}
                />
              );
            })}
          </Marquee>
        </div>
      )} */}
      </div>
    </Section>
  );
}

PlanHero.displayName = 'PlanHero';
PlanHero.Schema = Schema;
