import { AlignLeft } from '../icons/alignLeft';
import { ArrowLeft } from '../icons/arrowLeft';
import { ArrowLeftButton } from '../icons/arrowLeftButton';
import { ArrowRight } from '../icons/arrowRight';
import { Atom } from '../icons/atom';
import { Bars } from '../icons/bars';
import { BarsXAxis } from '../icons/barsXAxis';
import { Book } from '../icons/book';
import { Books } from '../icons/books';
import { Boundary } from '../icons/boundary';
import { Brackets } from '../icons/brackets';
import { Broom } from '../icons/broom';
import { Calendar } from '../icons/calendar';
import { CalendarUser } from '../icons/calendarUser';
import { Camera } from '../icons/camera';
import { CaretDown } from '../icons/caretDown';
import { Checkmark } from '../icons/checkmark';
import { ChevronDown } from '../icons/chevronDown';
import { CheckCircleFillLine } from '../icons/checkCircleFillLine';
import { CheckCircleFill } from '../icons/checkCircleFill';
import { Circles } from '../icons/circles';
import { Clock } from '../icons/clock';
import { Components } from '../icons/components';
import { ConnectedBlocks } from '../icons/connectedBlocks';
import { Copy } from '../icons/copy';
import { Dashboard } from '../icons/dashboard';
import { Devices } from '../icons/devices';
import { Disabled } from '../icons/disabled';
import { Document } from '../icons/document';
import { DotWithArrows } from '../icons/dotWithArrows';
import { Edit } from '../icons/edit';
import { Elements } from '../icons/elements';
import { ElementsPlus } from '../icons/elementsPlus';
import { File } from '../icons/file';
import { Globe } from '../icons/globe';
import { GlobeSearch } from '../icons/globeSearch';
import { Graph } from '../icons/graph';
import { GreenCircleCheckmark } from '../icons/greenCircleCheckmark';
import { Grid } from '../icons/grid';
import { GridFill } from '../icons/gridFill';
import { Hamburger } from '../icons/hamburger';
import { Hammer } from '../icons/hammer';
import { Handshake } from '../icons/handshake';
import { HorizontalChart } from '../icons/horizontalChart';
import { Info } from '../icons/info';
import { InfoEmpty } from '../icons/infoEmpty';
import { Key } from '../icons/key';
import { Laptop } from '../icons/laptop';
import { Layers } from '../icons/layers';
import { LayersFilled } from '../icons/layersFilled';
import { Lightning } from '../icons/lightning';
import { LightningSpeed } from '../icons/lightningSpeed';
import { Link } from '../icons/link';
import { LinkedDots } from '../icons/linkedDots';
import { LinkedTriangle } from '../icons/linkedTriangle';
import { Loading } from '../icons/loading';
import { LogoRebrandSquare } from '../icons/logoRebrandSquare';
import { LogoColorRebrandSquare } from '../icons/logoColorRebrandSquare';
import { MagicWandSquare } from '../icons/magicWandSquare';
import { Medal } from '../icons/medal';
import { Modules } from '../icons/modules';
import { Monitor } from '../icons/monitor';
import { Move } from '../icons/move';
import { NewFile } from '../icons/newFile';
import { Palette } from '../icons/palette';
import { Play } from '../icons/play';
import { PlayOverlay } from '../icons/playOverlay';
import { Plus } from '../icons/plus';
import { Refresh } from '../icons/refresh';
import { RefreshDot } from '../icons/refreshDot';
import { Rocket } from '../icons/rocket';
import { Route } from '../icons/route';
import { Samples } from '../icons/samples';
import { Script } from '../icons/script';
import { Select } from '../icons/select';
import { Send } from '../icons/send';
import { Share } from '../icons/share';
import { Shop } from '../icons/shop';
import { Shopify } from '../icons/shopify';
import { ShoppingBag } from '../icons/shoppingBag';
import { ShoppingCart } from '../icons/shoppingCart';
import { Sliders } from '../icons/sliders';
import { Slides } from '../icons/slides';
import { Stack } from '../icons/stack';
import { Stopwatch } from '../icons/stopwatch';
import { Storefront } from '../icons/storefront';
import { Target } from '../icons/target';
import { Terminal } from '../icons/terminal';
import { Toggle } from '../icons/toggle';
import { Toggles } from '../icons/toggles';
import { TwoWay } from '../icons/twoWay';
import { Union } from '../icons/union';
import { UserCheck } from '../icons/userCheck';
import { Users } from '../icons/users';
import { UserStar } from '../icons/userStar';
import { Verified } from '../icons/verified';
import { Vial } from '../icons/vial';
import { Video } from '../icons/video';
import { X } from '../icons/x';

export function Icons({ icon, ...rest }) {
  switch (icon) {
    case 'alignLeft':
      return <AlignLeft {...rest} />;
    case 'arrowLeft':
      return <ArrowLeft {...rest} />;
    case 'arrowLeftButton':
      return <ArrowLeftButton {...rest} />;
    case 'arrowRight':
      return <ArrowRight {...rest} />;
    case 'atom':
      return <Atom {...rest} />;
    case 'bars':
      return <Bars {...rest} />;
    case 'barsXAxis':
      return <BarsXAxis {...rest} />;
    case 'book':
      return <Book {...rest} />;
    case 'books':
      return <Books {...rest} />;
    case 'boundary':
      return <Boundary {...rest} />;
    case 'brackets':
      return <Brackets {...rest} />;
    case 'broom':
      return <Broom {...rest} />;
    case 'calendar':
      return <Calendar {...rest} />;
    case 'calendarUser':
      return <CalendarUser {...rest} />;
    case 'camera':
      return <Camera {...rest} />;
    case 'caretDown':
      return <CaretDown {...rest} />;
    case 'checkCircleFill':
      return <CheckCircleFill {...rest} />;
    case 'checkCircleFillLine':
      return <CheckCircleFillLine {...rest} />;
    case 'checkmark':
      return <Checkmark {...rest} />;
    case 'chevronDown':
      return <ChevronDown {...rest} />;
    case 'circles':
      return <Circles {...rest} />;
    case 'clock':
      return <Clock {...rest} />;
    case 'components':
      return <Components {...rest} />;
    case 'connectedBlocks':
      return <ConnectedBlocks {...rest} />;
    case 'copy':
      return <Copy {...rest} />;
    case 'dashboard':
      return <Dashboard {...rest} />;
    case 'devices':
      return <Devices {...rest} />;
    case 'disabled':
      return <Disabled {...rest} />;
    case 'dotWithArrows':
      return <DotWithArrows {...rest} />;
    case 'document':
      return <Document {...rest} />;
    case 'edit':
      return <Edit {...rest} />;
    case 'elements':
      return <Elements {...rest} />;
    case 'elementsPlus':
      return <ElementsPlus {...rest} />;
    case 'file':
      return <File {...rest} />;
    case 'globe':
      return <Globe {...rest} />;
    case 'globeSearch':
      return <GlobeSearch {...rest} />;
    case 'graph':
      return <Graph {...rest} />;
    case 'greenCircleCheckmark':
      return <GreenCircleCheckmark {...rest} />;
    case 'grid':
      return <Grid {...rest} />;
    case 'gridFill':
      return <GridFill {...rest} />;
    case 'hamburger':
      return <Hamburger {...rest} />;
    case 'hammer':
      return <Hammer {...rest} />;
    case 'handshake':
      return <Handshake {...rest} />;
    case 'horizontalChart':
      return <HorizontalChart {...rest} />;
    case 'info':
      return <Info {...rest} />;
    case 'infoEmpty':
      return <InfoEmpty {...rest} />;
    case 'key':
      return <Key {...rest} />;
    case 'laptop':
      return <Laptop {...rest} />;
    case 'layers':
      return <Layers {...rest} />;
    case 'layersFilled':
      return <LayersFilled {...rest} />;
    case 'lightning':
      return <Lightning {...rest} />;
    case 'lightningSpeed':
      return <LightningSpeed {...rest} />;
    case 'link':
      return <Link {...rest} />;
    case 'linkedDots':
      return <LinkedDots {...rest} />;
    case 'linkedTriangle':
      return <LinkedTriangle {...rest} />;
    case 'loading':
      return <Loading {...rest} />;
    case 'logoRebrandSquare':
      return <LogoRebrandSquare {...rest} />;
    case 'logoColorRebrandSquare':
      return <LogoColorRebrandSquare {...rest} />;
    case 'magicWandSquare':
      return <MagicWandSquare {...rest} />;
    case 'medal':
      return <Medal {...rest} />;
    case 'modules':
      return <Modules {...rest} />;
    case 'monitor':
      return <Monitor {...rest} />;
    case 'move':
      return <Move {...rest} />;
    case 'newFile':
      return <NewFile {...rest} />;
    case 'palette':
      return <Palette {...rest} />;
    case 'play':
      return <Play {...rest} />;
    case 'playOverlay':
      return <PlayOverlay {...rest} />;
    case 'plus':
      return <Plus {...rest} />;
    case 'refresh':
      return <Refresh {...rest} />;
    case 'refreshDot':
      return <RefreshDot {...rest} />;
    case 'rocket':
      return <Rocket {...rest} />;
    case 'route':
      return <Route {...rest} />;
    case 'samples':
      return <Samples {...rest} />;
    case 'select':
      return <Select {...rest} />;
    case 'send':
      return <Send {...rest} />;
    case 'script':
      return <Script {...rest} />;
    case 'share':
      return <Share {...rest} />;
    case 'shop':
      return <Shop {...rest} />;
    case 'shopify':
      return <Shopify {...rest} />;
    case 'shoppingBag':
      return <ShoppingBag {...rest} />;
    case 'shoppingCart':
      return <ShoppingCart {...rest} />;
    case 'sliders':
      return <Sliders {...rest} />;
    case 'slides':
      return <Slides {...rest} />;
    case 'stack':
      return <Stack {...rest} />;
    case 'stopwatch':
      return <Stopwatch {...rest} />;
    case 'storefront':
      return <Storefront {...rest} />;
    case 'target':
      return <Target {...rest} />;
    case 'terminal':
      return <Terminal {...rest} />;
    case 'toggle':
      return <Toggle {...rest} />;
    case 'toggles':
      return <Toggles {...rest} />;
    case 'twoWay':
      return <TwoWay {...rest} />;
    case 'union':
      return <Union {...rest} />;
    case 'userCheck':
      return <UserCheck {...rest} />;
    case 'users':
      return <Users {...rest} />;
    case 'userStar':
      return <UserStar {...rest} />;
    case 'verified':
      return <Verified {...rest} />;
    case 'vial':
      return <Vial {...rest} />;
    case 'video':
      return <Video {...rest} />;
    case 'x':
      return <X {...rest} />;
    default:
      return null;
  }
}
