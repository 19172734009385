import NextLink from 'next/link';
import { useSettings } from '@backpackjs/storefront';
import { Icons } from './Icons';

import { LogoColorRebrandSquare } from '../icons/logoColorRebrandSquare';

export function Footer() {
  const settings = useSettings();

  return (
    <footer className="mx-4 flex max-w-7xl flex-col gap-9 py-12 md:gap-16 md:pt-16 xl:mx-[80px] 2xl:mx-auto">
      <div className="flex flex-col gap-9 lg:flex-row">
        <div className="pb-8">
          {/* Pack Logo  */}
          <LogoColorRebrandSquare className="h-[60px] w-[60px]" />
        </div>

        <nav className="grid grow grid-cols-2 gap-8 md:grid-cols-4">
          {settings?.footer?.menu?.menuItems?.map(({ title, links }, index) => {
            return (
              <div className="footer__menu" key={index}>
                <p className="mb-4 text-sm font-semibold text-brand">
                  {title || 'Menu'}
                </p>
                <ul className="space-y-3 text-base font-semibold text-white-700">
                  {links?.map(({ link }, subLinkIndex) => {
                    return (
                      <li key={subLinkIndex}>
                        <NextLink href={link?.url || '#'}>
                          {link?.text || 'Link'}
                        </NextLink>
                      </li>
                    );
                  })}
                </ul>
              </div>
            );
          })}
        </nav>

        {settings?.footer?.buttons?.length > 0 && (
          <div className="flex shrink-0 flex-col gap-3">
            {settings.footer.buttons.map((button, index) => {
              return (
                <NextLink
                  key={`footerButton-${index}`}
                  href={button.link.url}
                  passHref
                >
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a
                    className={`${button.type} w-full lg:w-80`}
                    target={button.link.newTab ? '_blank' : '_self'}
                  >
                    {button.icon && <Icons icon={button.icon} />}
                    {button.link.text}
                  </a>
                </NextLink>
              );
            })}
          </div>
        )}
      </div>

      <div className="flex flex-col-reverse items-center gap-4 border-t border-[#0A0A0A] pt-8 text-white-700 md:flex-row md:justify-between">
        <p>
          © {new Date().getFullYear()}{' '}
          {settings?.footer?.legal?.copyrightNotice}
        </p>

        <nav>
          <ul className="flex items-center gap-4">
            {settings?.footer?.legal?.links?.map(({ link }, index) => {
              return (
                <li key={index}>
                  <NextLink href={link?.url || '#'}>
                    {link?.text || 'Link'}
                  </NextLink>
                </li>
              );
            })}
          </ul>
        </nav>
      </div>
    </footer>
  );
}
