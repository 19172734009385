export function Schema({ blog }) {
  if (!blog) return null;

  return {
    label: 'Blog Navigation',
    key: 'blog-navigation',
    fields: [
      {
        label: 'Links',
        name: 'links',
        component: 'group-list',
        fields: [
          {
            label: 'Link',
            name: 'link',
            component: 'link',
          },
          {
            label: 'Is featured link?',
            name: 'isFeatured',
            component: 'toggle',
            defaultValue: false,
          },
        ],
        itemProps: {
          label: '{{item.link.text}}',
        },
      },
    ],
  };
}
