export function Edit(props) {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="Frame" clipPath="url(#clip0_2410_69378)">
        <g id="Group">
          <path
            id="Vector"
            d="M16.3333 2.22223H4.66664C2.98108 2.22223 1.61108 3.59223 1.61108 5.27778V14.7222C1.61108 16.4078 2.98108 17.7778 4.66664 17.7778H10.2733C10.7333 17.7778 11.1066 17.4045 11.1066 16.9445C11.1066 16.4845 10.7333 16.1111 10.2733 16.1111H4.66664C3.90108 16.1111 3.27775 15.4878 3.27775 14.7222V8.8889H17.7222V9.16667C17.7222 9.62667 18.0955 10 18.5555 10C19.0155 10 19.3889 9.62667 19.3889 9.16667V5.27778C19.3889 3.59223 18.0189 2.22223 16.3333 2.22223ZM4.94442 6.66667C4.33108 6.66667 3.83331 6.1689 3.83331 5.55556C3.83331 4.94223 4.33108 4.44445 4.94442 4.44445C5.55775 4.44445 6.05553 4.94223 6.05553 5.55556C6.05553 6.1689 5.55775 6.66667 4.94442 6.66667ZM8.27775 6.66667C7.66442 6.66667 7.16664 6.1689 7.16664 5.55556C7.16664 4.94223 7.66442 4.44445 8.27775 4.44445C8.89108 4.44445 9.38886 4.94223 9.38886 5.55556C9.38886 6.1689 8.89108 6.66667 8.27775 6.66667Z"
            fill="currentColor"
          />
          <path
            id="Vector_2"
            d="M19.5167 11.5833C18.8923 10.9578 17.8089 10.9533 17.1845 11.5767L13.0812 15.68C12.8989 15.8622 12.7567 16.0778 12.6589 16.3222L11.9589 18.0556C11.8345 18.3633 11.9045 18.7167 12.1389 18.9533C12.2989 19.1156 12.5134 19.2011 12.7323 19.2011C12.8345 19.2011 12.9367 19.1822 13.0345 19.1445L14.7167 18.4889C14.9701 18.39 15.1945 18.2433 15.3856 18.0522L19.5256 13.9122C19.8356 13.5989 20.0067 13.1856 20.0045 12.7467C20.0023 12.3067 19.8289 11.8933 19.5178 11.5845L19.5167 11.5833Z"
            fill="currentColor"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_2410_69378">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
