import { useState, useEffect, useRef } from 'react';

export function ToggleButtons({
  onClick = () => {},
  contextState,
  setContextState,
  contexts,
}) {
  const toggleButtonsRef = useRef(null);
  const [isReady, setIsReady] = useState(false);
  const [activeToggleButtonWidth, setActiveToggleButtonWidth] = useState(0);
  const [activeToggleButtonOffsetLeft, setActiveToggleButtonOffsetLeft] =
    useState(0);

  useEffect(() => {
    function handleResize() {
      const activeNavItemButton = toggleButtonsRef.current?.querySelector(
        `[data-toggle-button="${contextState}"]`
      );

      const activeItem = activeNavItemButton;
      setTimeout(
        () => {
          if (activeItem) {
            const { offsetWidth: width, offsetLeft } = activeItem;
            setActiveToggleButtonWidth(width);
            setActiveToggleButtonOffsetLeft(offsetLeft);
          } else {
            setActiveToggleButtonWidth(0);
          }
        },
        isReady ? 0 : 150
      );
    }
    handleResize();
    setIsReady(true);

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [contextState, isReady]);

  return (
    <div
      className="black-02-gradient hover:black-05-gradient group relative flex items-center justify-center overflow-hidden rounded-full border border-[rgba(255,255,255,0.05)] p-1 transition-colors duration-300"
      ref={toggleButtonsRef}
    >
      <div
        className={`active-toggle-button ${contexts[contextState]?.contextHeadingActiveBgColor}`}
        style={{
          width: activeToggleButtonWidth,
          transform: `translateX(${activeToggleButtonOffsetLeft}px)`,
        }}
      />

      {contexts?.map((context, index) => {
        const isActiveIndex0 = index === 0 && contextState === 0;
        const isActiveIndex1 = index === 1 && contextState === 1;

        return (
          <button
            key={`context-${index}`}
            data-toggle-button={index}
            type="button"
            className={`z-[1] rounded-full py-2 px-4 text-sm no-underline transition-colors ${
              isActiveIndex0 || isActiveIndex1
                ? context.contextHeadingActiveTextColor
                : 'text-[rgba(255,255,255,0.8)]'
            } `}
            onClick={() => {
              onClick();
              setContextState(index);
            }}
          >
            {context.contextHeading}
          </button>
        );
      })}
    </div>
  );
}

ToggleButtons.displayName = 'ToggleButtons';
