export function Broom(props) {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="broom 1">
        <g id="Group">
          <path
            id="Vector"
            d="M12.0289 9.02667C11.8155 9.02667 11.6022 8.94556 11.44 8.78223C11.1144 8.45667 11.1144 7.9289 11.44 7.60334L17.9666 1.07778C18.2922 0.752229 18.82 0.752229 19.1455 1.07778C19.4711 1.40334 19.4711 1.93112 19.1455 2.25667L12.6189 8.78334C12.4566 8.94556 12.2433 9.02779 12.03 9.02779L12.0289 9.02667Z"
            fill="currentColor"
          />
          <path
            id="Vector_2"
            d="M9.99081 9.97992C11.1508 11.1955 12.523 12.2099 14.013 13.0044C14.1964 12.461 14.3153 11.8944 14.3286 11.3132C14.3608 9.87436 13.773 8.61325 12.5786 7.56547C11.1875 6.34547 9.31081 6.0988 7.63525 6.73992C8.2897 7.91436 9.07192 9.01658 9.99081 9.97992Z"
            fill="currentColor"
          />
          <path
            id="Vector_3"
            d="M8.7866 11.1311C7.76104 10.0567 6.90549 8.85889 6.19104 7.57556C6.01882 7.71334 5.84215 7.84445 5.68215 8.00667C4.27771 9.42889 3.54882 10.12 2.33215 10.2856C1.88771 10.3467 1.57104 10.7489 1.61549 11.1945C1.98993 14.9145 4.16215 17.6911 7.4266 18.62C7.69771 18.6978 7.9766 18.7356 8.25326 18.7356C8.9466 18.7356 9.63215 18.5 10.191 18.0533C10.6344 17.6989 12.2333 16.3445 13.3099 14.5189C11.6333 13.6345 10.0899 12.4978 8.7866 11.1311Z"
            fill="currentColor"
          />
        </g>
      </g>
    </svg>
  );
}
