import { IconSettings } from '../../settings/icons';

export function Schema() {
  return {
    label: 'Testimonial Block',
    key: 'testimonial-block',
    fields: [
      {
        label: 'Contexts',
        name: 'contexts',
        component: 'group-list',
        itemProps: {
          label: '{{item.contextHeading}}',
        },
        validate: {
          maxItems: 2,
        },
        fields: [
          {
            label: 'Context Heading',
            name: 'contextHeading',
            component: 'text',
          },
          {
            label: 'Context Heading - Active Text Color',
            name: 'contextHeadingActiveTextColor',
            component: 'select',
            options: [
              {
                label: 'Brand',
                value: 'text-brand',
              },
              {
                label: 'Black',
                value: 'text-black',
              },
              {
                label: 'White',
                value: 'text-[rgba(255,255,255,0.8)]',
              },
            ],
            defaultValue: 'text-black',
          },
          {
            label: 'Context Heading - Active Bg Color',
            name: 'contextHeadingActiveBgColor',
            component: 'select',
            options: [
              {
                label: 'Brand',
                value: 'bg-brand',
              },
              {
                label: 'Black',
                value: 'bg-black',
              },
            ],
            defaultValue: 'bg-brand',
          },
          {
            label: 'Heading',
            name: 'heading',
            component: 'text',
          },
          {
            label: 'Logos Marquee',
            name: 'logoMarquee',
            component: 'group-list',
            itemProps: {
              label: '{{item.imageAlt}}',
            },
            fields: [
              {
                label: 'Logo',
                name: 'imageLogo',
                component: 'image',
              },
              {
                label: 'Image Alt Text',
                name: 'imageAlt',
                component: 'text',
              },
            ],
          },
          {
            label: 'Testimonials',
            name: 'testimonials',
            component: 'group-list',
            fields: [
              {
                label: 'Logo',
                name: 'logo',
                component: 'image',
              },
              {
                label: 'Logo Alt',
                name: 'logoAlt',
                component: 'text',
              },
              {
                label: 'Logo Height',
                name: 'logoHeight',
                component: 'number',
                defaultValue: 30,
              },
              {
                label: 'Background Image',
                name: 'backgroundImage',
                component: 'image',
              },
              {
                label: 'Quote',
                name: 'quote',
                component: 'textarea',
              },
              {
                label: 'Author',
                name: 'author',
                component: 'text',
              },
              {
                label: 'Company',
                name: 'company',
                component: 'text',
              },
            ],
            validate: {
              maxItems: 3,
            },
            itemProps: {
              label: '{{item.company}}',
            },
          },
        ],
        defaultValue: [
          {
            contextHeading: 'Brands',
            heading: 'Great for brands, great for agencies.',
          },
          {
            contextHeading: 'Agencies',
            heading: 'Great for brands, great for agencies.',
          },
        ],
      },
      {
        label: 'Buttons',
        name: 'buttons',
        component: 'group-list',
        fields: [
          {
            label: 'Type',
            name: 'type',
            component: 'select',
            options: [
              { label: 'Primary', value: 'btn-primary' },
              { label: 'Outline', value: 'btn-outline-light' },
            ],
          },
          {
            label: 'Link',
            name: 'link',
            component: 'link',
          },
          IconSettings,
        ],
        itemProps: {
          label: '{{item.link.text}}',
        },
        validate: {
          maxItems: 2,
        },
        defaultItem: {
          type: 'btn-primary',
          link: { text: 'Get Started', url: '' },
          icon: '',
        },
        defaultValue: [
          {
            type: 'btn-primary',
            link: { text: 'Get Started', url: '' },
            icon: '',
          },
          {
            type: 'btn-outline',
            link: { text: 'Book a demo', url: '' },
            icon: 'clock',
          },
        ],
      },
    ],
  };
}
