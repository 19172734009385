export function Layers(props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.6954 2.31464C11.8893 2.22845 12.1107 2.22845 12.3046 2.31464L21.3046 6.31464C21.5755 6.43502 21.75 6.70361 21.75 7C21.75 7.29639 21.5755 7.56498 21.3046 7.68536L12.3046 11.6854C12.1107 11.7715 11.8893 11.7715 11.6954 11.6854L2.6954 7.68536C2.42455 7.56498 2.25 7.29639 2.25 7C2.25 6.70361 2.42455 6.43502 2.6954 6.31464L11.6954 2.31464ZM4.84666 7L12 10.1793L19.1533 7L12 3.82074L4.84666 7ZM2.31465 11.6954C2.48287 11.3169 2.92609 11.1464 3.30461 11.3146L12 15.1793L20.6954 11.3146C21.0739 11.1464 21.5171 11.3169 21.6854 11.6954C21.8536 12.0739 21.6831 12.5171 21.3046 12.6854L12.3046 16.6854C12.1107 16.7715 11.8893 16.7715 11.6954 16.6854L2.6954 12.6854C2.31689 12.5171 2.14642 12.0739 2.31465 11.6954ZM3.30461 16.3146C2.92609 16.1464 2.48287 16.3169 2.31465 16.6954C2.14642 17.0739 2.31689 17.5171 2.6954 17.6854L11.6954 21.6854C11.8893 21.7715 12.1107 21.7715 12.3046 21.6854L21.3046 17.6854C21.6831 17.5171 21.8536 17.0739 21.6854 16.6954C21.5171 16.3169 21.0739 16.1464 20.6954 16.3146L12 20.1793L3.30461 16.3146Z"
        fill="currentColor"
      />
    </svg>
  );
}
