export function Schema() {
  return {
    label: 'Contact Form',
    key: 'contact-form',
    fields: [
      {
        label: 'Heading',
        name: 'heading',
        component: 'text',
        defaultValue: 'Book a Call',
      },
      {
        label: 'Description',
        name: 'description',
        component: 'textarea',
        defaultValue:
          "We're excited to meet with you. Book some time with our ecommerce experts.",
      },
      {
        label: 'Image',
        name: 'image',
        component: 'image',
      },
      {
        label: 'Image Alt',
        name: 'imageAlt',
        component: 'text',
      },
      {
        label: 'Enable Max Width',
        name: 'enableMaxWidth',
        description: 'Adds container class',
        component: 'toggle',
        defaultValue: false,
      },
    ],
  };
}
