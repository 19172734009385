import { useEffect } from 'react';
import { getCalApi } from '@calcom/embed-react';
import { sendGTMEvent } from '@next/third-parties/google';

import { Schema } from './FeatureTimeline.schema';
import { Icons } from '../../snippets/Icons';

import { FeatureTimelineItem } from './FeatureTimelineItem';
import { FadeIn } from '../../animations/FadeIn';

import { Link } from '../../snippets/Link';
import { Section } from '../../snippets';

export function FeatureTimeline({ cms }) {
  const { heading, items, buttons } = cms;

  useEffect(() => {
    (async function () {
      const cal = await getCalApi();
      cal('ui', {
        theme: 'dark',
        styles: {
          branding: { brandColor: '#000000' },
        },
      });

      cal('on', {
        action: 'bookingSuccessful',
        callback: (e) => {
          // `data` is properties for the event.
          // `type` is the name of the action
          // `namespace` is the Cal namespace for which the event is fired

          const { data } = e.detail;

          sendGTMEvent({
            event: 'calendar_booking_successful',
            bookingId: data?.booking?.uid,
          });
        },
      });
    })();
  }, []);

  return (
    <Section cms={cms}>
      <div className="bg-black py-16">
        {heading ? (
          <FadeIn>
            <h2 className="mb-12 px-4 text-center selection:bg-brand md:mx-auto md:mb-[60px] md:max-w-3xl">
              {heading}
            </h2>
          </FadeIn>
        ) : null}

        {items?.length > 0 && (
          <div className="relative mb-12 flex flex-col md:mb-[60px]">
            {items.map((item, index) => {
              return (
                <FeatureTimelineItem
                  key={`timeline-item-${index}`}
                  index={index}
                  item={item}
                  items={items}
                />
              );
            })}
          </div>
        )}

        {buttons?.length > 0 && (
          <div className="mb-9 flex flex-col-reverse gap-3 px-4 md:order-1 md:mt-0 md:mb-[60px] md:flex-row md:justify-center md:px-0">
            {buttons.map((button, index) => {
              if (button?.scheduleLink?.calId) {
                return (
                  <button
                    type="button"
                    data-cal-link={button?.scheduleLink?.calId}
                    data-cal-config='{"theme":"dark"}'
                    className={button.type}
                  >
                    {button.icon && <Icons icon={button.icon} />}
                    {button?.scheduleLink?.buttonLabel}
                  </button>
                );
              }
              if (button?.link) {
                return (
                  <FadeIn delayAnimationSeconds={index * 50}>
                    <Link
                      className={button.type}
                      key={`featureTimelineButton-${index}`}
                      href={button?.link.url}
                      newTab={button?.link?.newTab}
                    >
                      {button.icon && <Icons icon={button.icon} />}
                      {button.link.text}
                    </Link>
                  </FadeIn>
                );
              }

              return null;
            })}
          </div>
        )}
      </div>
    </Section>
  );
}

FeatureTimeline.displayName = 'FeatureTimeline';
FeatureTimeline.Schema = Schema;
