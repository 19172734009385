export function Camera(props) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="SVG">
        <path
          id="Vector"
          d="M15.3334 4.6665L10.6667 7.99984L15.3334 11.3332V4.6665Z"
          stroke="currentColor"
          strokeWidth="1.33333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_2"
          d="M9.33342 3.3335H2.00008C1.2637 3.3335 0.666748 3.93045 0.666748 4.66683V11.3335C0.666748 12.0699 1.2637 12.6668 2.00008 12.6668H9.33342C10.0698 12.6668 10.6667 12.0699 10.6667 11.3335V4.66683C10.6667 3.93045 10.0698 3.3335 9.33342 3.3335Z"
          stroke="currentColor"
          strokeWidth="1.33333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}
