export function Palette(props) {
  return (
    <svg
      width="33"
      height="32"
      viewBox="0 0 33 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="Frame">
        <g id="Group">
          <path
            id="Vector"
            d="M26.4716 5.85847C23.7303 3.16318 20.0976 1.71696 16.2563 1.77954C8.86397 1.90451 2.59837 8.01136 2.2901 15.3932C1.97579 22.9557 7.61739 29.441 15.1347 30.1571C15.2432 30.1676 15.3518 30.1728 15.4593 30.1728C16.5445 30.1728 17.593 29.6572 18.3152 28.7543C19.2595 27.5737 19.4073 25.9513 18.691 24.6206L18.2682 23.8368C18.0026 23.3429 18.1962 22.9157 18.2926 22.7561C18.3881 22.5955 18.672 22.2222 19.2327 22.2222H24.5001C27.9306 22.2222 30.7223 19.4306 30.7223 16C30.7223 12.1554 29.2129 8.55376 26.4716 5.85847ZM8.05579 18.2222C6.82841 18.2222 5.83357 17.2274 5.83357 16C5.83357 14.7726 6.82841 13.7778 8.05579 13.7778C9.28317 13.7778 10.278 14.7726 10.278 16C10.278 17.2274 9.28317 18.2222 8.05579 18.2222ZM12.1004 11.6002C11.2325 12.4677 9.82557 12.4677 8.95766 11.6002C8.08975 10.7321 8.08975 9.32549 8.95766 8.4574C9.82557 7.58931 11.2325 7.58931 12.1004 8.4574C12.9683 9.32549 12.9683 10.7321 12.1004 11.6002ZM16.5002 9.77776C15.2729 9.77776 14.278 8.78291 14.278 7.55554C14.278 6.32816 15.2729 5.33331 16.5002 5.33331C17.7276 5.33331 18.7225 6.32816 18.7225 7.55554C18.7225 8.78291 17.7276 9.77776 16.5002 9.77776ZM24.0426 11.6002C23.1747 12.4677 21.7678 12.4677 20.8999 11.6002C20.032 10.7321 20.032 9.32549 20.8999 8.4574C21.7678 7.58931 23.1747 7.58931 24.0426 8.4574C24.9105 9.32549 24.9105 10.7321 24.0426 11.6002Z"
            fill="currentColor"
          />
        </g>
      </g>
    </svg>
  );
}
