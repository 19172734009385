export function Monitor(props) {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 2.75H7.94513C6.57754 2.74998 5.47522 2.74996 4.60825 2.86652C3.70814 2.98754 2.95027 3.24643 2.34835 3.84835C1.74643 4.45027 1.48754 5.20814 1.36652 6.10825C1.24996 6.97522 1.24998 8.07754 1.25 9.44513V9.5V11.5V11.5549C1.24998 12.9225 1.24996 14.0248 1.36652 14.8918C1.48754 15.7919 1.74643 16.5497 2.34835 17.1517C2.95027 17.7536 3.70814 18.0125 4.60825 18.1335C5.47522 18.25 6.57754 18.25 7.94512 18.25H7.94513H8H11.25V20.75H9C8.58579 20.75 8.25 21.0858 8.25 21.5C8.25 21.9142 8.58579 22.25 9 22.25H12H15C15.4142 22.25 15.75 21.9142 15.75 21.5C15.75 21.0858 15.4142 20.75 15 20.75H12.75V18.25H16H16.0549H16.0549C17.4225 18.25 18.5248 18.25 19.3918 18.1335C20.2919 18.0125 21.0497 17.7536 21.6517 17.1517C22.2536 16.5497 22.5125 15.7919 22.6335 14.8918C22.75 14.0248 22.75 12.9225 22.75 11.5549V11.5V9.5V9.44513C22.75 8.07754 22.75 6.97522 22.6335 6.10825C22.5125 5.20814 22.2536 4.45027 21.6517 3.84835C21.0497 3.24643 20.2919 2.98754 19.3918 2.86652C18.5248 2.74996 17.4225 2.74998 16.0549 2.75H16H8ZM12 16.75H16C17.4354 16.75 18.4365 16.7484 19.1919 16.6469C19.9257 16.5482 20.3142 16.3678 20.591 16.091C20.8678 15.8142 21.0482 15.4257 21.1469 14.6919C21.2484 13.9365 21.25 12.9354 21.25 11.5V9.5C21.25 8.06458 21.2484 7.06347 21.1469 6.30812C21.0482 5.57435 20.8678 5.18577 20.591 4.90901C20.3142 4.63225 19.9257 4.4518 19.1919 4.35315C18.4365 4.25159 17.4354 4.25 16 4.25H8C6.56458 4.25 5.56347 4.25159 4.80812 4.35315C4.07435 4.4518 3.68577 4.63225 3.40901 4.90901C3.13225 5.18577 2.9518 5.57435 2.85315 6.30812C2.75159 7.06347 2.75 8.06458 2.75 9.5V11.5C2.75 12.9354 2.75159 13.9365 2.85315 14.6919C2.9518 15.4257 3.13225 15.8142 3.40901 16.091C3.68577 16.3678 4.07435 16.5482 4.80812 16.6469C5.56347 16.7484 6.56458 16.75 8 16.75H12Z"
        fill="currentColor"
      />
    </svg>
  );
}
