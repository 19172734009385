export function Schema() {
  return {
    label: 'Main Hero',
    key: 'main-hero',
    fields: [
      {
        label: 'Hero Contexts',
        name: 'heroContexts',
        component: 'group-list',
        itemProps: {
          label: '{{item.contextHeading}}',
        },
        fields: [
          {
            label: 'Context Heading',
            name: 'contextHeading',
            component: 'text',
          },
          {
            label: 'Context Heading - Active Text Color',
            name: 'contextHeadingActiveTextColor',
            component: 'select',
            options: [
              {
                label: 'Brand',
                value: 'text-brand',
              },
              {
                label: 'Black',
                value: 'text-black',
              },
              {
                label: 'White',
                value: 'text-[rgba(255,255,255,0.8)]',
              },
            ],
            defaultValue: 'text-black',
          },
          {
            label: 'Context Heading - Active Bg Color',
            name: 'contextHeadingActiveBgColor',
            component: 'select',
            options: [
              {
                label: 'Brand',
                value: 'bg-brand',
              },
              {
                label: 'Black',
                value: 'bg-black',
              },
            ],
            defaultValue: 'bg-brand',
          },
          {
            label: 'Heading',
            name: 'heading',
            component: 'text',
            defaultValue: 'Main Hero Heading',
          },
          {
            label: 'Subheading',
            name: 'subheading',
            component: 'textarea',
            defaultValue: 'Main Hero Subheading.',
          },
          {
            label: 'Image (Mobile)',
            name: 'imageMobile',
            component: 'image',
          },
          {
            label: 'Image (Desktop)',
            name: 'imageDesktop',
            component: 'image',
          },
          {
            label: 'Image Alt Text',
            name: 'imageAlt',
            component: 'text',
          },
          {
            label: 'Video URL (Mobile)',
            name: 'videoMobile',
            component: 'text',
          },
          {
            label: 'Video URL (Desktop)',
            name: 'videoDesktop',
            component: 'text',
          },
          {
            label: 'Enable Email Form',
            name: 'enableEmailForm',
            component: 'toggle',
            defaultValue: true,
          },
          {
            label: 'Enable Copy Paste',
            name: 'enableCopyPaste',
            component: 'toggle',
            defaultValue: false,
          },
          {
            label: 'Copy Paste Text',
            name: 'copyPasteText',
            component: 'text',
          },
          {
            label: 'Copy Paste Value',
            name: 'copyPasteValue',
            component: 'text',
          },
        ],
      },
      {
        label: 'Hubspot ID',
        name: 'formId',
        component: 'text',
      },
      {
        label: 'Hubspot Button Label',
        name: 'buttonLabel',
        component: 'text',
        defaultValue: 'Get Started',
      },
      {
        label: 'Form Submission Redirect',
        name: 'formRedirect',
        component: 'text',
        description: 'URL to page. Leave blank to not redirect.',
      },
      {
        label: 'Hide Logo Marquee?',
        name: 'hideLogoMarquee',
        component: 'toggle',
        defaultValue: false,
      },
    ],
  };
}
