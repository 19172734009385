export function Hammer(props) {
  return (
    <svg
      width="32"
      height="33"
      viewBox="0 0 32 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="Frame">
        <g id="Group">
          <path
            id="Vector"
            d="M10.8582 19.5062L10.2253 29.6449C10.2022 30.0129 10.332 30.3738 10.5844 30.6422C10.8368 30.9107 11.1871 31.0618 11.5551 31.0618H20.444C20.812 31.0618 21.164 30.9107 21.4146 30.6422C21.6671 30.3738 21.7968 30.0129 21.7737 29.6449L21.1408 19.5062H10.8582Z"
            fill="currentColor"
          />
          <path
            id="Vector_2"
            d="M29.7151 11.348L21.2707 4.68136C21.036 4.49647 20.7445 4.39514 20.444 4.39514H4.88845C3.1729 4.39514 1.77734 5.7907 1.77734 7.50625V13.7285C1.77734 15.444 3.1729 16.8396 4.88845 16.8396H28.8885C29.6245 16.8396 30.2218 16.2423 30.2218 15.5063V12.3951C30.2218 11.988 30.0351 11.6023 29.7151 11.348Z"
            fill="currentColor"
          />
        </g>
      </g>
    </svg>
  );
}
