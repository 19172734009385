import { Markdown } from '../../snippets/markdown';
import { Schema } from './TwoColumnText.schema';

import { Section } from '../../snippets';

export function TwoColumnText({ cms }) {
  const { heading, subheading, description, content } = cms;

  return (
    <Section cms={cms}>
      <div className="mb-16 flex flex-col gap-16">
        {subheading || heading || description ? (
          <div className="flex flex-col gap-5">
            {subheading || heading ? (
              <div className="flex flex-col gap-3">
                {subheading ? (
                  <p className="text-base font-semibold text-brand">
                    {subheading}
                  </p>
                ) : null}
                {heading ? <h2 className="text-4xl">{heading}</h2> : null}
              </div>
            ) : null}
            {description ? <p className="text-xl">{description}</p> : null}
          </div>
        ) : null}
        {content ? <Markdown isTwoColumns>{content}</Markdown> : null}
      </div>
    </Section>
  );
}

TwoColumnText.displayName = 'TwoColumnText';
TwoColumnText.Schema = Schema;
