import { forwardRef, useCallback } from 'react';
import NextLink from 'next/link';
import PropTypes from 'prop-types';

// nextjs link docs: https://nextjs.org/docs/api-reference/next/link

export const Link = forwardRef(
  (
    {
      'aria-hidden': ariaHidden,
      'aria-label': ariaLabel,
      as,
      children,
      href = '',
      newTab = false,
      prefetch = false,
      query,
      replace = false,
      scroll = true,
      shallow = false,
      ...props
    },
    ref
  ) => {
    const isAnchorLink = href?.startsWith('#') && href?.length > 1;

    const scrollTo = useCallback(() => {
      if (!isAnchorLink) return;
      const element = document.querySelector(
        `[data-comp-id="${href?.replace('#', '')}"]`
      );
      window.scroll({
        behavior: 'smooth',
        left: 0,
        top: element.offsetTop,
      });
    }, [isAnchorLink, href]);

    if (isAnchorLink) {
      return (
        <button
          onClick={(e) => {
            e.preventDefault();
            scrollTo();
          }}
          ref={ref}
          type="button"
          {...props}
        >
          {children}
        </button>
      );
    }

    return href ? (
      <NextLink
        href={typeof query === 'object' ? { pathname: href, query } : href}
        as={as}
        passHref
        prefetch={prefetch}
        replace={replace}
        scroll={scroll}
        shallow={shallow}
      >
        <a
          ref={ref}
          {...(newTab ? { target: '_blank' } : null)}
          aria-hidden={ariaHidden}
          aria-label={ariaLabel}
          {...props}
        >
          {children}
        </a>
      </NextLink>
    ) : (
      <div ref={ref} {...props}>
        {children}
      </div>
    );
  }
);

Link.displayName = 'Link';
Link.propTypes = {
  as: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  href: PropTypes.string,
  newTab: PropTypes.bool,
  prefetch: PropTypes.bool,
  query: PropTypes.object,
  replace: PropTypes.bool,
  scroll: PropTypes.bool,
  shallow: PropTypes.bool,
};
