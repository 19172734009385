export function LogoColorRebrandSquare(props) {
  return (
    <svg
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="Frame 1321317156">
        <rect
          width="44"
          height="44"
          rx="11.2"
          fill="url(#paint0_linear_94_1226585)"
        />
        <g id="extrude-group">
          <g id="side-faces">
            <path
              id="side-face"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M23.2573 29.6467L21.3681 26.7117L20.1299 29.3671L22.019 32.3021L23.2573 29.6467Z"
              fill="#00BE8E"
            />
            <path
              id="side-face_2"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M35.0095 14.6211L31.7988 9.63672L30.5605 12.2922L33.7712 17.2765L35.0095 14.6211Z"
              fill="#00BE8E"
            />
            <path
              id="side-face_3"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M29.9273 8.61506L20.4346 8.60303L19.1963 11.2585L28.689 11.2705L29.9273 8.61506Z"
              fill="#00BE8E"
            />
            <path
              id="side-face_4"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M10.2363 8.64502L8.99805 11.3005L10.2363 8.64502Z"
              fill="#00BE8E"
            />
            <path
              id="side-face_5"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M15.5586 17.6899L17.466 20.6533L16.2277 23.3087L14.3203 20.3454L15.5586 17.6899Z"
              fill="#00BE8E"
            />
            <path
              id="side-face_6"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M17.4658 20.6531H24.586L23.3477 23.3085H16.2275L17.4658 20.6531Z"
              fill="#00BE8E"
            />
            <path
              id="side-face_7"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M13.6504 14.6741L12.4121 17.3295L13.6504 14.6741Z"
              fill="#00BE8E"
            />
            <path
              id="side-face_8"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M10.2375 8.64502C10.1181 8.6471 10.0296 8.70508 9.98924 8.78766L8.75098 11.4431C8.7913 11.3605 8.8798 11.3025 8.99926 11.3005L10.2375 8.64502Z"
              fill="#00BE8E"
            />
            <path
              id="side-face_9"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M13.5434 14.5955C13.5434 14.5955 11.9502 14.6214 10.0029 14.6214L8.76465 17.2769C10.7119 17.2769 12.3051 17.2509 12.3051 17.2509L13.5434 14.5955Z"
              fill="#00BE8E"
            />
            <path
              id="side-face_10"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M10.0029 14.6213C8.58562 14.6213 7.52272 14.6213 6.95213 14.6213L5.71387 17.2768C6.28445 17.2768 7.34736 17.2768 8.76465 17.2768L10.0029 14.6213Z"
              fill="#00BE8E"
            />
            <path
              id="side-face_11"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M6.95376 14.6211C6.83381 14.6211 6.74519 14.6762 6.70506 14.7565L5.4668 17.412C5.50693 17.3317 5.59555 17.2765 5.7155 17.2765L6.95376 14.6211Z"
              fill="#00BE8E"
            />
            <g id="Vector">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M24.5921 32.653C24.7278 32.653 24.8357 32.5763 24.8884 32.4716L23.6502 35.127C23.5974 35.2317 23.4896 35.3084 23.3538 35.3084L24.5921 32.653Z"
                fill="white"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M24.5921 32.653C24.7278 32.653 24.8357 32.5763 24.8884 32.4716L23.6502 35.127C23.5974 35.2317 23.4896 35.3084 23.3538 35.3084L24.5921 32.653Z"
                fill="url(#paint1_linear_94_1226585)"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.2688 23.656L17.3975 31.6282L16.1592 34.2836L11.0305 26.3114L12.2688 23.656Z"
                fill="white"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.2688 23.656L17.3975 31.6282L16.1592 34.2836L11.0305 26.3114L12.2688 23.656Z"
                fill="url(#paint2_linear_94_1226585)"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M19.2704 32.6512L21.6345 32.653L20.3962 35.3084L18.0322 35.3067L19.2704 32.6512Z"
                fill="white"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M19.2704 32.6512L21.6345 32.653L20.3962 35.3084L18.0322 35.3067L19.2704 32.6512Z"
                fill="url(#paint3_linear_94_1226585)"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M21.6345 32.653L24.5921 32.653L23.3538 35.3084L20.3962 35.3084L21.6345 32.653Z"
                fill="white"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M21.6345 32.653L24.5921 32.653L23.3538 35.3084L20.3962 35.3084L21.6345 32.653Z"
                fill="url(#paint4_linear_94_1226585)"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.68785 14.696C6.65081 14.7702 6.65508 14.8659 6.71419 14.9595L5.47592 17.6149C5.41682 17.5213 5.41255 17.4257 5.44959 17.3515L6.68785 14.696Z"
                fill="white"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.68785 14.696C6.65081 14.7702 6.65508 14.8659 6.71419 14.9595L5.47592 17.6149C5.41682 17.5213 5.41255 17.4257 5.44959 17.3515L6.68785 14.696Z"
                fill="url(#paint5_linear_94_1226585)"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.71419 14.9595C7.43978 16.109 9.66307 19.6055 12.2688 23.656L11.0305 26.3114C8.42481 22.261 6.20152 18.7644 5.47592 17.6149L6.71419 14.9595Z"
                fill="white"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.71419 14.9595C7.43978 16.109 9.66307 19.6055 12.2688 23.656L11.0305 26.3114C8.42481 22.261 6.20152 18.7644 5.47592 17.6149L6.71419 14.9595Z"
                fill="url(#paint6_linear_94_1226585)"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17.3975 31.6282C17.8074 32.2654 18.5129 32.6506 19.2704 32.6512L18.0322 35.3067C17.2746 35.3061 16.5691 34.9208 16.1592 34.2836L17.3975 31.6282Z"
                fill="white"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17.3975 31.6282C17.8074 32.2654 18.5129 32.6506 19.2704 32.6512L18.0322 35.3067C17.2746 35.3061 16.5691 34.9208 16.1592 34.2836L17.3975 31.6282Z"
                fill="url(#paint7_linear_94_1226585)"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M24.8884 32.4716C24.9371 32.3749 24.9388 32.2544 24.8721 32.1483L23.6338 34.8038C23.7005 34.9099 23.6988 35.0304 23.6502 35.127L24.8884 32.4716Z"
                fill="white"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M24.8884 32.4716C24.9371 32.3749 24.9388 32.2544 24.8721 32.1483L23.6338 34.8038C23.7005 34.9099 23.6988 35.0304 23.6502 35.127L24.8884 32.4716Z"
                fill="url(#paint8_linear_94_1226585)"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M24.8721 32.1483C24.5207 31.5895 23.9138 30.6292 23.2424 29.5859L22.0041 32.2413C22.6756 33.2846 23.2824 34.245 23.6338 34.8038L24.8721 32.1483Z"
                fill="white"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M24.8721 32.1483C24.5207 31.5895 23.9138 30.6292 23.2424 29.5859L22.0041 32.2413C22.6756 33.2846 23.2824 34.245 23.6338 34.8038L24.8721 32.1483Z"
                fill="url(#paint9_linear_94_1226585)"
              />
            </g>
            <path
              id="side-face_12"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M33.7165 23.7038C32.6703 22.0791 31.8144 20.7282 31.8144 20.7019L30.5762 23.3574C30.5762 23.3836 31.4321 24.7346 32.4782 26.3593L33.7165 23.7038Z"
              fill="#00BE8E"
            />
            <path
              id="side-face_13"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M31.7988 9.63693C31.3889 9.00076 30.6843 8.61595 29.9277 8.61499L28.6895 11.2704C29.4461 11.2714 30.1507 11.6562 30.5605 12.2924L31.7988 9.63693Z"
              fill="#00BE8E"
            />
            <path
              id="side-face_14"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M13.6504 14.6741C13.6504 14.6741 14.5087 16.0601 15.5578 17.6899L14.3195 20.3454C13.2705 18.7156 12.4121 17.3295 12.4121 17.3295L13.6504 14.6741Z"
              fill="#00BE8E"
            />
            <path
              id="side-face_15"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M24.5859 20.6532C28.502 20.6532 31.7061 20.6392 31.7061 20.6221L30.4678 23.2775C30.4678 23.2946 27.2637 23.3087 23.3477 23.3087L24.5859 20.6532Z"
              fill="#00BE8E"
            />
            <g id="Vector_2">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M21.3699 26.7114L28.494 26.6845L27.2557 29.3399L20.1317 29.3669L21.3699 26.7114Z"
                fill="#00BE8E"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M21.3699 26.7114L28.494 26.6845L27.2557 29.3399L20.1317 29.3669L21.3699 26.7114Z"
                fill="url(#paint10_linear_94_1226585)"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M28.494 26.6845L35.0077 26.6599L33.7695 29.3153L27.2557 29.3399L28.494 26.6845Z"
                fill="#00BE8E"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M28.494 26.6845L35.0077 26.6599L33.7695 29.3153L27.2557 29.3399L28.494 26.6845Z"
                fill="url(#paint11_linear_94_1226585)"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M35.2876 26.1445L33.716 23.7035L32.4777 26.359L34.0493 28.8L35.2876 26.1445Z"
                fill="#00BE8E"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M35.2876 26.1445L33.716 23.7035L32.4777 26.359L34.0493 28.8L35.2876 26.1445Z"
                fill="url(#paint12_linear_94_1226585)"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M35.0077 26.6599C35.145 26.6593 35.2539 26.58 35.3066 26.4728L34.0683 29.1283C34.0156 29.2355 33.9068 29.3148 33.7695 29.3153L35.0077 26.6599Z"
                fill="#00BE8E"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M35.0077 26.6599C35.145 26.6593 35.2539 26.58 35.3066 26.4728L34.0683 29.1283C34.0156 29.2355 33.9068 29.3148 33.7695 29.3153L35.0077 26.6599Z"
                fill="url(#paint13_linear_94_1226585)"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M35.3066 26.4728C35.3553 26.3738 35.3562 26.2511 35.2876 26.1445L34.0493 28.8C34.1179 28.9065 34.117 29.0293 34.0683 29.1283L35.3066 26.4728Z"
                fill="#00BE8E"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M35.3066 26.4728C35.3553 26.3738 35.3562 26.2511 35.2876 26.1445L34.0493 28.8C34.1179 28.9065 34.117 29.0293 34.0683 29.1283L35.3066 26.4728Z"
                fill="url(#paint14_linear_94_1226585)"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M35.346 20.6397L38.2676 20.6282L37.0294 23.2837L34.1077 23.2952L35.346 20.6397Z"
                fill="#00BE8E"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M35.346 20.6397L38.2676 20.6282L37.0294 23.2837L34.1077 23.2952L35.346 20.6397Z"
                fill="url(#paint15_linear_94_1226585)"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M38.5474 20.1129L35.01 14.6212L33.7717 17.2766L37.3091 22.7683L38.5474 20.1129Z"
                fill="#00BE8E"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M38.5474 20.1129L35.01 14.6212L33.7717 17.2766L37.3091 22.7683L38.5474 20.1129Z"
                fill="url(#paint16_linear_94_1226585)"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M31.814 20.7016C31.814 20.6742 33.3275 20.6477 35.346 20.6397L34.1077 23.2952C32.0892 23.3031 30.5757 23.3297 30.5757 23.3571L31.814 20.7016Z"
                fill="#00BE8E"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M31.814 20.7016C31.814 20.6742 33.3275 20.6477 35.346 20.6397L34.1077 23.2952C32.0892 23.3031 30.5757 23.3297 30.5757 23.3571L31.814 20.7016Z"
                fill="url(#paint17_linear_94_1226585)"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M38.2676 20.6282C38.4049 20.6277 38.5137 20.5484 38.5664 20.4412L37.3282 23.0967C37.2755 23.2039 37.1667 23.2831 37.0294 23.2837L38.2676 20.6282Z"
                fill="#00BE8E"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M38.2676 20.6282C38.4049 20.6277 38.5137 20.5484 38.5664 20.4412L37.3282 23.0967C37.2755 23.2039 37.1667 23.2831 37.0294 23.2837L38.2676 20.6282Z"
                fill="url(#paint18_linear_94_1226585)"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M38.5664 20.4412C38.6151 20.3422 38.616 20.2195 38.5474 20.1129L37.3091 22.7683C37.3777 22.8749 37.3769 22.9977 37.3282 23.0967L38.5664 20.4412Z"
                fill="#00BE8E"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M38.5664 20.4412C38.6151 20.3422 38.616 20.2195 38.5474 20.1129L37.3091 22.7683C37.3777 22.8749 37.3769 22.9977 37.3282 23.0967L38.5664 20.4412Z"
                fill="url(#paint19_linear_94_1226585)"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.98899 8.7874C9.95178 8.86363 9.95561 8.96083 10.014 9.05438L8.77577 11.7098C8.71735 11.6163 8.71351 11.5191 8.75073 11.4429L9.98899 8.7874Z"
                fill="#00BE8E"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.98899 8.7874C9.95178 8.86363 9.95561 8.96083 10.014 9.05438L8.77577 11.7098C8.71735 11.6163 8.71351 11.5191 8.75073 11.4429L9.98899 8.7874Z"
                fill="url(#paint20_linear_94_1226585)"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.014 9.05438C10.3398 9.576 10.957 10.5516 11.6628 11.6476L10.4246 14.303C9.71877 13.2071 9.10151 12.2315 8.77577 11.7098L10.014 9.05438Z"
                fill="#00BE8E"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.014 9.05438C10.3398 9.576 10.957 10.5516 11.6628 11.6476L10.4246 14.303C9.71877 13.2071 9.10151 12.2315 8.77577 11.7098L10.014 9.05438Z"
                fill="url(#paint21_linear_94_1226585)"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.6628 11.6476C12.6977 13.2545 13.5445 14.5952 13.5445 14.5952L12.3062 17.2507C12.3062 17.2507 11.4595 15.91 10.4246 14.303L11.6628 11.6476Z"
                fill="#00BE8E"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.6628 11.6476C12.6977 13.2545 13.5445 14.5952 13.5445 14.5952L12.3062 17.2507C12.3062 17.2507 11.4595 15.91 10.4246 14.303L11.6628 11.6476Z"
                fill="url(#paint22_linear_94_1226585)"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20.4355 8.60285C13.9965 8.59465 11.2279 8.62755 10.2373 8.64477L8.99901 11.3002C9.98962 11.283 12.7582 11.2501 19.1972 11.2583L20.4355 8.60285Z"
                fill="#00BE8E"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20.4355 8.60285C13.9965 8.59465 11.2279 8.62755 10.2373 8.64477L8.99901 11.3002C9.98962 11.283 12.7582 11.2501 19.1972 11.2583L20.4355 8.60285Z"
                fill="url(#paint23_linear_94_1226585)"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M31.7068 20.6217C31.7068 20.5821 28.0188 14.8413 27.9124 14.7154L26.6741 17.3709C26.7806 17.4968 30.4686 23.2375 30.4686 23.2771L31.7068 20.6217Z"
                fill="#00BE8E"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M31.7068 20.6217C31.7068 20.5821 28.0188 14.8413 27.9124 14.7154L26.6741 17.3709C26.7806 17.4968 30.4686 23.2375 30.4686 23.2771L31.7068 20.6217Z"
                fill="url(#paint24_linear_94_1226585)"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M27.9124 14.7154C27.8373 14.6265 27.429 14.6212 20.7422 14.6212L19.504 17.2766C26.1907 17.2766 26.599 17.282 26.6741 17.3709L27.9124 14.7154Z"
                fill="#00BE8E"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M27.9124 14.7154C27.8373 14.6265 27.429 14.6212 20.7422 14.6212L19.504 17.2766C26.1907 17.2766 26.599 17.282 26.6741 17.3709L27.9124 14.7154Z"
                fill="url(#paint25_linear_94_1226585)"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20.7422 14.6212C16.1677 14.6212 13.6516 14.6737 13.6516 14.6737L12.4133 17.3292C12.4133 17.3292 14.9294 17.2766 19.504 17.2766L20.7422 14.6212Z"
                fill="#00BE8E"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20.7422 14.6212C16.1677 14.6212 13.6516 14.6737 13.6516 14.6737L12.4133 17.3292C12.4133 17.3292 14.9294 17.2766 19.504 17.2766L20.7422 14.6212Z"
                fill="url(#paint26_linear_94_1226585)"
              />
            </g>
          </g>
          <path
            id="cap-face"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.2372 8.64477C10.0075 8.64877 9.89228 8.8595 10.014 9.05439C10.3397 9.57601 10.957 10.5516 11.6628 11.6476C12.6977 13.2545 13.5444 14.5952 13.5444 14.5952C13.5444 14.5952 11.9512 14.6212 10.004 14.6212C8.58666 14.6212 7.52376 14.6212 6.95317 14.6212C6.7225 14.6212 6.60768 14.825 6.73081 15.0201C7.4564 16.1695 9.67967 19.6661 12.2854 23.7166L17.4141 31.6888C17.824 32.326 18.5295 32.7112 19.287 32.7118L21.6511 32.7135H24.6087C24.8698 32.7135 25.0277 32.4299 24.8887 32.2089C24.5373 31.6501 23.9304 30.6898 23.259 29.6465L21.3699 26.7114L28.4939 26.6845L35.0077 26.6599C35.2718 26.6588 35.4304 26.3665 35.2876 26.1446L33.7159 23.7035C32.6698 22.0788 31.8139 20.7279 31.8139 20.7016C31.8139 20.6742 33.3274 20.6477 35.3459 20.6397L38.2676 20.6283C38.5316 20.6272 38.6903 20.3349 38.5473 20.1129L35.0099 14.6212L31.7993 9.63682C31.3894 9.00066 30.6848 8.61584 29.9282 8.61489L20.4355 8.60285C13.9964 8.59465 11.2279 8.62755 10.2372 8.64477ZM13.6516 14.6737C13.6516 14.6737 14.5099 16.0598 15.559 17.6896L17.4664 20.6529H24.5866C28.5027 20.6529 31.7068 20.6388 31.7068 20.6217C31.7068 20.5821 28.0188 14.8413 27.9124 14.7154C27.8373 14.6265 27.4289 14.6212 20.7422 14.6212C16.1676 14.6212 13.6516 14.6737 13.6516 14.6737Z"
            fill="white"
          />
        </g>
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_94_1226585"
          x1="22"
          y1="0"
          x2="22"
          y2="44"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="1" stopColor="#101010" />
          <stop stopColor="#1B1B1B" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_94_1226585"
          x1="8.9504"
          y1="13.6654"
          x2="26.6837"
          y2="30.0223"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00BE8E" />
          <stop offset="0.280919" stopColor="#00BE8E" />
          <stop offset="1" stopColor="#375DFB" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_94_1226585"
          x1="8.9504"
          y1="13.6654"
          x2="26.6837"
          y2="30.0223"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00BE8E" />
          <stop offset="0.280919" stopColor="#00BE8E" />
          <stop offset="1" stopColor="#375DFB" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_94_1226585"
          x1="8.9504"
          y1="13.6654"
          x2="26.6837"
          y2="30.0223"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00BE8E" />
          <stop offset="0.280919" stopColor="#00BE8E" />
          <stop offset="1" stopColor="#375DFB" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_94_1226585"
          x1="8.9504"
          y1="13.6654"
          x2="26.6837"
          y2="30.0223"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00BE8E" />
          <stop offset="0.280919" stopColor="#00BE8E" />
          <stop offset="1" stopColor="#375DFB" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_94_1226585"
          x1="8.9504"
          y1="13.6654"
          x2="26.6837"
          y2="30.0223"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00BE8E" />
          <stop offset="0.280919" stopColor="#00BE8E" />
          <stop offset="1" stopColor="#375DFB" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_94_1226585"
          x1="8.9504"
          y1="13.6654"
          x2="26.6837"
          y2="30.0223"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00BE8E" />
          <stop offset="0.280919" stopColor="#00BE8E" />
          <stop offset="1" stopColor="#375DFB" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_94_1226585"
          x1="8.9504"
          y1="13.6654"
          x2="26.6837"
          y2="30.0223"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00BE8E" />
          <stop offset="0.280919" stopColor="#00BE8E" />
          <stop offset="1" stopColor="#375DFB" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_94_1226585"
          x1="8.9504"
          y1="13.6654"
          x2="26.6837"
          y2="30.0223"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00BE8E" />
          <stop offset="0.280919" stopColor="#00BE8E" />
          <stop offset="1" stopColor="#375DFB" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_94_1226585"
          x1="8.9504"
          y1="13.6654"
          x2="26.6837"
          y2="30.0223"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00BE8E" />
          <stop offset="0.280919" stopColor="#00BE8E" />
          <stop offset="1" stopColor="#375DFB" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_94_1226585"
          x1="14.127"
          y1="7.5633"
          x2="31.0701"
          y2="31.3321"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00BE8E" />
          <stop offset="0.280919" stopColor="#00BE8E" />
          <stop offset="1" stopColor="#375DFB" />
        </linearGradient>
        <linearGradient
          id="paint11_linear_94_1226585"
          x1="14.127"
          y1="7.5633"
          x2="31.0701"
          y2="31.3321"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00BE8E" />
          <stop offset="0.280919" stopColor="#00BE8E" />
          <stop offset="1" stopColor="#375DFB" />
        </linearGradient>
        <linearGradient
          id="paint12_linear_94_1226585"
          x1="14.127"
          y1="7.5633"
          x2="31.0701"
          y2="31.3321"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00BE8E" />
          <stop offset="0.280919" stopColor="#00BE8E" />
          <stop offset="1" stopColor="#375DFB" />
        </linearGradient>
        <linearGradient
          id="paint13_linear_94_1226585"
          x1="14.127"
          y1="7.5633"
          x2="31.0701"
          y2="31.3321"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00BE8E" />
          <stop offset="0.280919" stopColor="#00BE8E" />
          <stop offset="1" stopColor="#375DFB" />
        </linearGradient>
        <linearGradient
          id="paint14_linear_94_1226585"
          x1="14.127"
          y1="7.5633"
          x2="31.0701"
          y2="31.3321"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00BE8E" />
          <stop offset="0.280919" stopColor="#00BE8E" />
          <stop offset="1" stopColor="#375DFB" />
        </linearGradient>
        <linearGradient
          id="paint15_linear_94_1226585"
          x1="14.127"
          y1="7.5633"
          x2="31.0701"
          y2="31.3321"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00BE8E" />
          <stop offset="0.280919" stopColor="#00BE8E" />
          <stop offset="1" stopColor="#375DFB" />
        </linearGradient>
        <linearGradient
          id="paint16_linear_94_1226585"
          x1="14.127"
          y1="7.5633"
          x2="31.0701"
          y2="31.3321"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00BE8E" />
          <stop offset="0.280919" stopColor="#00BE8E" />
          <stop offset="1" stopColor="#375DFB" />
        </linearGradient>
        <linearGradient
          id="paint17_linear_94_1226585"
          x1="14.127"
          y1="7.5633"
          x2="31.0701"
          y2="31.3321"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00BE8E" />
          <stop offset="0.280919" stopColor="#00BE8E" />
          <stop offset="1" stopColor="#375DFB" />
        </linearGradient>
        <linearGradient
          id="paint18_linear_94_1226585"
          x1="14.127"
          y1="7.5633"
          x2="31.0701"
          y2="31.3321"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00BE8E" />
          <stop offset="0.280919" stopColor="#00BE8E" />
          <stop offset="1" stopColor="#375DFB" />
        </linearGradient>
        <linearGradient
          id="paint19_linear_94_1226585"
          x1="14.127"
          y1="7.5633"
          x2="31.0701"
          y2="31.3321"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00BE8E" />
          <stop offset="0.280919" stopColor="#00BE8E" />
          <stop offset="1" stopColor="#375DFB" />
        </linearGradient>
        <linearGradient
          id="paint20_linear_94_1226585"
          x1="14.127"
          y1="7.5633"
          x2="31.0701"
          y2="31.3321"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00BE8E" />
          <stop offset="0.280919" stopColor="#00BE8E" />
          <stop offset="1" stopColor="#375DFB" />
        </linearGradient>
        <linearGradient
          id="paint21_linear_94_1226585"
          x1="14.127"
          y1="7.5633"
          x2="31.0701"
          y2="31.3321"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00BE8E" />
          <stop offset="0.280919" stopColor="#00BE8E" />
          <stop offset="1" stopColor="#375DFB" />
        </linearGradient>
        <linearGradient
          id="paint22_linear_94_1226585"
          x1="14.127"
          y1="7.5633"
          x2="31.0701"
          y2="31.3321"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00BE8E" />
          <stop offset="0.280919" stopColor="#00BE8E" />
          <stop offset="1" stopColor="#375DFB" />
        </linearGradient>
        <linearGradient
          id="paint23_linear_94_1226585"
          x1="14.127"
          y1="7.5633"
          x2="31.0701"
          y2="31.3321"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00BE8E" />
          <stop offset="0.280919" stopColor="#00BE8E" />
          <stop offset="1" stopColor="#375DFB" />
        </linearGradient>
        <linearGradient
          id="paint24_linear_94_1226585"
          x1="14.127"
          y1="7.5633"
          x2="31.0701"
          y2="31.3321"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00BE8E" />
          <stop offset="0.280919" stopColor="#00BE8E" />
          <stop offset="1" stopColor="#375DFB" />
        </linearGradient>
        <linearGradient
          id="paint25_linear_94_1226585"
          x1="14.127"
          y1="7.5633"
          x2="31.0701"
          y2="31.3321"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00BE8E" />
          <stop offset="0.280919" stopColor="#00BE8E" />
          <stop offset="1" stopColor="#375DFB" />
        </linearGradient>
        <linearGradient
          id="paint26_linear_94_1226585"
          x1="14.127"
          y1="7.5633"
          x2="31.0701"
          y2="31.3321"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00BE8E" />
          <stop offset="0.280919" stopColor="#00BE8E" />
          <stop offset="1" stopColor="#375DFB" />
        </linearGradient>
      </defs>
    </svg>
  );
}
