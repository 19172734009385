import { Schema } from './FeatureGrid.schema';
import { Icons } from '../../snippets/Icons';

import { Link } from '../../snippets/Link';
import { Section } from '../../snippets';

export function FeatureGrid({ cms }) {
  const { description, heading, items, gridColumns } = cms;
  let columnsCss = 'md:grid-cols-3';
  switch (gridColumns) {
    case '1':
      columnsCss = 'md:grid-cols-1';
      break;
    case '2':
      columnsCss = 'md:grid-cols-2';
      break;
    case '3':
      columnsCss = 'md:grid-cols-3';
      break;
    case '4':
      columnsCss = 'md:grid-cols-4';
      break;
    default:
      columnsCss = 'md:grid-cols-3';
  }
  return (
    <Section cms={cms}>
      <div className="md:px-4 md:py-24">
        <div className="black-to-green-gradient px-4 py-16 md:mx-auto md:max-w-7xl md:rounded-b-[32px] md:p-16 md:pt-0">
          {heading ? (
            <h2 className="mb-3 px-4 text-center selection:bg-brand md:mx-auto md:mb-4 md:max-w-3xl md:px-0">
              {heading}
            </h2>
          ) : null}
          {description ? (
            <p className="px-4 text-center text-base selection:bg-brand selection:text-white md:mx-auto md:max-w-3xl md:px-0 md:text-xl md:text-white-700">
              {description}
            </p>
          ) : null}
          {items?.length > 0 ? (
            <div className={`${columnsCss} mt-14 grid gap-8 md:mt-16`}>
              {items.map((item, index) => {
                return (
                  <div
                    key={`feature-grid-item-${index}`}
                    className="flex flex-col items-center md:items-start"
                  >
                    {item.icon ? (
                      <div className="badge-gradient mb-4 flex h-12 w-12 items-center justify-center rounded-xl">
                        <Icons
                          icon={item.icon}
                          className="h-7 w-7 text-brand"
                        />
                      </div>
                    ) : null}
                    {item.heading ? (
                      <h4 className="mb-2 text-center text-xl md:text-left">
                        {item.heading}
                      </h4>
                    ) : null}
                    {item.description ? (
                      <p className="mb-2 text-center text-sm text-white-700 md:text-left">
                        {item.description}
                      </p>
                    ) : null}
                    {item.link?.url ? (
                      <Link
                        className="text-link group mt-auto inline-flex items-center gap-2"
                        href={item.link.url}
                        newTab={item.link?.newTab}
                        passHref
                      >
                        {item.link.text}
                        <Icons icon="arrowRight" />
                      </Link>
                    ) : null}
                  </div>
                );
              })}
            </div>
          ) : null}
        </div>
      </div>
    </Section>
  );
}

FeatureGrid.displayName = 'FeatureGrid';
FeatureGrid.Schema = Schema;
