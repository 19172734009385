import { IconSettings } from '../../settings/icons';

export function Schema() {
  return {
    label: 'Feature Half Hero',
    key: 'feature-half-hero',
    fields: [
      {
        label: 'Heading',
        name: 'heading',
        component: 'text',
        defaultValue: 'Feature Half Hero Heading',
      },
      {
        label: 'Description',
        name: 'description',
        component: 'textarea',
        defaultValue:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras ullamcorper semper purus, vel consectetur massa vestibulum vel.',
      },
      {
        label: 'Badge Label',
        name: 'badgeLabel',
        component: 'text',
        defaultValue: 'Feature Label',
      },
      IconSettings,
      {
        label: 'Features',
        name: 'features',
        component: 'group-list',
        fields: [
          {
            label: 'Label',
            name: 'label',
            component: 'text',
          },
        ],
        itemProps: {
          label: '{{item.label}}',
        },
        defaultItem: {
          label: 'Feature Item',
        },
        defaultValue: [
          {
            label: 'Feature Item 1',
          },
          {
            label: 'Feature Item 2',
          },
          {
            label: 'Feature Item 3',
          },
        ],
      },

      {
        label: 'Schedule Link',
        name: 'scheduleLink',
        component: 'group',
        fields: [
          {
            label: 'Cal.com ID',
            name: 'calId',
            description: 'Add a Cal.com ID for this button to open a cal modal',
            component: 'text',
          },
          {
            label: 'Button Label',
            name: 'buttonLabel',
            description: 'Label for the button',
            component: 'text',
          },
        ],
      },
      {
        label: 'Hubspot ID',
        name: 'formId',
        component: 'text',
      },
      {
        label: 'Hubspot Button Label',
        name: 'buttonLabel',
        component: 'text',
        defaultValue: 'Get Started',
      },
      {
        label: 'Form Submission Redirect',
        name: 'formRedirect',
        component: 'text',
        description: 'URL to page. Leave blank to not redirect.',
      },
      {
        component: 'toggle',
        name: 'includeEmail',
        label: 'Include Email In Redirect',
        toggleLabels: {
            true: 'Yes',
            false: 'No',
        },
        defaultValue: false,
      },
      {
        label: 'Image',
        name: 'image',
        component: 'image',
      },
      {
        label: 'Image (Desktop)',
        name: 'imageDesktop',
        component: 'image',
      },
      {
        label: 'Image Alt',
        name: 'imageAlt',
        component: 'text',
      },
    ],
  };
}
