export function Schema() {
  return {
    label: 'Plan Hero',
    key: 'plan-hero',
    fields: [
      {
        label: 'Heading',
        name: 'heading',
        component: 'text',
        defaultValue: 'Pricing',
      },
      {
        label: 'Subheading',
        name: 'subheading',
        component: 'textarea',
        defaultValue: 'Unlock your blazing-fast Hydrogen storefront.',
      },
      {
        label: 'Is Beta?',
        name: 'isBeta',
        component: 'toggle',
        defaultValue: false,
      },
      {
        label: 'Plans',
        name: 'plans',
        component: 'group-list',
        itemProps: {
          label: '{{item.heading}}',
        },
        fields: [
          {
            label: 'Enable Border',
            name: 'enableBorder',
            component: 'toggle',
            defaultValue: false,
          },
          {
            label: 'Border Text',
            name: 'borderText',
            component: 'text',
          },
          {
            label: 'Heading',
            name: 'heading',
            component: 'text',
            defaultValue: 'Pro',
          },
          {
            label: 'Subheading',
            name: 'subheading',
            component: 'text',
            defaultValue:
              'Boost usage, support, and tap into enhanced content features.',
          },
          {
            label: 'Price',
            name: 'price',
            component: 'text',
            defaultValue: '$475',
          },
          {
            label: 'Unit',
            name: 'unit',
            component: 'text',
            description: 'Optional',
            defaultValue: 'month',
          },
          {
            label: 'Label',
            name: 'label',
            component: 'text',
            defaultValue: 'Usage-based plan',
          },
          {
            label: 'Features',
            name: 'features',
            component: 'group',
            fields: [
              {
                label: 'Items',
                name: 'items',
                component: 'group-list',
                itemProps: {
                  label: '{{item.text}}',
                },
                fields: [
                  {
                    label: 'Text',
                    name: 'text',
                    component: 'text',
                  },
                  {
                    label: 'Description',
                    name: 'description',
                    component: 'text',
                  },
                ],
              },
            ],
          },
          {
            label: 'Hightlights',
            name: 'hightlights',
            component: 'group',
            fields: [
              {
                label: 'Heading',
                name: 'heading',
                component: 'text',
                defaultValue: 'Pro',
              },
              {
                label: 'Items',
                name: 'items',
                component: 'group-list',
                itemProps: {
                  label: '{{item.text}}',
                },
                fields: [
                  {
                    label: 'Text',
                    name: 'text',
                    component: 'text',
                  },
                  {
                    label: 'Is Beta?',
                    name: 'isBeta',
                    component: 'toggle',
                    defaultValue: false,
                  },
                  {
                    label: 'Is Coming Soon?',
                    name: 'isComingSoon',
                    component: 'toggle',
                    description: 'Overrides Beta Tag',
                    defaultValue: false,
                  },
                  {
                    label: 'Description',
                    name: 'description',
                    component: 'text',
                  },
                ],
              },
            ],
          },
          {
            label: 'Bonus Hightlights',
            name: 'bonusHightlights',
            component: 'group',
            fields: [
              {
                label: 'Heading',
                name: 'heading',
                component: 'text',
                defaultValue: 'Pro',
              },
              {
                label: 'Items',
                name: 'items',
                component: 'group-list',
                itemProps: {
                  label: '{{item.text}}',
                },
                fields: [
                  {
                    label: 'Text',
                    name: 'text',
                    component: 'text',
                  },
                  {
                    label: 'Is Beta?',
                    name: 'isBeta',
                    component: 'toggle',
                    defaultValue: false,
                  },
                  {
                    label: 'Is Coming Soon?',
                    name: 'isComingSoon',
                    component: 'toggle',
                    description: 'Overrides Beta Tag',
                    defaultValue: false,
                  },
                  {
                    label: 'Description',
                    name: 'description',
                    component: 'text',
                  },
                  {
                    label: 'Primary Disclaimer Text',
                    name: 'primaryDisclaimerText',
                    component: 'text',
                  },
                  {
                    label: 'Secondary Disclaimer Text',
                    name: 'secondaryDisclaimerText',
                    component: 'text',
                  },
                ],
              },
            ],
          },
          {
            label: 'Button',
            name: 'button',
            component: 'group',
            fields: [
              {
                label: 'Type',
                name: 'type',
                component: 'select',
                options: [
                  { label: 'Primary', value: 'btn-primary' },
                  { label: 'Outline', value: 'btn-outline' },
                ],
              },
              {
                label: 'Link',
                name: 'link',
                component: 'link',
              },
            ],
          },
        ],
        defaultValue: [
          {
            heading: 'Free',
            subheading:
              'Spin up a few landing pages with Pack’s visual headless CMS and dev tools.',
            price: '$0',
            unit: 'month',
            label: 'Get started for free',
            features: {
              items: [
                {
                  text: '100 content entries',
                },
                {
                  text: '50k monthly page views',
                },
                {
                  text: 'Primary content environment',
                },
              ],
            },
            hightlights: {
              heading: 'Launch a storefront with:',
              items: [
                {
                  text: 'Visual content editor',
                },
                {
                  text: 'Shopify product sync',
                },
                {
                  text: 'Hydrogen integration',
                },
                {
                  text: 'Metaobjects integration',
                },
                {
                  text: 'Remix & Oxygen support',
                },
                {
                  text: 'Starter repo & component library',
                },
                {
                  text: '3rd party integrations library',
                },
                {
                  text: 'GitHub development workflow',
                },
                {
                  text: 'Community support',
                },
              ],
            },
            button: {
              type: 'btn-primary',
              link: {
                text: 'Get started',
              },
            },
          },
          {
            heading: 'Pro',
            subheading:
              'Boost usage, support, and tap into enhanced content features.',
            price: '$475',
            unit: 'month',
            label: 'Usage-based plan',
            features: {
              items: [
                {
                  text: '100 content entries',
                },
                {
                  text: '50k monthly page views',
                },
                {
                  text: '+1 content environment',
                },
              ],
            },
            hightlights: {
              heading: 'Everything in Free, plus:',
              items: [
                {
                  text: 'Content scheduling',
                },
                {
                  text: 'Global edge caching',
                },
                {
                  text: '3-day content history',
                },
                {
                  text: 'Standard support',
                },
              ],
            },
            bonusHightlights: {
              heading: 'Pro add-ons:',
              items: [
                {
                  text: 'Additional content entries',
                  primaryDisclaimerText: '+$79/month',
                  secondaryDisclaimerText: "per 100 add'l entries",
                },
                {
                  text: 'Additional page views',
                  primaryDisclaimerText: '+$279/month',
                  secondaryDisclaimerText: "per 100k add'l views",
                },
                {
                  text: 'Additional content environments',
                  primaryDisclaimerText: '+$179/month',
                  secondaryDisclaimerText: "per 1 add'l environment",
                },
              ],
            },
            button: {
              type: 'btn-primary',
              link: {
                text: 'Get started',
              },
            },
          },
          {
            heading: 'Scale',
            subheading:
              'Customize your usage, access CRO tools, and get advanced support.',
            price: 'Custom',
            unit: '',
            label: 'Custom plan',
            features: {
              items: [
                {
                  text: 'Custom content entries',
                },
                {
                  text: 'Custom page views',
                },
                {
                  text: 'Custom content environments',
                },
              ],
            },
            hightlights: {
              heading: 'Everything in Pro, plus:',
              items: [
                {
                  text: 'A/B testing',
                  isBeta: true,
                },
                {
                  text: 'AI code generation',
                  isBeta: true,
                },
                {
                  text: 'Multiple hydrogen storefronts',
                },
                {
                  text: 'Next.js support',
                },
                {
                  text: 'Multiple content environments',
                },
                {
                  text: 'Custom content history',
                },
                {
                  text: 'Multiple content schedules',
                },
                {
                  text: 'Priority support',
                },
                {
                  text: 'Content API',
                },
                {
                  text: 'Bring-your-own cloud (image CDN, Netlify, etc.)',
                },
              ],
            },
            button: {
              type: 'btn-primary',
              link: {
                text: 'Contact us',
              },
            },
          },
        ],
      },
    ],
  };
}
