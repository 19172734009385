import { Schema } from './Html.schema';

import { Section } from '../../snippets';

export function Html({ cms }) {
  return (
    <Section cms={cms}>
      <div
        className="html"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: cms.html }}
      />
    </Section>
  );
}

Html.displayName = 'Html';
Html.Schema = Schema;
