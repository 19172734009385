export function InfoEmpty(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="18"
      viewBox="0 0 16 18"
      fill="none"
      {...props}
    >
      <path
        d="M7.99903 15.667C4.31703 15.667 1.33203 12.682 1.33203 9.00001C1.33203 5.31801 4.31703 2.33301 7.99903 2.33301C11.681 2.33301 14.666 5.31801 14.666 9.00001C14.666 12.682 11.681 15.667 7.99903 15.667ZM7.99903 14.333C10.945 14.333 13.332 11.946 13.332 9.00001C13.332 6.05401 10.945 3.66701 7.99903 3.66701C5.05303 3.66701 2.66603 6.05401 2.66603 9.00001C2.66603 11.946 5.05303 14.333 7.99903 14.333ZM7.33203 5.66701H8.66603V7.00001H7.33203V5.66701ZM7.33203 8.33301H8.66603V12.333H7.33203V8.33301Z"
        fill="currentColor"
      />
    </svg>
  );
}
