export function GreenCircleCheckmark(props) {
  return (
    <svg
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="Check">
        <rect x="0.666748" width="18" height="18" rx="9" fill="#00BE8E" />
        <path
          id="Vector"
          d="M5.86792 9.5L8.36792 12L13.8679 6"
          stroke="black"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}
