export function BarsXAxis(props) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M18.0555 16.1113H1.94442C1.48442 16.1113 1.11108 16.4847 1.11108 16.9447C1.11108 17.4047 1.48442 17.778 1.94442 17.778H18.0555C18.5155 17.778 18.8889 17.4047 18.8889 16.9447C18.8889 16.4847 18.5155 16.1113 18.0555 16.1113Z"
        fill="currentColor"
      />
      <path
        d="M10.2777 2.22217H9.72215C8.64827 2.22217 7.77771 3.09273 7.77771 4.16661V13.0555C7.77771 14.1294 8.64827 14.9999 9.72215 14.9999H10.2777C11.3516 14.9999 12.2222 14.1294 12.2222 13.0555V4.16661C12.2222 3.09273 11.3516 2.22217 10.2777 2.22217Z"
        fill="currentColor"
      />
      <path
        d="M4.72217 6.66699H4.16661C3.09273 6.66699 2.22217 7.53755 2.22217 8.61144V13.0559C2.22217 14.1298 3.09273 15.0003 4.16661 15.0003H4.72217C5.79606 15.0003 6.66661 14.1298 6.66661 13.0559V8.61144C6.66661 7.53755 5.79606 6.66699 4.72217 6.66699Z"
        fill="currentColor"
      />
      <path
        d="M15.8334 10H15.2778C14.2039 10 13.3334 10.8706 13.3334 11.9444V13.0556C13.3334 14.1294 14.2039 15 15.2778 15H15.8334C16.9073 15 17.7778 14.1294 17.7778 13.0556V11.9444C17.7778 10.8706 16.9073 10 15.8334 10Z"
        fill="currentColor"
      />
    </svg>
  );
}
