import { useGlobalContext } from '../contexts/globalContext';

export function Modal() {
  const {
    state: { modal },
  } = useGlobalContext();

  const { className, ...props } = { ...modal.props };

  return modal.children ? (
    <div
      className={`bg-background fixed top-1/2 left-1/2 z-50 w-[calc(100%-2rem)] max-w-[1200px] -translate-x-1/2 -translate-y-1/2 overflow-hidden ${className}`}
      {...props}
    >
      <div className="scrollbar-hide px-contained py-contained max-h-[calc(var(--viewport-height)-2rem)] overflow-y-auto">
        {modal.children}
      </div>
    </div>
  ) : null;
}

Modal.displayName = 'Modal';
