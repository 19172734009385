export function Schema({ blog }) {
  if (!blog) return null;
  return {
    label: 'Blog Grid',
    key: 'blog-grid',
    fields: [
      {
        label: 'Heading',
        name: 'heading',
        component: 'text',
      },
      {
        label: 'Link to article?',
        name: 'linkEnabled',
        component: 'toggle',
        defaultValue: true,
      },
    ],
  };
}
