export function Schema() {
  return {
    label: 'Feature Gallery',
    key: 'feature-gallery',
    fields: [
      {
        label: 'Heading',
        name: 'heading',
        component: 'text',
        defaultValue: 'Feature Gallery Heading',
      },
      {
        label: 'Features',
        name: 'features',
        component: 'group-list',
        maxItems: 4,
        itemProps: {
          label: '{{item.label}}',
        },
        defaultValue: [
          {
            value: 'Value',
            label: 'Feature Label',
            description: 'Feature Description',
            profileName: 'Profile Name',
            profileDescription: 'Profile Description',
          },
          {
            value: 'Value',
            label: 'Feature Label',
            description: 'Feature Description',
            profileName: 'Profile Name',
            profileDescription: 'Profile Description',
          },
        ],
        defaultItem: {
          value: 'Value',
          label: 'Feature Label',
          description: 'Feature Description',
          profileName: 'Profile Name',
          profileDescription: 'Profile Description',
        },
        fields: [
          {
            label: 'Logo',
            name: 'logo',
            component: 'image',
          },
          {
            label: 'Value',
            name: 'value',
            component: 'text',
          },
          {
            label: 'Label',
            name: 'label',
            component: 'text',
          },
          {
            label: 'Description',
            name: 'description',
            component: 'textarea',
          },
          {
            label: 'Profile Image',
            name: 'profileImage',
            component: 'image',
          },
          {
            label: 'Profile Name',
            name: 'profileName',
            component: 'text',
          },
          {
            label: 'Profile Description',
            name: 'profileDescription',
            component: 'text',
          },
          {
            label: 'Desktop Image',
            name: 'imageDesktop',
            component: 'image',
          },
          {
            label: 'Mobile Image',
            name: 'imageMobile',
            component: 'image',
          },
        ],
      },
    ],
  };
}
