export function Vial(props) {
  return (
    <svg
      width="32"
      height="33"
      viewBox="0 0 32 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="Frame">
        <g id="Group">
          <path
            id="Vector"
            d="M26.7462 25.2343L20.444 12.9623V5.2841H21.7773C22.5133 5.2841 23.1107 4.68677 23.1107 3.95077C23.1107 3.21477 22.5133 2.61743 21.7773 2.61743H10.2218C9.48576 2.61743 8.88843 3.21477 8.88843 3.95077C8.88843 4.68677 9.48576 5.2841 10.2218 5.2841H11.5551V12.9623L5.25287 25.2343C4.6111 26.4841 4.66443 27.9454 5.39687 29.1454C6.12932 30.3454 7.40576 31.0619 8.81021 31.0619H23.1871C24.5933 31.0619 25.8698 30.3454 26.6004 29.1454C27.3329 27.9454 27.3862 26.4841 26.7444 25.2343H26.7462ZM14.0742 13.8939C14.172 13.7054 14.2218 13.4957 14.2218 13.2841V5.2841H17.7773V13.2841C17.7773 13.4957 17.8271 13.7054 17.9249 13.8939L20.8067 19.5063H11.1924L14.0742 13.8939Z"
            fill="currentColor"
          />
        </g>
      </g>
    </svg>
  );
}
