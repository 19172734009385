import { useState, useEffect } from 'react';
import { Schema } from './ComparePlans.schema';

import { ComparePlansHeader } from './ComparePlansHeader';
import { ComparePlansRow } from './ComparePlansRow';
import { Section } from '../../snippets';

export function ComparePlans({ cms }) {
  const { heading, subheading, plans } = cms;

  const { headers, rows } = { ...plans };

  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  return (
    <Section cms={cms}>
      <div className="mx-auto max-w-7xl py-16 px-4">
        {/* Heading */}
        {heading && (
          <h2 className="h2 mx-auto my-4 max-w-3xl px-4 text-center max-md:text-[44px] md:px-0">
            {heading}
          </h2>
        )}
        {subheading && (
          <p className="sub-heading text-center text-white-700">{subheading}</p>
        )}

        {mounted && (
          <div className="mx-auto w-full overflow-auto">
            <table className="w-full min-w-[700px] table-fixed">
              <ComparePlansHeader headers={headers} />
              <tbody>
                <ComparePlansRow rows={rows} />
              </tbody>
              <ComparePlansHeader headers={headers} isFooter />
            </table>
          </div>
        )}
      </div>
    </Section>
  );
}

ComparePlans.displayName = 'ComparePlans';
ComparePlans.Schema = Schema;
